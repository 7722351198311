<template>
    <router-link v-if="to" :to="to" :class="classnames" >
        <slot></slot>
    </router-link>
    <span v-else :class="classnames">
        <slot></slot>
    </span>
</template>

<script>
export default {
    name: 'm-tab-label',

    props: {
        size: {
            type: String,
            default: '32'
        },
        to: {
            type: String,
            default: ''
        },
        is_actived: {
            type: Boolean,
            default: false
        },
        is_disabled: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            classnames: ['m-tab-label'],
        }
    },

    mounted () {
        this.classnames.push('size-' + this.size);
        if (this.is_actived) this.classnames.push('actived');
        if (this.is_disabled) this.classnames.push('disabled');
    }
}
</script>

<style lang="scss">

    .m-tab-label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: content-box;
        font: var(--text-s-regular);
        color: var(--Text-default);
        transition: all 0.2s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        max-width: 100%;
        outline: none;
        cursor: pointer;
        position: relative;
        
        &.size {
            &-32 {
                height: 32px;
            }

            &-40 {
                height: 40px;
                font: var(--text-md-regular);
            }
        }

        svg {
            height: 20px;
            width: 20px;
            min-width: 20px;
            min-height: 20px;
            display: block;
            margin-right: 4px;
        }

        &:hover {
            color: var(--Text-primary);
        }

        &.router-link-exact-active,
        &.actived:hover,
        &.actived {
            color: var(--Text-primary);

            &::before {
                height: 3px;
                position: absolute;
                bottom: 0;
                border-radius: var(--radius-minimal);
                content: '';
                left: 0;
                width: 100%;
                background-color: var(--Border-primary);
            }
        }

        &.disabled:hover,
        &.disabled {
            pointer-events: none;
            color: var(--Text-disabled);
        }
    }

</style>
