export default function(category, action, label, noninteraction = false) {    
    console.log('GA EVENT | ' + category + ' |', action + ' |', label);

    if (typeof gtag === 'function') {
        if (noninteraction) {
            gtag('event', action, {
                'event_category' : category,
                'event_label' : label,
                'non_interaction' : true
            });
        } else {
            gtag('event', action, {
                'event_category' : category,
                'event_label' : label
            });
        }
    }
}