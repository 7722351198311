<template>
    <div :class="classnames">
        <div ref="inputs" class="ui-input-box">
            <CodeInput :key="refresh_cnt" :loading="false" :auto-focus="true" :field-height="44" :field-width="44" @change="onChange" @complete="onComplete" />
        </div>
    </div>
</template>

<script>
import CodeInput from 'vue-verification-code-input';

export default {
    name: 'm-verify-code',

    components: {
        CodeInput,
    },

    props: {
        is_settings: {
            type: Boolean,
            default: false
        },

        is_email_subscription: {
            type: Boolean,
            default: false
        },

        email_login_user_id: {
            type: Number,
            default: 0
        },

        email: {
            type: String,
            default: ''
        },

    },

    data() {
        return {
            the_styles: '',
            is_hover: false,
            verification_code: '',
            active_resend: false,
            tooltip_text: '',
            tooltip_bg_color: '',
            refresh_cnt: 0,
            display_error: false,
            time: 60,
            timer: null
        }
    },

    watch: {
    },

    computed: {
        classnames() {
            let arr = ['m-verify-code'];
            return arr;
        }
    },

    mounted() {
        this.start();
    },

    unmounted() {
        if (this.timer !== null) window.clearInterval(this.timer)
    },

    methods: {
        start() {
            if (this.is_settings && !this.email) window.location.replace('/me/settings/account-security');
        },

        onChange(v) {
            setTimeout(() => {
                this.$emit('changeInput', v);
            }, 10)

            if (v === '' && this.display_error) {
                this.$emit('validateCode', true)
            }
        },

        onComplete(v) {
            this.$emit('validateCode', v)
        },

    }


}
</script>

<style lang="scss">
.m-verify-code {
    position: relative;

    .react-code-input {
        display: flex;
        align-items: center;

        input {
            cursor: pointer;
            caret-color: transparent;
            margin-right: 6px;
            padding: 0 8px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font: var(--text-md-regular);
            border-radius: var(--radius-minimal) !important;

        }
    }

    .error-text {
        margin-top: 6px;
        color: var(--Text-danger);
        font: var(--text-s-light);
    }
}
</style>
