export default function (fn, callback, errback, timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 1000)
    interval = interval || 50;
  
    (function p() {
      // If the condition is met, we're done! 
      if(fn()) {
        callback()
      }
  
      // If the condition isn't met but the timeout hasn't elapsed, go again
      else if (Number(new Date()) < endTime) {
        setTimeout(p, interval)
      }
  
      // Didn't match and too much time, reject!
      else {
        if (typeof errback === 'function') errback(new Error('timed out for ' + fn + ': ' + arguments))
      }
    })()
  }
  