<template>
    <svg v-if="name" :class="className" xmlns="http://www.w3.org/2000/svg">
      <use :xlink:href="'#' + name + '--sprite'" xmlns:xlink="http://www.w3.org/1999/xlink"/>
      {{ getSvg }}
    </svg>
  </template>
  
  <script>
  export default {
    name: 'svg-icon',
  
    props: {
      name: {
        type: String,
        required: true
      }
    },
  
    computed: {
      getSvg() {
        
        let icon = require(`@/assets/${this.name}.svg?sprite`);
        if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
          icon = icon.default;
        }
      },
  
      className() {
        return 'svg-icon svg-icon--' + this.name;
      }
    },
  };
  </script>
  
  <style>
    .svg-icon {
      fill: currentColor;
      display: block;
    }
  </style>
  