<template>
    <div>
        <div class="edit-popup-menu" ref="share">
            <Modal class="edit-popup-menu--wrapper" v-if="share_open_value" :class="['type--' + type]" type="dialog"
                :title="show_title" @close="share_open_value = false" :key="show_title">
                <div class="body" v-if="type === 'edit-post'">
                    <div class="item-list" v-if="!$store.state.is_logged">
                        <Item @click.native="share">Share</Item>
                        <Item @click.native="follow">Follow User</Item>
                    </div>

                    <div class="item-list" v-else-if="$route.name === 'private-profile-social'">
                        <Item v-if="post.is_pin" @click.native="pinPost(false)">Unpin content</Item>
                        <Item v-else @click.native="show_pin_modal = true;">Pin content</Item>
                        <Item><router-link class="btn" :to="`/postdetails/${post.unique_key}`">View details</router-link>
                        </Item>
                        <Item @click.native="share">Share</Item>
                        <Item v-if="show_recreate_button" @click.native="createPost()">Repost to Motom</Item>
                        <Item @click.native="tagNewProducts">Tag product</Item>
                        <Item @click.native="sendEmail('Report product')">Report product</Item>
                        <Item @click.native="show_delete_modal = true">Delete</Item>
                        <Item @click.native="sendEmail('Something is wrong')">Something is wrong</Item>
                    </div>

                    <div class="item-list" v-else-if="$route.name === 'private-profile-me'">
                        <Item v-if="post.is_pin" @click.native="pinPost(false)">Unpin this post</Item>
                        <Item v-else @click.native="show_pin_modal = true;">Pin this post</Item>
                        <Item v-if="!post.post_scraped"><router-link class="btn"
                                :to="`/postdetails/${post.unique_key}`">View post details</router-link></Item>
                        <Item @click.native="share">Share</Item>
                        <Item v-if="show_recreate_button" @click.native="createPost()">Repost</Item>
                        <Item @click.native="editPost">Edit</Item>
                        <Item @click.native="sendEmail('Report post or product')">Report post or product</Item>
                        <Item @click.native="show_delete_modal = true">Delete</Item>
                        <Item @click.native="sendEmail('Something is wrong')">Something is wrong</Item>
                    </div>

                    <div class="item-list" v-else-if="$route.name === 'public-profile-posts' && is_me">
                        <Item @click.native="share">Share post</Item>
                        <Item v-if="show_recreate_button" @click.native="createPost()">Create post promoting this content
                        </Item>
                        <Item><router-link class="btn" :to="`/postdetails/${post.unique_key}`">View post
                                details</router-link></Item>
                        <Item @click.native="sendEmail('Report post or product')">Report post or product</Item>
                        <Item @click.native="sendEmail('Something is wrong')">Something is wrong</Item>
                    </div>

                    <div class="item-list" v-else-if="$route.name === 'public-profile-posts' && !is_me">
                        <Item v-if="!is_loaded_data">
                            <span class="btn">Loading</span>
                        </Item>
                        <Item v-else @click.native="follow">
                            <span v-if="is_followed" class="btn">Unfollow user</span>
                            <span v-else class="btn">Follow user</span>
                        </Item>
                        <Item @click.native="share">Share post</Item>
                        <Item v-if="show_recreate_button" @click.native="createPost()">Create post promoting this
                            content</Item>
                        <Item><router-link class="btn" :to="`/postdetails/${post.unique_key}`">View post
                                details</router-link></Item>
                        <Item @click.native="sendEmail('Report post or product')">Report post or product</Item>
                        <Item @click.native="sendEmail('Something is wrong')">Something is wrong</Item>
                    </div>

                    <div class="item-list" v-else-if="is_me">
                        <Item><router-link class="btn" :to="`/postdetails/${post.unique_key}`">View Motom
                                post</router-link></Item>
                        <Item @click.native="share">Share post</Item>
                        <Item v-if="!post.post_scraped" @click.native="editPost">Edit</Item>
                        <Item @click.native="show_delete_modal = true">Delete</Item>
                        <Item @click.native="createPost()">Repost</Item>
                        <Item @click.native="sendEmail('Report post or product')">Report post or product</Item>
                        <!-- v-if="post.tagged_products && post.tagged_products.length !== 0" -->
                        <Item @click.native="sendEmail('Something is wrong')">Something is wrong</Item>
                    </div>

                    <div class="item-list" v-else>
                        <Item v-if="!is_loaded_data">
                            <span class="btn">Loading</span>
                        </Item>
                        <Item v-else @click.native="follow">
                            <span v-if="is_followed" class="btn" >Unfollow user</span>
                            <span v-else class="btn" >Follow user</span>
                        </Item>
                        <Item @click.native="share">Share post</Item>
                        <Item @click.native="sendEmail('This content is suspicious or spam')">This content is suspicious
                            or spam</Item>
                        <Item v-if="post.tagged_products && post.tagged_products.length !== 0"
                            @click.native="sendEmail('Report a tagged product')">Report a tagged product</Item>
                    </div>
                </div>

                <div class="body" v-else-if="type === 'dislike-post' || type === 'dislike-product'">
                    <div class="select-include-products"
                        v-if="show_likeboards && type === 'dislike-post' && post.tagged_products && post.tagged_products.length !== 0">
                        <div class="ui-checkbox-item">
                            <label @click="is_include_products = !is_include_products">
                                <h4>Include all tagged products from post</h4>
                                <p>All 'likes' are saved to your private Default Collections automatically.</p>
                            </label>

                            <input id="include-all-products" type="checkbox" name="include-all-products" value="1"
                                v-model="is_include_products">
                            <span></span>
                        </div>
                    </div>

                    <div class="add-to-likeboards--list" v-if="show_likeboards">
                        <Loading v-if="is_loading" :classname="['static']" />
                        <div class="box" style="padding: 4px 12px;" v-else>
                            <!-- is_default -->
                            <!-- <div class="is-default" v-if="likeboards[0].is_default == 1">
                                    <Item type="likeboard"
                                        @click.native="select(likeboards[0], likeboards[0].is_liked_post)">
                                        <Layout :item="likeboards[0]" />
                                        <div class="add-to-info">
                                            <h3>All Liked</h3>
                                            <p v-if="likeboards[0].is_public">Default shopping list</p>
                                            <p v-if="!likeboards[0].is_public"><svg-icon name="private3" />Default shopping
                                                list</p>
                                        </div>
                                        <Checkbox :is_checked="(likeboards[0].is_liked_post && !remove_ids.includes(likeboards[0].id)) || (!likeboards[0].is_liked_post && add_ids.includes(likeboards[0].id))" :type="'radio'" />
                                    </Item>
                                </div> -->
                            <div class="like-list">
                                <Item type="likeboard" v-for="item in likeboards" :key="item.id"
                                    @click.native="select(item, item.is_liked_post)" v-show="item.is_default !== 1">
                                    <Layout :item="item" />
                                    <div class="add-to-info">
                                        <h3>{{ item.name }}</h3>
                                        <!-- <svg-icon name="view" />private2  -->
                                        <p v-if="item.is_public">Public</p>
                                        <p v-if="!item.is_public"><svg-icon name="private3" />Private</p>
                                        <!-- <p class="number">{{ item.likes_count ? item.likes_count : 0 }} Likes</p> -->
                                    </div>
                                    <Checkbox
                                        :is_checked="(item.is_liked_post && !remove_ids.includes(item.id)) || (!item.is_liked_post && add_ids.includes(item.id))"
                                        :type="'radio'" />
                                </Item>
                            </div>
                        </div>

                    </div>

                    <div class="item-list" v-else>
                        <Item v-if="is_my_likeboard" @click.native="show_unlike_this_modal = true">Remove from this
                            Collections</Item>
                        <Item @click.native="show_unlike_modal = true">Unlike from all Collections</Item>
                        <Item @click.native="show_likeboards = true">Add / remove from other Collections</Item>
                    </div>
                </div>

                <div class="body" v-else>
                    <slot></slot>
                </div>
                <footer v-if="(type === 'dislike-post' || type === 'dislike-product') && show_likeboards" class="flex-footer">
                    <m-button size="40" :key="1 + add_ids.length + remove_ids.length" type="secondary"
                        @click.native="openLikeboardInfo(0)"><svg-icon name="plus-bold" />Create</m-button>
                    <m-button size="40" :key="add_ids.length + remove_ids.length"
                        :is_disabled="add_ids.length === 0 && remove_ids.length === 0"
                        @click.native="unlikePost('select')">Confirm</m-button>
                </footer>

                <footer v-else>
                    <m-button size="40" type="secondary" :is_width="true" @click.native="close(false)">Cancel</m-button>
                </footer>

            </Modal>

            <Modal v-show="show_delete_modal" class="confirm-modal" @close="show_delete_modal = false" type="confirm"
                algin="center">
                <h2>Are you sure you want to delete this post?</h2>
                <p> Your content and tags will be removed</p>
                <footer>
                    <m-button type="tertiary" @click.native="show_delete_modal = false">Cancel</m-button>
                    <m-button @click.native="deletePost">Yes</m-button>
                </footer>
            </Modal>

            <Modal class="confirm-modal" v-show="show_unlike_modal" @close="show_unlike_modal = false" type="confirm"
                algin="center">
                <h2>Are you sure you want to remove from all Collections?</h2>
                <footer>
                    <m-button type="tertiary" @click.native="show_unlike_modal = false">
                        Cancel
                    </m-button>
                    <m-button @click.native="unlikePost('all')">
                        Yes
                    </m-button>
                </footer>
            </Modal>

            <Modal class="confirm-modal" v-show="show_unlike_this_modal" @close="show_unlike_this_modal = false"
                type="confirm" algin="center">
                <h2>Are you sure you want to remove from this Collections?</h2>
                <footer>
                    <m-button type="tertiary" @click.native="show_unlike_this_modal = false">
                        Cancel
                    </m-button>
                    <m-button @click.native="unlikePost('number', likeboard_id)">
                        Yes
                    </m-button>
                </footer>
            </Modal>


            <Modal class="confirm-modal" v-show="show_pin_modal" @close="show_pin_modal = false" type="confirm"
                algin="center">
                <h2>Pin this Post</h2>
                <p>When you pin a post, it will appear at the top of your Motom posts and replace the previously pinned
                    post.</p>
                <footer>
                    <m-button type="tertiary" @click.native="show_pin_modal = false;">Cancel</m-button>
                    <m-button @click.native="pinPost(true); show_pin_modal = false">Pin</m-button>
                </footer>
            </Modal>
        </div>

        <LikeboardInfoPopUp ref="edit_menu" @createLikeboard="createLikeboard" />
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';
import Pool from '@/utils/Pool';
import Modal from '@/components/Modal.vue';
import Layout from '@/components/likeboards/ImagesLayout.vue';
import LikeboardInfoPopUp from '@/components/LikeboardInfoPopUp.vue';
import Item from '@/components/ui/NewUi/ListItem.vue';
import Checkbox from '@/components/ui/NewUi/CheckOrRadio.vue';
import Loading from '@/components/Loading.vue';

export default {
    name: 'EditMenuPopup',

    components: {
        Modal,
        Layout,
        LikeboardInfoPopUp,
        Item,
        Checkbox,
        Loading,
    },

    data() {
        return {
            is_me: false,
            is_followed: false,
            is_loaded_data: false,
            is_my_likeboard: false,
            is_include_products: true,
            is_loading: false,
            show_recreate_button: false,
            show_delete_modal: false,
            show_unlike_modal: false,
            show_unlike_this_modal: false,
            // show_tooltip: false,
            show_likeboards: false,
            likeboards: [],
            default_likeboard_id: 0,
            add_ids: [],
            remove_ids: [],
            show_pin_modal: false,
            tooltip_text: '',
            tooltip_bg_color: '',
            is_oc_post: false,
            show_title: '',
            share_open_value: false,
        };
    },

    props: {
        post: Object,
        likeboard_id: Number,
        type: String,
        title: {
            type: String,
            default: ''
        }
    },

    watch: {
        show_likeboards: {
            handler: function () {
                if (this.show_likeboards) {
                    this.show_title = 'Add to / Remove from ';
                } else {
                    this.show_title = 'What would you like to do?';
                }
            }
        }
    },

    methods: {
        checkData() {
            this.show_likeboards = false;
            this.add_ids = [];
            this.remove_ids = [];
            this.likeboards = [];
            this.default_likeboard_id = 0;

            if (this.title) this.show_title = this.title;
            if (this.type === 'edit-post') {
                this.show_title = 'What would you like to do?';
                this.is_me = (this.post.post_author_id === Number(this.$store.state.user.id));
                this.show_recreate_button = (this.post.post_type === 'INSTAGRAM' || this.post.post_type === 'TIKTOK' || this.post.post_type === 'YOUTUBE');

                if (!this.is_me) {
                    this.$ajax.get('/api/user/' + this.post.post_author_id).then(res => {
                        this.is_loaded_data = true;
                        this.is_followed = res.data.profiles.is_followed;
                    });
                }
            } else if (this.type === 'dislike-post' || this.type === 'dislike-product') {

                this.show_title = 'What would you like to do?';

                if (typeof this.likeboard_id === 'number' && this.likeboard_id !== 0) {
                    this.is_my_likeboard = true;
                } else {
                    this.is_my_likeboard = false;
                }

                const patt = /post/i;
                const lbtype = this.type.match(patt) ? '4' : '3';
                const lbtargetID = this.type.match(patt) ? this.post.post_id : this.post.id;
                const lbapi = '/api/likeboards?type=' + lbtype + '&target_id=' + lbtargetID;

                this.is_loading = true;
                this.$ajax(lbapi, {
                    method: 'get',
                    withCredentials: true
                }).then(res => {
                    res.data.data.forEach(list => {
                        if (list.is_default) this.default_likeboard_id = list.id;
                        const timestamp = new Date(list.updated_at.replace(' ', 'T'));
                        list.timestamp = timestamp.getTime();
                        list.is_liked_post = list.target_in_the_likeboard;
                    });

                    const list = res.data.data;
                    list.sort((a, b) => b.timestamp - a.timestamp);
                    this.likeboards = list;
                    this.is_loading = false;
                });
            }
        },

        createPost() {
            let UserID = '';
            let PostID = this.post.unique_key;

            if (this.$store.state.is_logged) {
                UserID = window.user_id_alpha;
                let type = '';

                switch (this.post.post_type) {
                    case 'INSTAGRAM':
                        type = 'instagram';
                        break;

                    case 'YOUTUBE':
                        type = 'video';
                        break;

                    case 'TIKTOK':
                        type = 'tiktok';
                        break;
                }

                if (this.post.is_reposted) {
                    //edit
                    EventBus.$emit('open-repost-modal', this.post);
                    // const obj = {};
                    // obj.post = this.post;
                    // obj.is_edit_post = true;
                    // EventBus.$emit('edit-post', obj);
                } else {
                    this.$ajax.get('/api/post/get-my-post-from-media?resource_origin=' + type + '&link=' + this.post.social_post_url, {
                        method: 'get',
                        withCredentials: true,
                    }).then(res => {
                        if (res.data.status === 'success' && res.data.data.length !== 0) {
                            //edit
                            const obj = {};
                            obj.post = res.data.data;
                            obj.is_edit = false;
                            obj.is_edit_post = true;
                            EventBus.$emit('edit-post', obj);
                        } else {
                            console.log('create-post')
                            EventBus.$emit('create-post', {
                                type: type,
                                post: this.post
                            });
                        }
                    }).catch(err => {
                        // this.$router.push({ path: '/create-post/' + type });
                        setTimeout(() => {
                            //new
                            EventBus.$emit('create-post', {
                                type: type,
                                post: this.post
                            });
                        }, 500);
                    });
                    this.close();
                }
            } else {
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Ready to create a post?',
                    text: 'You’ll need an account to create post or simply log in. '
                });
            }

            this.GA_Tracking('Repost', 'Click', `${UserID},${PostID}`);
        },

        follow() {
            if (this.$store.state.is_logged) {
                let api = '/api/post/user/' + this.post.post_author_id + '/follow';

                let actionLabel = '';

                if (this.$route.name === 'postdetails') {
                    actionLabel = 'Post Detail-';
                } else {
                    actionLabel = 'Post-';
                }

                if (this.is_followed) {
                    api = '/api/post/user/' + this.post.post_author_id + '/unfollow';
                }

                this.$store.state.show_loading=true;
                this.$ajax(api, {
                    method: 'post',
                    withCredentials: true
                }).then(res => {
                    if (res.data.status === 'success') {
                        let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
                        label = label + ',' + this.post.post_author_unique_key + ',';
                        if (this.is_followed) {
                            this.GA_Tracking('Follow People', actionLabel + 'Unfollow', label);
                        } else {
                            this.GA_Tracking('Follow People', actionLabel + 'Follow', label);
                        }

                        this.is_followed = !this.is_followed;

                        EventBus.$emit('follow-user', {
                            user_id: this.post.post_author_id,
                            is_followed: this.is_followed
                        });

                        // Removed all cache
                        this.$ajax.cache.store = {};
                    }
                    this.$store.state.show_loading=false;
                })
                    .catch(err => {
                        if (err.response.data.message === 'Unauthenticated.') {
                            this.$store.commit('openLogin', {
                                is_show: true,
                                title: '',
                                text: ''
                            });
                        } else {
                            console.log(err.response.data.data.message);
                        }
                        this.$store.state.show_loading=false;
                    });
            } else {
                console.log('login')
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Like & collect',
                    text: "You’ll need an account to add what you like to a Collections or simply log in. "
                });
            }


        },

        editPost() {
            const obj = {};
            obj.post = this.post;
            obj.is_edit_post = true;
            EventBus.$emit('edit-post', obj);
            this.close();
        },

        tagNewProducts() {
            const obj = {};
            obj.post = this.post;
            EventBus.$emit('tag-new-products', obj);
        },

        deletePost() {
            this.show_delete_modal = false;
            this.$store.state.show_loading = true;
            this.$ajax(`/user/post/${this.post.post_id}/delete`, {
                method: 'post',
                withCredentials: true
            }).then(res => {
                this.$store.state.show_loading = false;

                if (res.data.status === 'success') {
                    const el = document.querySelector('#js-post--' + this.post.post_id);
                    if (el !== null) el.style.display = 'none';

                    this.close();

                    // Removed all cache
                    this.$ajax.cache.store = {};
                }
            })
                .catch(this.err);
        },

        select(item, type) {
            if (type) {
                console.log('to remove');

                if (this.remove_ids.includes(item.id)) {
                    for (var i = 0; i < this.remove_ids.length; i++) {
                        if (this.remove_ids[i] === item.id) {
                            this.remove_ids.splice(i, 1);
                        }
                    }
                } else {
                    this.remove_ids.push(item.id);
                }
            } else {
                console.log('to add');

                if (this.add_ids.includes(item.id)) {
                    console.log('conatins');
                    for (let i = 0; i < this.add_ids.length; i++) {
                        if (this.add_ids[i] === item.id) {
                            this.add_ids.splice(i, 1);
                        }
                    }
                } else {
                    this.add_ids.push(item.id);
                }
            }
        },

        unlikePost(type, id = false) {
            if (type === 'select' && this.add_ids.length === 0 && this.remove_ids.length === 0) return;
            this.show_unlike_modal = false;
            this.show_unlike_this_modal = false;

            Pool(
                () => {
                    return (this.likeboards.length !== 0);
                },
                () => {
                    this.add(type);
                    this.remove(type, id);
                }
            );
        },

        remove(type, id) {
            if (type === 'select' && this.remove_ids.length === 0) return;
            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            let event_name = 'UNLIKE';

            const form_data = new FormData();
            let is_unlike = false;
            let api = '/api/likeboards/dislike-post';

            switch (type) {
                case 'all':
                    is_unlike = true;
                    this.likeboards.forEach(likeboard => {
                        form_data.append('likebook_ids[]', likeboard.id);

                    });
                    break;
                case 'number':
                    if (id === this.default_likeboard_id) {
                        is_unlike = true;
                    }
                    form_data.append('likebook_ids[]', id);
                    break;
                case 'select':
                    this.remove_ids.forEach(id => {
                        form_data.append('likebook_ids[]', id);
                    });
                    break;
            }

            if (this.type === 'dislike-product') {
                let pid = this.post.id;

                api = '/api/likeboards/dislike-product';
                label = label + ',,,' + pid;

                if (type === 'number' || this.remove_ids.length === 1) {
                    this.tooltip_text = 'Removed from Liked Collections';//'Product removed from this Collection';
                } else {
                    this.tooltip_text = 'Removed from Liked Collections';//Product removed from Collections';
                }

                if (type === 'all') {
                    const els = document.querySelectorAll('.js-product-likeid-' + pid);
                    if (els.length !== 0) {
                        els.forEach(el => {
                            el.classList.remove('is-liked');
                        });
                    }

                    const ps = document.querySelectorAll('.js-bookmark-el-' + pid);
                    if (ps.length !== 0) {
                        ps.forEach(p => {
                            p.classList.remove('actived');
                        });
                    }
                }

                if (typeof this.post.parent_id !== 'undefined' && this.post.parent_id !== 0) pid = this.post.parent_id;
                form_data.append('target_id', pid);
            } else {
                label = label + ',' + this.post.unique_key + ',,';

                if (type === 'number' || this.remove_ids.length === 1) {
                    this.tooltip_text = 'Removed from Liked Collections'//'Post removed from this Collection';
                } else {
                    this.tooltip_text = 'Removed from Liked Collections'//'Post removed from Collections';
                }

                form_data.append('target_id', this.post.post_id);
            }

            this.tooltip_bg_color = 'black';

            this.$ajax(api, {
                method: 'post',
                data: form_data,
                withCredentials: true
            }).then(res => {
                if (res.data.status === 'success') {
                    if (this.type === 'dislike-product') {
                        if (is_unlike) {
                            const els = document.querySelectorAll('.js-product-likeid-' + this.post.id);
                            if (els.length !== 0) {
                                els.forEach(el => {
                                    el.classList.remove('is-liked');

                                });
                            }
                        }

                        if (this.is_my_likeboard) {
                            const el = document.querySelector('#js-product--' + this.post.id);
                            if (el !== null) el.style.display = 'none';
                        }
                    } else {
                        if (is_unlike) {
                            const els = document.querySelectorAll('.js-post-likeid-' + this.post.post_id);
                            if (els.length !== 0) {
                                els.forEach(el => {
                                    el.classList.remove('is-liked');
                                });
                            }
                        }

                        if (this.is_my_likeboard) {
                            const el = document.querySelector('#js-post--' + this.post.post_id);
                            if (el !== null) el.style.display = 'none';
                        }
                    }

                    this.GA_Tracking(event_name, 'CLICK', label);
                    this.close();
                    this.$ajax.cache.store = {};

                    if (this.$route.name === 'public-likeboard') {
                        window.location.reload();
                    }

                    this.$notify({
                        text: this.tooltip_text,
                        type: this.tooltip_bg_color,
                        duration: 5000,
                    });
                }
            }).catch(this.err);
        },

        add(type) {
            const self = this;
            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            let event_name = 'LIKE';

            if (self.add_ids.length === 0) return;
            // console.log('like', self.add_ids)

            const form_data = new FormData();
            const only_add = self.add_ids.length !== 0 && self.remove_ids.length === 0;
            let api = '/api/likeboards/like-post';

            self.add_ids.forEach(id => {
                form_data.append('likebook_ids[]', id);
            });

            form_data.append('target_user_id', self.$store.state.user.id);

            if (self.type === 'dislike-product') {
                api = '/api/likeboards/like-product';
                let pid = this.post.id;

                if (typeof this.post.parent_id !== 'undefined' && this.post.parent_id !== 0) pid = this.post.parent_id;
                label = label + ',,,' + pid;
                form_data.append('target_id', pid);
            } else {
                if (this.is_include_products && this.post.tagged_products && this.post.tagged_products.length !== 0) {
                    this.post.tagged_products.forEach(product => {
                        let pid = product.id;
                        if (typeof product.parent_id !== 'undefined' && product.parent_id !== 0) pid = product.parent_id;
                        form_data.append('sub_target_ids[]', pid);
                    });
                }
                label = label + ',' + self.post.unique_key + ',,';
                form_data.append('target_id', self.post.post_id);
            }

            self.$ajax(api, {
                method: 'post',
                data: form_data,
                withCredentials: true
            }).then(res => {
                if (res.data.status === 'success' && only_add) {
                    if (self.add_ids.length === 1) {
                        self.tooltip_text = '<strong>Added to Collections!</strong>';
                    } else {
                        self.tooltip_text = '<strong>Added to Collections!</strong>';
                    }

                    self.tooltip_bg_color = 'primary';

                    self.close();

                    // Removed all cache
                    self.$ajax.cache.store = {};

                    this.$notify({
                        text: this.tooltip_text,
                        type: this.tooltip_bg_color,
                        duration: 5000,
                    });

                    this.GA_Tracking(event_name, 'CLICK', label);

                    // Facebook Tracking: Add Wishlist: save to custom likeboard for products, always fire event
                    self.fb_add_wishlist_function();

                    // Pinterest Tracking: Leads: save to custom likeboard for products, always fire event
                    self.pinterest_leads_function();
                }

                if (this.$route.name === 'public-likeboard') {
                    window.location.reload();
                }
            })
                .catch(self.err);
        },

        // createdLikeboard(book) {
        //     this.likeboards.unshift(book);
        // },

        openLikeboardInfo(info_type) {
            this.$refs.edit_menu.open(info_type);
        },

        createLikeboard(likeboard) {
            this.likeboards.splice(0, 0, likeboard);
            this.select(this.likeboards[0], this.likeboards[0].is_liked_post);
        },

        fb_add_wishlist_function() {
            const self = this;

            if (self.type === 'dislike-post') {
                self.post.tagged_products.forEach(product => {
                    const fbTracking_Obj = {
                        content_name: product.brand.name,
                        contents: [{ id: product.id, quantity: 1, category: 'Custom' }],
                        content_ids: product.id,
                        content_category: product.google_category,
                        currency: product.currency === '' ? 'USD' : product.currency
                    };
                    const evtID = product.id + '-' + Date.now();
                    self.FB_Tracking('AddToWishlist', fbTracking_Obj, { eventID: evtID });

                    self.$ajax('/api/facebook/conversion/send-event', {
                        method: 'post',
                        withCredentials: true,
                        data: {
                            event_name: 'AddToWishlist',
                            event_source_url: window.location.href,
                            event_id: evtID,
                            client_http_agent: window.navigator.userAgent,
                            custom_data: fbTracking_Obj
                        }
                    });
                    // .then(res => {
                    //     // Removed all cache
                    //     self.$ajax.cache.store = {};
                    // })
                    //     .catch(error => {
                    //         if (error.response.data.status === 'fail') {
                    //             alert(error.response.data.data.message);
                    //         }
                    //     });
                });
            } else {
                const product = self.post;

                const fbTracking_Obj = {
                    content_name: product.brand.name,
                    contents: [{ id: product.id, quantity: 1, category: 'Custom' }],
                    content_ids: product.id,
                    content_category: product.google_category,
                    currency: product.currency === '' ? 'USD' : product.currency
                };
                const evtID = product.id + '-' + Date.now();
                self.FB_Tracking('AddToWishlist', fbTracking_Obj, { eventID: evtID });

                self.$ajax('/api/facebook/conversion/send-event', {
                    method: 'post',
                    withCredentials: true,
                    data: {
                        event_name: 'AddToWishlist',
                        event_source_url: window.location.href,
                        event_id: evtID,
                        client_http_agent: window.navigator.userAgent,
                        custom_data: fbTracking_Obj
                    }
                });
                // .then(res => {
                //     // Removed all cache
                //     self.$ajax.cache.store = {};
                // })
                //     .catch(error => {
                //         if (error.response.data.status === 'fail') {
                //             alert(error.response.data.data.message);
                //         }
                //     });
            }
        },

        pinterest_leads_function() {
            const self = this;

            if (self.type === 'dislike-post') {
                self.post.tagged_products.forEach(product => {
                    const pinterestTracking_Obj = {
                        product_ids: [product.id + ''],
                        product_name: product.name,
                        product_category: product.google_category,
                        product_brand: product.brand.name,
                        lead_type: 'Custom'
                    };
                    self.Pinterest_Tracking('lead', pinterestTracking_Obj);
                });
            } else {
                const product = self.post;

                const pinterestTracking_Obj = {
                    product_ids: [product.id + ''],
                    product_name: product.name,
                    product_category: product.google_category,
                    product_brand: product.brand.name,
                    lead_type: 'Custom'
                };
                self.Pinterest_Tracking('lead', pinterestTracking_Obj);
            }
        },

        open() {
            this.is_loaded_data = false;
            this.is_include_products = true;

            setTimeout(() => {
                this.share_open_value = true;
                this.checkData();
            }, 10);

        },

        close(is_click_button_close) {
            const self = this;
            setTimeout(() => {
                self.$emit('closeCallback');

                if (is_click_button_close) {
                    self.$emit('callback');
                }
            }, 300);

            this.share_open_value = false;
        },

        share() {
            const obj = {};
            this.close();

            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            label = label + ',' + this.post.unique_key + ',,';
            this.GA_Tracking('SHARE', 'CLICK', label);

            obj.description = this.post.post_caption;
            obj.shareURL = `${window.location.origin}/postdetails/${this.post.unique_key}`;
            obj.postType = this.post.post_type;
            obj.share_post = this.post;

            EventBus.$emit('open-post-share', obj);
        },

        sendEmail(title) {
            // help@motom.me
            // console.log(this.post.post_id)
            const body1 = `The post id is ${this.post.post_id}`;
            const body2 = `The author is ${this.post.post_author_name} (user id ${this.post.post_author_id}) `;
            window.location.assign('mailto:help@motom.me?Subject=' + encodeURIComponent(title) + '&body=' + encodeURIComponent(body1) + '%0D%0A' + body2);
        },

        err(err) {
            this.$store.state.show_loading = false;
            if (err.response.data.message === 'Unauthenticated.') {
                this.$store.commit('openLogin', {
                    is_show: true,
                    title: '',
                    text: ''
                });
            } else {
                console.log(err.response.data.data.message);
            }
        },

        pinPost(is_pin) {
            const url = is_pin ? '/api/pin' : '/api/unpin';

            this.$ajax(url, {
                method: 'post',
                withCredentials: true,
                data: {
                    item_id: this.post.post_id,
                    item_type: this.$route.name == 'private-profile-social' ? 0 : 1
                }
            }).then(res => {
                this.$set(this.post, 'is_pin', true);

                EventBus.$emit('upPinPost', {
                    post: this.post.post_id,
                    is_pin: is_pin,
                });
            }).catch(error => {
                console.log(error)
            })
            this.close();
        },
    },


};
</script>

<style lang="scss">
@import '../resources/sass/_variables.scss';

.remove-link {
    color: var(--Text-danger);
}

.confirm-modal {
    z-index: 10009;
}</style>