<template>
    <header  class="back-header">
        <div v-if="$route.name ==='earnings'" class="back-link">
            <span @click="backPage" style="width: 12px"><svg-icon :name="back_icon" /></span> <span @click="backPage" style="width: calc(100% - 100px); padding-left: 8px">{{back_text}} </span><span style="width: 9rem; font-size: var(--font-s);"><a href="mailto:help@motom.me" target="_blank">Contact Us</a></span>
        </div>
        <div v-else class="back-link">
            <!-- {{ $route.name }} -->
            <span @click="backPage"><svg-icon :name="back_icon" /> {{back_text}}</span>
        </div>
        <slot></slot>
        <div v-if="$route.name === 'productdetails'" class="ico" @click="shareProduct()"><i><svg-icon class="ico-share" name="share" /></i></div>
        <m-button v-if="show_right_btn" :is_disabled="!actived_right_btn" @click.native="gotoNext">{{ $route.params.type === 'profile-set'? 'Save' : 'Next' }}</m-button>
        <m-button v-if="show_save_btn" :is_disabled="!actived_save_btn" @click.native="$emit('save')">Save</m-button>
    </header>
</template>

<script>
import { EventBus } from '@/events/bus.js';

export default {
    name: 'back-header',
    props: {
        back_url: {
            type: String,
            default: ''
        },
        back_text: {
            type: String,
            default: 'Back'
        },
        back_icon: {
            type: String,
            default: 'back'
        },
        back_type: {
            type: String,
            default: 'push'
        },
        show_right_btn: {
            type: Boolean,
            default: false
        },
        show_save_btn: {
            type: Boolean,
            default: false
        },
        actived_save_btn: {
            type: Boolean,
            default: false
        },
        actived_right_btn: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            title: this.$route.query.groupName ? this.$route.query.groupName : 'Just From Me',
        }
    },
    methods: {
        
        backPage() {

            if (this.back_url !== '') {
                switch (this.back_type) {
                    case 'replace':
                        this.$router.replace(this.back_url);
                        break;
                
                    case 'push':
                        this.$router.push(this.back_url);
                        break;
                }
                
            } else {
                // if (this.$route.name ==='settings-payment'){
                //     // p42-2342: requirements specifically overwrite back behavior in the tipalti payment to go to user setting page
                //     // let backCnt =  this.$store.state.payment_init_history - window.history.length - 1;
                //     // this.$router.go(backCnt);
                // } else 
                
                if ((this.$route.name === 'settings-linked-accounts' || this.$route.name === 'settings-account-privacy') && this.$route.query.payload) {
                    let history_index = window.localStorage.getItem('MOTOM_history_index');
                    if (history_index !== null) {
                        history_index = Number(history_index);
                        history_index = history_index - window.history.length;

                        if (isNaN(history_index)) {
                            this.$router.replace('/me/settings');
                        } else {
                            this.$router.go(history_index);
                        }

                    }
                }

                else if (this.$route.name === 'create-draft') {
                    // this.$cookie.set('is_clicked_by_me', '1', { expires: '10m' });
                    if (this.$cookie.get('is_clicked_by_me')) {
                        this.$router.replace('/me/posts');
                        this.$cookie.delete('is_clicked_by_me');
                    } else {
                        this.$router.replace('/');
                    }
                }

                else {
                    this.$router.go(-1);
                }
                    
            }
        },
        shareProduct() {
            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            label = label  + ',' + this.$route.params.id + ',,';
            this.GA_Tracking('SHARE', 'CLICK', label);

            let obj = {};
            obj.description = '';
            obj.shareURL = window.location.href;
            obj.postType = '';
            EventBus.$emit('open-post-share', obj);
        },
        gotoNext() {
            this.$emit('gotoNext');
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '../resources/sass/_variables.scss';

    .back-header {
        position: fixed;
        width: 100%;
        z-index: 99;
        background: var(--Bg-light);
        top: 40px;
        box-shadow: var(--bot-2dp);
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        color: var(--Text-primary);
        
        &.back-of-settings {
            position: sticky !important;
            top: 0;
        }

        &.back-of-invitation {
            top: 0;
            display: flex;
            align-items: center;
            background-color: var(--Text-brand);
            border-top: 2px solid var(--Brand-Primary);

            .back-link {
                svg {
                    fill: var(--Black-00);               
                }
            }
            
        }

        .back-link {
            display: flex;
            align-items: center;
            font: var(--sub-3);
            flex: 1;
            color: var(--Text-primary);

            span {
                padding-right: 10px;
                height: 16px;
                cursor: pointer;
                display: flex;
                align-items: center;
                text-transform: uppercase;
            }

            .title {
               display: inline-block;
               flex: 96;
               text-align:center;
               margin-left: -20px;
            }

            svg {
                height: 24px;
                width: 24px;
                fill: var(--Text-primary);
                margin-right: 10px;

                &.svg-icon--back {
                    height: 16px;
                    width: 16px;
                }
            }
        }

        .ico {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: var(--Text-default);
            font: var(--sub-6);

            i {
                display: block;
                height: 20px;
                width: 20px;
                margin-right: 5px;
            }
            
            svg {
                height: 100%;
                width: 100%;
                fill: var(--Text-default);

                &.active {
                    fill: var(--Brand-Primary);
                }

                &.ico-share {
                    fill: var(--Text-secondary);
                }
            }                        
        }

    }


</style>
