<template>
    <div class="share-popup-menu" ref="share">
        <div class="share-popup-menu--wrapper">
            <div class="share-popup-menu--container">
                <header class="share-popup-menu--header">
                    <h2 v-if="description !== ''" class="share-popup-menu--title" >{{title}}</h2>
                    <h2 v-else class="share-popup-menu--title" >Share to</h2>
                    <div class="share-popup-menu--skip" @click="close(true)">
                        <svg-icon name="close"/>
                    </div>
                </header>

                <footer :class="['share-popup-menu--footer', {'no-caption': description === ''}]">
                    <div class="icons">
                        <ul>
                            <li v-if="is_can_share">
                                <span @click="shareTo('share')" class="icon icon-link">
                                    <svg-icon name="shareto"/>
                                </span>
                                <span class="text">Share</span>
                            </li>
                            <li v-for="(item,key) in shareList" :key="key">
                                <span @click="handleShare(item.key)" :class="[`icon icon-${item.key}`]">
                                    <svg-icon :name="item.icon"/>
                                </span>
                                <span class="text">{{ item.text }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-wrap" v-if="is_show_share_button">
                        <m-button type="secondary" @click.native="openLinks">Get affiliate shortlink</m-button>
                    </div>
                </footer>
            </div>
            <div class="share-popup-menu--bg" @click="close(false)"></div>
        </div>
    </div>
        
</template>

<script>
import { EventBus } from '@/events/bus.js';
import copyToClipboard from 'copy-to-clipboard';
import { shareList } from '@/utils/SocialData.js';

export default {
    name: 'SharePopup',

    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        shareURL: {
            type: String,
            default: '',
        },
        postType: {
            type: String,
            default: '',
        },
        twitter_text: {
            type: String,
            default: '',
        },
        email_subject: {
            type: String,
            default: '',
        },
        email_body: {
            type: String,
            default: '',
        },
        is_show_share_button: {
            type: Boolean,
            default: false,
        },
        share_post: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            is_can_share: false,
            repostTypes: ['instagram','video','tiktok'],
            shareList:shareList,        
        }
    },
    components: {
    },

    methods: {
        handleShare(val){
            if(val === 'link'){
                this.copy(val);
            } else {
                this.shareTo(val);
            }
        },
        open() {
            let self = this;
            self.$refs.share.classList.add('open');

            setTimeout(() => {
                self.$refs.share.classList.add('open-bg');
            }, 10);

            setTimeout(() => {
                self.$refs.share.classList.add('open-container');
            }, 150);
        },

        close(is_click_button_close) {
            let self = this;
            
            if (self.$refs.share) self.$refs.share.classList.remove('open-container');
            
            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open-bg');
            }, 200);

            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open');
                self.$emit('closeCallback');

                if (is_click_button_close) {
                    self.$emit('callback');
                }
            }, 300);
        },

        shareTo(type) {
            let text = this.$refs.text ? this.$refs.text.innerHTML : '';
            // let link = this.shareURL;
            let link = this.removeDuplicateParams(this.shareURL);
            let share_url = '';

            if (this.$route.name === 'invitefriend') {
                text = this.description;
            }
            
            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            let sharelink = new URL(link);
            let shareType = sharelink.pathname.split("/")[1];
            let uniqueID = sharelink.pathname.split("/").pop();

            if (shareType.indexOf('likeboard')) {
                label = label  + ',,' + uniqueID + ',';
            }
            else {
                label = label  + ',' + uniqueID + ',,';
            }
            
            this.GA_Tracking('SHARE', 'CLICK', label);

            switch (type) {
                case 'share':
                    if (navigator.share) {
                        navigator.share({
                            title: this.email_subject,
                            text: this.email_body,
                            url: link
                        })
                    }
                    break;

                case 'email':
                    if (this.email_subject && this.email_body) {
                        share_url = 'mailto:?subject=' + this.email_subject + '&body='+ this.email_body;
                    } else {
                        share_url = 'mailto:?subject=' + text + '&body='+ text +' %0A%0A Check out this Motom Post at: ' + link;
                    }
                    
                    break;
                case 'twitter':
                    if (this.twitter_text) {
                        share_url = 'https://twitter.com/intent/tweet?url=&text=' + this.twitter_text + '&original_referer=' + link;
                    } else {
                        share_url = 'https://twitter.com/intent/tweet?url=&text=' + text + ' ' + link + '&original_referer=' + link;
                    }
                    break;
                case 'facebook':
                    share_url = 'http://www.facebook.com/sharer/sharer.php?u=' + link;
                    break;
                case 'pinterest':
                    share_url = 'http://pinterest.com/pin/create/link/?url=' + link;
                    break;
            }

            if (type !== 'share') window.open(share_url, '_blank');

        },

        removeDuplicateParams(url) {
            const urlObj = new URL(url);
            const params = new URLSearchParams(urlObj.search);

            const uniqueParams = new Map();
            for (const [key, value] of params.entries()) {
                uniqueParams.set(key, value);
            }

            urlObj.search = '';

            for (const [key, value] of uniqueParams.entries()) {
                urlObj.searchParams.append(key, value);
            }

            return urlObj.toString();
        },

        copy(type) {
            let text = this.$refs.text ? this.$refs.text.innerHTML : '';
            let link = this.removeDuplicateParams(this.shareURL);
            
            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            let sharelink = new URL(link);
            let shareType = sharelink.pathname.split('/')[1];
            let uniqueID = sharelink.pathname.split('/').pop();

            if (shareType.indexOf('likeboard')) {
                label = label  + ',,' + uniqueID + ',';
            } else {
                label = label  + ',' + uniqueID + ',,';
            }


            
            
            this.GA_Tracking('SHARE', 'CLICK', label);

            switch (type) {
                case 'text':
                    copyToClipboard(text,{format:'text/plain'});
                    break;
                case 'link':
                    copyToClipboard(link,{format:'text/plain'});
                    break;
            }

            this.close();
            this.$emit('copied', type);

        },

        openLinks() {
            this.close();
            EventBus.$emit('open-share-links');
        }
    },

    mounted() {
        if (navigator.share) this.is_can_share = true;
    },


}
</script>

<style lang="scss">
    @import '../resources/sass/_variables.scss';

    .share-popup-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999999;
        display: none;

        &--wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &--container {
            position: absolute;
            z-index: 9;
            overflow: hidden;
            left: 50%;
            bottom: 0;
            width: 100%;
            transition: transform .3s ease;
            transform: translate3d(-50%, 100%, 0);
            background: var(--Bg-light);
            border-radius:  16px 16px 0 0;
            max-width: 420px;
            box-shadow: var(--bot-1dp);
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(var(--Black-100-rgba), .8);
            opacity: 0;
            transition: opacity .2s ease;
        }

        &--header {
            padding: 0 8px 0 16px;
            display: flex;
            height: 48px;
            box-sizing: content-box;
            align-items: center;
            border-bottom: 1px solid var(--Black-20);
        }

        &--title {
            font: var(--heading-2);
            color: var(--Text-primary);
            flex: 1;
            text-transform: uppercase;
        }

        &--body {
            color: var(--Text-primary);
            background: var(--Bg-light);
            overflow: hidden;

            ul {
                display: flex;
                align-items: center;
                width: 100%;
                padding: var(--spacing-05);

                li:first-child {
                    flex: 1;
                    padding-right: 16px;
                }
            }

            .copy-text {
                padding: 0 16px;
                border-radius: 20px;
                background: var(--Black-10);
                color: var(--Text-secondary);
                // height: 40px;
                // line-height: 40px;
                font-weight: var(--font-regular);
                padding: var(--spacing-04);
            }

            .button {
                height: 40px;
                width: 8rem;
                cursor: pointer;
                line-height: 38px;
                border-radius: 20px;
                border: 1px solid var(--Black-75);
                color: var(--Text-secondary);
                text-align: center;
                font-weight: var(--font-regular);

                &:hover {
                    border-color: var(--Text-brand);
                    color: var(--Text-brand);
                }
            }


        }

        &--footer {
            padding: 16px 16px 16px 12px;

            .icons {
                overflow: auto;
                width: 100%;
            }

            ul {
                display: flex;
            }

            li {
                width: 100%;
            }

            .icon {
                height: 48px;
                width: 48px;
                position: relative;
                background: var(--Brand-Primary);
                border-radius: 50%;
                display: block;
                margin: 0 auto 5px auto;
                cursor: pointer;

                svg {
                    height: 22px;
                    width: 22px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    fill: var(--Black-00);
                }

                &-link {
                    background: var(--Brand-Primary);

                    svg {
                        fill: var(--Black-00);
                    }
                }

                &-email {
                    background: vvar(--Brand-Primary);
                }

                &-facebook {
                    background-color: var(--Facebook-new);

                    svg {
                        height: 24px;
                        width: 24px;
                        color: var(--Text-invert);
                    }
                }

                &-twitter {
                    background-color: var(--Twitter);
                }

                &-pinterest {
                    background-color: var(--Pinterest);
                }
            }

            .text {
                text-align: center;
                display: block;
                font-size: var(--font-xs);
                font-weight: var(--font-regular);
                margin: 2px 0 0;
                color: var(--Text-secondary);
                cursor: pointer;
            }

            .btn-wrap {
                padding: 24px 0 0;
                .m-button {
                    width: 100%;
                    padding: 0;
                }
            }
        }

        &--skip {        
            color: var(--Text-invert);

            svg {
                cursor: pointer;
                height: 32px;
                width: 32px;
                display: block;
                position: relative;
                right: -.4rem;
                fill: var(--Text-default);
            }

            &:hover svg {
                fill: var(--Brand-Primary);
            }
        }

        &.open {
            display: block;
        }

        &.open-container &--container {
            transform: translate3d(-50%, 0%, 0);
        }

        &.open-bg &--bg {
            opacity: 1;
        }
    }

    @media (min-width: $mobile_size) {
        .share-popup-menu {

            &--container {
                opacity: 0;
                transition: opacity .2s ease;
                transform: translate3d(-50%, -50%, 0);
                border-radius:  16px;
                right: auto !important;
                bottom: auto !important;
                left: 50%;
                top: 50%;
            }


            &.open-container &--container {
                transform: translate3d(-50%, -50%, 0);
            }

            &.open-bg &--container,
            &.open-bg &--bg {
                opacity: 1;
            }
        }
    }
</style>
