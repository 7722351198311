<template>
    <!-- not useing now -->
    <aside class="global-navi" v-if="is_show || $store.state.type !== 'mobile'" ref="navi">
        <div class="inner">
            <header v-if="$store.state.type === 'mobile'">
                <div class="logo">
                    <svg-icon name="logo-motom" />
                </div>
                <div class="invite-button" v-if="$store.state.is_logged">
                    <m-button size="24" type="primary" @click.native="hide()" to="/invitefriend">Invite</m-button>
                </div>
                <div class="close-ico" @click="hide()">
                    <span><svg-icon name="close-no-circle" /></span>
                </div>
            </header>

            <main>
                <nav>
                    <ul class="main-menu">
                        <li>
                            <m-button class="link-home" type="tertiary" :size="button_size" to="/" @click.native="hide()">
                                <svg-icon name="home2" />
                                <span>Home</span>
                            </m-button>
                        </li>
                        <li>
                            <m-button class="link-shop" type="tertiary" :size="button_size" to="/home/shop" @click.native="hide()">
                                <svg-icon name="shopping_bag" />
                                <span>Shop</span>
                            </m-button>
                        </li>
                        <li>
                            <m-button class="link-likeboards" type="tertiary" :size="button_size" to="/home/collections" @click.native="hide()">
                                <svg-icon name="grid" />
                                <span>Collections</span>
                            </m-button>
                        </li>
                        <li>
                            <m-button class="link-profile" v-if="$store.state.is_logged" type="tertiary" :size="button_size" to="/me" @click.native="hide()">
                                <svg-icon name="user" />
                                <span>Profile</span>
                            </m-button>
                            <m-button v-else type="tertiary" :size="button_size" @click.native="hide()">
                                <svg-icon name="user" />
                                <span>Profile</span>
                            </m-button>
                        </li>
                        <li>
                            <m-button v-if="$store.state.is_logged" type="tertiary" :size="button_size" @click.native="showSearchBar">
                                <svg-icon name="search" />
                                <span>Search</span>
                            </m-button>
                        </li>
                    </ul>
                    <ul class="user-menu" v-if="$store.state.is_logged">
                        <li v-if="$store.state.is_show_motom_bank">
                            <m-button class="link-earnings" type="tertiary" :size="button_size" to="/me/earnings" @click.native="hide()">
                                <svg-icon name="bank" />
                                <span>Bank</span>
                            </m-button>
                        </li>
                        <li>
                            <m-button class="link-metrics" type="tertiary" :size="button_size" to="/me/metrics" @click.native="hide()">
                                <svg-icon name="views" />
                                <span>Views</span>
                            </m-button>
                        </li>
                        <li>
                            <m-button class="link-settings" type="tertiary" :size="button_size" to="/me/settings" @click.native="hide()">
                                <svg-icon name="settings3" />
                                <span>Settings</span>
                            </m-button>
                        </li>
                    </ul>
                    <div class="create-button" v-if="$store.state.is_logged">
                        <m-button type="secondary" size="40" @click.native="gotoCreatePage">
                            <svg-icon name="create" />
                            <span>Create</span>
                        </m-button>
                    </div>
                    <LoginBox v-if="!$store.state.is_logged" />
                </nav>
                <div class="links">
                    <p>
                        <a href="https://company.motom.me/forcreators/" target="_blank">For Creators</a> •
                        <a href="https://company.motom.me/forbrands/" target="_blank">For Brands</a> • <br>
                        <a href="https://www.creatorcommunity.motom.me/" target="_blank">Creator Resource Hub</a>
                    </p>
                    <p>
                        <a href="https://company.motom.me/" target="_blank">About Us</a> •
                        <router-link @click.native="hide()" to="/page/terms_of_use">Terms of Use</router-link> •
                        <a href="https://support.motom.me/" target="_blank">Support Center</a>
                    </p>
                    <p>
                        <router-link @click.native="hide()" to="/page/privacy_policy">Privacy Policy</router-link> •
                        <a href="mailto:help@motom.me">Contact Us</a>
                        <!-- <router-link @click.native="hide()" to="/help">Contact Us</router-link> -->
                    </p>
                </div>
                <p class="copyright">All rights reserved by Motom {{new Date().getFullYear()}}</p>
            </main>
        </div>

        <div class="bg" @click="hide()"></div>
    </aside>
</template>

<script>
import { EventBus } from '@/events/bus.js';
import LoginBox from '@/components/LoginBox.vue';

export default {
    name: 'global-navi',

    data() {
        return {
            is_show: false,
            button_size: '48',
        };
    },

    components: {
        LoginBox
    },

    methods: {
        show() {
            this.is_show = true;

            setTimeout(() => {
                this.$refs.navi.classList.add('show-bg');
            }, 10);

            setTimeout(() => {
                this.$refs.navi.classList.add('show-navi');
            }, 100);
        },


        hide() {
            this.$refs.navi.classList.remove('show-bg');
            this.$refs.navi.classList.remove('show-navi');

            setTimeout(() => {
                this.is_show = false;
            }, 300);
        },

        showSearchBar() {
            this.hide();
            const obj = {};
            obj.is_show = true;

            EventBus.$emit('toggle-search-bar', obj);
        },

        gotoCreatePage() {
            this.hide();
            if (this.$store.state.is_logged) {
                // this.$router.push({ path: '/create-post' });
                EventBus.$emit('open-create-post');
            } else {
                // window.location.href = '/login';
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Ready to create a post?',
                    text: 'You’ll need an account to create post or simply log in. '
                });
            }
        }
    },

    mounted() {
        if (window.innerWidth < 768) {
            this.button_size = '40';
        }
        EventBus.$on('toggle-sidebar', obj => {
            if (obj.is_show) {
                this.show();
            } else {
                this.hide();
            }
        });
    }
};

</script>
<style lang="scss">
    @import '../resources/sass/_variables.scss';

    .global-navi {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 9999;

        .bg {
            width: 100%;
            height: 100%;
            background: rgba(var(--Black-100-rgba), .2);
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            transition: .2s opacity ease-in-out;
            opacity: 0;
            cursor: pointer;
        }

        .inner {
            width: var(--sidebar-width);
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: var(--Bg-light);
            transition: .2s transform ease-in-out;
            transform: translate(100%, 0%);
            box-shadow: var(--left-4dp);
            display: grid;
            grid-template-rows: auto 1fr auto;
            grid-template-columns: 100%;
            z-index: 3;
        }

        header {
            display: flex;
            align-items: center;
            padding: 6px 16px;

            .logo {
                $size: 20px;
                flex: 1;

                svg {
                    fill: var(--Brand-Primary);
                    height: $size;
                    width:  $size * 5;
                    display: block;
                }
            }

            .close-ico {
                height:  28px;
                width:  28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    height: 20px;
                    width: 20px;
                    fill: var(--Brand-Primary);
                }
            }

            .invite-button {
                padding-right: 8px;
            }
        }

        main {
            overflow: auto;

            nav > ul {
                padding: 12px 8px;
                border-bottom: 1px solid var(--Black-10);

                &:first-child {
                    padding-top: 16px;
                }

                li {
                    position: relative;

                    .m-button {
                        display: flex;
                        justify-content: flex-start;
                        text-transform: uppercase;
                        color: var(--Text-primary);

                        svg {
                            transition: left .2s ease-in-out;
                            position: relative;
                        }

                        span {
                            transition: opacity .2s ease-in-out;
                        }
                    }
                }
            }


            .create-button {
                padding: 12px 16px;

                .m-button {
                    position: relative;
                    display: flex;
                    text-transform: uppercase;
                    text-align: center;

                    span {
                        opacity: 1;
                        position: absolute;
                        left: 0;
                        width: 100%;
                    }

                    svg {
                        display: block;
                        position: absolute;
                        height: 20px;
                        width: 20px;
                        top: 50%;
                        left: 50%;
                        margin: -10px 0 0 -10px;
                        opacity: 0;
                    }

                    svg,
                    span {
                        transition: opacity .2s ease-in-out;
                    }
                }
            }
        }

        .links {
            padding: 12px 16px;
            font: var(--text-s-light);

            p {
                padding-top: 6px;
                color: var(--Text-default);
            }

            a {
                color: var(--Text-default);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .copyright {
            font-weight: var(--font-light);
            font-size: var(--font-xxs);
            color: var(--Text-default);
            text-align: left;
            padding: 0 16px 16px;
        }

        &.show-bg .bg {
            opacity: 1;
        }

        &.show-navi .inner {
            transform: translate(0%, 0%);
        }

        .login-box {
            margin: 16px 0 16px 16px;

            & + .links::before {
                display: none;
            }
        }

    }

    @media (min-width: $mobile_size) {
        .container {
            margin-left: var(--sidebar-width);
            transition: margin .2s ease-in-out;
        }

        .global-navi {
            width: var(--sidebar-width);
            transition: width .2s ease-in-out;
            height: calc(100% - 48px);
            top: 48px;

            .bg {
                display: none;
            }

            .inner {
                transform: translate(0%, 0%);
                // grid-template-rows: 1fr auto;
                background: var(--Bg-light);
                display: block;
                width: 100%;
                box-shadow: unset;
            }

            .login-box {
                width: 258px;
                opacity: 1;
                transition: opacity .2s ease-in-out;
            }

            main {
                min-height: 100%;
                display: grid;
                grid-template-rows: 1fr auto auto;
                grid-template-columns: 100%;
                position: relative;
                z-index: 9999;

                nav {
                    padding: 0 0  0 0;
                    transition: padding .2s ease-in-out;
                }

            }

            .copyright,
            .links {
                opacity: 1;
                width: calc(var(--sidebar-width) - 32px);
                transition: opacity .2s ease-in-out;
            }
        }
        
        #mobile-wrap.page--private-profile-settings .global-navi main nav ul li .link-settings,
        #mobile-wrap.page--metrics .global-navi main nav ul li .link-metrics,
        #mobile-wrap.page--earnings .global-navi main nav ul li .link-earnings,
        #mobile-wrap.page--home-home .global-navi main nav ul li .link-home,
        #mobile-wrap.page--home-tot .global-navi main nav ul li .link-home,
        #mobile-wrap.page--home-article  .global-navi main nav ul li .link-home,
        #mobile-wrap.page--home-tag .global-navi main nav ul li .link-home,
        #mobile-wrap.page--shop-brands .global-navi main nav ul li .link-shop,
        #mobile-wrap.page--likeboards .global-navi main nav ul li .link-likeboards,
        #mobile-wrap.page--home-shop .global-navi main nav ul li .link-shop,
        #mobile-wrap.page--home-tot .global-navi main nav ul li .link-home,
        #mobile-wrap.page--home-article  .global-navi main nav ul li .link-home,
        #mobile-wrap.page--home-tag .global-navi main nav ul li .link-home,
        #mobile-wrap.page--shop-brands .global-navi main nav ul li .link-shop,
        #mobile-wrap.page--private-profile .global-navi main nav ul li .link-profile {
            border-color: var(--Border-neu);
            background-color: var(--Surface-tertiary-selected);
            color: var(--Text-primary);
        }

        html.device-is-tablet:not(.open-tablet-navi),
        html.collapse-desktop-navi {
            .container {
                margin-left: var(--collapsed-sidebar-width);
            }

            .explain-piggy-modal .modal--container {
                left : calc(var(--collapsed-sidebar-width) / 2);
            }

            .global-navi {
                width: var(--collapsed-sidebar-width);

                .copyright,
                .links {
                    opacity: 0;
                }

                .login-box {
                    opacity: 0;
                    pointer-events: none;
                }

                main {
                    nav {
                        padding-left: 0;

                        & > ul .m-button {
                            margin-left: 8px;
                            margin-right: 8px;

                            span {
                                opacity: 0;
                            }

                            svg {
                                left: 2px;
                            }
                        }
                    }

                    .create-button {
                        .m-button {
                            span {
                                opacity: 0;
                            }

                            svg {
                                opacity: 1;
                            }
                        }
                    }

                    .links {
                        opacity: 0;
                    }
                }
            }
        }

        html.device-is-tablet {
            .container {
                margin-left: var(--collapsed-sidebar-width);
            }
        }

        #mobile-wrap.page--create,
        #mobile-wrap.page--globalsearch,
        #mobile-wrap.page--private-profile-storefront-add,
        #mobile-wrap.page--private-profile-merchant-add,
        #mobile-wrap.page--private-profile-likeboards-add,
        #mobile-wrap.page--trending-list {
            .container {
                margin-left: 0px;
            }

            .global-navi {
                width: 0px;
            }
        }
    }
</style>
