<template>
    <div class="popup-tiktok" v-if="is_show">
        <div class="popup-tiktok--wrapper">
            <div class="popup-tiktok--container" :class="[{ 'open': is_open }]">
                <header class="popup-tiktok--header">
                    <span class="close" @click="close()">
                        <svg-icon name="close-no-circle" />
                    </span>

                    <h2>
                        <m-avatar :size="'small'" :type="post.post_author_type" :image="post.post_author_image"
                            :is_influencer="Boolean(post.post_author_register_type === 2)" />
                        <span v-if="post.post_author_type === 2 && !post.post_author_image">Motom Creator Watch</span>
                        <span v-else>{{ post.post_author_name }}</span>
                    </h2>

                    <div class="btn" v-if="user_profile">
                        <FollowBtn :item="user_profile" size="32" />
                    </div>
                </header>

                <div class="popup-tiktok--body">
                    <div v-if="post.post_type === 'TIKTOK'" class="embed-tiktok" :class="{ 'is-iphone': is_iphone }">
                        <div class="tiktok-embed-wrap" v-html="embed_html"></div>
                        <script type="application/javascript" src="//www.tiktok.com/embed.js"></script>
                        <img :src="image" @error="imageError" />
                    </div>
                    <div v-else-if="post.post_type === 'INSTAGRAM'" class="embed-instagram">
                        <div :data-link="instagram_media_url">
                            <blockquote class="instagram-media" data-instgrm-captioned :data-instgrm-permalink="instagram_media_url" data-instgrm-version="14"></blockquote>
                            <div class="bg" style="padding-bottom: calc(54px + 100%);"></div>
                        </div>
                        <img v-if="post.payload.thumbnail_url" :src="post.payload.thumbnail_url" @error="imageError" />
                    </div>
                    <div v-else class="embed-video">
                        <iframe :src="`${youtubeEmbedURL(post.social_post_url)}?controls=0&playsinline=1`" frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                    <div class="tagged-products" v-if="post.tagged_products && post.tagged_products.length !== 0">
                        <Swiper :options="product_swiper" ref="bottomSwiper">
                            <SwiperSlide v-for="(product, index) in post.tagged_products" :key="product.id + '-' + index">
                                <product :show_seen_in="false" :skimlinks_tracking="post.unique_key" :product="product"
                                    :post_type="post.post_type" :post_unique_key="post.unique_key" />
                                <!-- <Product :show_info_2="true" :hide_like_button="true" :skimlinks_tracking="post.unique_key" :product="product"/> -->
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

                <div class="popup-tiktok--footer">
                    <ul>
                        <div class="left">
                            <li v-if="post.tagged_products && post.tagged_products.length > 0"
                                :content="showTippy('Shop this post')" v-tippy="{ zIndex: 100000 }">
                                <div class="ico" @click="showTaggedPopup()"><i><svg-icon name="shopping_bag" /></i></div>
                                <span v-if="post.tagged_products && post.tagged_products.length !== 0">{{
                                    post.tagged_products.length }}</span>
                            </li>
                            <li v-if="is_show_like" :content="showTippy(post.is_liked ? 'Unlike' : 'Like')"
                                v-tippy="{ zIndex: 100000 }"
                                :class="['js-post-likeid-' + post.post_id, { 'is-liked': post.is_liked }]" ref="like">
                                <div class="ico" @click="like()"><i><svg-icon
                                            :name="post.is_liked ? 'heart' : 'heart2'" /></i></div>
                            </li>

                            <li v-if="is_show_add_tag" :content="showTippy('Add tag')" v-tippy="{ zIndex: 100000 }"
                                :class="{ 'is-tagged': is_tagged }">
                                <div class="ico" @click="tagNewProducts()"><i><svg-icon name="tag-offer-outline" /></i>
                                </div>
                            </li>
                            <li v-if="is_show_repost" :content="showTippy('Repost')" v-tippy="{ zIndex: 100000 }"
                                :class="['js-repost-id-' + post.post_id, { 'is-reposted': post.is_reposted }]"
                                @click="createPost()">
                                <div class="ico"><i><svg-icon name="repost" /></i></div>
                            </li>
                        </div>
                        <li v-if="is_show_share">
                            <div class="ico" @click="share()" :content="showTippy('Share')" v-tippy="{ zIndex: 100000 }">
                                <i><svg-icon name="share" /></i></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import Avatar from '@/components/ui/Avatar.vue';
import YoutubeEmbedURL from '@/utils/YoutubeEmbedURL';
import FollowBtn from '@/components/ui/FollowPostBtn.vue';

export default {
    name: 'popup-post',

    data() {
        return {
            is_show: false,
            is_open: false,
            is_tagged: false,
            type: '',
            post: null,
            embed_html: '',
            is_iphone: false,
            image: '',
            is_show_like: false,
            is_show_repost: false,
            is_show_add_tag: false,
            is_show_share: false,
            user_profile: false,
            instagram_media_url: '',
            product_swiper: {
                slidesPerView: 'auto',
                watchOverflow: false,
                // slidesPerView: 1.3,
                spaceBetween: 0,
                freeMode: true,
                // mousewheel: true,
                // navigation: {
                //     nextEl: '.js-next',
                //     prevEl: '.js-prev',
                // },
            },
        }
    },

    watch: {
        '$route.params': {
            handler(params) {
                if (this.is_open) this.close();
            },
            immediate: true,
        }
    },
    components: {
        //Product,
        Swiper,
        SwiperSlide,
        Avatar,
        FollowBtn,
    },
    methods: {
        youtubeEmbedURL: YoutubeEmbedURL,

        showTippy(val) {
            if (window.innerWidth > 680) {
                return val;
            } else {
                return null;
            }
        },

        open() {
            this.is_show = true;
            document.body.classList.add('is-show-tiktok-popup');

            setTimeout(() => {
                this.is_open = true;

                if (this.post.post_type === 'INSTAGRAM') {
                    if (this.post.social_post_url.indexOf('.instagram.com/reel/') !== -1) {
                        this.instagram_media_url = this.post.social_post_url.replace('.instagram.com/reel/', '.instagram.com/p/');
                    } else {
                        this.instagram_media_url = this.post.social_post_url;
                    }
                

                    setTimeout(() => {
                        if (window.instgrm && window.instgrm.Embeds) window.instgrm.Embeds.process();
                    }, 500);
                }
            }, 10);
        },

        close() {
            this.is_open = false;

            setTimeout(() => {
                this.embed_html = '';
                this.is_show = false;
                document.body.classList.remove('is-show-tiktok-popup');
            }, 310);
        },

        imageError() {
            const doc = new DOMParser().parseFromString(this.post.tiktok_payload.html, 'text/xml');
            const post_url = doc.firstChild.getAttribute('cite')

            this.$ajax('https://www.tiktok.com/oembed?url=' + post_url, {
                method: 'get',
            }).then(res => {
                if (res.data.status_msg && res.data.status_msg === 'Something went wrong') {
                    console.log('post remove ' + this.post.post_id)
                    let el = document.querySelector('#js-post--' + this.post.post_id);
                    if (el !== null) el.style.display = 'none';
                    EventBus.$emit('disable-post', {
                        post_id: this.post.post_id
                    });
                } else if (res.data.thumbnail_url) {
                    this.image = res.data.thumbnail_url;
                    let form_data = new FormData();
                    form_data.append('post_id', this.post.post_id);
                    form_data.append('thumbnail_url', btoa(this.image));
                    this.$ajax('/api/save-tiktok-thumbnail-url-from-post-id', {
                        method: 'post',
                        data: form_data,
                        withCredentials: true,
                    }).then(res => {
                        if (res.data.status === 'success') {
                            console.log('save image ' + res.data.data.local_thumbnail_url);
                        }
                    });
                }
            }).catch(err => {
                this.$ajax.cache.store = {};
            });
        },

        like() {

            if (this.$store.state.is_logged) {
                let obj = {};
                obj.type = 'post';
                obj.post = this.post;
                obj.unique_key = this.post.unique_key;
                obj.is_liked = this.$refs.like.classList.contains('is-liked');
                obj.is_likeboard_post = this.is_likeboard_post;
                obj.is_my_likeboard = this.is_my_likeboard;
                obj.likeboard_id = this.likeboard_id;


                EventBus.$emit('open-add-likeboards', obj);
                this.post.is_liked = !obj.is_liked;
                // this.post.is_liked = true;

            } else {
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Like & collect',
                    text: "You’ll need an account to add what you like to a Collections or simply log in. "
                });
            }
        },

        createPost() {
            if (this.$store.state.is_logged) {

                setTimeout(() => {
                    EventBus.$emit('create-post', {
                        type: 'tiktok',
                        post: this.post,
                    });
                }, 500);

                this.$router.push({ path: '/create-post/tiktok' });
            } else {
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Ready to create a post?',
                    text: 'You’ll need an account to create post or simply log in. '
                });
            }

        },

        showTaggedPopup() {
            let obj = {};
            let is_have_who_tagged = true;
            obj.post = JSON.parse(JSON.stringify(this.post));
            obj.post.tagged_products.forEach(product => {
                if (typeof product.who_tagged === 'undefined') is_have_who_tagged = false;
            });

            if (is_have_who_tagged) {

                EventBus.$emit('open-tagged-popup', obj);
            } else {
                this.$store.state.show_loading = true;

                this.$ajax('/api/post/' + this.post.unique_key, {
                    method: 'get',
                    withCredentials: true,
                }).then(res => {
                    if (res.data.status === 'success') {
                        obj.post = res.data.data;
                        EventBus.$emit('open-tagged-popup', obj);
                    }

                    this.$store.state.show_loading = false;
                });
            }


        },

        tagNewProducts() {
            let obj = {};
            obj.post = this.post;
            EventBus.$emit('tag-new-products', obj);
        },

        share() {
            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            label = label + ',' + this.post.unique_key + ',,';
            this.GA_Tracking('SHARE', 'CLICK', label);

            let obj = {};
            obj.description = this.post.post_caption;
            obj.shareURL = `${window.location.origin}/postdetails/${this.post.unique_key}`;
            obj.postType = this.post.post_type;
            EventBus.$emit('open-post-share', obj);
        },

        videoReady(event) {
            event.target.playVideo();
            setTimeout(() => {
                event.target.stopVideo();
            }, 100);
        },

        videoError() {
            if (!this.is_error) {
                this.is_error = true;
                console.log('error video', this.post);
                EventBus.$emit('disable-post', {
                    post_id: this.post.post_id
                });
            }
        },
    },

    mounted() {
        this.is_iphone = !window.MSStream && /iPhone|iPod/.test(navigator.userAgent);



        EventBus.$off('popup-post');
        EventBus.$on('popup-post', obj => {
            this.open();
            this.post = obj.post;
            this.type = obj.type;
            this.is_tagged = false;
            this.is_show_share = true;
            this.is_show_like = (this.$route.name !== 'public-profile-social' && this.$route.name !== 'private-profile-social');
            this.is_show_add_tag = true;
            this.is_show_repost = true;
            this.user_profile = false;

            if (this.post.tiktok_payload) {
                this.image = this.post.tiktok_payload.thumbnail_url;
                let n = this.post.tiktok_payload.html.indexOf("<script");
                this.embed_html = this.post.tiktok_payload.html.substr(0, n);
            }

            if (this.post.tagged_products && this.post.tagged_products.length !== 0) {

                if (this.$route.name === 'public-profile-social' || this.$route.name === 'private-profile-social') {
                    this.is_tagged = this.post.tagged_products.filter(p => p.who_tagged.user_id === this.post.post_author_id).length > 0 ? true : false;
                } else {
                    this.post.tagged_products.forEach(product => {
                        if (typeof product.who_tagged !== 'undefined' && product.who_tagged.is_me) this.is_tagged = true;
                    });
                }
            }

            setTimeout(() => {
                this.$ajax.get('/api/user/' + this.post.post_author_id).then(res => {
                    this.post.is_following_author = res.data.profiles.is_followed;
                    this.user_profile = res.data.profiles;
                });
            }, 500);
        });
    },
}
</script>

<style lang="scss">
@import '../resources/sass/_variables.scss';

.popup-tiktok {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99998; //100000;
    transition: opacity .3s ease;

    &--wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        box-sizing: border-box;
        left: 0%;
        transform: translate(0%, 0);
    }

    &--container {
        position: relative;
        z-index: 9;
        background: var(--Black-20);
        margin: auto;
        overflow: hidden;
        transition: transform .3s ease;
        height: 100%;
        width: 100%;
        transform: translate(100%, 0);
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 100%;

        &.open {
            transform: translate(0%, 0);
        }
    }




    &--header {
        height: 48px;
        display: flex;
        align-items: center;
        background: var(--Bg-dark);
        position: relative;
        z-index: 9;
        padding: 0 12px;

        .close {
            height: 18px;
            width: 18px;
            cursor: pointer;

            svg {
                height: 100%;
                width: 100%;
                fill: var(--Black-00);
            }
        }

        h2 {
            flex: 1;
            font: var(--sub-5);
            color: var(--Text-invert);
            padding: 0 12px;
            display: flex;
            align-items: center;
            text-transform: uppercase;

            .ui-avatar {
                margin-right: 8px;
            }

            .ico {
                position: absolute;
                height: 12px;
                width: 12px;
                background: var(--Bg-light);
                border-radius: 50%;
                z-index: 3;
                bottom: 0;
                right: 0;

                svg {
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    bottom: 1px;
                    right: 1px;
                }
            }
        }

        .btn {
            color: var(--Text-invert);
        }
    }



    &--body {
        color: var(--Text-invert);
        overflow: auto;
        background: var(--Black-100);
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: 100%;

        a {
            color: var(--Text-invert);
        }

        .embed-instagram {
            padding: var(--spacing-04);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;

            & > div {
                position: relative;
                max-width: 488px;
                max-height: calc(100vh - 232px);
                width: calc(100% - 32px);
                min-width: 326px;
                z-index: 3;
                border-radius: var(--radius-medium);

            }

            & > img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: .12;
                filter: blur(3px);
            }


            .instagram-media {
                width: calc(100% + 2px) !important;
                height: calc(100% + 2px) !important;
                min-width: unset !important;
                overflow: hidden;
                position: absolute;
                left: -1px;
                top: -1px;
                // border-radius: 0!important;
                box-shadow: none!important;
                display: block;
                margin: 0px !important;
                padding: 0px !important;
            }
        }


        .embed-tiktok {
            padding: var(--spacing-04);
            position: relative;

            &.is-iphone {
                .tiktok-embed {
                    max-height: 774px;
                }

                iframe {
                    min-height: 774px;
                }
            }

            & > img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: .12;
                filter: blur(3px);
            }

            .tiktok-embed-wrap {
                width: 100%;
                max-width: 326px;
                margin: 0 auto;
                overflow: hidden;
                border-radius: var(--radius-medium);
                display: block;
                position: relative;
                z-index: 3;
            }

            .tiktok-embed {
                margin: 0;
            }
        }


        .tagged-products {
            position: relative;
            background: var(--Bg-light);
            z-index: 3;

            .swiper-container {
                background: var(--Bg-02);
                padding: 4px;

                .swiper-slide {
                    padding: 4px;
                    width: 9rem;
                    height: 9rem;
                    box-sizing: content-box;
                }
            }

        }

    }

    &--footer {
        background: var(--Bg-light);
        height: 52px;

        ul {
            display: flex;
            // justify-content: center;
            padding: 0 6px;
            color: var(--Text-default);

            .left {
                display: flex;

                li {
                    margin-right: 24px;
                }
            }


            li {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin: 0 6px;

                &:last-child {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                }

                .ico {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: var(--Text-default);
                    font: var(--sub-6);
                    height: 52px;

                    i {
                        display: block;
                        height: 20px;
                        width: 20px;
                        margin-right: 5px;
                    }

                    svg {
                        height: 100%;
                        width: 100%;
                        fill: var(--Text-default);

                        &.active {
                            fill: var(--Brand-Primary);
                        }
                    }
                }

                &.is-reposted .ico,
                &.is-liked .ico,
                &.is-tagged .ico {
                    color: var(--Text-brand);

                    svg {
                        fill: var(--Brand-Primary);
                    }
                }

                @at-root {

                    .page--private-profile-private-profile-social &,
                    .page--public-profile-public-profile-social & {
                        &:nth-child(1) {
                            order: 1;
                        }

                        &:nth-child(2) {
                            order: 3;
                        }

                        &:nth-child(3) {
                            order: 2;
                        }
                    }
                }
            }
        }
    }
}

.embed-video {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal--container,
.modal-leave-active .modal--container {
    transform: scale(1.1);
}</style>
