<template>
  <div id="app">
    <Mobile />
    <!-- <Message ref="msg" :type="msg_type" /> -->
  </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';
import getQueryVariable from '@/utils/GetQueryVariable';
import Mobile from './main/Mobile.vue';
// import Message from '@/components/ui/Message.vue';

export default {
    name: 'app',

    watch: {
        '$route': function(newRoute, oldRoute) {        

            if ((newRoute.name === 'settings-linked-accounts' || newRoute.name === 'settings-account-privacy') && !newRoute.query.payload) {
                this.log('history_index => ' + (window.history.length - 1))
                window.localStorage.setItem('MOTOM_history_index', (window.history.length - 1));
            }

            if (newRoute.name === 'productdetails' && oldRoute.name === 'public-likeboard') {
                this.$store.state.back_urls.product_detail = '';
            } else if (newRoute.name === 'productdetails' && oldRoute.name !== 'productposts') {
                this.$store.state.back_urls.product_detail = oldRoute.path;
            }

            if (newRoute.name === 'globalsearch' && oldRoute.name !== 'globalsearch') {
                this.$store.state.back_urls.global_search = oldRoute.path;
            }

            if (!this.has_gtag_user && this.$store.state.is_logged && typeof gtag === 'function' ) {
                gtag('config', window.GTAG_ID, {'user_id': window.user_id_alpha, 'transport_type': 'beacon'});
                gtag('config', window.GTAG_ID3, {'user_id': window.user_id_alpha, 'transport_type': 'beacon'});
                this.has_gtag_user = true;
            } 
            else if (typeof gtag === 'function' ) {
                gtag('config', window.GTAG_ID, {'page_path': location.pathname + location.search, 'transport_type': 'beacon'});
                gtag('config', window.GTAG_ID3, {'page_path': location.pathname + location.search, 'transport_type': 'beacon'});
            }

            // console.log(newRoute.name + ' => ' + oldRoute.name);
            const creator_header = document.querySelector('.profile-header.is-creator');
            // if (newRoute.name.indexOf('private-profile') !== -1 && oldRoute.name.indexOf('private-profile') !== -1) {
            if (creator_header !== null) {
                this.$store.state.is_disable_scroll = true;
                let rect = creator_header.getBoundingClientRect();
                let bottom_position = (rect.bottom + window.scrollY - 47);

                if (rect.bottom - 48 < 0) {
                    window.scrollTo(0, bottom_position);
                }

                setTimeout(() => {
                    this.$store.state.is_disable_scroll = false;
                }, 500);
            }

            setTimeout(() => {
                if (this.isMotomApp || this.isWebview()) {
                    this.$SmoothScroll(3, 500);
                } else {
                    if (!this.$store.state.is_disable_scroll) window.scrollTo(0, 0);
                }

                if (this.$store.state.is_disable_scroll) {
                    setTimeout(() => {
                        this.$store.state.is_disable_scroll = false;
                    }, 500);
                }
            }, 1);

            // if (!this.$store.state.is_disable_pageview && this.is_inited && typeof ttq === 'object' && typeof ttq.page === 'function') {
            //     ttq.page();
            // }

            if (!this.$store.state.is_disable_pageview && this.is_inited) {
                this.tiktok_tracking('PageView', {}, this);
            }

            this.$store.state.is_disable_pageview = false;
            this.is_inited = true;
            this.updateMetaTag();

        },
    },

    components: {
        Mobile,
        // Message,
    },

    data() {
        return {
            is_inited: false,
            logo: 'https://www.motom.me' + require('./assets/images/MOTOM_fb_share.png'),
            //is_init: true,
            has_gtag_user: false,
            meta_tag: null,
            // msg_type: 'facebook',
        }
    },

    metaInfo() {
        return {
            title: 'Motom - Social Shopping From Feed to Front Door',
            meta:[
                // Facebook OpenGraph https://loftie.com/post/how-to-add-twitter-and-facebook-cards-to-your-vue-app/
                {property: 'og:title', content: 'You are invited to join Motom'},
                {property: 'og:site_name', content: 'Motom.me'},
                {property: 'og:url', content: 'https://www.motom.me/register'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content:  this.logo},
                {property: 'og:description', content: 'A new people-first shopping platform that centralizes your social feeds and makes it easeir to shop social!'},
                {property: 'description', content: 'Buy an outfit you see on Instagram; Shop online fashion boutiques, be inspired, get discovered.'}
            ]
        }
    },

    FB_Tracking(eventName) {
        if (typeof fbq === 'function') {
            //PageView takes no parameter
            
            fbq('track', eventName, {}, {'eventID': evtID});
        }
    },

    methods: {
        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        updateMetaTag() {
            if (!this.meta_tag) {
                this.meta_tag = document.createElement('meta');
                this.meta_tag.name = 'apple-itunes-app';
                this.meta_tag.content = 'app-id=1610241336, app-argument=' + window.location.href;
                document.getElementsByTagName('head')[0].appendChild(this.meta_tag);
            } else {
                this.meta_tag.setAttribute('content', 'app-id=1610241336, app-argument=' + window.location.href);
            }
        },
    },

    mounted() {
        this.tiktok_tracking('OnlyPageView', {}, this);

        // const is_ios = [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        if (document.querySelector("[name='description']")) document.querySelector("[name='description']").remove();

        // /public-likeboards/8NgVr0E16dx/edit/info

        let back_url = this.$cookie.get('motom_back_url');
        let self = this;
        if (back_url !== null && typeof getQueryVariable('social_platform') !== 'string' && this.$route.name !== 'register-settings') {
            this.$cookie.delete('motom_back_url');
            if (back_url != window.location.origin + '/') window.location.replace(back_url);
        }

        let utm_source = getQueryVariable('utm_source');
        let utm_medium = getQueryVariable('utm_medium');
        let utm_campaign = getQueryVariable('utm_campaign');
        let utm_term = getQueryVariable('utm_term');
        let utm_content = getQueryVariable('utm_content');
        let expires_time = '10m';

        if (typeof utm_source === 'string' && utm_source !== '') this.$cookie.set('motom_utm_source', utm_source, { expires: expires_time });
        if (typeof utm_medium === 'string' && utm_medium !== '') this.$cookie.set('motom_utm_medium', utm_medium, { expires: expires_time });
        if (typeof utm_campaign === 'string' && utm_campaign !== '') this.$cookie.set('motom_utm_campaign', utm_campaign, { expires: expires_time });
        if (typeof utm_term === 'string' && utm_term !== '') this.$cookie.set('motom_utm_term', utm_term, { expires: expires_time });
        if (typeof utm_content === 'string' && utm_content !== '') this.$cookie.set('motom_utm_content', utm_content, { expires: expires_time });

        if (this.isMotomApp) {
            let obj;

            if (this.$store.state.is_logged) {
                obj = {
                    type: 'apply_for_notification_permission',
                    user_id: window.user_id,
                    is_logged: 'yes',
                    first_check_permission_time: window.first_check_permission_time,
                    current_check_permission_status: window.current_check_permission_status,
                    is_need_check_notification_permission: window.is_need_check_notification_permission,
                }
            } else {
                obj = {
                    type: 'apply_for_notification_permission',
                    is_logged: 'no',
                }
            }

            window.ReactNativeWebView.postMessage(JSON.stringify(obj));

            let app_login = getQueryVariable('app_login');
            if (!this.$store.state.is_logged && typeof app_login === 'string' && app_login !== '') {
                let title = '';
                let text = '';
                let is_signup = false;

                if (app_login === '1') {
                    //is_signup = true;
                    title = 'Social shopping, simplified';
                    // text = "Be a part of the first connected social commerce community that brings live TikTok, Instagram, and YouTube feeds together into a one-stop social shop.";
                } else {
                    title = 'Social shopping, simplified';
                }

                setTimeout(() => {
                    this.$store.commit('openLogin', {
                        is_show: true,
                        is_signup: is_signup,
                        title: title,
                        text: text
                    });
                }, 1000);
            }
        }
        
        window.addEventListener('message', function(event) {
            if (event && event.data && typeof event.data === 'string' && self.isJsonString(event.data)) {
                // console.log(event.data);
                const obj = JSON.parse(event.data);

                if (typeof obj.type === 'string' && obj.type === 'app_login') {
                    self.$store.state.show_loading = true;
                    self.$ajax(`/api/ios-login-info/login`, {
                        method: 'post',
                        withCredentials: true,
                        data: {
                            user_id: obj.user_id,
                            uuid: obj.uuid,
                            device_id: obj.device_id,
                        }
                    }).then(res => {
                        window.location.replace('/');
                        self.$store.state.show_loading = false;
                    })
                    .catch(error => {
                        self.$store.state.show_loading = false;
                    });
                }

                if (typeof obj.type === 'string' && obj.type === 'app_save_device_id') {
                    self.$ajax(`/api/ios-login-info/add`, {
                        method: 'post',
                        withCredentials: true,
                        data: {
                            uuid: obj.uuid,
                            device_id: obj.device_id,
                        }
                    }).then(res => {
                        console.log('success::' + obj.uuid + '::' + obj.device_id);
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error));
                    });
                }

                
                if (typeof obj.type === 'string' && obj.type === 'app_permissions') {
                    if (obj.permissions && obj.permissions.notification === '1') {
                        self.$store.state.app_notification_permission = true;
                    } else {
                        self.$store.state.app_notification_permission = false;
                    }

                    EventBus.$emit('update-permission', {
                        obj: obj,
                    });
                }

                if (typeof obj.social_platform === 'string' && 
                    (
                        obj.social_platform === 'google' || 
                        obj.social_platform === 'apple' ||
                        obj.social_platform === 'facebook' ||
                        obj.social_platform === 'facebook_business' ||
                        obj.social_platform === 'instagram_business' ||
                        obj.social_platform === 'tikTok' ||
                        obj.social_platform === 'youtube' ||
                        obj.social_platform === 'instagram'
                    )
                ) {
                    // alert('start login');
                    let origin_page = '';
                    if (obj.origin_page && obj.origin_page !== '') {
                        origin_page = obj.origin_page;
                        // self.$cookie.set('login_origin_page', obj.origin_page, { expires: '30m' });
                    }

                    let type = obj.social_platform;
                    if (type === 'tikTok') type = 'tiktok_business';

                    self.$store.state.show_loading = true;
                    setTimeout(() => {
                        self.$ajax(`/login/social/${type}/callback`, {
                            method: 'post',
                            withCredentials: true,
                            data: {
                                custom_payload: event.data,
                                origin_page: origin_page,
                            }
                        }).then(res => {
                            window.location.replace(res.request.responseURL);
                            self.$store.state.show_loading = false;
                        }).catch(error => {
                            self.$store.state.show_loading = false;
                        });
                    }, 500);
                }
            }
        });
    },
}
</script>
