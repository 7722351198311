import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { setup } from 'axios-cache-adapter'
import device from '@/utils/CheckDevice';
import SlideUpDown from 'vue-slide-up-down';
import vueSmoothScroll from 'vue-smoothscroll';
import Meta from 'vue-meta';
import GATracking from './utils/GA_Tracking';
import GA4Tracking from './utils/GA4_Tracking';
import { FBTracking, FBTrackingCustom } from './utils/FB_Tracking';
import PinterestTracking from './utils/Pinterest_Tracking';
import TiktokTracking from './utils/TiktokTracking';
import VueObserveVisibility from 'vue-observe-visibility';
import GetValidImageUrl from './utils/GetValidImageUrl';
import Log from '@/utils/Log';
import { VueMasonryPlugin } from 'vue-masonry';
import moment from 'moment-timezone';
import Notifications from 'vue-notification';
import Croppa from 'vue-croppa';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Button from '@/components/ui/NewButton.vue';
import Avatar from '@/components/ui/Avatar.vue';
import TabLabel from './components/ui/TabLabel.vue';
import ProductCard from '@/components/ui/ProductCard.vue';
import Search from '@/components/ui/Input/InputSearch.vue';
import VueCookie from 'vue-cookie';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.config.productionTip = false;

Vue.use(VueTippy);
Vue.use(Croppa);
Vue.use(Notifications);
Vue.use(VueMasonryPlugin);
Vue.use(VueCookie);
let ajax_url = '/';

if (process.env.NODE_ENV === 'development') {
    ajax_url = 'https://www.qa.motom.me';
} else if (process.env.NODE_ENV === 'test') {
    ajax_url = 'https://www.qa.motom.me'//'https://www.dev.motom.me';
}


const ajax = setup({
    baseURL: ajax_url,
    cache: {
        maxAge: 20 * 60 * 1000, // 20min
        exclude: {
            query: false
        }
    }
});

let headers = {
    'Content-Type': 'multipart/form-data'
};


ajax.interceptors.response.use(
    response => response,
    error => {
        if (typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined') {
            if (error.response.status === 401 || error.response.status === 500) {
                if (error.response.data.message === 'Unauthenticated.') {
                    store.commit('isLogged', false);
                }
                // return;
            }
        }
        return Promise.reject(error);
    }
);

Vue.prototype.$ajax = ajax;
Vue.prototype.ajaxBaseURL = ajax_url ==='/' ? 'https://www.dev.motom.me': ajax_url;
Vue.prototype.isDesktop = device('desktop');
Vue.prototype.isTablet = device('tablet');
Vue.prototype.isMobile = device('mobile');
Vue.prototype.GA_Tracking = GATracking;
Vue.prototype.GA4_Tracking = GA4Tracking;
Vue.prototype.FB_Tracking = FBTracking;
Vue.prototype.FB_Tracking_Custom = FBTrackingCustom;
Vue.prototype.Pinterest_Tracking = PinterestTracking;
Vue.prototype.tiktok_tracking = TiktokTracking;
Vue.prototype.Get_Image = GetValidImageUrl;
Vue.prototype.log = Log;
Vue.prototype.isMotomApp = (navigator.userAgent.indexOf(' MotomApp') !== -1);
Vue.prototype.isSwiftMotomApp = (navigator.userAgent.indexOf(' SwiftMotomApp') !== -1);
Vue.prototype.moment = moment;
Vue.prototype.isWebview = function () {
    const ua = navigator.userAgent;
    const rules = ['BytedanceWebview', 'Instagram'];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    return Boolean(ua.match(regex) && ua.indexOf(' MotomApp') === -1);
};

if ((navigator.userAgent.indexOf(' MotomApp') !== -1) || (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/))) {
    Vue.prototype.historyLength = 1;
} else {
    if (document.referrer === '') {
        Vue.prototype.historyLength = 2;
    } else {
        Vue.prototype.historyLength = 1;
    }

}

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    headers.Cookie = window.TOKEN;
}

Vue.prototype.ajax_headers = headers;

Vue.component('svg-icon', SvgIcon);
Vue.component('m-button', Button);
Vue.component('m-avatar', Avatar);
Vue.component('search', Search);
Vue.component('tab-label', TabLabel);
Vue.component('product', ProductCard);
Vue.component('tippy', TippyComponent);
Vue.component('slide-up-down', SlideUpDown);
Vue.use(Meta);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');


Vue.use(vueSmoothScroll);
Vue.use(VueObserveVisibility);

moment.tz.setDefault('America/New_York');

router.beforeEach((to, from, next) => {
    // console.log(to, from);
    // console.log('from ' + from.params.action);
    // console.log('to ' + to.params.action);

    if (from.meta === 'create' && to.meta !== 'create' && !store.state.create.is_exit && store.state.create.show_next) {
        store.state.create.show_modal = true;
        next(false);
    }
    else if (
        from.meta === 'create' &&
        (from.params.action === 'your-items' || from.params.action === 'search-product' || from.params.action === 'add-product' || from.params.action === 'brand-product') &&
        to.params.action === 'preview' &&
        store.state.create.is_tagged_products === false &&
        document.querySelector('#js-tagged-products-count') !== null
    ) {
        store.state.create.show_tag_modal = true;
        next(false);
    }
    else {
        if (to.meta === 'create' && from.meta !== 'create') {
            store.state.create.exit_url = from.path;
        }

        store.state.create.show_tag_modal = false;
        store.state.create.show_modal = false;
        store.state.create.is_exit = false;
        store.state.create.is_tagged_products = false;
        next();
    }

});
