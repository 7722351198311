<template>
    <div v-if="!isSwiftMotomApp" class="header" :class="{'back-header': true}">
        <div class="header-inner">
            <div class="logo">
                <span @click="toggleNavi" v-if="$store.state.type !== 'mobile'"><svg-icon name="hamburger" /></span>
                <router-link to="/"><svg-icon class="svg" name="logo-motom" /></router-link>
            </div>
            <div v-if="!($route.name === 'reset-password' && $route.params.type !=='email')" class="left">
                <div class="back-wrap" v-if="is_back_header" @click="back">                  
                    <div class="back-icon">
                        <svg-icon name="back" />
                    </div>
                    <div class="back-text" v-if="back_text" v-html="back_text"></div>
                </div>
                <div class="back-wrap" v-if="is_close_header" @click="close">
                    <div class="close-icon">
                        <svg-icon name="close-no-circle" />
                    </div>
                </div>
                <search v-if="$store.state.type !== 'mobile'" placeholder="Search Motom" @input="doInput" @enter="doSearch" :text="search_value" :spec="true"/>
            </div>
            <div class="title-type-1"><div class="main" /></div>
            <div class="title-type-2"><div id="main-title" class="main" /><div id="sub-title" class="sub" /></div>
            <div v-if="!is_close_header" class="right">

                <div v-if="!is_back_header" class="search-icon" @click="showSearchBar">
                    <svg-icon name="search" />
                </div>
                
                <div class="notification-icon" @click="gotoLink('/notifications?backPath=' + $route.path)" v-if="(!is_back_header && $store.state.type === 'mobile') || ($store.state.type !== 'mobile' && $store.state.is_logged) || $route.meta === 'private-profile'">
                    <svg-icon name="notification" v-if="$store.state.is_logged" />
                    <svg-icon name="hamburger" v-else />
                    <span v-if="notifications_count > 9" class="count">9+</span>
                    <span v-else-if="notifications_count > 0" class="count">{{notifications_count}}</span>
                </div>
                
                <div class="hamburger-icon" v-if="is_back_header" @click="showSidebar">
                    <svg-icon name="hamburger" />
                </div>

                <div v-if="$store.state.type !== 'mobile' && $store.state.is_logged" class="avatar-wrap" :class="{'hide': is_hide_menu}">
                    <Avatar id="js-header-avatar" @click.native="hideMenu" :to="'/me'" :size="'small'" :image="$store.state.user.avatar" />

                    <ul class="profile-menu" :class="{'hide': is_hide_menu}">
                        <li><router-link @click.native="hideMenu" to="/me"><svg-icon name="user" /> Profile <svg-icon name="arrow-right2" /></router-link></li>
                        <li><router-link @click.native="hideMenu" to="/me/following"><svg-icon name="user-followed" /> Following <svg-icon name="arrow-right2" /></router-link></li>
                        <li><router-link @click.native="hideMenu" to="/me/followers"><svg-icon name="followers-2" /> Followers <svg-icon name="arrow-right2" /></router-link></li>
                        <li><router-link @click.native="hideMenu" to="/me/metrics"><svg-icon name="views" /> Views <svg-icon name="arrow-right2" /></router-link></li>
                        <li><router-link @click.native="hideMenu" to="/me/earnings"><svg-icon name="bank" /> Bank <svg-icon name="arrow-right2" /></router-link></li>
                        <li><router-link @click.native="hideMenu" to="/me/settings"><svg-icon name="settings3" /> Settings & Privacy <svg-icon name="arrow-right2" /></router-link></li>
                        <li><a @click="hideMenu" href="/logout"><svg-icon name="logout" /> Log out <svg-icon name="arrow-right2" /></a></li>
                    </ul>
                </div>

                <m-button v-if="!$store.state.is_logged" type="secondary" @click.native="showLogin(false)">Log In</m-button>
                <m-button v-if="!$store.state.is_logged" type="primary" @click.native="showLogin(true)">Sign Up</m-button>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/events/bus.js';
import Avatar from '@/components/ui/Avatar.vue';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import MButton from '@/components/ui/NewButton.vue';

export default {
    name: 'global-header',

    props: {
        is_back_header: {
            type: Boolean,
            default: false
        },
        is_close_header: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            back_text: '',
            back_url: '',
            back_type: '',
            is_hide_menu: false,
            notifications_count: 0,
            user_id: '',
            search_value:'',
            subject: encodeURI('Motom Bank Issue - '),
            body: encodeURI(`We're sorry to hear you're having a problem with your Motom Bank. Please describe your issue below:`) + '%0d%0a%0d%0a%0d%0a',
        };
    },

    components: {
        Avatar,
        SvgIcon,
        MButton,
    },

    watch: {
        '$route' () {
            this.back_text = '';
            this.back_url = '';
            this.back_type = '';

            // if (this.$store.state.is_logged) this.getNotificationsCount();
            this.$store.state.login_modal.is_show = false;
        },

        '$store.state.global_search'(val){
            this.search_value =val;
        }

    },

    methods: {
        hideMenu() {
            this.is_hide_menu = true;
            setTimeout(() => {
                this.is_hide_menu = false;
            }, 800);
        },

        doInput(search_value) {
            this.search_value = search_value;
            this.$store.state.global_search = this.search_value.replace(/\(|\)/g, '');
        },
        
        doSearch(search_value) {
            // let search_value = $event.target.value;
            search_value = search_value.replace(/\(|\)/g, '');

            if (search_value) {
                this.$router.push({
                    path: '/globalsearch/top',
                    query: { query: search_value }
                });
            }
        },

        showSearchBar() {
            const obj = {};
            obj.is_show = true;

            EventBus.$emit('toggle-search-bar', obj);
        },

        showLogin(is_signup = false) {
            let title = '';
            let text = '';

            if (is_signup) {
                title = 'Social shopping, simplified';
                // text = "Be a part of the first connected social commerce community that brings live TikTok, Instagram, and YouTube feeds together into a one-stop social shop.";
            } else {
                title = 'Social shopping, simplified';

            }

            this.$store.commit('openLogin', {
                is_show: true,
                is_signup: is_signup,
                title: title,
                text: text
            });
        },

        showSidebar() {
            //if (this.$route.meta === 'private-profile') {
                EventBus.$emit('open-profile-menu');
            //} else {
                //const obj = {};
                //obj.is_show = true;
                //EventBus.$emit('toggle-sidebar', obj);
            //}

        },

        toggleNavi() {
            if (this.$store.state.type === 'desktop') {
                document.documentElement.classList.toggle('collapse-desktop-navi');
            } else {
                document.documentElement.classList.toggle('open-tablet-navi');
            }
        },

        login() {
            this.$store.commit('openLogin', {
                is_show: true,
                title: '',
                text: ''
            });
        },

        back() {
            const prev_page = window.location.href;
            let is_prev_page = false;

            if (this.back_url !== '' && this.back_type !== '') {
                switch (this.back_type) {
                    case 'replace':
                        this.$router.replace(this.back_url);
                        break;

                    case 'push':
                        this.$router.push(this.back_url);
                        break;
                }
            } 
            else if (this.$route.query.back_path) {
                this.$router.replace(this.$route.query.back_path);
            }
            else if(this.$route.path === '/me/settings/myEmail'){
                let val=this.$store.state.setting_sub_email_page;
                if(!val.verify_email && !val.add_email_page){
                    this.$router.go(-1);
                    is_prev_page = true;
                }else{
                    this.$store.state.setting_sub_email_page.verify_email=false;
                    this.$store.state.setting_sub_email_page.add_email_page=false;
                };
            }
            else {
                this.$router.go(-1);
                is_prev_page = true;
            }

            if (is_prev_page) {
                setTimeout(() => {
                    if (prev_page === window.location.href) {
                        this.$router.replace('/');
                    }
                }, 100);
            }
        },

        close() {
            this.$router.push('/');
        },

        getNotificationsCount() {
            // new Promise

            let request1 = this.$ajax('/api/me/notification/count', {
                method: 'get',
                withCredentials: true
            }).catch(error => {
                throw { error, source: 'request1' };
            });

            let request2 = this.$ajax.get('/api/is-need-check-new-social-account').catch(error => {
                throw { error, source: 'request2' };
            });

            Promise.all([request1, request2]).then(([res1, res]) => {
                this.notifications_count = (
                    res1.data.data.follower.count +
                    res1.data.data.interaction.count +
                    res1.data.data.motom.count +
                    res1.data.data.anchor_invitation.count
                );

                if (res.data && res.data.status === 'success') {
                    this.updateNotificationsCount(res);
                }
                
            }).catch(({ error, source }) => {
                if (source === 'request1') {
                    // request1 error
                } else if (source === 'request2') {
                    // request2 error
                    // const res = {};
                    // res.data = {"data":[{"social_account_id":216485,"social_platform":"instagram","is_creator":true,"handle":"ghbjy1128","is_expired":false,"have_anchor_storefront":true,"is_show_popup":true,"is_show_notification":true,"need_connect":true,"already_have_connect":false,"is_user_cancel_before":true,"time_summary":"3 minutes ago","notification_created_at":"2024-04-29T08:33:14.000000Z"}],"status":"success"}

                    // this.updateNotificationsCount(res);
                }
             });

        },

        updateNotificationsCount(res) {
            const ids = [];
            let is_instagram = false;
            let is_tiktok = false;
            let is_not_instagram = false;
            let is_not_tiktok = false;

            res.data.data.forEach(item => {
                if (item.is_show_popup) {
                    this.$store.state.social_state.popup_accounts.push(item);
                    ids.push(item.social_account_id);

                    if (item.social_platform === 'instagram' || item.social_platform === 'instagram_business') {
                        is_instagram = true;
                    } else if (item.social_platform === 'tikTok') {
                        is_tiktok = true;
                    }
                }

                if (item.is_show_notification) {
                    if (item.social_platform === 'instagram' || item.social_platform === 'instagram_business') {
                        is_not_instagram = true;
                    } else if (item.social_platform === 'tikTok') {
                        is_not_tiktok = true;
                    }

                    this.$store.state.social_state.notification_date = item.time_summary;
                }
            });


            if (is_not_instagram || is_not_tiktok) {
                this.notifications_count += 1;
                this.$store.state.social_state.is_show_notification = true;
            }

            if (is_not_instagram) {
                this.$store.state.social_state.is_show_post = true;
                this.$store.state.social_state.post_link = '/me/settings/linked-accounts';
            }

            if (is_instagram && is_tiktok) {
                this.$store.state.social_state.text = `
                    <h2>Please refresh your social connections for more creator opportunities.</h2>
                `;
                this.$store.state.social_state.link = '/me/settings/linked-accounts';
                this.$store.state.social_state.button_text = 'Reconnect';
            } else if (is_instagram) {
                this.$store.state.social_state.text = `
                    <h2>Please refresh your social connections for more creator opportunities.</h2>
                `;
                this.$store.state.social_state.link = 'facebook_business';

                

                this.$store.state.social_state.button_text = 'Reconnect';

                this.$ajax.get(`/api/user/${this.$store.state.user.id}`).then(res => {
                    if (res.data.profiles.register_type === 1) this.$store.state.social_state.link = 'instagram';
                });
            } else if (is_tiktok) {
                this.$store.state.social_state.text = `
                    <h2>Please refresh your social connections for more creator opportunities. </h2>
                `;
                this.$store.state.social_state.link = 'tikTok';
                this.$store.state.social_state.button_text = 'Reconnect';
            }

            if (is_not_instagram && is_not_tiktok) {
                this.$store.state.social_state.notification_text =  `Please refresh your social connections for more creator opportunities. `;
                this.$store.state.social_state.notification_link = '/me/settings/linked-accounts';
                this.$store.state.social_state.notification_button_text = 'Reconnect';
            } else if (is_not_instagram) {
                this.$store.state.social_state.notification_text =  `Please refresh your social connections for more creator opportunities. `;
                this.$store.state.social_state.notification_link = '/me/settings/linked-accounts';
                this.$store.state.social_state.notification_button_text = 'Reconnect';
            } else if (is_not_tiktok) {
                this.$store.state.social_state.notification_text =  `Please refresh your social connections for more creator opportunities. `;
                this.$store.state.social_state.notification_link = '/me/settings/linked-accounts';
                this.$store.state.social_state.notification_button_text = 'Reconnect';
            }

            if (ids.length !== 0) {
                this.$store.state.social_state.ids = ids;
                if (this.$cookie.get('is_close_check_popup') === null) this.$store.state.social_state.is_show_popup = true;
            }
        },

        gotoLink(url) {
            // console.log('Notification Bell');
            this.GA_Tracking('Notification Bell', 'CLICK', window.user_id_alpha);
            this.$router.push(url);
        },
    },

    mounted() {
        this.user_id = window.user_id;

        EventBus.$on('back-data', obj => {
            if (obj.text) this.back_text = obj.text;
            if (obj.url) this.back_url = obj.url;
            if (obj.type) this.back_type = obj.type;
        });

        if(this.$store.state.global_search){
            this.search_value = this.$store.state.global_search;
        }


        if (this.user_id) this.getNotificationsCount();
    }
}
</script>

<style lang="scss">
    @import '../resources/sass/_variables.scss';
    $size: 20px;

    .header {
        position: sticky;
        z-index: 999;
        background: var(--Bg-light);
        height: 40px;
        width: 100%;
        top: 0;
        left: 0;
        // box-shadow: var(--bot-2dp);

        .header-inner {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 16px;
            position: relative;
            z-index: 3;
            background: var(--Bg-light);
        }

        .logo {
            height: 100%;
            position: static;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            background-color: var(--Bg-light);
            width: 100px;

            span {
                margin: 0 24px 0 16px;
                height: 20px;
                width: 20px;
                cursor: pointer;
                display: none;
                position: relative;
                top: 1px;

                svg {
                    height: 100%;
                    width: 100%;
                    fill: var(--Text-secondary);
                }

                &:hover svg {
                    fill: var(--Brand-Primary);
                }
            }

            .svg {
                height: $size;
                width:  $size * 5;
                display: block;
                margin: 0 auto;
                fill: var(--Brand-Primary);
            }
        }

        &.back-header {
            .logo {
                margin-left: -50px;
                position: absolute;
                left: 50%;
            }
        }

        .left {
            flex: 1;
        }

        .right {
            display: flex;
            align-items: center;

            .notification-icon {
                height: 32px;
                width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                
                svg {
                    height: 22px;
                    width: 22px;
                    color: var(--Text-primary);

                    &.svg-icon--hamburger {
                        height: 24px;
                        width: 24px;
                    }
                }

                &:hover svg {
                    color: var(--Text-primary);
                }
                
                .count {
                    color: var(--Bg-light);
                    background: var(--Red-30);
                    min-width: 12px;
                    height: 14px;
                    border-radius: 10px;
                    font: var(--sub-7);
                    text-align: center;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: -3px;
                    top: -2px;
                    z-index: 9;
                    padding: 0 1px;
                }
            }

            .hamburger-icon,
            .search-icon {
                height: 32px;
                width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    height: 24px;
                    width: 24px;
                    fill: var(--Text-primary);
                }

                &:hover svg {
                    fill: var(--Text-primary);
                }
            }

            .m-button {
                display: none;
            }
        }

        // .search-icon {
        //     height: 20px;
        //     width: 20px;
        //     cursor: pointer;

        //     input {
        //         width: 100%;
        //         font: var(--sub-5);
        //         color: var(--Text-primary);
        //         padding: 0 0 0 40px;
        //         height: 100%;
        //         border: none;
        //         background: transparent;
        //         display: none;

        //         &::placeholder {
        //             color: var(--Text-default);
        //         }
        //     }

        //     svg {
        //         height: 100%;
        //         width: 100%;
        //         fill: var(--Text-default);
        //     }

        //     &:hover svg {
        //         fill: var(--Brand-primary);
        //     }
        // }

        .back-wrap {
            float: left;
            padding-right: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;

            & + .search-icon {
                display: none;
            }

            &:hover {
                .back-icon svg {
                    fill: var(--Brand-Primary);
                }

                .back-text {
                    p,
                    span {
                        color: var(--Text-brand);
                    }
                }
            }

            .back-icon, .close-icon {
                height: 16px;
                width: 16px;
                padding-right: 10px;
                box-sizing: content-box;

                svg {
                    height: 16px;
                    width: 16px;
                    fill: var(--Text-primary);
                }
            }

            .back-text {
                font: var(--sub-6);
                position: relative;
                margin: -1px 0 0;
                min-width: 80px;
                max-width: 130px;
                color: var(--Text-secondary);

                p {
                    line-height: 1;
                    max-width: 52px;
                    white-space: nowrap;
                }

                span {
                    display: block;
                    font-size: var(--font-xxs);
                    color: var(--Text-default);
                    white-space: nowrap;
                    max-width: 80px;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    &::after {
                        content:  '...';
                    }

                    @at-root {
                        @media (max-width: 330px) {
                            & {
                                max-width: 60px;
                            }
                        }
                    }
                }
            }
        }

        .notification-icon {
            // height: 32px;
            // width: 32px;
            // display: block;
            // cursor: pointer;
            // color: var(--Text-secondary);

            // svg {
            //     height: 16px;
            //     width: 16px;
            // }


            .count {
                color: var(--Text-invert);
                background: var(--Red-30);
                width: 16px;
                height: 16px;
                border-radius: 100px;
                font: var(--sub-7);
                text-align: center;
                position: absolute;
                right: 16px;
                z-index: 9;
                padding: 1px 0;
            }
        }

        .profile-menu {
            position: absolute;
            top: calc(100% + 8px);
            right: -10px;
            z-index: 999;
            background: var(--Bg-light);
            border-radius: 6px;
            box-shadow: var(--bot-4dp);
            // overflow: hidden;
            min-width: 200px;
            transition: .35s all ease;
            opacity: 0;
            transform: translate(0, 10%);
            pointer-events: none;

            &.hide {
                pointer-events: none !important;
            }

            &:hover {
                opacity: 1;
                transform: translate(0, 0%);
                pointer-events: auto;
            }

            &::after {
                content: '';
                position: absolute;
                top: -10px;
                right: 18px;
                border: 6px solid transparent;
                border-bottom-color: var(--Bg-light);
            }

            li {

                svg {
                    &:first-child {
                        margin-right: 4px;
                        height: 20px;
                        width: 20px;
                    }

                    //special icon
                    &.svg-icon--user{
                        width: 22px;
                        height: 22px;
                        margin-left: -2.5px;
                        margin-right: 4.3px;
                    }
                   &.svg-icon--user-followed{
                        width: 17px;
                        height: 17px;
                        margin-left: 2.2px;
                        margin-right: 4.5px;
                   }

                    &:last-child {
                        margin-left: auto;
                        height: 12px;
                        width: 12px;
                    }
                }

                &:last-child {
                    border-top: 1px solid var(--Border-default);
                }
            }
        }



        @at-root {

            @media (min-width: $mobile_size) {
                & {
                    z-index: 9990;
                    height: 48px;

                    &.back-header .logo,
                    .logo {
                        position: fixed;
                        margin: 0;
                        left: 0;
                        top: 0;
                        width: var(--sidebar-width);
                        justify-content: flex-start;
                        height: 48px;
                        margin: 0;

                        span {
                            display: block;
                        }

                        $size: 22px;
                        
                        .svg {
                            height: $size;
                            width:  $size * 5;
                        }
                    }


                    .left {
                        padding-left: calc(var(--sidebar-width) - 8px);
                        display: flex;

                        .m-search{
                            max-width: calc(var(--max-search-input) - 105px);
                            min-width: initial;
                        }
                    }

                    .right {
                        display: flex;
                        align-items: center;

                        div {
                            display: flex;
                            align-items: center;
                        }

                        .search-icon,
                        .hamburger-icon {
                            display: none !important;
                        }

                        .notification-icon {
                            height: 32px;
                            width: 32px;

                            svg {
                                fill: var(--Text-default);
                                height: 22px;
                                width: 22px;
                            }
                        }

                        .avatar-wrap {
                            padding-left: 8px;
                            position: relative;

                            &.hide {
                                pointer-events: none !important;
                            }

                            &::before {
                                content: '';
                                position: absolute;
                                top: 100%;
                                left: -16px;
                                height: 8px;
                                width: calc(100% + 16px);
                                background: var(--Border-default);
                                opacity: 0;
                            }

                            .ui-avatar > div {
                                border-color: var(--Text-default);
                            }

                            &:hover {
                                .ui-avatar > div {
                                    border-color: var(--Text-brand);
                                }

                                .profile-menu {
                                    opacity: 1;
                                    transform: translate(0, 0%);
                                    pointer-events: auto;
                                }
                            }


                        }

                        .m-button {
                            margin-left: 8px;
                            display: inline-flex;
                        }
                    }

                    .back-wrap {
                        height: 32px;
                        width: auto;
                        min-width: 32px;
                        margin-right: 16px;
                        align-items: center;

                        & + .search-icon {
                            display: block;
                        }

                        .back-icon {

                            svg {
                                fill: var(--Text-secondary);
                                min-width: 16px;
                                min-height: 16px;
                            }
                        }

                        .back-text {
                            padding: 0;

                            span {
                                max-width: 100%;
                            }
                        }

                        &:hover {

                            .back-icon {

                                svg {
                                    fill: var(--Brand-Primary);
                                }
                            }
                        }
                    }

                    // .search-icon {
                    //     height: 32px;
                    //     width: 100%;
                    //     max-width: 270px;
                    //     position: relative;
                    //     border-radius: 16px;
                    //     border: 1px solid var(--Border-default);
                    //     background: var(--Surface-default);

                    //     input {
                    //         display: block;
                    //     }

                    //     svg {
                    //         height: 24px;
                    //         width: 24px;
                    //         fill:  var(--Text-primary);
                    //         position: absolute;
                    //         left: 8px;
                    //         top: 50%;
                    //         margin: -12px 0 0;
                    //     }

                    //     &:hover {
                    //         border: 1px solid var(--Border-hover);
                    //     }
                    // }
                }

                html.device-is-tablet,
                html.collapse-desktop-navi & {
                    .desktop-back-wrap {
                        left: var(--collapsed-sidebar-width);
                    }

                    &.back-header .header-inner::before {
                        left: var(--collapsed-sidebar-width);
                    }
                }
            }
        }
    }

</style>
