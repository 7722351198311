export default function(url = '', path = '') {
    //console.log(url, /^(\/\/)/.test(url), /^(f|ht)tps?:\/\//i.test(url));
    let new_url = '';
    let site_url = '';
    // let new_url = window.decodeURIComponent(url);
    // new_url = newUrl.trim().replace(/\s/g, '');
    if (!url && path.indexOf('/avatars/') !== -1) {
        new_url = 'https://www.motom.me/app/img/default-user.png';
    }

    else if(!/^(\/\/)/.test(url) && !/^(f|ht)tps?:\/\//i.test(url)) {
        if (process.env.NODE_ENV === 'development') site_url = 'https://www.qa.motom.me';
        else if (process.env.NODE_ENV === 'test') site_url = 'https://www.qa.motom.me';

        url = url.replace('/avatars/', '');
        new_url = site_url + path + url;
    } 
    
    else {
        new_url = url;
    }


    return new_url;
};