<template>
    <Transition name="fade" v-if="is_show">
        <div class="connect-social-modal">

            <div class="inner">
                <div class="box">
                    <i class="close" @click="closePopup"><svg-icon name="close-no-circle" /></i>
                    <div class="top">
                        <div class="icons">
                            <i class="ico instagram"><svg-icon :name="icon_name" /></i>
                            <i v-if="type === 'facebook_business'" class="ico facebook"><svg-icon name="facebook" /></i>
                            <i class="ico arrow"><svg-icon name="arrow-next" /></i>
                            <i class="ico hero-logo"><svg-icon name="hero-logo-blue" /></i>
                        </div>
                        <div class="col">
                            <h2 class="center">{{ title_text }}</h2>
                            <p v-if="type === 'facebook_business'" class="text">Please connect your Facebook to Motom for Instagram insights We use Instagram insights for your brand storefront opportunities.</p>
                            <dl v-if="type === 'facebook_business'" class="lists">
                                <dt>Before connecting your Facebook on Motom,</dt>
                                <dd><i>1</i>
                                    Your Facebook account must have a public Facebook Page. This is different than a Facebook profile.
                                    <a href="https://support.motom.me/" target="_blank">Learn more</a>.
                                </dd>
                                <dd><i>2</i>
                                    That Facebook Page must be selected and connected to the Instagram profile. 
                                    <a href="https://support.motom.me/" target="_blank">Learn more</a>.
                                </dd>
                            </dl>
                            <p v-if="type === 'facebook_business'" class="link-text">If you don't have a Facebook account/page, please <span @click="connect('fb-to-ig')">click here</span> to connect your Instagram.</p>
                            <div class="verified-text">
                                <svg-icon name="verified" />
                                <h3>This does not allow us to post on your behalf or access your private information.</h3>
                                <p>This connection allows us to access your basic information (such as your name and profile picture), your profile and content insights (such as engagement and follower demographics), and your published content to streamline your posting abilities on Motom.</p>
                            </div>
                            <dl v-if="type === 'facebook_business'" class="links">
                                <dt>Please see the following for help:</dt>
                                <dd><a href="https://support.motom.me/how-to-connect-your-instagram-to-a-facebook-page/how-do-i-create-a-facebook-page" target="_blank">How do I create a public Facebook Page?</a></dd>
                                <dd><a href="https://support.motom.me/how-to-connect-your-instagram-to-a-facebook-page/how-do-i-connect-my-facebook-account-on-motom/linking-your-instagram-creatorbusiness-account-to-a-public-facebook-page" target="_blank">How do I connect a Facebook Page to my Instagram profile?</a></dd>
                                <dd><a href="https://support.motom.me/how-to-connect-your-instagram-to-a-facebook-page/why-do-i-need-to-connect-facebook-and-instagram-to-motom" target="_blank">Why do I need to connect Facebook and Instagram to Motom?</a></dd>
                                <dd><a href="https://support.motom.me/how-to-connect-your-instagram-to-a-facebook-page" target="_blank">I'm having another issue.</a></dd>
                            </dl>
                            <p class="cop">Motom's <a @click="openPage($event)" href='/page/terms_of_use'>Terms of Use</a> & <a @click="openPage($event)" href='/page/privacy_policy'>Privacy Policy</a>. Subject to changes.</p>
                        </div>
                    </div>
                    <footer>
                        <m-button @click.native="connect()" size="40">{{ button_text }}</m-button>
                    </footer>
                </div>
            </div>
            
        </div>
    </Transition>
    <Transition name="fade" v-else-if="is_show_instagrams">
        <div class="connect-social-modal">
            <div class="inner">
                <div class="box">
                    <i class="close right" v-if="is_show_instagram_error" @click="is_show_instagrams = false"><svg-icon name="close-no-circle" /></i>
                    <div class="top">
                        <div class="col"  v-if="is_show_instagram_error">
                            <h3>Connect Instagram</h3>
                            <h2>Apologies! We're having issues</h2>
                            <p class="texts">Instagram may be taking a bit longer than usual to set up your new Facebook Page and Instagram account connection.</p>
                            <p class="texts">If you have multiple Facebook Pages, make sure you've given approval to the one that's connected to the Instagram account you're trying to add.</p>
                            <p class="texts"><strong>Please try refreshing your connection.</strong></p>
                            <!-- <p class="cop">Motom's <a @click="openPage($event)" href='/page/terms_of_use'>Terms of Use</a> & <a @click="openPage($event)" href='/page/privacy_policy'>Privacy Policy</a>. Subject to changes.</p> -->
                        </div>
                        <div class="col" v-else>
                            <h2>Which Instagram profile would you like to connect?</h2>
                            
                            <div class="instagrams">
                                <div class="instagram-item" v-for="item in instagrams" :key="item.account.id" @click="selectAccount(item)">
                                    <figure><img :src="item.account.instagram_avatar" /><i><svg-icon name="instagram" /></i></figure>
                                    <h3>{{ '@' + item.account.instagram_name.replace('@', '') }} <!-- <span v-if="item.selected">(Previously connected)</span> --></h3>
                                    <svg-icon :name="item.selected ? 'checked_box_new2' : 'check_box_new'" />
                                </div>
                            </div>
                            <p class="cop">Motom's <a @click="openPage($event)" href='/page/terms_of_use'>Terms of Use</a> & <a @click="openPage($event)" href='/page/privacy_policy'>Privacy Policy</a>. Subject to changes.</p>
                        </div>
                    </div>
                    <footer>
                        <m-button v-if="is_show_instagram_error" @click.native="connect('facebook_business')" size="40">Continue with Facebook</m-button>
                        <m-button v-else @click.native="selected" size="40">Confirm</m-button>
                    </footer>
                </div>
            </div>
        </div>
    </Transition>
    <Transition name="fade" v-else-if="$store.state.social_state.is_show_popup">
        <div class="connect-social-modal">
            <div class="inner">
                <div class="box">
                    <i class="close" @click="close"><svg-icon name="close-no-circle" /></i>
                    <div class="top">
                        <div class="col">
                            <div class="icons">
                                <i class="ico hero-logo"><svg-icon name="hero-logo-blue" /></i>
                            </div>
                            <div class="pop" v-html="$store.state.social_state.text"></div>
                            
                            <div class="instagrams">
                                <div class="instagram-item" v-for="item in $store.state.social_state.popup_accounts">
                                    <!-- <figure><img :src="item.account.instagram_avatar" /><i><svg-icon name="instagram" /></i></figure> -->
                                    <h3 style="padding-left: 0;">{{ '@' + item.handle.replace('@', '') }}</h3>
                                    <span class="expired">Expired</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer>
                        <m-button @click.native="next" size="40">{{ $store.state.social_state.button_text }}</m-button>
                    </footer>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { EventBus } from '@/events/bus.js';

export default {
    name: 'connect-social-modal',

    data () {
        return {
            is_show: false,
            is_show_instagrams: false,
            is_show_instagram_error: false,
            icon_name: 'instagram',
            button_text: 'Connect My Facebook',
            title_text: 'Connect Instagram via Facebook',
            instagrams: [],
            facebook_id: '',
            type: '',
            act: '',
        }
    },

    // props: {
    //     type: {
    //         type: String,
    //         default: ''
    //     },
    // },

    methods: {
        show(type, act) {
            if (type === 'tiktok' && this.$store.state.is_tiktok_banned) {
                this.$store.state.is_show_tiktok_down_popup = true;
                return;
            }

            this.is_show = true;
            this.is_show_instagram_error = false;
            this.type = type;
            this.act = act;
            this.button_text = 'Connect';

            switch (this.type) {
                case 'facebook_business':
                    // this.button_text = 'Connect My Facebook';
                    this.title_text = 'Connect Instagram via Facebook';
                    this.icon_name = 'instagram';
                    break;
                case 'instagram':
                    // this.button_text = 'Connect My Instagram';
                    this.title_text = 'Connect Instagram';
                    this.icon_name = 'instagram';
                    break;
                case 'tiktok':
                    // this.button_text = 'Connect My TikTok';
                    this.title_text = 'Connect TikTok';
                    this.icon_name = 'tiktok';
                    break;
                case 'youtube':
                    // this.button_text = 'Connect My YouTube';
                    this.title_text = 'Connect YouTube';
                    this.icon_name = 'youtube';
                    break;
            }

        },

        openPage(e) {
            e.preventDefault();
            const page = e.target.href.split('/').pop();
            EventBus.$emit('open-page-popup', page);
        },
        
        next() {
            this.close();

            switch (this.$store.state.social_state.link) {
                case 'facebook_business':
                    this.show('facebook_business', '?origin_page=linked-accounts');
                    break;

                case 'instagram':
                    this.show('instagram', '?origin_page=linked-accounts');
                    break;

                case 'tikTok':
                    this.show('tiktok', '?origin_page=linked-accounts');
                    break;
            
                default:
                    window.location.href = this.$store.state.social_state.link;
                    break;
            }
        },

        closePopup() {
            this.is_show = false;
            window.localStorage.removeItem('linked_accounts_reconnect');
        },

        close() {
            this.$store.state.social_state.is_show_popup = false;
            this.$cookie.set('is_close_check_popup', '1', { expires: '1D' });
            this.$ajax('/api/cancel-check-new-social-account-status', {
                method: 'post',
                withCredentials: true,
                data: {
                    social_account_ids: this.$store.state.social_state.ids
                }
            }).then(res => {
                if (res.data.status === 'success') {
                    console.log('cancel success');
                }
            });
        },

        connect(type = '') {
            if (type === 'fb-to-ig') {
                this.type = 'instagram';
            } else if (type === 'facebook_business') {
                this.type = type;

                // console.log(this.$route.name)

                switch (this.$route.name) {
                    case 'register':
                        this.act = '?origin_page=register';
                        break;

                    case 'settings-linked-accounts':
                        this.act = '?origin_page=linked-accounts';
                        break;

                    case 'switch-to-creator':
                        this.act = '?origin_page=switch-to-creator';
                        break;

                    case 'create-new-post':
                        this.act = '?origin_page=create-new-post';
                        break;
                }
            }

            // console.log(type,this.type)

            EventBus.$emit('connect-social-account', this.type);

            if (this.act === '?origin_page=create-new-post') {
                this.$cookie.set('create_type', this.type.toLowerCase(), { expires: '50m' });
            }

            switch (this.type) {
                case 'facebook':
                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_facebook');
                    } else {
                        window.location.href = '/login/social/facebook' + this.act;
                    }
                    break;
                
                case 'facebook_business':
                    if (this.$route.name !== 'register') window.localStorage.setItem('instagram_business_connection', 1);

                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_business_facebook');
                    } else {
                        // console.log('/login/social/facebook_business' + this.act)
                        window.location.href = '/login/social/facebook_business' + this.act;
                    }

                    break;

                case 'instagram':
                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_instagram');
                    } else {
                        // console.log('/login/social/instagram' + this.act)
                        window.location.href = '/login/social/instagram' + this.act;
                    }

                    break;

                case 'tiktok':
                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_tiktok');
                    } else {
                        window.location.href = '/login/social/tiktok_business' + this.act;
                    }
                    break;

                case 'youtube':
                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_youtube');
                    } else {
                        window.location.href = '/login/social/youtube' + this.act;
                    }
                    break;

                case 'google':
                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_google');
                    } else {
                        window.location.href = '/login/social/google' + this.act;
                    }
                    break;

            }

        },

        selectAccount(item) {
            this.instagrams.forEach(instagram => {
                instagram.selected = false;
            });

            item.selected = true;
            // this.$emit('select-instagram-account', item);
        },

        selected() {
            if (this.facebook_id) {
                this.postSelectedAccount();
            } else {
                const selected = this.instagrams.find(instagram => instagram.selected).account;
                EventBus.$emit('select-instagram-account', selected);
            }

            this.is_show_instagrams = false;
        },

        showSelectAccount(item) {
            if (item.payload.user.instagram_accounts.length === 0) {
                this.is_show_instagram_error = true;
            } else {
                item.payload.user.instagram_accounts.forEach((account, index) => {
                    this.instagrams.push({account: account, selected: index === 0});
                });
            }

            // this.instagrams = [];
            this.is_show_instagrams = true;
        },

        postSelectedAccount() {
            const selected = this.instagrams.find(instagram => instagram.selected).account.instagram_id;
            
            this.$ajax.post('/api/login/social/save-instagram-business-select-result', {
                facebook_id: this.facebook_id,
                instagram_account_ids: [selected]}
            ).then(response => {
                window.location.reload();
            });
        },

        checkInstagrams(data) {
            if (!data.data.instagram_accounts || data.data.instagram_accounts.length === 0) {
                this.is_show_instagram_error = true;
                this.is_show_instagrams = true;
                
            } else {
                this.facebook_id = data.data.facebook_id;

                if (data.data.instagram_accounts.length === 1) {
                    data.data.instagram_accounts.forEach((account, index) => {
                        this.instagrams.push({account: account, selected: index === 0});
                    });

                    this.postSelectedAccount();
                } else if (data.data.instagram_accounts.length > 1) {
                    data.data.instagram_accounts.forEach((account, index) => {
                        this.instagrams.push({account: account, selected: index === 0});
                    });

                    this.is_show_instagrams = true;
                }
            }


        }
    },

    mounted() {
        // this.is_show_instagrams = true;
        // this.is_show_instagram_error = true;
        if (window.is_logged && window.localStorage.getItem('instagram_business_connection') !== null) {
            this.$ajax.get('/api/login/social/get-prior-instagram-business-connection').then(response => {
                this.checkInstagrams(response.data);
            }).catch(error => {
                // console.log('error', error.response.status);
                if (error.response.status === 400) {
                    this.is_show_instagram_error = true;
                    this.is_show_instagrams = true;
                }

                // const response = {};
                // response.data = {
                //     "data": {
                //         "facebook_id": "122127953810272905",
                //         "instagram_accounts": [
                //             {
                //                 "facebook_page_id": "350500768143730",
                //                 "facebook_page_name": "Ghbjy1128 Connected Page",
                //                 "facebook_page_avatar": "https:\/\/scontent-iad3-2.xx.fbcdn.net\/v\/t39.30808-1\/448305294_122101953950359326_823337949617504437_n.jpg?stp=c253.120.294.294a_cp0_dst-jpg_s50x50&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=2hYC-v3I6joQ7kNvgFoJmV3&_nc_ht=scontent-iad3-2.xx&edm=AGaHXAAEAAAA&oh=00_AYC77jV96wVwCQNR9RyqD-HU3rMNfgUffHxTha0psf5WMg&oe=6676CCF3",
                //                 "instagram_token": "EAAGHZBvsHesEBOZCurPulO3jJ7xssQJe4SdUHRZCRFaitgIljuSdyMhoMTwgeY5ksZCeHTw1oGeJBPUUv6ZCk0U7EZB4eAPxhIqiGI1KyVsEd0ahnnBUTgqLaaAfcG7ZCIkQAzmp79YV1c13T9ddugiRbQx5g88w4RlZAn4amEquQkbmSpRXStcddgIoREjkSg7Wb86FwYq6",
                //                 "instagram_id": "17841436163845780",
                //                 "instagram_name": "ella_guziqi",
                //                 "instagram_avatar": "https:\/\/scontent-iad3-1.xx.fbcdn.net\/v\/t51.2885-15\/272237412_659954698429404_5286593392388175186_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=7d201b&_nc_ohc=HIUdT0Yi4PMQ7kNvgER9mE3&_nc_ht=scontent-iad3-1.xx&edm=AGaHXAAEAAAA&oh=00_AYCTumOs_Uckf1SN93pf2KdZWKwq_vwXpd9FCs0pBDcniQ&oe=6676A58F",
                //                 "permission_check_result": {
                //                     "permit_facebook_auth": true,
                //                     "facebook_page_connect_instagram": true,
                //                     "instagram_handle_match": true,
                //                     "the_token_is_valid": true,
                //                     "instagram_handle": "ella_guziqi",
                //                     "facebook_page_name": "Ghbjy1128 Connected Page",
                //                     "facebook_page_avatar": "https:\/\/scontent-iad3-2.xx.fbcdn.net\/v\/t39.30808-1\/448305294_122101953950359326_823337949617504437_n.jpg?stp=c253.120.294.294a_cp0_dst-jpg_s50x50&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=2hYC-v3I6joQ7kNvgFoJmV3&_nc_ht=scontent-iad3-2.xx&edm=AGaHXAAEAAAA&oh=00_AYC77jV96wVwCQNR9RyqD-HU3rMNfgUffHxTha0psf5WMg&oe=6676CCF3",
                //                     "instagram_avatar": "https:\/\/scontent-iad3-1.xx.fbcdn.net\/v\/t51.2885-15\/272237412_659954698429404_5286593392388175186_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=7d201b&_nc_ohc=HIUdT0Yi4PMQ7kNvgER9mE3&_nc_ht=scontent-iad3-1.xx&edm=AGaHXAAEAAAA&oh=00_AYCTumOs_Uckf1SN93pf2KdZWKwq_vwXpd9FCs0pBDcniQ&oe=6676A58F"
                //                 },
                //                 "is_authorize_permission": 1,
                //                 "is_public_account": 1
                //             },
                //             {
                //                 "facebook_page_id": "350500768143730",
                //                 "facebook_page_name": "Ghbjy1128 Connected Page",
                //                 "facebook_page_avatar": "https:\/\/scontent-iad3-2.xx.fbcdn.net\/v\/t39.30808-1\/448305294_122101953950359326_823337949617504437_n.jpg?stp=c253.120.294.294a_cp0_dst-jpg_s50x50&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=2hYC-v3I6joQ7kNvgFoJmV3&_nc_ht=scontent-iad3-2.xx&edm=AGaHXAAEAAAA&oh=00_AYC77jV96wVwCQNR9RyqD-HU3rMNfgUffHxTha0psf5WMg&oe=6676CCF3",
                //                 "instagram_token": "EAAGHZBvsHesEBOZCurPulO3jJ7xssQJe4SdUHRZCRFaitgIljuSdyMhoMTwgeY5ksZCeHTw1oGeJBPUUv6ZCk0U7EZB4eAPxhIqiGI1KyVsEd0ahnnBUTgqLaaAfcG7ZCIkQAzmp79YV1c13T9ddugiRbQx5g88w4RlZAn4amEquQkbmSpRXStcddgIoREjkSg7Wb86FwYq6",
                //                 "instagram_id": "17841436163845780",
                //                 "instagram_name": "ella_guziqi",
                //                 "instagram_avatar": "https:\/\/scontent-iad3-1.xx.fbcdn.net\/v\/t51.2885-15\/272237412_659954698429404_5286593392388175186_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=7d201b&_nc_ohc=HIUdT0Yi4PMQ7kNvgER9mE3&_nc_ht=scontent-iad3-1.xx&edm=AGaHXAAEAAAA&oh=00_AYCTumOs_Uckf1SN93pf2KdZWKwq_vwXpd9FCs0pBDcniQ&oe=6676A58F",
                //                 "permission_check_result": {
                //                     "permit_facebook_auth": true,
                //                     "facebook_page_connect_instagram": true,
                //                     "instagram_handle_match": true,
                //                     "the_token_is_valid": true,
                //                     "instagram_handle": "ella_guziqi",
                //                     "facebook_page_name": "Ghbjy1128 Connected Page",
                //                     "facebook_page_avatar": "https:\/\/scontent-iad3-2.xx.fbcdn.net\/v\/t39.30808-1\/448305294_122101953950359326_823337949617504437_n.jpg?stp=c253.120.294.294a_cp0_dst-jpg_s50x50&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=2hYC-v3I6joQ7kNvgFoJmV3&_nc_ht=scontent-iad3-2.xx&edm=AGaHXAAEAAAA&oh=00_AYC77jV96wVwCQNR9RyqD-HU3rMNfgUffHxTha0psf5WMg&oe=6676CCF3",
                //                     "instagram_avatar": "https:\/\/scontent-iad3-1.xx.fbcdn.net\/v\/t51.2885-15\/272237412_659954698429404_5286593392388175186_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=7d201b&_nc_ohc=HIUdT0Yi4PMQ7kNvgER9mE3&_nc_ht=scontent-iad3-1.xx&edm=AGaHXAAEAAAA&oh=00_AYCTumOs_Uckf1SN93pf2KdZWKwq_vwXpd9FCs0pBDcniQ&oe=6676A58F"
                //                 },
                //                 "is_authorize_permission": 1,
                //                 "is_public_account": 1
                //             }
                //         ]
                //     },
                //     "status": "success"
                // };

                // this.checkInstagrams(response.data);
            });
        }
        window.localStorage.removeItem('instagram_business_connection');

    }
    

}
</script>

<style lang="scss">

    .connect-social-modal {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 19991;
        background: rgba(0, 0, 0, 0.5);

        .inner {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .box {
            width: calc(100% - 32px);
            max-width: 520px;
            padding: 32px;
            background: #fff;
            border-radius: var(--radius-medium);
            box-shadow: var(--bot-4dp);
            display: grid;
            grid-template-rows: 1fr auto;
            grid-template-columns: 100%;
            position: relative;
            max-height: 100%;

            .close {
                position: absolute;
                left: 8px;
                top: 8px;
                cursor: pointer;

                &.right {
                    left: auto;
                    right: 8px;
                }

                svg {
                    height: 24px;
                    width: 24px;
                    fill: var(--Black-40);
                }

                &:hover {
                    svg {
                        fill: var(--Text-brand);
                    }
                }
            }

            @media screen and (max-width: 768px) {
                width: calc(100% - 16px);
                max-height: calc(100% - 16px);
                padding: 24px;
            }

            // @media screen and (max-width: 480px) {
            //     width: calc(100% - 16px);
            //     max-height: calc(100% - 16px);
            //     overflow: auto;
            //     padding: 16px;
            // }
            .top {
                overflow: auto;
            }

            a {
                color: var(--Text-link);

                &:hover {
                    text-decoration: underline;
                }
            }

            h2 {
                font: var(--sub-2);
                margin-bottom: 16px;
                color: var(--Text-primary);

                &.center {
                    text-align: center;
                }
            }

            h3 {
                font: var(--sub-3);
                margin-bottom: 16px;
                color: var(--Text-primary);
            }

            .link-text {
                font: var(--text-s-regular);
                color: var(--Text-primary);
                margin-bottom: 16px;
                text-align: left;

                span {
                    color: var(--Text-link);
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .texts {
                font: var(--text-md-light);
                color: var(--Text-primary);
                margin-bottom: 16px;

                strong {
                    font: var(--sub-5);
                }
            }

            .text {
                font: var(--text-s-regular);
                color: var(--Text-default);
                margin-bottom: 16px;
                text-align: center;
            }


            .icons {
                display: flex;
                justify-content: center;
                margin: 2vh 0 3.5vh 0;

                @media screen and (max-width: 768px) {
                    margin: 12px 0;
                }

                // @media screen and (max-width: 480px) {
                //     margin: 8px 0;
                // }

                .ico {
                    height: 82px;
                    width: 82px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    background: var(--Black-90);
                    margin: 24px 0;

                    @media screen and (max-width: 768px) {
                        height: 64px;
                        width: 64px;
                    }

                    // @media screen and (max-width: 480px) {
                    //     height: 48px;
                    //     width: 48px;
                    // }

                    svg {
                        height: 72%;
                        width: 72%;
                        fill: var(--Text-invert);

                        &.svg-icon--hero-logo-blue {
                            height: 100%;
                            width: 100%;
                        }

                        &.svg-icon--arrow-next {
                            width: 40%;
                            height: 40%;
                            fill: var(--Black-90);
                        }
                    }

                    &.arrow {
                        background: var(--Text-invert);
                    }

                    &.hero-logo {
                        background: var(--Brand-Primary);
                    }

                    &.facebook {
                        margin-left: -24px;
                        z-index: 1;
                    }
                }
            }

            .pop {
                text-align: center;
                padding-bottom: 24px;

                p {
                    color: var(--Text-default);
                }
            }

            .lists {
                margin: 24px 0;

                dt {
                    font: var(--sub-5);
                    margin-bottom: 8px;
                    color: var(--Text-secondary);
                    text-align: left;
                }

                dd {
                    font: var(--text-s-regular);
                    color: var(--Text-default);
                    margin-bottom: 8px;
                    padding-left: 28px;
                    position: relative;
                    text-align: left;

                    i {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: -10px;
                        font-style: normal;
                        font: var(--text-s-regular);
                        color: var(--Text-invert);
                        height: 20px;
                        line-height: 20px;
                        width: 20px;
                        text-align: center;
                        border-radius: 50%;
                        background-color: var(--Brand-Primary);
                    }
                }
            }

            .verified-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 16px 0;
                text-align: left;
                background-color: var(--Neu-10);
                padding: 16px 16px 16px 38px;
                position: relative;
                border-radius: 16px;

                svg {
                    position: absolute;
                    height: 14px;
                    width: 14px;
                    fill: var(--Text-Primary);
                    left: 16px;
                    top: 17px;
                }

                h3 {
                    font: var(--sub-5);
                    color: var(--Text-primary);
                    margin: 0 0 8px;
                }

                p {
                    font: var(--span-regular);
                    color: var(--Text-secondary);
                }
            }

            .links {
                text-align: left;

                dt {
                    font: var(--text-s-regular);
                    margin: 8px 0;
                    color: var(--Text-primary);
                }

                dd {
                    font: var(--text-s-regular);
                    margin: 8px 0;
                
                }
            }

            .cop {
                font: var(--span-regular);
                color: var(--Text-default);
                margin: 16px 0;
                text-align: left;
            }

            .m-button {
                display: flex;
                text-align: center;
                margin: 24px 16px 0;
            }
        }

        .instagram-item {
            display: block;
            margin: 8px 0;
            height: 48px;
            padding: 0 12px;
            display: flex;
            align-items: center;
            border: 1px solid var(--Black-10);
            border-radius: var(--radius-small);
            cursor: pointer;

            &:hover {
                background-color: var(--Neu-10);
            }

            figure {
                height: 32px;
                width: 32px;
                position: relative;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    background-color: var(--Black-10);
                }

                i {
                    position: absolute;
                    right: -2px;
                    bottom: -2px;
                    height: 18px;
                    width: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--Black-90);
                    border: 1px solid var(--Text-invert);
                    border-radius: 50%;
                    overflow: hidden;

                    svg {
                        height: 70%;
                        width: 70%;
                        fill: var(--Text-invert);
                    }
                }
            }

            h3 {
                flex: 1;
                padding: 0 12px;
                font: var(--text-m-regular);
                color: var(--Text-primary);
                margin-bottom: 0;

                span {
                    color: var(--Text-default);
                    padding-left: 8px;
                }
            }

            svg {
                height: 20px;
                width: 20px;
            }

            .expired {
                font: var(--text-m-regular);
                color: var(--Text-danger);
                text-transform: uppercase;
                margin-left: 8px;
            }
        }

    }


</style>
