<template>
    <div class="login-box" :class="{'home-login-box': is_home}" v-if="!is_home || (is_home && ( $store.state.type==='mobile' || isMotomApp))">
        <header v-if="is_home">
            <h1>WELCOME TO MOTOM</h1>
            <h3>Join today!</h3>
        </header>
        <h2 v-else>Join Motom Today</h2>
        <ul>
            <li><m-button type="base" @click.native="connect('email', $event)" class="email"><i><svg-icon name="email" /></i><span>Sign up with email</span></m-button></li>
            <!-- 8979 not sign up in tk again ,but can login-->
            <!-- <li><m-button type="base" @click.native="connect('tiktok', $event)" class="tiktok"><i><svg-icon name="tiktok" /></i><span>Sign up with TikTok</span></m-button></li> -->
            <li>
                <m-button type="base" :is_disabled="is_webview" @click.native="connect('Google', $event)" class="google">
                    <i v-if="is_webview"><svg-icon name="google-g-no-circle" /></i>
                    <i v-else><svg-icon name="google-color" /></i>
                    <span>Sign up with Google</span>
                </m-button>
                <p v-if="is_webview"><svg-icon name="alert" /> Please open Motom.me in your mobile browser to sign up with Google</p>
            </li>
            <li>
                <m-button type="base" class="apple" @click.native="connect('apple', $event)"><i><svg-icon name="apple" /></i><span>Sign up with Apple</span></m-button>
            </li>
            <li><m-button type="base" @click.native="connect('Facebook', $event)" class="facebook"><i><svg-icon name="facebook" /></i><span>Sign up with Facebook</span></m-button></li>
        </ul>
        <p class="p">By continuing you agree to Motom’s <router-link @click.native="hideSidebar" to="/page/terms_of_use">Terms of Use</router-link> &amp; <router-link to="/page/privacy_policy" @click.native="hideSidebar">Privacy Policy</router-link></p>
        <m-button type="secondary" v-if="is_home" @click.native="loginUrl" style="
        margin: auto;
        display: flex;
        width: 220px;
        ">Already have an account? Login</m-button>
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';

export default {
    name: 'login-box',

    props: {
        is_home: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            is_webview: false,
        }
    },

    methods: {
        setBackUrl(type) {
            this.GA_Tracking('Account Auth', 'Account Auth', type);
            if (type !== 'Email') {
                this.$cookie.set('motom_back_url', window.location.href, { expires: '5m' });
            }
        },
        loginUrl() {
            // if (this.isMotomApp && this.$route.query.app_landing === '1') {
            //     return '/login/email?app_landing=1';
            // } else {
            //     return '/login/email';
            // }
            this.$store.commit('openLogin', {
                is_show: true,
                is_signup: false,
                title: '',
                text: ''
            });
        },
        hideSidebar() {
            if (!this.is_home) {
                const obj = {};
                obj.is_show = false;

                EventBus.$emit('toggle-sidebar', obj);
            }
        },

        connect(type) {
            if (type === 'tiktok' && this.$store.state.is_tiktok_banned) {
                this.$store.state.is_show_tiktok_down_popup = true;
                return;
            }
            this.hideSidebar();

            switch (type) {
                case 'Google':
                    if (this.is_webview) {
                        this.show_webview_content = true;
                    } else {
                        if (this.isMotomApp) {
                            window.ReactNativeWebView.postMessage('connect_google');
                        } else {
                            window.location.href = '/login/social/google';
                        }
                        this.setBackUrl('Google');
                    }

                    break;

                case 'Facebook':
                    // EventBus.$emit('show-error-msg', 'facebook');
                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_facebook');
                    } else {
                        window.location.href = '/login/social/facebook';
                    }


                    this.setBackUrl('Facebook');
                    break;

                case 'apple':
                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_apple');
                    } else {
                        window.location.href = '/login/social/apple';
                    }

                    this.setBackUrl('Apple');
                    break;

                case 'tiktok':
                    if (this.isMotomApp) {
                        window.ReactNativeWebView.postMessage('connect_tiktok');
                    } else {
                        window.location.href = '/login/social/tiktok_business';
                    }
                    this.setBackUrl('TikTok');
                    break;

                case 'email':
                    window.location.href = '/register?email=true';
                    break;
            }

        },

        setBackUrl(type) {
            this.GA_Tracking('Account Auth', 'Account Auth', type);
            // this.fb_tracking_registration(false);
            this.$cookie.set('motom_back_url', window.location.href, { expires: '5m' });
        },

    },
    
    mounted() {
        this.is_webview = this.isWebview();
    },
};

</script>
<style lang="scss">
    @import '../resources/sass/_variables.scss';

    .login-box {
        margin: var(--spacing-05);
        padding: var(--spacing-05);
        border-radius: 16px;
        background: var(--Bg-02);

        h2 {
            font: var(--heading-3);
            margin: 0 0 10px;
            color: var(--Black-100);
        }

        p {
            font: var(--text-s-light);
            margin: 12px 0 0;
            color: var(--Text-default);

            a {
                border-bottom: 1px solid;
                color: var(--Text-default);
            }
        }

        li {
            margin: 8px 0;

            p{
                display: flex;
                color: var(--Text-danger);
                font: var(--text-s-light);
                text-align: left !important;
                margin: 0 !important;

                svg{
                    height: 12px;
                    width: 12px;
                    fill: var(--Text-danger);
                    margin-right: 6px;
                }
            }

            .m-button {
                display: flex;
                justify-content: flex-start;

                span {
                    flex: 1;
                    text-align: center;
                }
            }
        }

        li > {
            .facebook {
                i svg {
                    fill: var(--Facebook-new);
                    color: var(--Facebook-new);
                }
            }

            .tiktok {
                i svg {
                    fill: var(--TikTok);
                    color: var(--TikTok);
                }
            }

            .apple {
                i svg {
                    fill: var(--Apple);
                    color: var(--Apple);
                }
            }

            .email {
                i svg {
                    fill: var(--Black-100);
                    color: var(--Black-100);
                }
            }
        }

        &.home-login-box {
            margin: 0 auto 2px;
            max-width: 865px; // max-width: 520px;
            border-radius: 0 0 16px 16px;
            padding: 20px;

            h1 {
                color: var(--Black-100);
                font: var(--heading-0);
                text-align: center;
                margin: 0 0 8px;
            }

            h3 {
                color: var(--Black-100);
                font: var(--heading-4);
                margin: 0 0 20px 0;
                text-align: center;
            }

            p {
                font: var(--text-s-light);
                margin: 12px 0 20px;
                text-align: center;
            }


        }
    }

    @media (min-width: $mobile_size) {
        .login-box.home-login-box {
            //display: none;

        }
    }
</style>
