<template>
    <div>
        <div class="more-popup-menu" ref="share">
            <div class="more-popup-menu--wrapper" :class="['type--' + type]">
                <div class="more-popup-menu--container">
                    <header class="more-popup-menu--header">
                        <h2 class="more-popup-menu--title">{{ show_likeboards ? 'Select Collections' : title }}</h2>
                        <span class="close-button" @click="close()">
                            <svg-icon name="close" />
                        </span>
                    </header>

                    <div class="more-popup-menu--body">
                        <slot></slot>
                    </div>

                    <footer class="more-popup-menu--footer select-likeboard-footer" v-if="show_likeboards">
                        <m-button type="secondary" size="40" key="1" @click.native="openLikeboardInfo(0)"><svg-icon
                                name="plus-bold" />Create</m-button>
                        <m-button size="40" :is_disabled="!confirm_actived" :key="confirm_actived"
                            @click.native="confirmLikeboard()">Done</m-button>
                    </footer>
                    <footer class="more-popup-menu--footer" v-else>
                        <m-button size="40" type="secondary" @click.native="close(false)">Cancel</m-button>
                    </footer>
                </div>
                <div class="more-popup-menu--bg" @click="close(false)"></div>
            </div>
        </div>

        <LikeboardInfoPopUp ref="edit_menu" @createLikeboard="createLikeboard" />
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';
import LikeboardInfoPopUp from '@/components/LikeboardInfoPopUp.vue';

export default {
    name: 'MoreMenuPopup',

    data() {
        return {
            is_me: false,
            is_followed: false,
            is_loaded_data: false,
            is_my_likeboard: false,
            is_include_products: true,
            show_recreate_button: false,
            show_delete_modal: false,
            show_unlike_modal: false,
            show_unlike_this_modal: false,
            show_tooltip: false,
            likeboards: [],
            default_likeboard_id: 0,
            add_ids: [],
            remove_ids: [],
            show_pin_modal: false,
            tooltip_text: '',
            is_oc_post: false,
            show_title: '',
        };
    },

    components: {
        LikeboardInfoPopUp,
    },

    props: {
        post: Object,
        likeboard_id: Number,
        type: String,
        title: {
            type: String,
            default: ''
        },
        show_likeboards: {
            type: Boolean,
            default: false
        },
        confirm_actived: {
            type: Boolean,
            default: false
        }
    },


    methods: {


        open() {
            this.is_loaded_data = false;
            this.is_include_products = true;
            this.$refs.share.classList.add('open');

            setTimeout(() => {
                this.$refs.share.classList.add('open-bg');
                // this.checkData();
            }, 10);

            setTimeout(() => {
                this.$refs.share.classList.add('open-container');
            }, 150);
        },

        close(is_click_button_close) {
            const self = this;

            if (self.$refs.share) self.$refs.share.classList.remove('open-container');

            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open-bg');
                this.$emit('backMainPage');
            }, 200);

            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open');
                self.$emit('closeCallback');

                if (is_click_button_close) {
                    self.$emit('callback');
                }
            }, 300);
        },

        share() {
            const obj = {};
            this.close();

            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            label = label + ',' + this.post.unique_key + ',,';
            this.GA_Tracking('SHARE', 'CLICK', label);

            obj.description = this.post.post_caption;
            obj.shareURL = `${window.location.origin}/postdetails/${this.post.unique_key}`;
            obj.postType = this.post.post_type;
            obj.share_post = this.post;

            EventBus.$emit('open-post-share', obj);
        },

        sendEmail(title) {
            const body1 = `The post id is ${this.post.post_id}`;
            const body2 = `The author is ${this.post.post_author_name} (user id ${this.post.post_author_id}) `;
            window.location.assign('mailto:help@motom.me?Subject=' + encodeURIComponent(title) + '&body=' + encodeURIComponent(body1) + '%0D%0A' + body2);
        },

        backMainPage() {
            this.$emit('backMainPage');
        },

        confirmLikeboard() {
            this.$emit('confirmLikeboard');
        },

        openLikeboardInfo(info_type) {
            this.$refs.edit_menu.open(info_type);
        },

        createLikeboard(likeboard) {
            this.$emit('createLikeboard', likeboard);
        },

        err(err) {
            this.$store.state.show_loading = false;
            if (err.response.data.message === 'Unauthenticated.') {
                this.$store.commit('openLogin', {
                    is_show: true,
                    title: '',
                    text: ''
                });
            } else {
                console.log(err.response.data.data.message);
            }
        },

    },


};
</script>

<style lang="scss">
@import '../resources/sass/_variables.scss';

.remove-link {
    color: var(--Text-danger);
}

.more-popup-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    display: none;

    &--wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &.type--dislike-product,
        &.type--dislike-post {
            .more-popup-menu--title {
                text-align: center;
            }
        }
    }

    &--container {
        position: absolute;
        z-index: 9;
        overflow: hidden;
        left: 50%;
        width: 100%;
        max-width: var(--modal-max-width);
        box-shadow: var(--pop-medium);
        bottom: 0;
        transition: transform .3s ease;
        transform: translate3d(-50%, 100%, 0);
        background: var(--Bg-01);
        border-radius: 16px 16px 0 0;
    }

    &--bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(var(--Black-100-rgba), .8);
        opacity: 0;
        transition: opacity .2s ease;
    }

    &--header {
        padding: 0 16px;
        min-height: 48px;
        display: flex;
        align-items: center;
        background-color: var(--Bg-light);

        h2 {
            flex: 1;
            text-transform: uppercase;
        }

        .close-button {
            height: 32px;
            width: 32px;
            color: var(--Bg-light);
            cursor: pointer;
            position: relative;
            right: -.8rem;

            svg {
                height: 32px;
                width: 32px;
                display: block;
                fill: var(--Text-default);
            }

            &:hover svg {
                fill: var(--Brand-Primary);
            }
        }
    }

    &--title {
        font: var(--sub-2);
        position: relative;
        background: var(--Bg-light);
        color: var(--Text-primary);
        overflow: hidden;
    }

    &--body {
        color: var(--Text-primary);
        background: var(--Bg-01);
        max-height: 60vh;
        overflow: auto;

        @media (max-width: 480px) {}

    }

    .more-popup-menu--footer {
        padding: var(--spacing-05);
        border-top: 1px solid var(--Black-20);
        display: flex;
        background-color: var(--Bg-light);

        .m-button {
            width: 100%;
            padding: 0;
        }

    }

    .select-likeboard-footer {

        .m-button {
            flex: 1;

            svg {
                margin-right: 8px;
                width: 12px;
                height: 12px;
                min-width: 12px;
                min-height: 12px;
            }

            flex: initial;
            width: calc(100% - 90px);

            &:first-child {
                width: 82px;
                margin-right: 10px;
            }

        }
    }

    .select-include-products {
        border-top: 1px solid var(--Black-20);
    }

    .ui-checkbox-item {
        $size: 24px;
        background: var(--Bg-light);
        padding: 12px 12px 12px;
        display: flex;
        position: relative;

        label {
            flex: 1;
            color: var(--Text-secondary);
            font: var(--sub-5);

            &::before {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                content: '';
                z-index: 3;
                cursor: pointer;
            }

            &>h4 {
                margin: 0 0 8px;
                text-align: left;
                font-size: var(--font-s);
            }

            &>p {
                font-size: var(--font-xxs);
                text-align: left;
            }
        }

        input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: -1;
            visibility: hidden;
        }

        span {
            border: 1px solid var(--Black-40);
            box-sizing: border-box;
            height: $size;
            border-radius: $size / 2;
            width: $size * 1.8;
            display: block;
            position: relative;
            transition: all .2s;
            background: var(--Black-10);
            top: -.2rem;

            &::before {
                position: absolute;
                box-sizing: border-box;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
                border: 1px solid var(--Black-40);
                height: $size;
                width: $size;
                top: -1px;
                left: -1px;
                content: '';
                background: var(--Bg-light);
                border-radius: 50%;
                transition: all .2s;
            }
        }

        input:checked+span {

            &::before {
                border: 1px solid var(--Black-40);
                left: $size*.8;
                background: var(--Brand-Primary);

            }
        }
    }

    .add-to-likeboards--create {
        margin: 8px 0;
    }

    &.open {
        display: block;
    }

    &.open-container &--container {
        transform: translate3d(-50%, 0%, 0);
    }

    &.open-bg &--bg {
        opacity: 1;
    }
}

.delete-modal {
    z-index: 10002;

    .modal--container {
        border-radius: 16px;
    }

    .box {
        padding: 30px 20px;
        text-align: center;
        width: 320px;
        margin: 0 auto;
    }

    h2 {
        font-weight: var(--font-light);
        font-size: var(--font-xl);
        color: var(--Text-primary);
    }

    p {
        margin: 10px 0;
        color: var(--Text-secondary);
    }

    .ico {
        display: block;
        margin: 0 0 30px;

        svg {
            fill: var(--Yellow-30);
            height: 130px;
            width: 130px;
        }
    }

    ul {
        display: flex;
        justify-content: center;
        margin: 30px 0 0;

        li {
            border: none !important;
        }
    }

    .btn {
        cursor: pointer;
        line-height: 1;
        padding: 12px 20px;
        border-radius: 6px;
        border: 1px solid var(--Black-50);
        text-align: center;
        display: inline-block;
        min-width: 8rem;
        color: var(--Text-secondary);
        background: var(--Bg-light);
        font-weight: var(--font-light);

        &:hover {
            border-color: var(--Text-brand);
            color: var(--Text-brand);
        }

        &.disabled,
        &.disabled:hover {
            cursor: default;
            background: var(--Black-10);
            border-color: var(--Black-10);
            color: var(--Text-secondary);
        }

        &.actived,
        &.actived:hover {
            background: var(--Brand-Primary);
            border-color: var(--Text-brand);
            color: var(--Bg-light);
            cursor: pointer;
        }
    }
}

@media (min-width: $mobile_size) {
    .more-popup-menu {

        &--container {
            opacity: 0;
            transition: opacity .2s ease;
            transform: translate3d(-50%, -50%, 0);
            border-radius: 16px;
            right: auto !important;
            bottom: auto !important;
            left: 50%;
            top: 50%;
        }

        &--header {
            display: flex;
            align-items: center;
        }

        &.open-container &--container {
            transform: translate3d(-50%, -50%, 0);
        }

        &.open-bg &--container,
        &.open-bg &--bg {
            opacity: 1;
        }
    }
}
</style>
