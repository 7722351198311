<template>
    <transition name="m-modal">
        <div class="m-modal" ref="modal" :class="['type-' + type, 'algin-' + algin].concat(classname)">
            <div class="m-modal--wrapper">
                <div class="m-modal--container">
                    <header class="m-modal--header">
                        <slot name="header"></slot>
                        <svg-icon v-if="type === 'question'" name="question-circle" />
                        <h2 v-if="title" class="m-modal--title" v-html="title"></h2>
                        <i v-if="show_close" class="m-modal--close" @click="$emit('close')"><svg-icon
                                name="close-no-circle" /></i>
                    </header>
                    <div class="m-modal--body" v-if="text" v-html="text">
                    </div>
                    <div :class="['m-modal--content',bc_white && 'm-modal--content-white']">
                        <slot></slot>

                        <footer class="m-modal--footer" v-if="type === 'question'">
                            <m-button @click.native="$emit('close')">Close</m-button>
                        </footer>
                    </div>

                    <footer class="m-modal--footer" v-if="type === 'dialog' && had_footer">
                        <m-button size="40" type="secondary" @click.native="$emit('close')" :is_width="true">Cancel</m-button>
                    </footer>

                </div>
                <div class="m-modal--bg" @click="$emit('close')"></div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'modal',
    props: {
        classname: Array,
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        show_close: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: 'default'//confirm, question ,dialog
        },
        algin: {
            type: String,
            default: 'left'
        },
        had_footer:{
            type:Boolean,
            default:false,
        },
        bc_white:{
            type:Boolean,
            default:false,
        }
    },
}
</script>

<style lang="scss">
@import '../resources/sass/_variables.scss';

@mixin flexCenter {
    display: flex;
    align-items: center;
}

.m-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10008;
    transition: opacity .3s ease;

    .m-modal--header{
        h2{
            text-transform: uppercase;
        }
    }

    a {
        color: var(--Text-link);
    }

    &.type-confirm {
        .m-modal--container {
            min-width: 320px;
            max-width: 380px;
        }

        .m-modal--content {
            padding: 8px 24px 24px;

            h2 {
                font: var(--sub-4);
                color: var(--Text-primary);
                margin-bottom: 10px;
            }

            .icon {
                margin: 0 auto 24px;
                height: 68px;
                width: 68px;
                display: block;

                svg {
                    display: block;
                    height: 100%;
                    width: 100%;
                }

                .svg-icon--question-circle {
                    fill: var(--Text-warning);
                }

                .svg-icon--checked-circle {
                    fill: var(--Text-safe);
                }

                .svg-icon--clock {
                    fill: var(--Text-link);
                }
            }

            p {
                font: var(--text-s-light);
                color: var(--Text-secondary);
                margin: 8px 0;
            }

            a {
                color: var(--Text-link);
            }

            footer {
                margin-top: 24px;

                .m-button {
                    min-width: 54px;
                    text-align: center;
                    margin: 0 4px;
                }
            }
        }
    }

    &.algin-center {
        .m-modal--content {
            text-align: center;
        }
    }

    &.algin-left {
        .m-modal--content footer {
            text-align: center;
        }
    }

    &.algin-left-right {
        footer {
            text-align: right;
        }
    }

    &--wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: auto;
        align-items: center;
        box-sizing: border-box;
        padding: var(--spacing-04);
    }

    &--container {
        position: relative;
        z-index: 9;
        background: var(--Black-00);
        margin: auto;
        border-radius: 16px;
        overflow: hidden;
        min-width: 260px;
        max-width: 520px;
        box-shadow: var(--pop-medium);
        transition: all .3s ease;
        width: 100%;
    }

    &--bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(var(--Black-100-rgba), .8);
    }

    &--header {
        padding: 0 16px;
        min-height: 48px;
        display: flex;
        align-items: center;
        position: relative;
        // border-bottom: 1px solid var(--Black-20);
    }

    &--close {
        height: 20px;
        width: 20px;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);

        svg {
            height: 100%;
            width: 100%;
            display: block;
            fill: var(--Text-secondary);
        }

        &:hover svg {
            fill: var(--Brand-Primary);
        }
    }

    &--title {
        font: var(--heading-2);
        color: var(--Text-primary);
    }

    &--body {
        font-size: var(--font-s);
        color: var(--Text-primary);
        text-align: center;
        padding: 0 16px 16px;
    }

    &.type-question {
        min-height: initial;

        .m-modal--header {
            padding: var(--spacing-05) var(--spacing-05) 6px;

            h2 {
                font: var(--sub-3);
                color: var(--Text-primary);
            }
        }

        .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: var(--spacing-03);
            fill: var(--Text-brand);
        }

        .m-modal--content {
            padding: 0 var(--spacing-05) var(--spacing-05);

            p {
                font: var(--text-md-light);
                margin-bottom: var(--spacing-06);
                color: var(--Text-secondary);
            }

        }

        footer {
            display: flex;
            justify-content: flex-end;

            .m-button {
                height: initial;
                padding: var(--spacing-04) var(--spacing-07);
            }
        }
    }

    &.type-dialog {

        .m-modal--header {
            h2 {
                font: var(--sub-3);
                color: var(--Text-primary);
            }
        }

        .m-modal--content {
            background-color: var(--Bg-01);

            &.m-modal--content-white{
                background-color: var(--Bg-light);
            }

            .body{
                padding: var(--spacing-03) var(--spacing-05);
            }

            .inner-check-box {
                .item {
                    display: flex;
                    align-items: center;
                    font: var(--text-s-regular);
                    color: var(--Text-secondary);
                    cursor: pointer;
                    height: 32px;

                    &:hover {
                        background-color: var(--Neu-10);
                    }

                    &:last-child {
                        margin-bottom: unset;
                    }

                    &.actived {
                        color: var(--Text-brand);
                    }

                    svg {
                        width: 15px;
                        height: 15px;
                        fill: var(--Text-default);
                        margin-right: 4px;

                        &.svg-icon--checked-circle {
                            fill: var(--Brand-Primary);
                        }
                    }

                }

            }
        }

        footer {
            padding: var(--spacing-05) var(--spacing-06);
            @include flexCenter();
            background-color: var(--Bg-light);
            // margin-top: var(--spacing-04);
            box-shadow: var(--top-1dp);

            &.flex-footer{
                .m-button {
                    &.tertiary {
                        width: 80px;
                    }

                    &.primary {
                        margin-left: 10px;
                    }
                }
            }

        }

        @media (max-width: $mobile_size) {
            .m-modal--wrapper {
                padding: 0;
            }

            .m-modal--container {
                transform: translate3d(-50%, -50%, 0);
                border-radius: var(--spacing-05) var(--spacing-05) 0 0;
                right: auto !important;
                bottom: auto !important;
                left: 50%;
                top: 50%;
                max-width: initial;
            }

        }
    }

}

.m-modal-enter {
    opacity: 0;
}

.m-modal-leave-active {
    opacity: 0;
}

.m-modal-enter .m-modal--container,
.m-modal-leave-active .m-modal--container {
    transform: scale(1.1);
}
</style>
