<template>
    <div class="m-search" :class="[{'is-focus': is_foucs}, 'size-' + size]">
        <svg-icon name="search" />
        <input 
            type="text" 
            :placeholder="placeholder" 
            @focus="is_foucs = true; $emit('focus', keyword)" 
            @blur="is_foucs = false; $emit('blur', keyword)" 
            @input="$emit('input', keyword)" 
            @keyup.enter="$emit('enter', keyword)" 
            @clean="clean"
            v-model="keyword" 
            ref="input"
        >
        <i v-if="keyword" @click="clean"><svg-icon name="close-circle" /></i>
    </div>
</template>

<script>


export default {
    name: 'm-search',

    data() {
        return {
            is_foucs: false,
            keyword: '',
        }
    },

    props: {
        text: {
            default: '',
            type: String,
        },
        placeholder: {
            default: '',
            type: String,
        },
        spec:{
            default: false,
            type:Boolean
        },
        size: {
            default: '32',
            type: String,
        },
    },

    methods:{
        clean(){
            this.keyword = ''; 
            this.$refs.input.focus();
            this.$emit('clean', this.keyword);

            if (this.spec) {
                this.$emit('input', this.keyword);
            }
        }
    },

    mounted() {
        this.keyword = this.text;
    },

}
</script>

<style lang="scss">
.m-search {
    display: flex;
    position: relative;
    height: 32px;
    width: 100%;
    max-width: 270px;
    position: relative;
    border-radius: 16px;
    border: 1px solid var(--Brand-grey);
    background: var(--Bg-01);
    transition: all 0.2s ease;

    &.size-40 {
        height: 40px;
        max-width: 320px;
        border-radius: 20px;

        i {
            width: 40px;
            height: 40px;
        }
    }

    input {
        width: 100%;
        font: var(--sub-5);
        color: var(--Text-primary);
        padding: 0 0 0 40px;
        height: 100%;
        border: none;
        background: transparent;

        &::placeholder {
            color: var(--Text-default);
        }
    }

    .svg-icon--search {
        height: 22px;
        width: 22px;
        fill: var(--Text-primary);
        position: absolute;
        top: 50%;
        margin-top: -11px;
        left: 8px;
        pointer-events: none;
    }

    i {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            height: 18px;
            width: 18px;
            fill: var(--Text-default);
        }

        &:hover {
            svg {
                fill: var(--Text-primary);
            }
        }
    }

    &:hover {
        border-color: var(--Border-neu);
        background: var(--Bg-01);
        //background-color: var(--Surface-hover);

        input {
            &::placeholder {
                color: var(--Text-secondary);
            }
        }
    }
    
    &.is-focus,
    &.is-focus:hover {
        border-color: var(--Border-primary);
        background: var(--Bg-01);
        //background-color: var(--Surface-hover);

        input {
            &::placeholder {
                color: var(--Text-primary);
            }
        }

    }

}
</style>
