<template>
  <div class="colors-list">
    <ul>
      <li
        v-for="(color, index) in colors"
        :key="index"
        @click="$emit('changeColor', color.id)"
      >
        <ColorPickUi :is_checked="color.id === active_color_id" :color="color.color" />
      </li>
    </ul>
  </div>
</template>

<script>
import ColorPickUi from "@/components/ui/NewUi/ColorPickUi.vue";
import { LikeBoardColors } from "@/utils/OrginList";

export default {
  name: "LikeboardsColorList",
  components: {
    ColorPickUi,
  },

  props: {
    active_color_id: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      colors: LikeBoardColors,
    };
  },
};
</script>

<style lang="scss">
.colors-list {
  width: 100%;
  overflow: auto;
  margin-top: 6px;
  margin-bottom: -8px;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin-right: 10px;
    margin-bottom: var(--spacing-03);
  }
}
</style>
