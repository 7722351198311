<template>
    <div class="product-card" v-if="product" :id="'js-product--' + data.productInfo.id" :key="data.productInfo.id" :class="{'show-footer': is_show_footer}">
        <div class="item-box" @click="openMerchant()">
            <div class="image">
                <img v-if="image" :src="image" @error="error($event)" />
                
                <div v-if="show_seen_in && !is_like_card && social_list.filter(function (s) { return s.show; }).length > 0"  @click.stop="openMerchant('all')" class="product-info-seen-in">
                    <i v-for="(item, index) in social_list.filter((s) => { return s.show }).slice(0, 2)" :key="index" :class="[item.key]" @click.stop="openMerchant(item.key)">
                        <svg-icon :name="item.icon" />
                    </i>
                </div>

                <div class="ico-container" v-if="show_seen_in && show_hot_product">
                    <div v-if="data.productInfo.is_hot" class="hot-ico">
                        <svg-icon name="hot-no-color" />
                    </div>
                </div>
                
                <div class="ico-container" v-else-if="show_seen_in && ($route.name === 'private-profile-liked' || $route.name === 'private-profile-storefront' || $route.name === 'private-profile-tagged' || $route.name === 'private-profile-links')">
                    <div v-if="is_monetizable" class="piggy-ico">
                        <svg-icon name="piggybank-nocolor" />
                    </div>
                </div>

                <div v-if="is_like_card" :class="['like-button', { 'is-liked': data.productInfo.is_liked }, 'js-product-likeid-' + data.productInfo.id]" @click.stop="like" ref="like">
                    <svg-icon name="heart" />
                    <svg-icon name="heart2" />
                </div>
            </div>

            <div v-if="show_info && !is_like_card" class="info">
                <h3 class="product-name" v-if="data.productInfo.name">
                    <span v-html="data.productInfo.name"></span>
                </h3>
                <p class="brand-name" v-if="data.productInfo.brand_name && $route.meta !== 'brand-profile'" :title="data.productInfo.brand_name">
                    <span @click="saveUserJourney()" v-html="data.productInfo.brand_name"></span>
                </p>
                <div class="bottom">
                    <div class="price" v-if="data.productInfo.lowest_price">
                        <span v-html="data.productInfo.lowest_price"></span>
                    </div>

                    <div :class="['liked-btn', { 'is-liked': data.productInfo.is_liked }, 'js-product-likeid-' + data.productInfo.id]" @click.stop="like" ref="like">
                        <svg-icon name="heart" />
                        <svg-icon name="heart2" />
                    </div>
                    
                </div>
            </div>
        </div>
        <footer class="footer-icons" v-if="is_show_footer">
            <i class="add-bookmark" :class="[{ 'actived': data.productInfo.in_other_likeboard_count }, 'js-bookmark-el-' + data.productInfo.id]" @click.stop="bookmark">
                <svg-icon name="bookmark-add" />
                <svg-icon name="bookmark" />
            </i>
            <i class="add-store" :class="{ 'actived': data.productInfo.is_storefront }" @click.stop="store">
                <svg-icon name="storefront-round" v-if="data.productInfo.is_storefront"/>
                <svg-icon name="storefront-filled" v-else/>
            </i>
            
            <i v-if="is_monetizable" class="share" @click.stop="openMerchant('open-share-links')">
                <svg-icon name="link-2" />
            </i>
            <span>
                <i class="menu" @click.stop="showPopup('open_more_menu')">
                    <svg-icon name="post-pop-up-menu" />
                </i>
            </span>
        </footer>
    </div>
</template>

<script>
import { EventBus } from "@/events/bus.js";
import handleProductData from "@/utils/HandleProductData.js";
import { Orgin_social_list } from "@//utils/SocialData";

export default {
    name: 'product-card',
    data() {
        return {
            data: {
                productInfo: {}
            },
            social_list: JSON.parse(JSON.stringify(Orgin_social_list)),
            is_myself: false,
            is_private: false,
            is_resell: true,
            is_monetizable: false,
            is_show_footer: false,
            shorten_link: "",
            current_route: {},
            tooltip_text: '',
            tooltip_bg_color: '',
            image: '',
        };
    },
    

    props: {
        product: {
            type: Object,
            default: () => ({})
        },

        image_size: {
            type: String,
            default: '400x400'
        },

        is_like_card: {
            type: Boolean,
            default: false
        },

        is_show_menu: {
            type: Boolean,
            default: false
        },

        likeboard_id: {
            type: Number,
            default: 0
        },

        is_my_likeboard: {
            type: Boolean,
            default: false
        },

        show_info: {
            type: Boolean,
            default: false
        },

        show_seen_in: {
            type: Boolean,
            default: true
        },

        show_hot_product: {
            type: Boolean,
            default: false,
        },

        skimlinks_tracking: {
            type: String,
            default: ''
        },

        post_unique_key: {
            type: String,
            default: ''
        },

        from_popup: {
            type: Boolean,
            default: false
        },

        btn_type: {
            type: String,
            default: ''
        },
    },

    mounted() {
        this.is_private = this.$route.name.startsWith('private');
        this.social_list = [] ;
        this.social_list = JSON.parse(JSON.stringify(Orgin_social_list));
        this.getSocialList(this.product);

        this.current_route = this.$route;
        this.data.productInfo = handleProductData(this.product);
        this.is_monetizable = this.data.productInfo.merchant_list && Object.values(this.data.productInfo.merchant_list.flat()).find(mp => mp.is_monetizable == true) ? true : false;
        this.is_show_footer = (this.is_private && this.show_seen_in && !this.is_like_card) || this.is_show_menu;

        switch (this.image_size) {
            case '200x200':
                this.image = this.Get_Image(this.data.productInfo.images.img_200x200, '/product_photos/200x200/');
                break;

            case '400x400':
                this.image = this.Get_Image(this.data.productInfo.images.img_400x400, '/product_photos/400x400/');
                break;

            case 'original':
                this.image = this.Get_Image(this.data.productInfo.images.original, '/product_photos/original/');
                break;

            default:
                this.image = this.data.productInfo.thumbnail;
                break;
        }

    },

    methods: {
        // popup() {
        //     if (this.btn_type && this.btn_type === 'Share') {
        //         this.showPopup('share_merchant');
        //     } else {
        //         this.showPopup('open_merchant');
        //     }
        // },

        showPopup(type) {
            const obj = {
                type: type,
                data: this.data,
                btn_type: this.btn_type,
                tooltip_text: this.tooltip_text,
                tooltip_bg_color: this.tooltip_bg_color,
            };


            console.log(obj)

            if (this.skimlinks_tracking !== '') obj.post_ref = this.skimlinks_tracking;

            EventBus.$emit('profile_product_popup', obj);
        },


        like() {
            if (this.$store.state.is_logged) {
                const obj = {};
                obj.type = 'product';
                obj.post = JSON.parse(JSON.stringify(this.data.productInfo));
                obj.unique_key = JSON.parse(JSON.stringify(this.data.productInfo.id));
                obj.is_liked = this.$refs.like.classList.contains('is-liked');
                obj.is_my_likeboard = this.is_my_likeboard;
                obj.likeboard_id = this.likeboard_id;
                obj.is_bookmark = false;
                // this.data.productInfo.is_liked = !obj.is_liked;
                EventBus.$emit('open-add-likeboards', obj);
            } else {
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: "Like & collect",
                    text: "You’ll need an account to add what you like to a Collections or simply log in. "
                });
            }
        },

        bookmark() {
            const obj = {};
            obj.type = 'product';
            obj.post = this.data.productInfo;
            obj.unique_key = this.data.productInfo.id;
            obj.is_my_likeboard = this.is_my_likeboard;
            obj.likeboard_id = this.likeboard_id;
            obj.is_bookmark = true;
            EventBus.$emit('open-add-likeboards', obj);
        },
        store() {
            if (!this.$store.state.is_logged) {
                this.$store.commit('openLogin', {
                    is_show: true,
                });

                return;
            }

            this.$store.state.show_loading = true;
            let url = this.data.productInfo.is_storefront ? '/api/store-front/remove' : '/api/store-front/create';
            let remove_from = this.$route.name === 'private-profile-storefront' ? 0 : this.$route.name === 'private-profile-tagged' ? 1 : 2;

            this.$ajax(url, {
                method: 'post',
                withCredentials: true,
                data: {
                    product_ids: [this.data.productInfo.id],
                    id: this.data.productInfo.id,
                    remove_from: remove_from
                }
            }).then(res => {
                this.$store.state.show_loading = false;

                if (this.$route.name === 'private-profile-storefront' && this.data.productInfo.is_storefront) {
                    EventBus.$emit('removeStorefront', this.data.productInfo.id);
                } else {
                    this.tooltip_text = !this.data.productInfo.is_storefront
                    ? `Added to your Shop！`
                    : `Removed from your Shop！`;
                    this.tooltip_bg_color = !this.data.productInfo.is_storefront
                        ? "primary"
                        : "black";

                    this.showPopup('show_tooltip');
                }
                // remove all cache
                this.$ajax.cache.store = {};
                this.data.productInfo.is_storefront = !this.data.productInfo.is_storefront;
            }).catch(error => {
                this.$store.state.show_loading = false;
                console.log(error);
            });
        },

        openMerchant(type = 'details') {
            const rname = this.$route.name;
            const query = {};
            query.merchant = true;
            if (rname.includes('likeboard')) query.lb = true;
            if (this.skimlinks_tracking) query.post_ref = this.skimlinks_tracking;
            query.product = this.data.productInfo;
            query.view_type = type;

            if (type === 'share') query.hide_content = true;
            if (rname === 'private-profile-liked' || rname === 'private-profile-storefront' || rname === 'private-profile-tagged' || rname === 'private-profile-links') {
                query.type = 'share';
            }
            
            if (this.post_unique_key) query.post_unique_key = this.post_unique_key;

            if (type === 'open-share-links') {
                query.hide_content = true;
                query.open_links = true;
                this.$store.state.show_loading = true;
            }
            
            EventBus.$emit('open-merchant-list', query);
            
        },

        saveUserJourney() {
            this.$store.commit('setPostID', this.skimlinks_tracking);

            if (this.skimlinks_tracking !== '') {
                this.$store.commit('setCustomID', this.skimlinks_tracking);
            } else {
                switch (this.current_route.name) {
                    case 'shop':
                    case 'brands':
                        this.$store.commit('setCustomID', 'SHOP0000000');
                        break;
                    case 'brand-sub-page':
                        if (this.current_route.params.type === "shop") {
                            this.$store.commit(
                                'setCustomID',
                                "B" + String(this.current_route.params.brand_id).padStart(10, "0")
                            );
                        }
                        break;
                    case 'trending':
                        if (this.current_route.path.includes("product")) {
                            this.$store.commit(
                                'setCustomID',
                                this.current_route.params.type.toUpperCase() +
                                String(this.product.hashtag_id).padStart(
                                    9,
                                    "0"
                                )
                            );
                        }
                        break;
                }


            }
            if (this.likeboard_id) {
                this.$store.commit("setLikeBoardID", this.likeboard_id);
            } else {
                this.$store.commit("setLikeBoardID", "");
            }
        },

        getSocialList(product) {
            if (product.instagramposts_count > 0 || product.instagram_posts_count > 0) {
                this.social_list[0].show = true;
            }

            if (product.video_posts_count > 0) { 
                this.social_list[1].show = true;
            }

            if (product.tiktok_posts_count > 0) { 
                this.social_list[2].show = true;
            }

            if (product.imageposts_count > 0 || product.image_posts_count > 0) {
                this.social_list[3].show = true;
            }

            if (product.thisorthatposts_count > 0 || product.this_or_that_posts_count > 0) {
                this.social_list[4].show = true;
            }

            if (product.likeboards_count > 0) { this.social_list[5].show = true; }

        },


        error($event) {
            if ($event.target.src.indexOf('.webp') > -1) {
                $event.target.src = $event.target.src.replace('.webp', '.jpg');
                $event.target.onerror = () => {
                    $event.target.src = require('@/assets/images/no-image.png');

                    if ((this.$route.name === 'shop') || (this.$route.name === 'brand-sub-page') || (this.$route.name === 'trending') || (this.$route.name === 'article') || (this.$route.name === 'globalsearch'))
                        EventBus.$emit('emptyImage', this.data.productInfo.id);
                    else
                        console.log('product error image => ', this.data.productInfo.images);
                };
            } else {
                $event.target.src = require('@/assets/images/no-image.png');
                if ((this.$route.name === 'shop') || (this.$route.name === 'brand-sub-page')
                    || (this.$route.name === 'trending') || (this.$route.name === 'article') || (this.$route.name === 'globalsearch'))
                    EventBus.$emit('emptyImage', this.data.productInfo.id);
                else
                    console.log('product error image => ', this.data.productInfo.images);
            }
        }
    },

};
</script>

<style lang="scss">
    .product-card {
        position: relative;
        background: var(--Bg-light);
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.03);
        box-sizing: border-box;
        min-height: 100%;
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: 100%;
        transition: all 0.2s ease;
        overflow: hidden;

        &.show-footer {
            border-color: var(--Border-default);
        }

        &:hover {
            border-color: var(--Border-hover);
        }

        .item-box {
            padding: 2px;
            background: var(--Bg-light);
            overflow: hidden;
            min-height: 100%;
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-columns: 100%;

            & > .image {
                position: relative;
                display: block;
                cursor: pointer;
                border-radius: 6px;
                overflow: hidden;
                background-color: var(--Bg-01);

                &::before {
                    content: "";
                    display: block;
                    padding: 0 0 100%;
                }
            }

            img {
                position: absolute;
                display: block;
                max-width: 100%;
                max-height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
            }
        }

        .info {
            padding: 10px 10px 8px;
            flex: 1;
            display: grid;
            grid-template-rows: auto 1fr auto;
            grid-template-columns: 100%;

            .brand-name {
                padding-top: 4px;
                font: var(--heading-6);
                cursor: pointer;
                color: var(--Text-primary);

                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .product-name {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font: var(--text-md-regular);
                color: var(--Text-secondary);
                cursor: pointer;

                a {
                    color: var(--Text-secondary);
                }
            }

            .bottom {
                display: flex;
                align-items: center;
                margin-top: 8px;
                
                .price {
                    flex: 1;

                    span {
                        font: var(--sub-4);
                        color: var(--Text-primary);
                    }
                }

                .liked-btn {
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    svg {
                        height: 100%;
                        width: 100%;
                        fill: var(--Text-default);

                        &:first-child {
                            display: none;
                        }

                        &:last-child {
                            display: block;
                        }
                    }

                    &.is-liked {
                        svg {
                            &:first-child {
                                display: block;
                            }

                            &:last-child {
                                display: none;
                            }
                        }
                    }

                    &.is-liked svg,
                    &:hover svg {
                        fill: var(--Surface-primary);
                    }
                }
            }
        }

        .like-button {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 2;
            border-radius: 2px 0 0 0;
            background-color: var(--Bg-light);

            svg {
                height: 18px;
                width: 18px;
                fill: var(--Text-default);

                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block;
                }
            }

            &.is-liked {
                svg {
                    &:first-child {
                        display: block;
                    }

                    &:last-child {
                        display: none;
                    }
                }
            }

            &.is-liked svg,
            &:hover svg {
                fill: var(--Surface-primary);
            }
        }

        .ico-container {
            position: absolute;
            top: 0;
            left: 4px;
            display: flex;
            z-index: 2;

            .hot-ico {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                background: var(--Brand-Orange);
                border-radius: 0 0 2px 2px;
                justify-content: center;
                margin-right: 4px;

                svg {
                    width: 16px;
                    height: 16px;
                    fill: var(--Black-00);
                }
            }

            .piggy-ico {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                background: var(--Surface-light);
                border-radius: 0 0 6px 6px;
                justify-content: center;
                margin-right: 4px;

                svg {
                    width: 20px;
                    height: 16px;
                    fill: var(--Text-primary);
                }
            }
        }

        .product-info-seen-in {
            background: var(--Bg-light);
            box-sizing: border-box;
            border-radius: 4px 0px 0 0px;
            position: absolute;
            bottom: -1px;
            right: 0px;
            //width: 75%;
            max-width: 130px;
            height: 20px;
            display: flex;
            align-items: center;
            padding-left: 3px;
            padding-right: 3px;

            i {
                margin: 0 3px;
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 12px;
                width: 12px;

                svg {
                    height: 100%;
                    width: 100%;
                    fill: var(--Text-primary);
                    display: block;
                }
            }
        }

        .footer-icons {
            display: flex;
            align-items: center;
            border-top: 1px solid var(--Border-disable);
            background-color: var(--Bg-01);
            padding: 6px 8px;

            span {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                i {
                    margin: 0;
                }
            }

            i {
                height: 20px;
                width: 20px;
                display: block;
                cursor: pointer;
                margin: 0 3px;

                svg {
                    height: 100%;
                    width: 100%;
                    fill: var(--Text-default);
                }

                &:hover,
                &.actived {
                    svg {
                        fill: var(--Surface-primary);
                    }
                }
            }

            .add-bookmark {
                svg {
                    &:first-child {
                        display: block;
                    }

                    &:last-child {
                        display: none;
                    }
                }

                &.actived svg {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }
        }

    }
</style>
