<template>
    <div class="tiktok-player" ref="tiktok_player" >
        <div class="bg-image">
            <img :src="image" @error="imageError"/>
            <div v-if="$route.name !== 'earnings-product'" class="play-btn" @click="openPost('tiktok')">
                <div class="btn">
                    <svg-icon class="ico-play" name="tiktop-player-play" />
                </div>
            </div>
            <a class="author-link" target="_black" v-if="post && $route.name !== 'earnings-product'" :href="author_url">
                <i><svg-icon name="tiktok-no-circle" /></i>
                <span v-html="handleName"></span>
            </a>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/events/bus.js';

export default {
    name: 'video-player',

    props: {
        // post_id: Number,
        post: Object,
    },

    data() {
        return {
            image: '',
            author_url: '',
        }
    },


    computed: {
        handleName() {
            if (this.author_url) {
                let tturl = new URL(this.author_url);
                return tturl.pathname.replace('/@','');
            }
        }
    },
    methods: {

        imageError() {
            const doc = new DOMParser().parseFromString(this.post.tiktok_payload.html, 'text/xml');
            const post_url = doc.firstChild.getAttribute('cite')

            this.$ajax('https://www.tiktok.com/oembed?url=' + post_url, {
                method: 'get',
            }).then(res => {
                if (res.data.status_msg && res.data.status_msg === 'Something went wrong') {
                    console.log('post remove ' + this.post.post_id)
                    let el = document.querySelector('#js-post--' + this.post.post_id);
                    if (el !== null) el.style.display = 'none';
                    EventBus.$emit('disable-post', {
                        post_id: this.post.post_id
                    });
                } else if (res.data.thumbnail_url) {
                    this.image = res.data.thumbnail_url;
                    let form_data = new FormData();
                    form_data.append('post_id', this.post.post_id);
                    form_data.append('thumbnail_url', btoa(this.image));
                    this.$ajax('/api/save-tiktok-thumbnail-url-from-post-id', {
                        method: 'post',
                        data: form_data,
                        withCredentials: true,
                    }).then(res => {
                        if (res.data.status === 'success') {
                            console.log('save image ' + res.data.data.local_thumbnail_url);
                        }
                    });
                }
            }).catch(err=>{
                this.$ajax.cache.store = {};
            });
        },

        openPost(type) {
            let obj = {};
            obj.post = this.post;
            obj.type = type;
            EventBus.$emit('popup-post', obj);
        },

    },

    mounted() {
        if (this.post.tiktok_payload && this.post.tiktok_payload.thumbnail_url) {
            this.image = this.post.tiktok_payload.thumbnail_url;
        }
        else if (this.post.draft_thumbnail_url) {
            this.image = this.post.draft_thumbnail_url;
        }

        if (this.post.tiktok_payload.author_url) {
            this.author_url = this.post.tiktok_payload.author_url;
        } else {
            this.author_url = this.post.social_profile_url;
        }
    },

}

</script>

<style lang="scss" scoped>
    @import '../resources/sass/_variables.scss';

    .tiktok-player {
        position: relative;
        min-height: 400px;

        .play-btn {
            position: absolute;
            left: 0%;
            top: 0%;
            cursor: pointer;
            height: 100%;
            width: 100%;
            z-index: 3;

            .btn {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                //padding: 0 18px;
                background: var(--Bg-light);
                border-radius: 20px;
                font-weight: var(--font-medium);
                font-size: var(--font-m);
                color: var(--Text-primary);
                white-space: nowrap;

                svg {
                    height: 19px;
                    width: 13px;
                    position: relative;
                    z-index: 3;
                    fill: var(--Text-secondary);
                    left: 1px;
                    //margin-right: 8px;
                }
            }

        }

        .author-link {
            position: absolute;
            left: 12px;
            bottom: 12px;
            background: var(--Bg-light);
            height: 24px;
            line-height: 24px;
            padding: 0 1px;
            color: var(--Text-primary);
            font: var(--sub-6);
            border-radius: var(--radius-large);
            display: flex;
            align-items: center;
            z-index: 5;

            span {
                padding: 0 8px 0 6px;
            }

            i {
                height: 22px;
                width: 22px;
                background: var(--TikTok-dark);
                border-radius: 50%;
                position: relative;
            }

            svg {
                height: 16px;
                width: 16px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

        }

        .bg-image {
            position: relative;
            border-radius: var(--radius-medium);
            overflow: hidden;
            background: var(--Black-100);

            img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: .65;
            }


            &:before {
                display: block;
                padding: 0 0 150%;
                content: '';
            }
            
        }



    }
</style>