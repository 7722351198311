<template>
    <div v-if="post" class="post-wrap" :id="'js-draft-post-' + post.post_id">
        <article class="draft-post">
            <div class="draft-post--content">
                <div class="draft-post--media-bg">
                    <div class="draft-post--media">
                        <div class="draft-post--main">
                            <section v-if="post.post_type === 'INSTAGRAM'" class="draft-post--instagram-embed" :data-link="post.draft_media_url">
                                <blockquote class="instagram-media" :data-instgrm-permalink="post.draft_media_url" data-instgrm-captioned data-instgrm-version="14"></blockquote>
                                <div class="bg" style="padding-bottom: calc(54px + 100%);"></div>
                            </section>
                            <section v-else-if="post.post_type === 'IMAGE'" class="draft-post--image-item">
                                <div v-for="(image, index) in post.image_list" :key="index">
                                    <img v-if="image.url" :src="Get_Image(image.url + image.dir + '400x400/' + image.file_name)" >
                                    <img v-else :src="Get_Image(image.dir + '400x400/' + image.file_name)" >
                                </div>
                            </section>
                            <section v-else-if="post.post_type === 'THISORTHAT'" class="draft-post--tot-item">
                                <div class="tot-main-container--wrapper" v-if="post.left_image && post.right_image && post.left_image.data && post.right_image.data">
                                    <div class="tot-ico">or</div>
                                    <div class="tot-main-container--tot-left">
                                        <div class="tot-container">
                                            <img v-if="post.left_image.url" :src="Get_Image(post.left_image.url + post.left_image.dir  + '400x400/' +  post.left_image.file_name)" class="tot-container--img" @error="error">
                                            <img v-else :src="post.left_image.product.images.img_400x400" class="tot-container--img" @error="error">
                                        </div>
                                        <div class="tot-voteResult"></div>
                                    </div>

                                    <div class="tot-main-container--tot-right">
                                        <div class="tot-container">
                                            <img v-if="post.right_image.url" :src="Get_Image(post.right_image.url + post.right_image.dir  + '400x400/' +  post.right_image.file_name)" class="tot-container--img" @error="error">
                                            <img v-else :src="post.right_image.product.images.img_400x400" class="tot-container--img" @error="error">
                                        </div>
                                        <div class="tot-voteResult"></div>
                                    </div>
                                </div>
                            </section>
                            <section v-else-if="post.post_type === 'YOUTUBE' && post.draft_media_url" class="draft-post--video-item">
                                <div class="video-player">
                                    <iframe :src="post.draft_media_url" allowfullscreen></iframe>
                                </div>
                            </section>
                            <section v-else-if="post.post_type === 'TIKTOK'" class="draft-post--tiktok-item">
                                <TiktokEmbed :post="post" />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            
            <footer class="draft-post--footer">
                <div class="is-link" :class="[post.post_type.toLowerCase()]">
                    <i v-if="post.post_type      === 'INSTAGRAM' "> <svg-icon name="instagram" /></i>
                    <i v-else-if="post.post_type === 'IMAGE'     "> <svg-icon name="image" /></i>
                    <i v-else-if="post.post_type === 'THISORTHAT'"> <svg-icon name="this-or-that" /></i>
                    <i v-else-if="post.post_type === 'TIKTOK'   "> <svg-icon name="tiktok" /></i>
                    <i v-else-if="post.post_type === 'YOUTUBE'    "> <svg-icon name="youtube" /></i>

                    <time class="expiring" v-if="(Number(post.draft_status_text) === 6)">Expiring today</time>
                    <time class="expiring" v-else-if="(Number(post.draft_status_text) === 5)">Expiring in 1 day</time>
                    <time v-else-if="(Number(post.draft_status_text) === 0)">Today</time>
                    <time v-else>{{post.draft_status_text}} days ago</time>
                </div>

                <div class="icon-list">
                    <ul>
                        <li><div class="ico" @click="$emit('remove', post)"> <svg-icon name="remove" /></div></li>
                        <li><div class="ico" @click="$emit('edit', post)"> <svg-icon name="edit" /></div></li>
                    </ul>
                </div>
            </footer>

        </article>

    </div>

</template>

<script>
import TiktokEmbed from '@/components/ui/TiktokEmbed.vue';

export default {
    name: 'draft-post',
    props: {
        post: Object,
    },

    methods: {

        videoReady(event) {
            event.target.playVideo();
            setTimeout(() => {
                event.target.stopVideo();
            }, 100);
        },

        error($event) {
            if ($event.target.src.indexOf('.webp') > -1) {
                $event.target.src = $event.target.src.replace('.webp','.jpg');
                $event.target.onerror = () => {
                    $event.target.src = require('@/assets/images/no-image.png');
                };
            }
            else
                $event.target.src = require('@/assets/images/no-image.png');
        },
    
    },


    components: {
        TiktokEmbed,
    },

    mounted () {
        const self = this;


        if (typeof self.post !== 'undefined' && typeof self.post.post_type !== 'undefined' && self.post.post_type === 'INSTAGRAM') {
            setTimeout(() => {
                if (window.instgrm && window.instgrm.Embeds) window.instgrm.Embeds.process();
            }, 500);
        }

        // The manually product does not have the brand object, so it needs to be pull the brand name from other data
        self.$nextTick(() => {
            self.default_CMSUser_image = require('@/assets/images/Motom-Creator.png');

            self.default_image = require('@/assets/images/default-user.png');

            if (self.post.tagged_products && self.post.tagged_products.length !== 0) {
                self.post.tagged_products.forEach(product => {
                    if (typeof product.who_tagged !== 'undefined' && product.who_tagged.is_me) self.is_tagged = true;
                    product.brand_id = 0;
                    product.brand_name = '';
                    product.brand_logo = '';

                    // if (product.brand === null && isJson(product.other_data)) {
                    //     let obj = JSON.parse(product.other_data);
                    //     if (obj !== null && typeof obj.brand_name !== 'undefined' && obj.brand_name !== null) product.brand_name = obj.brand_name;
                    // }
                    // else
                    if (product.brand && product.brand.parent_id && product.brand.parent) {
                        product.brand_id = product.brand.parent_id;

                        if (product.brand.parent.name) {
                            product.brand_name = product.brand.parent.name;
                        } else {
                            product.brand_name = product.brand.parent.title;
                        }

                        product.brand_logo = product.brand.parent.image;
                    } else if (product.brand && !product.brand.parent_id) {
                        product.brand_id = product.brand.id;

                        if (product.brand.name) {
                            product.brand_name = product.brand.name;
                        } else {
                            product.brand_name = product.brand.title;
                        }

                        product.brand_logo = product.brand.image;
                    }

                    // if (typeof product.who_tagged !== 'undefined' && product.who_tagged.is_me) self.is_tagged = true;
                    // if (product.brand === null) {
                    //     product.brand = {};
                    //     product.brand.name = '';
                    // }

                    // if (isJson(product.other_data)) {
                    //     let obj = JSON.parse(product.other_data);
                    //     if (obj !== null && typeof obj.brand_name !== 'undefined' && obj.brand_name !== null) product.brand.name = obj.brand_name;
                    // }
                });
            }

        });
    }
};
</script>

<style lang="scss">
    @import '../resources/sass/_variables.scss';
    @import '@/sass/desktop_variables.scss';

    .draft-post {
        border-radius: 14px;
        max-width: var(--post-max-width);
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        background-color: var(--Bg-light);
        position: relative;
        z-index: 1;
        box-shadow: var(--bot-1dp);

        &--main {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: var(--radius-medium);

            img {
                border-radius: var(--radius-medium);
                cursor: pointer;
            }

            .video-play {
                position: absolute;
                width: 32px;
                height: 32px;
                background-color: var(--Bg-light);
                border-radius: 100px;
                border: 1px solid var(--Black-10);
                padding: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                svg {
                    display: flex;
                    overflow: hidden;
                    fill: var(--Text-secondary);
                    width: 18px;
                    height: 18px;
                    padding-left: 4px;
                }
            }
        }

        &--footer {
            padding: 8px 9px 8px 6px;
            display: flex;
            align-items: center;

            .is-link {
                cursor: pointer;
                flex: 1;
                padding-left: 2px;
                display: flex;
                align-items: center;

                i {
                    height: 20px;
                    width: 20px;
                    display: block;

                    svg {
                        height: 100%;
                        width: 100%;
                        display: block;
                        fill: var(--Text-primary);
                    }
                }

                time {
                    font-size: var(--font-xs);
                    padding-left: 10px;
                    color: var(--Text-default);
                    font-weight: var(--font-light);

                    &.expiring {
                        color: var(--Text-danger);
                    }
                }
            }

            .icon-list {
                // min-width: 160px;

                ul {
                    display: flex;
                    align-items: center;

                    li {
                        display:flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 8px;
                        cursor: pointer;
                        
                        svg {
                            width: 16px;
                            height: 16px;
                            fill: var(--Text-default);
                            display: block;
                        }

                        &:hover svg {
                            fill: var(--Brand-Primary);
                        }
                    }
                }
            }
        }

        .tot-container .img {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        a {
            text-decoration: none;
        }

        ul, li {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        &--content {
            width: 100%;

            .img-icon {
                position: absolute;
                margin-top: 6px;
                margin-left: 6px;
                .ui-icon {
                    height: 20px !important;
                    width: 20px !important;

                    svg {
                        height: 12px !important;
                        width: 12px !important;
                    }
                }
            }

            &.fullWidth > .draft-post--media-bg {
                display: block;
            }

            .draft-post--main {
                &.space-around {
                    padding: 8px 8px 0 8px;
                }
                // &.space-part {
                //     padding: 8px 0 0 8px;
                // }
            }
        }

        &--media {
            flex: 1;
            order: 1;
            width: 100%;
            &-bg {
                display: flex;
                width: 100%;
                height: 100%;
                &-color {
                    background: var(--Blue-10);
                    padding: 6px;
                    border-radius: 8px 8px 0 0;
                }
            }
        }

        &--image-list {
            position: relative;

            .swiper-container {
                min-width: 0;
                width: 100%;
            }

            .swiper-slide {
                position: relative;
                width: 100%;
            }

            img {
                display: block;
                width: 100%;
            }
        }

        &--tagged-products + &--media {
            width: calc(100% - 8rem);
        }

        &--instagram-embed {
            width: 100%;
            overflow: hidden;
            position: relative;
            background-color: var(--Black-10);

            .bg {
                display: block;
                padding: 0 0 120%;
            }

            .instagram-media {
                width: 100% !important;
                height: 100% !important;
                min-width: unset !important;
                overflow: hidden;
                position: absolute;
                left: 0;
                top: 0;
                // border-radius: 0!important;
                box-shadow: none!important;
                display: block;
                margin: 0px !important;
                padding: 0px !important;
            }
        }

        &--image-item {
            width: 100%;
            overflow: hidden;
            position: relative;
            background-color: var(--Black-10);
            border-radius: var(--radius-medium);
            border: 1px solid var(--Black-10);

            img {
                width: 100%;
                display: block;
                height: auto;
            }
        }

        &--video-item {
            flex: 1;

            .embed-video {
                width: 100%;
                overflow: hidden;
                position: relative;

                &::before {
                    display: block;
                    padding: 0 0 56.6%;
                    content: '';
                }

                iframe {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100% !important;
                    width: 100% !important;
                }
            }
            iframe {
                height: 100% !important;
                width: 100% !important;
                border-radius: var(--radius-medium);
            }
        }

        &--tiktok-item {
            flex: 1;

            .embed-tiktok {
                width: 100%;
                overflow: hidden;
                position: relative;

                &.is-iphone iframe {
                    min-height: 774px;
                }
            }

            .tiktok-player {
                min-height: unset;
            }

            .tiktok-player .play-btn {
                .btn {
                    padding: 0 14px;
                }

                .btn svg {
                    margin: 0;
                }
                
                span {
                    display: none;
                }
            }
        }

        &--tot-item {
            width: 100%;
            overflow: hidden;
            background: var(--Black-10);
            position: relative;
            border-radius: var(--radius-medium);
        }

        .tot-main-container {
            &--wrapper {
                position: relative;
                overflow: hidden;
                cursor: pointer; 
                z-index: 3;
                display: flex;

                &::after {
                    content: '';
                    pointer-events: none;
                    left: 50%;
                    top: 0;
                    height: 100%;
                    margin-left: -.2rem;
                    border-left: 4px solid var(--Black-10);
                    z-index: 3;
                    position: absolute;
                }

                .tot-ico {
                    height: 32px;
                    width: 32px;
                    border: 2px solid;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    background-color: var(--Bg-light);
                    border-radius: 50%;
                    margin: -1.6rem 0 0 -1.6rem;
                    text-align: center;
                    z-index: 9;
                    color: var(--Text-primary);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    font: var(--sub-4);
                    line-height: 1;
                    pointer-events: none;
                }
            }

            &--tot-left {
                width: 50%;
                overflow: hidden;

                .tot-container img {
                    border-radius: 8px 0 0 8px !important;
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }

            &--tot-right {
                height: 100%;
                width: 50%;
                overflow: hidden;

                .tot-container img {
                    border-radius: 0 8px 8px 0 !important;
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }

        .tot-voteResult {
            display: none;
        }
    }

    .draft-post--instagram-embed {
        border-radius: var(--radius-medium); 
        position: relative;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            height: 99.5%;
            width: 99.5%;
            content: '';
            z-index: 999;
            border-radius: var(--radius-medium);
            border: 1px solid var(--Black-10);
            pointer-events: none;
        }
    }
</style>
