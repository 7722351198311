import formatMoney from '@/utils/formatMoney';
import isJson from '@/utils/IsJson';
import moment from 'moment';


export default function (data) {
    let product = data;

    product.prices = [];
    product.is_available = false;
    
    if (typeof product.merchant_prices !== 'undefined') {
        product.total_sales = 0;
        product.total_promos = 0;
        
        Object.keys(product.merchant_prices).forEach(function(key) {
            let item = product.merchant_prices[key];
            
            let price = 0;
            // let is_noskim = false;

            if (item.on_sale === 1) {
                price = Number(item.on_sale_price);
            } else {
                price = Number(item.price);
            }

            let today = moment(moment().toString()).format('YYYY-MM-DD HH:mm:ss');
            let product_merchant_id = typeof item.product_merchant !== 'undefined' && item.product_merchant !== null ? item.product_merchant.id : 0;

            if (price === 0) price = NaN;



            // handle deals
            if (typeof item.product_merchant !== 'undefined' && item.product_merchant !== null) {
                product.total_sales += item.product_merchant.deals.filter(function(d, index, array) {
                    return d.offer_type == 1 && d.end_date >= today && d.start_date <= today
                }).length;
                product.total_promos += item.product_merchant.deals.filter(function(d, index, array) {
                    return d.offer_type == 2 && d.end_date >= today && d.start_date <= today
                }).length;
            }

            if (item.product_merchant && item.product_merchant.deals) item.product_merchant.deals.forEach(d => Object.assign(d, {sovrn_status : item.product_merchant.sovrn, skimlinks_status : item.product_merchant.skimlinks}))
            // handle deals:end
            
            let merchant_name = (typeof item.product_merchant !== 'undefined' && item.product_merchant !== null && item.product_merchant.name != item.product_merchant.display_name ) ? item.product_merchant.display_name : key;
            let merchant_id = '';
            let merchant_from_product_id = '';

            if (item.product_merchant && item.product_merchant.id) {
                merchant_id = item.product_merchant.id;
            }

            if (item.merchant_from_product_id) {
                merchant_from_product_id = item.merchant_from_product_id;
            }

            product.prices.push({
                name: merchant_name,
                price: price,
                currency: item.currency,
                recommend_user: item.recommend_user,
                product_url: item.affiliate_profile.product_url, 
                merchant_link: item.merchant_link,
                is_monetizable: item.is_monetizable,
                continue_check_skimlink: item.continue_check_skimlink,
                skimlinks_status: item.skimlinks_status,
                is_reseller: typeof item.product_merchant !== 'undefined' && item.product_merchant !== null ? item.product_merchant.is_reseller : 0,
                deals: typeof item.product_merchant !== 'undefined' && item.product_merchant !== null ? item.product_merchant.deals.filter(d => d.end_date >= today && d.start_date <= today) : null,
                show_sales: false,
                show_promos: false,
                sovrn_flag: item.sovrn_flag,
                is_noskim: item.is_noskim,
                product_merchant_id: merchant_id,
                merchant_from_product_id: merchant_from_product_id,
            });
        });
    }

    product.prices.sort(function(a, b) {
        return a.price - b.price;
    });

    product.merchant_list = [
        [],
        [],
        [],
        [],
        [],
    ];
    
    product.total_merchants = product.prices.length;
    product.prices.forEach(item => {
        if (item.product_url && item.product_url !== '') product.is_available = true;

        if (isNaN(item.price)) {
            item.price_str = 'no-price';
        } else {
            let price_str = (item.price) ? formatMoney(item.price) : '';
            switch (item.currency) {
                case 'USD':
                    item.price_str = '$' + price_str;
                    product.merchant_list[0].push(item);
                    break;

                case 'CAD':
                    item.price_str = '$' + price_str + ' (CAD)';
                    product.merchant_list[1].push(item);
                    break;

                case 'GBP':
                    item.price_str = '£' + price_str;
                    product.merchant_list[2].push(item);
                    break;

                case 'EUR':
                    item.price_str = '€' + price_str;
                    product.merchant_list[3].push(item);
                    break;

                case 'AUD':
                    item.price_str = '$' + price_str + ' (AUD)';
                    product.merchant_list[4].push(item);
                    break;

                default:
                    item.price_str = '$' + price_str;
                    product.merchant_list[0].push(item);
                    break;
            }

        }
    });



    product.merchant_list = product.merchant_list.filter(item => item.length !== 0);


    if (product.merchant_list.length === 0) {
        let currency＿str = (product.currency === 'GBP') ? '£' : (product.currency === 'EUR') ? '€' : '$';
        let price_str = (product.price) ? formatMoney(product.price) : '';

        // from https://project42.atlassian.net/browse/P42-5816  product.price can be null
        if (product.price) {
            if (product.price != 0){
                product.lowest_price = currency＿str + price_str;
            } 
            else {
                product.lowest_price = 'no-price';
            }
        }        
        else {
            product.lowest_price = 'no-price';
        }        
    } else {
        product.lowest_price = product.merchant_list[0][0].price_str;    
    }


    
    let clone_brand_id = product.brand_id;
    let clone_brand_name = product.brand_name;
    
    product.brand_id = 0;
    product.brand_name = '';
    product.brand_logo = '';

    if (product.brand === null && isJson(product.other_data)) {
        let obj = JSON.parse(product.other_data);
        if (obj !== null && typeof obj.brand_name !== 'undefined' && obj.brand_name !== null) product.brand_name = obj.brand_name;
    }

    else if (product.brand && product.brand.parent_id && product.brand.parent) {
        product.brand_id = product.brand.parent_id;

        if (product.brand.parent.name) {
            product.brand_name = product.brand.parent.name;
        } else {
            product.brand_name = product.brand.parent.title;
        }
    
        product.brand_logo = product.brand.parent.image;
    }

    else if (product.brand && !product.brand.parent_id) {
        product.brand_id = product.brand.id;

        if (product.brand.name) {
            product.brand_name = product.brand.name;
        } else {
            product.brand_name = product.brand.title;
        }
        
        product.brand_logo = product.brand.image;
    }

    else if (clone_brand_id && clone_brand_name) {
        product.brand_id = Number(clone_brand_id);
        product.brand_name = clone_brand_name;
    }


    return product;
}