<template>
    <div v-if="follow_item.user_id" class="ui-follow-button" :key="follow_item.is_followed" :class="[{ 'is-loading': is_loading }, {'is-followed' : follow_item.is_followed}, 'size-' + size]" @click="follow()">
        <svg-icon v-if="follow_item.is_followed" name="user-followed" /> 
        <svg-icon v-else name="user-follower" /> 
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';

export default {
    name: 'FollowButton',

    props: {
        item: {
            type: Object,
            default: {
                is_followed: true,
                user_id: 0,
                post_author_unique_key: 0,
                unique_key: 0
            }
        },

        actionLabel: {
            type: String,
            default: 'Post'
        },

        size: {
            type: String,
            default: '22'
        },
    },

    data() {
        return {
            is_loading: false,
            follow_item: {},
            key_refrash: 0,
        }
    },

    methods: {
        follow() {
            console.log('this.follow_item',this.follow_item)
            if (!this.$store.state.is_logged) {
                this.$cookie.set('motom_follow_user', this.follow_item.user_id , { expires: '15m' });

                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: `Follow  ${this.follow_item.nickname ? this.follow_item.nickname : this.follow_item.post_author_name }`,
                    text: 'Don’t want to miss their posts or tag on Motom. It’s as simple as sign up and follow'
                });
                return;
            }

            this.is_loading = true;
            let follow_api = '/api/post/user/' + this.follow_item.user_id + '/follow';
            let unfollow_api = '/api/post/user/' + this.follow_item.user_id + '/unfollow';
            let api = this.follow_item.is_followed ? unfollow_api : follow_api;

            this.$ajax(api, {
                method: 'post',
                withCredentials: true
            }).then(res => {
                this.is_loading = false;

                if (res && res.data && res.data.status === 'success') {
                    this.follow_item.is_followed = !this.follow_item.is_followed;

                    if(this.follow_item.unique_key!==undefined){
                        let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
                        if (!this.follow_item.unique_key && !this.follow_item.post_author_unique_key) {
                            label = label + ',,' + followID;
                        } else if (this.follow_item.post_author_unique_key) {
                            label = label + ',' + this.follow_item.post_author_unique_key + ',';
                        } else {
                            label = label + ',' + this.follow_item.unique_key + ',';
                        }

                        if (this.follow_item.is_followed) {
                            this.GA_Tracking('Follow People', `${this.actionLabel}-Unfollow`, label);
                        } else {
                            this.GA_Tracking('Follow People', `${this.actionLabel}-Follow`, label);
                        }
                    }
                    // Removed all cache
                    this.$ajax.cache.store = {};
                    EventBus.$emit('follow-user-on-post', {
                        follow: api !== unfollow_api,
                        id:this.follow_item.user_id
                    });

                    EventBus.$emit('follow-user', {
                        user_id: this.follow_item.user_id,
                        is_followed: api !== unfollow_api
                    });

                    EventBus.$emit('follow-user-refresh');
                }
            })
            .catch(err => {
                console.log('err', err)
                if (err.response && err.response.data.message === 'Unauthenticated.') {
                    this.$store.commit('openLogin', {
                        is_show: true,
                        title: '',
                        text: ''
                    });
                }
            });

        },

    },

    mounted() {
        this.follow_item = this.item;
    }
}
</script>

<style lang="scss">
.ui-follow-button {
    //border: 1px solid var(--Brand-Primary);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    border-radius: 50%;
    cursor: pointer;
    
    &.is-followed:hover,
    &.is-followed {
        border-color: var(--Black-00);

        svg {
            fill: var(--Black-40);
        }
    }

    &:hover {
        svg {
            fill: var(--Brand-Primary);
        }
    }

    &.is-loading {
        pointer-events: none;
        opacity: .3;
    }

    &.size-22 {
        height: 20px;
        width: 20px;

        svg {
            width: 14px;
            height: 14px;
            display: block;
            fill: var(--Text-secondary);
        }

        // &.is-followed {

        //     svg {
        //         height: 16px;
        //         width: 16px;
        //     }
        // }
    }

    &.size-32 {
        height: 30px;
        width: 30px;

        svg {
            width: 20px;
            height: 20px;
        }
    }


}
</style>
