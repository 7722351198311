<template>
    <div class="loading" :class="classname">
        <div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'loading',
    props: {
        classname: Array,
        requestURL: {
            type: String,
            default: ''
        }
    },
}
</script>
<style lang="scss">
.loading {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(var(--Black-00-rgba), .5);
    z-index: 999999999;

    &.absolute {
        display: block;
        background: transparent;
        position: absolute;
        background-color: rgba(#fff, .5);

        & > div {
            width: 160px;
            height: 160px;
        }
    }

    &.static {
        display: block;
        background: transparent;
        position: static;
        width: 100%;

        & > div {
            width: 160px;
            height: 160px;
            position: relative;
            left: auto;
            top: auto;
            margin: auto;
            transform: unset;
        }
    }

    & > div {
        width: 50vw;
        height: 50vw;
        max-width: 160px;
        max-height: 160px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        display: flex;
        justify-content: center;
        align-items: center;

        .line {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            background-color: var(--Text-brand);
            margin-right: 5px;  
        }

        .line:nth-last-child(1) {
            animation: loadingC 0.6s 0.1s linear infinite;
            -webkit-animation: loadingC 0.6s 0.1s linear infinite;
        }

        .line:nth-last-child(2) {
            animation: loadingC 0.6s 0.2s linear infinite;
            -webkit-animation: loadingC 0.6s 0.2s linear infinite;
        }

        .line:nth-last-child(3) {
            animation: loadingC 0.6s 0.3s linear infinite;
            -webkit-animation: loadingC 0.6s 0.3s linear infinite;
        }
    }

    &.small {
        & > div {
            width: 100px;
            height: 80px;
            max-width: 100px;
            max-height: 80px;

            .line {
                width: 10px;
                height: 10px;
                min-width: 10px;
                border-radius: 10px;
                margin-right: 4px;
            }
        }
    }

    &.nobg > div {
        background: transparent;
    }
}

.profile-container .loading.static {
    z-index: 1;

    &>div {
        background: transparent;
    }
}

.page--private-profile .profile-container .loading.static {
    margin-top: 40px;

    &>div {
        background: transparent;
    }
}


@-webkit-keyframes loadingC {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(0, 15px);
        transform: translate(0, 15px);
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}


@keyframes loadingC {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, 0);
    }
}
</style>

