import Vue from 'vue';
import Vuex from 'vuex';
import { EventBus } from '@/events/bus.js';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        type: 'mobile',
        is_logged : false,
        show_login_popup : false,
        is_set_password: 1,
        is_set_email: 1,
        user: {
            id: 0,
            name: '',
            avatar: '',
            register_type: '1',
        },
        // anchor_invite: {
        //     id: '',
        //     logo: '',
        //     name: '',
        // },
        is_show_motom_bank: false,
        app_notification_permission: false,
        show_mobile_navi: false,
        show_loading: false,
        show_product_popup: false,
        show_tagged_products_popup: false,
        // show_app_banner: false,
        long_idle: false,
        is_close_signup: false,
        is_disable_pageview: false,
        is_disable_scroll: 0,
        notifications_count: 0,
        notifications_maxID: 0,
        sales_date: {
            text: '',
            key: 0,
            start: null,
            end: null,
        },

        selected_category: 'all',

        login_modal: {
            is_show: false,
            is_signup: false,
            title: '',
            text: '',
            el: null,
            must_be_login:false,
        },

        create: {
            show_modal: false,
            show_tag_modal: false,
            is_exit: false,
            exit_url: '',
            post_of_new_tag: {},
            is_edit: false,
            is_add_tag: false,
            is_edit_old_post: false,
            is_tagged_products: false,
        },

        tag_product_url: '',

        merchantBuy_tracking: {
            last_click_postID: '',
            last_click_likeBoardID: '',
            customID: '',
        },
        
        back_urls: {
            product_detail: '/',
            global_search: ''
        },

        global_search:'',

        edit_cover_type: 'autofill',

        setting_sub_email_page:{
            verify_email:false,
            add_email_page:false
        },

        popup_open: {
            date_picker:false,
            share_profile:false,
        },

        key_refrash: {
            home_header:0
        },

        social_state: {
            is_show_popup: false,
            notification_text: '',
            notification_date: '',
            notification_link: '',
            notification_button_text: '',
            link: '',
            text: '',
            button_text: '',
            ids: [],
            is_show_notification: false,
            is_show_post: false,
            post_link: '',
            popup_accounts: [],
            // is_show_tiktok: false,
        },

        is_need_add_email: false,
        is_show_tiktok_down_popup: false,
        is_tiktok_banned: false,
    },
    
    mutations: {
        openLogin (state, data) {
            state.login_modal.is_show = data.is_show;
            state.login_modal.text = data.text;
            state.login_modal.title = data.title;

            if (data.is_signup) {
                state.login_modal.is_signup = data.is_signup;
            } else {
                state.login_modal.is_signup = false;
            }

            state.login_modal.must_be_login=data.must_be_login? data.must_be_login:false;

            EventBus.$emit('login', data.is_show);
        },

        openLoginPopup (state, data) {
            EventBus.$emit('login-popup', data);
        },


        setPostID(state, data) {
            state.merchantBuy_tracking.last_click_postID = data;
        },
        setCustomID(state, data) {
            state.merchantBuy_tracking.customID = data;
        },        
        setLikeBoardID(state, data) {
            state.merchantBuy_tracking.last_click_likeBoardID = data;
        },

        isLogged(state, data) {
            state.is_logged = data;
        },
    },
    /*actions: {
    },
    modules: {
    }
    */
});