<template>
    <div
        :class="['m-list-item', `type-${type}`, `size-${size}`, { 'is-hover': is_hover && type !== 'user' }, { 'is-loading': is_loading }]">

        <div class="user-item" v-if="type === 'user'">
            <m-avatar size="small" :key="avatar" :image="avatar" :to="link" 
                :is_influencer="Boolean(user_data.post_author_register_type === 2) || Boolean(user_data.register_type === 2)"
                :is_brand="typeof user_data.id === 'number' && typeof user_data.is_influencer === 'undefined'" />
            <div class="info">
                <h3><router-link :to="link" v-html="name"></router-link></h3>
                <p class="recently" v-if="is_recently"><span>New</span></p>
                <p class="about-me" v-if="user_data.motom_handle">@{{ user_data.motom_handle }}</p>
            </div>
            <div class="btn" v-if="$store.state.is_logged && Number($store.state.user.id) === Number(user_data.user_id)">
                <span class="yourself">Yourself</span>
            </div>
            <div class="btn" v-else>
                <m-button :key="'followed'" size="24" v-if="user_data.is_followed" @click.native="follow" type="secondary">
                    Following
                </m-button>
                <m-button :key="'follow'" size="24" v-else @click.native="follow" type="primary">
                    Follow
                </m-button>
            </div>
        </div>

        <div class="router-item" v-if="type === 'router'">
            <a v-if="user_data.is_a && user_data.link" :href="user_data.link" target="_blank"></a>
            <router-link v-if="!user_data.is_a && user_data.link" :to="user_data.link"></router-link>
            <div class="tab">
                <div class="left" v-if="!is_slot">
                    <slot name="content"></slot>
                    <svg-icon v-if="user_data.left_icon" :name="user_data.left_icon" />
                    <p>{{ user_data.name }}</p>
                </div>
                <div class="left" v-else>
                    <slot name="content"></slot>
                </div>

                <div class="right">
                    <svg-icon v-if="user_data.alert" name="alert" />
                    <span class="label" v-if="user_data.label">{{ user_data.label }}</span>
                    <div :class="['main-icon', user_data.open && 'main-icon-open']">
                        <svg-icon name="list-right" />
                    </div>
                </div>
            </div>
        </div>

        <div :class="['check-item', had_default && 'check-item-default']" v-if="type === 'check'">
            <div class="tab">
                <div :class="['left', user_data.selected && 'left-checked']">
                    <svg-icon v-if="user_data.left_icon" :name="user_data.left_icon" />
                    <p>{{ user_data.name }}</p>
                </div>
                <div class="right">
                    <svg-icon v-if="user_data.selected" name="radio_check_circle" />
                    <svg-icon v-else name="radio_button_unchecked" />
                </div>
            </div>
        </div>

        <div class="connet-item" v-if="type === 'connet'">
            <a v-if="user_data.link && !user_data.not_show_link" :href="user_data.link" @click="connect(user_data.key, $event)"></a>
            <div class="tab">
                <div class="left">
                    <svg-icon v-if="user_data.left_icon" :name="user_data.left_icon" />
                    <p v-if="user_data.handle">@{{ user_data.handle.replace('@', '') }}</p>
                    <p v-else>{{ user_data.name }}</p>
                    <svg-icon v-if="is_show_alert" name="alert" />
                </div>
                <div class="right" v-if="!is_slot">
                    <m-button size="24" v-if="user_data.conn_status === 1"
                        @click.native="$emit('disconnect', { key: user_data.key, id: user_data.id })" type="secondary">
                        Connected
                    </m-button>
                    <m-button size="24" v-else>
                        Connect
                    </m-button>
                </div>
                <div class="right" v-else>
                    <slot name="btn"></slot>
                </div>
            </div>
        </div>
        <slot v-else></slot>
    </div>
</template>

<script>
export default {
    name: 'm-list-item',

    props: {
        user_data: {
            type: Object,
            default: () => { }
        },
        type: {
            type: String,
            default: 'text'
        },
        size: {
            type: String,
            default: '40'
        },
        is_hover: {
            type: Boolean,
            default: true
        },
        is_recently: {
            type: Boolean,
            default: false
        },
        is_slot:{
            type: Boolean,
            default: false
        },
        is_show_alert: {
            type: Boolean,
            default: false
        },
        had_default: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            is_loading: false,
            link: '',
            avatar: '',
            name: '',
            follow_api: '',
            unfollow_api: '',
        }
    },
    methods: {
        connect(type, e) {
            if (this.isMotomApp) {
                window.ReactNativeWebView.postMessage('connect_' + type.toLowerCase());
                e.preventDefault();
            }
        },
        follow() {
            let api = this.follow_api;

            if (this.user_data.is_followed) {
                api = this.unfollow_api;
            }

            let actionLabel = '';

            switch (this.$route.name) {
                case 'globalsearch':
                    actionLabel = 'Search-';
                    break;
                case 'followers-list':
                case 'following-list':
                    actionLabel = 'Connections-';
                    break;
                case 'groups-page':
                    actionLabel = 'Groups-';
                    break;
            }

            this.is_loading = true;

            this.$ajax(api, {
                method: 'post',
                withCredentials: true
            }).then(res => {
                if (res && res.data && res.data.status === 'success') {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.user_data.is_followed = !this.user_data.is_followed;
                    const followID = api.split('/')[api.split('/').length - 2];

                    let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';

                    if (!this.user_data.unique_key && !this.user_data.post_author_unique_key) {
                        label = label + ',,' + followID;
                    } else if (this.user_data.post_author_unique_key) {
                        label = label + ',' + this.user_data.post_author_unique_key + ',';
                    } else {
                        label = label + ',' + this.user_data.unique_key + ',';
                    }

                    if (this.user_data.is_followed) {
                        this.GA_Tracking('Follow People', actionLabel + 'Unfollow', label);
                    } else {
                        this.GA_Tracking('Follow People', actionLabel + 'Follow', label);
                    }
                }

                this.is_loading = false;
            })
                .catch(err => {
                    this.is_loading = false;
                    if (err.response.data.message === 'Unauthenticated.') {
                        this.$store.commit('openLogin', {
                            is_show: true,
                            title: '',
                            text: ''
                        });
                    }
                });
        },
    },
    mounted() {
        if (this.type === 'user') {
            if (this.user_data.user_id) {
                this.name = this.user_data.nickname;
                this.link = '/user/' + this.user_data.user_id + '/profile';
                this.follow_api = '/api/post/user/' + this.user_data.user_id + '/follow';
                this.unfollow_api = '/api/post/user/' + this.user_data.user_id + '/unfollow';
                this.avatar = this.user_data.avatar;
            } else if (this.user_data.post_author_id) {
                this.name = this.user_data.post_author_name;
                this.link = '/user/' + this.user_data.post_author_id + '/profile';
                this.follow_api = '/api/post/user/' + this.user_data.post_author_id + '/follow';
                this.unfollow_api = '/api/post/user/' + this.user_data.post_author_id + '/unfollow';
                this.avatar = this.user_data.post_author_image;
            } else {
                this.name = this.user_data.name;
                this.link = '/brand/' + this.user_data.id + '/profile/shop';
                this.follow_api = '/api/post/brand/' + this.user_data.id + '/watch';
                this.unfollow_api = '/api/post/brand/' + this.user_data.id + '/unwatch';
                this.avatar = this.user_data.image;
            }
        }
    },
}
</script>

<style lang="scss">
@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.m-list-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    border-radius: var(--radius-minimal);
    background-color: var(--Surface-light);
    color: var(--Text-primary);
    font: var(--text-md-regular);
    margin: 8px 0;

    &.is-hover {
        cursor: pointer;
        transition: .2s all ease;

        &:hover {
            background-color: var(--Neu-10);
        }
    }

    &.is-loading {
        opacity: .5;
        pointer-events: none;
    }

    .svg-icon:nth-child(1) {
        margin-right: 8px;
    }

    .text {
        flex: 1;

        &+.svg-icon {
            margin-left: 8px;
        }
    }

    &.size {
        &-32 {
            height: 32px;

            .svg-icon {
                width: 16px;
                height: 16px;

                svg {
                    width: 13px;
                    height: 13px;
                }
            }
        }

        &-40 {
            height: 40px;

            .svg-icon {
                width: 20px;
                height: 20px;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .user-item {
        display: flex;
        align-items: center;
        width: 100%;

        .info {
            flex: 1;
            padding: 0 10px;

            h3 {
                font: var(--text-md-regular);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-word;

                a {
                    color: var(--Text-primary);
                }
            }

            .about-me {
                font-size: var(--font-xs);
                color: var(--Text-default);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-word;
            }

            .recently {
                color: var(--Text-default);

                span {
                    color: var(--YouTube);
                }
            }
        }

        .yourself {
            color: var(--Text-default);
            padding-right: 5px;
        }
    }

    .router-item,
    .check-item,
    .connet-item {
        width: 100%;

        .tab {
            @include flexCenter();
            position: relative;
            justify-content: space-between;

            .left {
                @include flexCenter();

                p {
                    margin: 0 var(--spacing-03);
                }

                .svg-icon {
                    margin-right: 0;
                }

                .svg-icon--alert {
                    margin-left: -4px;
                    height: 12px;
                    width: 12px;
                    fill: var(--Text-danger);
                }
            }

            .right {
                @include flexCenter();
                justify-content: center;

                .label {
                    color: var(--Text-default);
                    font: var(--text-s-regular)
                }

                .main-icon {
                    width: 16px;
                    height: 16px;
                    @include flexCenter();
                    justify-content: center;

                    .svg-icon {
                        height: 8px;
                        margin-right: 0;
                        fill: var(--Text-default)
                    }
                }

                .main-icon-open {
                    transform: rotate(90deg);

                    .svg-icon {
                        fill: var(--Text-primary);
                    }
                }

                .svg-icon--alert {
                    width: 10px;
                    fill: var(--Text-danger);
                    margin-right: var(--spacing-02);

                }
            }
        }

        a {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 9;
            left: 0;
            top: 0;
        }

    }

    .check-item-default {

        .left {
            color: var(--Text-default);

            svg {
                fill: var(--Text-default);
            }
        }

        .left-checked {
            color: var(--Text-primary);

            svg {
                fill: var(--Text-primary);
            }
        }

        .right {
            .svg-icon {
                width: 16px;
                height: 16px;

                svg {
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }

    &.type-likeboard {
        height: auto;
        padding: 0;
        overflow: hidden;

        .likeboard-layout {
            width: 70px;
            min-width: 70px;
        }

        .info {
            flex: 1;
            padding: 10px;
            color: var(--Text-primary);

            strong {
                display: block;
                font: var(--span-regular);
                color: var(--Text-default);
                margin-top: 6px;
            }

            p {
                display: flex;
                align-items: center;
                font: var(--span-regular);
                color: var(--Text-primary);

                svg {
                    height: 12px;
                    width: 16px;
                }
            }
        }
    }

    a{
        color:var(--Text-primary);
    }
}
</style>
