<template>
    <a v-if="is_external_link" :href="to" :class="classnames">
        <slot></slot>
    </a>
    <router-link v-else-if="to" :to="to" :class="classnames">
        <slot></slot>
    </router-link>
    <span v-else :class="classnames">
        <slot></slot>
    </span>
</template>

<script>
export default {
    name: 'm-button',

    props: {
        type: {
            type: String,
            default: 'primary' // primary, base, secondary, tertiary, primary-invert, chips
        },
        size: {
            type: String,
            default: '32'
        },
        to: {
            type: String,
            default: ''
        },
        is_hover: {
            type: Boolean,
            default: true
        },
        is_actived: {
            type: Boolean,
            default: false
        },
        is_disabled: {
            type: Boolean,
            default: false
        },
        is_external: {
            type: Boolean,
            default: false
        },
        is_svg_at_right: {
            type: Boolean,
            default: false
        },
        is_width:{
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            is_external_link: false,
            //classnames: ['m-button'],
        }
    },

    computed:{
        classnames(){
            return [
                'm-button', 
                this.type,
                'size-' + this.size,
                {'actived':this.is_actived},
                {'disabled':this.is_disabled},
                {'svg-at-right':this.is_svg_at_right},
                {'hover':this.is_hover || this.to},
                {'is-100': this.is_width}
            ]
        }
    },

    mounted () {
        if (this.is_external || this.to.includes('/login/social') || this.to.includes('/logout') || this.to.includes('mailto:')) {
            // check if the email
            this.is_external_link = true;
        }
        // this.classnames.push(this.type)
        // this.classnames.push('size-' + this.size)
        // if (this.is_actived) this.classnames.push('actived');
        // if (this.is_disabled) this.classnames.push('disabled');
        // if (this.is_svg_at_right) this.classnames.push('svg-at-right');
        // if (this.is_hover || this.to) this.classnames.push('hover');
    }
}
</script>

<style lang="scss">

    .m-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        box-sizing: content-box;
        font: var(--text-s-regular);
        border-radius: var(--radius-minimal);
        transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
        padding: 0 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        max-width: 100%;
        outline: none;

        &.is-100{
            width: 100%;
            padding: 0;
        }

        &.hover {
            cursor: pointer;
        }

        &.size {
            &-24 {
                height: 22px;
                &.chips {
                    border-radius: var(--radius-minimal);//var(--radius-medium);
                }
            }

            &-32 {
                height: 30px;

                &.chips {
                    border-radius: var(--radius-large);
                }
            }

            &-40 {
                height: 38px;

                &.chips {
                    border-radius: var(--radius-large);
                }
            }

            &-42 {
                height: 40px;

                &.chips {
                    border-radius: var(--radius-large);
                }
            }

            &-48 {
                height: 46px;
                font: var(--text-l-regular);

                svg {
                    height: 24px;
                    width: 24px;
                    margin-right: 8px;
                }
            }
        }

        svg {
            height: 20px;
            width: 20px;
            min-width: 20px;
            min-height: 20px;
            display: block;
            margin-right: 4px;

            &.svg-icon--back {
                height: 10px;
                width: 10px;
                min-width: 10px;
                min-height: 10px;
                transform: rotate(-180deg);
                margin-left: 4px;
                margin-right: 0;
            }
        }

        &.svg-at-right {
            svg {
                margin-left: 4px;
                margin-right: 0;
            }
        }

        &.primary {
            border-color: var(--Border-primary);
            background-color: var(--Surface-primary);
            color: var(--Text-invert);

            &.hover:hover {
                background-color: var(--Surface-primary-hover);
                border-color: var(--Border-primary);
            }

            &.actived:hover,
            &.actived {
                border-color: var(--Border-dark);
                background-color: var(--Surface-dark);
            }

            &.disabled:hover,
            &.disabled {
                pointer-events: none;
                background-color: var(--Surface-disable);
                border-color: var(--Surface-disable);
                color: var(--Text-disabled);
            }
        }

        &.base {
            border-color: var(--Border-neu);
            background-color: var(--Surface-light);
            color: var(--Text-default);

            &.hover:hover {
                border-color: var(--Border-hover);
                background-color: var(--Surface-hover);
                color: var(--Text-primary);
            }

            &.actived:hover,
            &.actived {
                border-color: var(--Border-primary);
                background-color: var(--Surface-primary);
                color: var(--Surface-light);
            }

            &.disabled:hover,
            &.disabled {
                pointer-events: none;
                background-color: var(--Surface-disable);
                border-color: var(--Surface-disable);
                color: var(--Text-disabled);
            }
        }

        &.secondary {
            border-color: var(--Border-primary);
            background-color: var(--Surface-light);
            color: var(--Text-secondary);

            &.hover:hover {
                background-color: var(--Surface-hover);
                border-color: var(--Border-primary);
                color: var(--Text-brand);
            }

            &.actived:hover,
            &.actived {
                border-color: var(--Border-primary);
                background-color: var(--Surface-tertiary-selected);
                color: var(--Text-primary);
            }

            &.disabled:hover,
            &.disabled {
                pointer-events: none;
                background-color: var(--Surface-disable);
                border-color: var(--Surface-disable);
                color: var(--Text-disabled);
            }
        }

        &.tertiary {
            border-color: transparent;
            background-color: transparent;
            color: var(--Text-secondary);

            &.hover:hover {
                background-color: var(--Border-hover);
                border-color: var(--Surface-hover);
                color: var(--Text-primary);
            }

            &.actived:hover,
            &.actived {
                border-color: var(--Border-neu);
                background-color: var(--Surface-tertiary-selected);
                color: var(--Text-primary);
            }

            &.disabled:hover,
            &.disabled {
                pointer-events: none;
                background-color: transparent;
                border-color: transparent;
                color: var(--Text-disabled);
            }
        }

        &.primary-invert {
            border-color: var(--Border-light);
            background-color: var(--Surface-light);
            color: var(--Text-brand);

            &.hover:hover {
                background-color: var(--Surface-hover);
                border-color: var(--Border-hover);
            }

            &.actived:hover,
            &.actived {
                color: var(--Text-invert);
                border-color: var(--Border-dark);
                background-color: var(--Surface-dark);
            }

            &.disabled:hover,
            &.disabled {
                pointer-events: none;
                background-color: var(--Surface-disable);
                border-color: var(--Surface-disable);
                color: var(--Text-disabled);
            }
        }

        &.chips {
            border-color: var(--Neu-40);
            background-color: var(--Neu-10);
            color: var(--Text-primary);
            border-radius: var(--radius-medium);

            span + i {
                height: 16px;
                width: 16px;
                margin-left: 4px;
                color: var(--Text-primary);

                svg {
                    height: 100%;
                    width: 100%;
                    min-width: 100%;
                    min-height: 100%;
                    margin: 0;
                }
            }

            &.hover:hover {
                border-color: var(--Border-hover);
                background-color: var(--Surface-hover);
            }

            &.router-link-exact-active,
            &.router-link-exact-active:hover,
            &.actived:hover,
            &.actived {
                color: var(--Text-invert);
                border-color: var(--Border-primary);
                background-color: var(--Surface-primary);
            }
        }
    }

</style>
