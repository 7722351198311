<template>
    <div :class="classnames" @click="checkBox">
        <svg-icon class="pick-icon" v-if="is_checked" :name="icon_checked" />
        <svg-icon class="pick-icon" v-else :name="icon" />
        <p v-if="text" class="label-desc">{{ text }}</p>
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'my-checkbox',

    props: {
        type: {
            type: String,
            default: 'checkbox',//radio
        },

        is_checked: {
            type: Boolean,
            default: false,
        },

        text: {
            type: String,
            default: '',
        },

        size: {
            type: String,
            default: '40' // 32
        },
    },

    data() {
        return {

        }
    },

    computed: {
        classnames() {
            let arr = ['my-checkbox', `size-${this.size}`,`check-${this.is_checked}`];
            return arr;
        },
        icon() {
            return this.type === 'checkbox' ? 'check_box_new' : 'radio_button_unchecked'

        },
        icon_checked() {
            return this.type === 'checkbox' ? 'checked_box_new' : 'radio_check_circle'
        },
    },

    methods: {
        checkBox() {
            this.$emit('checked', !this.is_checked);
        },
    },

    mounted() {
    },
}

</script>

<style lang="scss">
.my-checkbox {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    color: var(--Text-primary);

    &.size {
        .pick-icon {
            fill:var(--Text-primary);
        }
        
        &-32 {
            font: var(--text-s-regular);

            .pick-icon {
                width: 16px;
                height: 16px;
            }
        }

        &-40 {
            font: var(--text-md-regular);

            .pick-icon {
                width: 20px;
                height: 20px;
            }
        }
    }
}
</style>