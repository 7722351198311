<template>
    <nav class="global-menu">
        <ul>
            <li class="link-home">
                <router-link to="/">
                    <svg-icon name="home" />
                    <svg-icon name="home2" />
                </router-link>
                <span class="back-top" @click="gotoTop"></span>
            </li>
            <li class="link-shop">
                <router-link to="/home/shop">
                    <svg-icon name="shopping_bag" />
                    <svg-icon name="shopping_bag_fill" />
                </router-link>
                <span class="back-top" @click="gotoTop"></span>
            </li>
            <li class="link-create">
                <span @click="gotoCreatePage">
                    <svg-icon name="create" />
                </span>
            </li>
            <li class="link-likeboard">
                <router-link to="/home/collections">
                    <svg-icon name="grid" />
                    <svg-icon name="grid-fill" />
                </router-link>
                <span class="back-top" @click="gotoTop"></span>
            </li>
            <li class="link-user">
                <router-link to="/me">
                    <svg-icon name="user" />
                    <svg-icon name="user-fill" />
                </router-link>
                <span class="back-top" @click="gotoTop"></span>
            </li>
        </ul>
    </nav>
</template>

<script>
import { EventBus } from '@/events/bus.js';
// import Avatar from '@/components/ui/Avatar.vue';
import SvgIcon from '@/components/ui/SvgIcon.vue';

export default {
    name: 'global-menu',

    data() {
        return {
            is_end: {
                people: false,
                brand: false
            }
        };
    },

    components: {
        // Avatar,
        SvgIcon,
    },

    methods: {
        gotoCreatePage() {
            if (this.$store.state.is_logged) {
                // this.$router.push({ path: '/create-post' });
                EventBus.$emit('open-create-post');
            } else {
                // window.location.href = '/login';
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Ready to create a post?',
                    text: 'You’ll need an account to create post or simply log in. '
                });
            }
        },
        gotoTop() {
            this.$SmoothScroll(0, 500);
        }
    }
};

</script>
<style lang="scss">
    @import '../resources/sass/_variables.scss';

    .global-menu {
        position: fixed;
        bottom: 0rem;
        z-index: 109;
        height: 50px;
        background: var(--Bg-light);
        width: 100%;
        border: 1px solid var(--Black-10);
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
        padding-bottom: 8px;
        border-radius: 16px 16px 0px 0px;
        transition: .2s transform ease-in-out;
        transform: translate(0, 0%);

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            li {
                height: 100%;
                width: 20%;
                position: relative;

                & > span,
                & > a {
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: relative;
                    cursor: pointer;

                    &:hover svg {
                        fill: var(--Brand-Primary);
                    }

                    &.router-link-exact-active.router-link-active {
                        svg:nth-child(1) {
                            display: none;
                        }

                        svg:nth-child(2) {
                            display: block;
                        }

                        & + .back-top {
                            display: block;
                        }
                    }
                }

                svg {
                    fill: var(--Text-default);
                    height: 24px;
                    width: 24px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                svg:nth-child(2) {
                    display: none;
                }
            }

            .back-top {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
                display: none;
            }

            // .link-home {
            //     svg {
            //         height: 30px;
            //         width: 30px;
            //     }
            // }

            .link-create {
                // margin: 0 6px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    cursor: pointer;
                    height: 32px;
                    width: 32px;
                    display: block;
                    position: relative;
                    border-radius: 50%;
                    background: var(--Brand-Primary)
                    // box-shadow: 1px 2px 4px rgba(249, 163, 38, 0.32), 0px 4px 8px rgba(241, 101, 41, 0.35);
                    // background: var(--Brand-Primary);
                }

                svg {
                    height: 20px;
                    width: 20px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    fill: var(--Black-00);
                }
            }
        }

        @at-root {

            #mobile-wrap.page--home-home .global-menu ul li.link-home,
            #mobile-wrap.page--home-tot .global-menu ul li.link-home,
            #mobile-wrap.page--home-article  .global-menu ul li.link-home,
            #mobile-wrap.page--home-tag .global-menu ul li.link-home,
            #mobile-wrap.page--shop-brands .global-menu ul li.link-shop,
            #mobile-wrap.page--likeboards .global-menu ul li.link-likeboard,
            #mobile-wrap.page--home-shop .global-menu ul li.link-shop
            {
                a {
                    svg:nth-child(1) {
                        display: none;
                    }

                    svg:nth-child(2) {
                        display: block;
                        fill: var(--Brand-Primary);
                    }

                    & + .back-top {
                        display: block;
                    }
                }
            }

            // #mobile-wrap.page--private-profile .global-menu ul li.link-user {
            //     .avatar-wrap {

            //         a > div {
            //             border: none;
            //             background: var(--Brand-Primary);
            //             height: 32px;
            //             width: 32px;
            //         }

            //         .back-top {
            //             display: block;
            //         }
            //     }
            // }

            .is-sticky & {
                transform: translate(0, 140%);
            }

            .is-sticky.show-header & {
                transform: translate(0, 0%);
            }
        }
    }

</style>
