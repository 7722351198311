/* eslint-disable */
export default function(eventName, eventParams = false) {    
    if (typeof pintrk === 'function') {
        if (eventParams) {
            pintrk('track', eventName, eventParams);
        }
        else {
            pintrk('track', eventName);
        }        
    }
}