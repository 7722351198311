import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue'
// import device from '@/utils/CheckDevice';



Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

let mobile_routes = [
    {
        path: '/',
        name: 'home',
        meta: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },

    {
        path: '/home/login',
        name: 'home-login',
        meta: 'home-login',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/login/email',
        name: 'home-email-login',
        meta: 'home-email-login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/home/featured',
        name: 'home-featured',
        meta: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },

    {
        path: '/home/latest',
        name: 'tag',
        meta: 'home-tag',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/home/tot',
        name: 'tot',
        meta: 'home-tot',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/home/tot/:slug',
        name: 'tot-sub',
        meta: 'home-tot',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/home/collections',
        name: 'home-likeboards',
        meta: 'likeboards',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/home/shop',
        name: 'shop',
        meta: 'home-shop',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/shop/brands',
        name: 'brands',
        meta: 'shop-brands',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/home/article',
        name: 'articles',
        meta: 'home-article',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/help',
        name: 'help',
        component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
    },

    {
        path: '/me',
        name: 'private-profile-storefront',
        meta: 'private-profile',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/Private.vue')
    },

    {
        path: '/me/posts',
        name: 'private-profile-me',
        meta: 'private-profile',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/Private.vue')
    },

    {
        path: '/me/posts/:type',
        name: 'private-profile-me',
        meta: 'private-profile',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/Private.vue')
    },

    {
        path: '/me/storefront/add-product',
        name: 'private-profile-storefront-add-product',
        meta: 'private-profile-storefront-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/all-brands',
        name: 'private-profile-storefront-all-brands',
        meta: 'private-profile-storefront-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/brand-product/:slug',
        name: 'private-profile-storefront-brand-product',
        meta: 'private-profile-storefront-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/your-items',
        name: 'private-profile-storefront-your-items',
        meta: 'private-profile-storefront-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/your-items/:slug',
        name: 'private-profile-storefront-yourstuffsubpage',
        meta: 'private-profile-storefront-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/search-product',
        name: 'private-profile-storefront-search-product',
        meta: 'private-profile-storefront-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/search-product/:slug',
        name: 'private-profile-storefront-search-product',
        meta: 'private-profile-storefront-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },


    {
        path: '/me/storefront/:merchant_account_id/add-product',
        name: 'private-profile-merchant-add-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/:merchant_account_id/search-product',
        name: 'private-profile-merchant-search-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/:merchant_account_id/search-product/:slug',
        name: 'private-profile-merchant-search-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    // Collection add product
    {
        path: '/me/storefront/:merchant_account_id/collection/:collection_id/add-product',
        name: 'private-profile-merchant-add-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/:merchant_account_id/collection/:collection_id/search-product',
        name: 'private-profile-merchant-search-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/:merchant_account_id/collection/:collection_id/search-product/:slug',
        name: 'private-profile-merchant-search-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront/:merchant_account_id/collection/:collection_id/your-items',
        name: 'private-profile-merchant-your-items',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },
    // Collection add product end


    // Storefront post add product
    {
        path: '/me/storefront-post/:merchant_account_id/post/:post_id/add-product',
        name: 'private-profile-merchant-add-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront-post/:merchant_account_id/post/:post_id/search-product',
        name: 'private-profile-merchant-search-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront-post/:merchant_account_id/post/:post_id/search-product/:slug',
        name: 'private-profile-merchant-search-product',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/storefront-post/:merchant_account_id/post/:post_id/your-items',
        name: 'private-profile-merchant-your-items',
        meta: 'private-profile-merchant-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },
    // Storefront post add product end

    {
        path: '/me/likeboards/tagged',
        name: 'private-profile-tagged',
        meta: 'private-profile',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/Tagged.vue')
    },

    {
        path: '/me/likeboards/liked',
        name: 'private-profile-liked',
        meta: 'private-profile',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/Private.vue')
    },
    
    // {
    //     path: '/me/links',
    //     name: 'private-profile-links',
    //     meta: 'private-profile',
    //     component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/Private.vue')
    // },

    {
        path: '/me/likeboards',
        name: 'private-profile-likeboards',
        meta: 'private-profile',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/Private.vue')
    },

    {
        path: '/me/likeboards/:slug',
        name: 'private-profile-likeboards-like',
        meta: 'private-profile',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/Private.vue')
    },

    {
        path: '/me/likeboards/:id/add-product',
        name: 'private-profile-likeboards-add-product',
        meta: 'private-profile-likeboards-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/likeboards/:id/all-brands',
        name: 'private-profile-likeboards-all-brands',
        meta: 'private-profile-likeboards-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/likeboards/:id/brand-product/:slug',
        name: 'private-profile-likeboards-brand-product',
        meta: 'private-profile-likeboards-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/likeboards/:id/your-items',
        name: 'private-profile-likeboards-your-items',
        meta: 'private-profile-likeboards-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/likeboards/:id/your-items/:slug',
        name: 'private-profile-likeboards-yourstuffsubpage',
        meta: 'private-profile-likeboards-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/likeboards/:id/search-product',
        name: 'private-profile-likeboards-search-product',
        meta: 'private-profile-likeboards-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/likeboards/:id/search-product/:slug',
        name: 'private-profile-likeboards-search-product',
        meta: 'private-profile-likeboards-add',
        component: () => import(/* webpackChunkName: "myprofile-add-product" */ '../views/profile/AddProduct.vue')
    },

    {
        path: '/me/settings',
        name: 'settings',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },

    {
        path: '/me/settings/info',
        name: 'settings-info',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },
    {
        path: '/me/settings/account/deactivate',
        name: 'settings-account-deactivate',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },
    {
        path: '/me/settings/account-security',
        name: 'settings-account-security',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },

    {
        path: '/me/settings/account-security/:type',
        name: 'settings-account-security-edit',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },

    {
        path: '/me/settings/account-security-email/code',
        name: 'settings-account-security-email-code',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },

    {
        path: '/me/settings/update-success/:type',
        name: 'settings-update-success',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },

    {
        path: '/me/settings/linked-accounts',
        name: 'settings-linked-accounts',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },

    {
        path: '/me/settings/linked',
        name: 'settings-linked',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },


    {
        path: '/me/settings/account-privacy',
        name: 'settings-account-privacy',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },

    {
        path: '/me/settings/about-me',
        name: 'settings-about-me',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },
    {
        path: '/me/settings/myEmail',
        name: 'settings-myEmail',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },
    {
        path: '/me/settings/myInterests',
        name: 'settings-myInterests',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },
    {
        path: '/me/settings/myBrands',
        name: 'settings-myBrands',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },
    {
        path: '/me/settings/myCauses',
        name: 'settings-myCauses',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },
    {
        path: '/me/settings/payment',
        name: 'settings-payment',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    },

    {
        path: '/me/switch-to-creator/:step',
        name: 'switch-to-creator',
        meta: 'switch-to-creator',
        component: () => import(/* webpackChunkName: "myprofile" */ '../views/profile/SwitchAccount.vue')
    },
    // {
    //     path: '/me/settings/push-notifications',
    //     name: 'settings-notifications',
    //     meta: 'private-profile-settings',
    //     component: () => import(/* webpackChunkName: "settings" */ '../views/profile/Settings.vue')
    // },

    {
        path: '/me/earnings',
        name: 'earnings',
        meta: 'earnings',
        component: () => import(/* webpackChunkName: "earnings" */ '../views/profile/Earnings.vue')
    },
    {
        path: '/me/payments',
        name: 'payments',
        meta: 'earnings',
        component: () => import(/* webpackChunkName: "earnings" */ '../views/profile/Earnings.vue')
    },
    {
        path: '/me/transactions',
        name: 'transactions',
        meta: 'earnings',
        component: () => import(/* webpackChunkName: "earnings" */ '../views/profile/Earnings.vue')
    },
    {
        path: '/me/sales',
        name: 'sales',
        meta: 'earnings',
        component: () => import(/* webpackChunkName: "earnings" */ '../views/profile/SalesReport.vue')
    },
    {
        path: '/me/earnings/product/:id',
        name: 'earnings-product',
        meta: 'earnings',
        component: () => import(/* webpackChunkName: "earnings" */ '../views/profile/ProductReport.vue')
    },
    {
        path: '/me/metrics',
        name: 'metrics',
        meta: 'metrics',
        component: () => import(/* webpackChunkName: "metrics" */ '../views/profile/Metrics.vue')
    },
    {
        path: '/me/metrics/:slug',
        name: 'metrics-sub-page',
        meta: 'metrics',
        component: () => import(/* webpackChunkName: "metrics" */ '../views/profile/Metrics.vue')
    },

    {
        path: '/user/:user_id/profile',
        name: 'public-profile-storefront',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },

    {
        path: '/user/:user_id/posts',
        name: 'public-profile-posts',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },

    {
        path: '/user/:user_id/posts/:type',
        name: 'public-profile-posts',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },

    // {
    //     path: '/user/:user_id/profile',
    //     name: 'public-profile',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },

    // {
    //     path: '/user/:user_id/profile/social',
    //     name: 'public-profile-social',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },

    // {
    //     path: '/user/:user_id/storefront',
    //     name: 'public-profile-storefront',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },

    // {
    //     path: '/user/:user_id/tagged',
    //     name: 'public-profile-tagged',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },

    // {
    //     path: '/user/:user_id/tags',
    //     name: 'public-tags',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },

    {
        path: '/user/:user_id/likeboards',
        name: 'public-likeboards',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },
    // {
    //     path: '/user/:user_id/likeboards/liked-by-you',
    //     name: 'public-likeboards-like',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },
    {
        path: '/user/:user_id/likeboards/:slug',
        name: 'public-likeboards-liked',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },
    // {
    //     path: '/user/:user_id/seen-in',
    //     name: 'public-seen-in',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },

    {
        path: '/me/following',
        name: 'following-list',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "following" */ '../views/profile/Following.vue')
    },
    {
        path: '/me/following/:type',
        name: 'following-list',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "following" */ '../views/profile/Following.vue')
    },
    {
        path: '/me/followers',
        name: 'followers-list',
        meta: 'private-profile-settings',
        component: () => import(/* webpackChunkName: "following" */ '../views/profile/Following.vue')
    },

    {
        path: '/group/:group_id',
        name: 'groups-page',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "groups" */ '../views/profile/Group.vue')
    },
    {
        path: '/brand/:brand_id/profile',
        name: 'brand',
        meta: 'brand-profile',
        component: () => import(/* webpackChunkName: "brand" */ '../views/profile/Brand.vue')
    },
    {
        path: '/brand/:brand_id/profile/:type',
        name: 'brand-sub-page',
        meta: 'brand-profile',
        component: () => import(/* webpackChunkName: "brand" */ '../views/profile/Brand.vue')
    },

    {
        path: '/collections/:unique_key',
        name: 'private-likeboard',
        meta: 'private-likeboard',
        component: () => import(/* webpackChunkName: "likeboard-details" */ '../views/LikeboardDetails.vue')
    },


    {
        path: '/public-collections/:unique_key',
        name: 'public-likeboard',
        meta: 'likeboard',
        component: () => import(/* webpackChunkName: "likeboard-details" */ '../views/LikeboardDetails.vue')
    },
    {
        path: '/public-collections/:unique_key/:type',
        name: 'public-likeboard-types',
        meta: 'likeboard',
        component: () => import(/* webpackChunkName: "likeboard-details" */ '../views/LikeboardDetails.vue')
    },
    {
        path: '/collections/:unique_key/edit/:slug',
        name: 'edit-likeboard',
        meta: 'private-likeboard',
        component: () => import(/* webpackChunkName: "likeboard-edit" */ '../views/LikeboardEdit.vue')
    },

    {
        path: '/public-collections/:unique_key/edit/:slug',
        name: 'edit-likeboard',
        meta: 'likeboard',
        component: () => import(/* webpackChunkName: "likeboard-edit" */ '../views/LikeboardEdit.vue')
    },
    {
        path: '/collections/:unique_key/:type',
        name: 'public-likeboard-types',
        meta: 'private-likeboard',
        component: () => import(/* webpackChunkName: "likeboard-details" */ '../views/LikeboardDetails.vue')
    },
    {
        path: '/hotproducts',
        name: 'hotproducts',
        component: () => import(/* webpackChunkName: "product" */ '../views/HotProducts.vue')
    },
    {
        path: '/productdetails/:id',
        name: 'productdetails',
        meta: 'productdetails',
        component: () => import(/* webpackChunkName: "product" */ '../views/ProductDetails.vue')
    },
    {
        path: '/productdetails/:id/posts/:type',
        name: 'productposts',
        meta: 'productdetails',
        component: () => import(/* webpackChunkName: "product-posts" */ '../views/ProductPosts.vue')
    },

    {
        path: '/postdetails/:id',
        name: 'postdetails',
        meta: 'postdetails',
        component: () => import(/* webpackChunkName: "postdetail" */ '../views/PostDetails.vue')
    },
    // {
    //     path: '/live-video/:id',
    //     name: 'live-video',
    //     component: () => import(/* webpackChunkName: "live-video" */ '../views/LiveVideoDetails.vue')
    // },
    {
        path: '/globalsearch',
        name: 'globalsearch',
        meta: 'globalsearch',
        component: () => import(/* webpackChunkName: "search" */ '../views/GlobalSearch.vue')
    },
    {
        path: '/notifications',
        name: 'notifications',
        meta: 'notifications',
        component: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications.vue')
    },
    {
        path: '/notifications/:type',
        name: 'notifications',
        meta: 'notifications',
        component: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications.vue')
    },
    {
        path: '/notifications/:type/:subtype',
        name: 'notifications',
        meta: 'notifications',
        component: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications.vue')
    },
    {
        path: '/globalsearch/:filter_type',
        name: 'globalsearch',
        meta: 'globalsearch',
        component: () => import(/* webpackChunkName: "search" */ '../views/GlobalSearch.vue')
    },
    {
        path: '/article/:id',
        name: 'article',
        meta: 'article',
        component: () => import(/* webpackChunkName: "article" */ '../views/ArticleContent.vue')
    },
    {
        path: '/invitefriend',
        name: 'invitefriend',
        component: () => import(/* webpackChunkName: "invitefriend" */ '../views/InviteFriend.vue')
    },

    {
        path: '/create-post',
        name: 'create-index',
        meta: 'create',
        component: () => import(/* webpackChunkName: "create" */ '../views/create/Index.vue')
    },

    {
        path: '/create-post/draft',
        name: 'create-draft',
        meta: 'create',
        component: () => import(/* webpackChunkName: "drafts" */ '../views/create/DraftsPage.vue')
    },

    {
        path: '/create-post/:type/all-brands',
        name: 'create-post-all-brands',
        meta: 'create',
        component: () => import(/* webpackChunkName: "create" */ '../views/create/Index.vue')
    },

    {
        path: '/create-post/thisorthat/product/:id/:action',
        name: 'create-post-tot-product',
        meta: 'create',
        component: () => import(/* webpackChunkName: "create" */ '../views/create/Index.vue')
    },

    {
        path: '/create-post/thisorthat/product/:id/:action/:slug',
        name: 'create-post-tot-product',
        meta: 'create',
        component: () => import(/* webpackChunkName: "create" */ '../views/create/Index.vue')
    },

    {
        path: '/create-post/:type',
        name: 'create-post',
        meta: 'create',
        component: () => import(/* webpackChunkName: "create" */ '../views/create/Index.vue')
    },

    {
        path: '/create-post/:type/:action',
        name: 'create-post-action',
        meta: 'create',
        component: () => import(/* webpackChunkName: "create" */ '../views/create/Index.vue')
    },

    {
        path: '/create-post/:type/:action/:slug',
        name: 'create-post-slug',
        meta: 'create',
        component: () => import(/* webpackChunkName: "create" */ '../views/create/Index.vue')
    },

    {
        path: '/register',
        name: 'register',
        meta: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
    },


    {
        path: '/forgot-password/:type',
        name: 'reset-password',
        meta: 'reset-password',
        component: () => import(/* webpackChunkName: "register" */ '../views/ResetPassword.vue')
    },
    {
        path: '/register-settings',
        name: 'register-settings',
        meta: 'register',
        component: () => import(/* webpackChunkName: "register-settings" */ '../views/RegisterSettings.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/page/:slug',
        name: 'page',
        meta: 'page',
        component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue')
    },
    {
        path: '/trending/product/:type/:text',
        name: 'trending',
        meta: 'trending-product',
        component: () => import(/* webpackChunkName: "trending" */ '../views/Trending.vue')
    },
    {
        path: '/trending/post/:type/:text',
        name: 'trending',
        meta: 'trending-post',
        component: () => import(/* webpackChunkName: "trending" */ '../views/Trending.vue')
    },
    {
        path: '/trending/list/:type',
        name: 'trending-list',
        meta: 'trending-list',
        component: () => import(/* webpackChunkName: "trending" */ '../views/MoreHashtags.vue')
    },
    {
        path: '/trending/list/:type/:keyword',
        name: 'trending-list',
        meta: 'trending-list',
        component: () => import(/* webpackChunkName: "trending" */ '../views/MoreHashtags.vue')
    },
    // {
    //     path: '/@:handle',
    //     name: 'public-profile',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },
    // {
    //     path: '/@:handle/profile',
    //     name: 'public-profile',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },
    // {
    //     path: '/@:handle/profile/social',
    //     name: 'public-profile-social',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },
    // {
    //     path: '/@:handle/storefront',
    //     name: 'public-profile-storefront',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },
    // {
    //     path: '/@:handle/tagged',
    //     name: 'public-profile-tagged',
    //     meta: 'public-profile',
    //     component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    // },
    {
        path: '/@:handle',
        name: 'public-profile-storefront',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },
    {
        path: '/@:handle/profile',
        name: 'public-profile-storefront',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },
    {
        path: '/@:handle/posts',
        name: 'public-profile-posts',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },
    {
        path: '/@:handle/posts/:type',
        name: 'public-profile-posts',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },
    {
        path: '/@:handle/likeboards',
        name: 'public-likeboards',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },
    {
        path: '/@:handle/likeboards/:slug',
        name: 'public-likeboards-liked',
        meta: 'public-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Public.vue')
    },

    // {
    //     path: '/connect-facebook',
    //     name: 'connect-facebook',
    //     meta: 'connect-facebook',
    //     component: () => import(/* webpackChunkName: "connect-facebook" */ '../components/register/ConnectFacebook.vue')
    // },
    
    {
        path: '*',
        name: 'not-found',
        props: true,
        component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')
    },
];

let routes = mobile_routes;



const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
});


// router.beforeRouteUpdate((to, from, next)  => {
//     // console.log('Update', to, from)
//     // next();
// })

// router.beforeRouteLeave((to, from, next)  => {

//     // console.log('Leave', to, from)
//     // if (this.is_exit || to.meta === 'create') {
//     //     // next();
//     // } else {
//     //     this.is_show_exit_modal = true;
//     // }
// })

export default router;