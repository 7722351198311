export default function (category, label) {
    console.log('GA EVENT | ' + category + '|', label);

    if (typeof gtag === 'function') {

        gtag('event', category, {
            'loggin_user_id': label.loggin_user_id,
            'parent_brand_id': label.parent_brand_id,
            'product_id': label.product_id,
            'custom_id': label.custom_id,
            'merchant_id': label.merchant_id,
            'post_owner_id': label.post_owner_id,
            'media_owner_id': label.media_owner_id,
            'is_likeboard': label.is_likeboard,
        });

    }
}