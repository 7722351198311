export default function(file, quality = 0.8, size = 1600) {
    const oldFileSize = file.size;

    return new Promise(function (resolve, reject) {
        if (file.type.startsWith('image')) {
            if (file.type.match(/(jpeg)|(png)/)) {
                const reader = new FileReader();

                reader.onload = function () {
                    if (this.result) {
                        const oldDataURL = this.result;
                        const img = new Image();
    
                        img.onload = function () {
                            
    
    
                            if (img.width > size || img.height > size) {
                                //console.log('old image size width:' + img.width, 'old image size height:' + img.height);
    
                                const $canvas = document.createElement('canvas');
                                let width = 0, height = 0;
    
                                if (img.width > img.height) {
                                    width = size;
                                    height = Number((size * (img.height/img.width)).toFixed(0));
                                } else {
                                    height = size;
                                    width = Number((size * (img.width/img.height)).toFixed(0));
                                }
    
                                //console.log('new image size width:' + width, 'new image size height:' + height);
    
    
                                $canvas.width = width;
                                $canvas.height = height;
                                const ctx = $canvas.getContext('2d');
                                // ctx.drawImage(img, 0, 0);
                                drawImageProp(ctx, img, 0, 0, width, height);

        
                                $canvas.toBlob(function (blob) {
                                    const newFile = blob;
                                    newFile.lastModified = file.lastModified;
                                    newFile.name = file.name;
                                    const newFileSize = newFile.size;
                                    const compressRate = 
                                    // @ts-ignore
                                    ((oldFileSize - newFileSize) / oldFileSize).toFixed(2) * 100;
        
                                    //console.log('oldFile: ' + oldFileSize + 'byte\nnewFile: ' + newFileSize + 'byte\ncompressRate: ' + compressRate + '%');
                                    if (newFile.size < oldFileSize)
                                        resolve(newFile);
                                    else {
                                        //console.warn('original image size >= compressed image size, so original image returned.');
                                        resolve(file);
                                    }
                                }, file.type, quality);
                            } else {
                                //console.log(file);
                                resolve(file);
                            }
    
    
                        };
    
                        img.onerror = function () {
                            console.log('img onerror');
                        };
    
                        img.src = oldDataURL;
                    }

                };
                
                reader.readAsDataURL(file);
            } else {
                reject(new Error('Only support jpeg/png type'));
            }
        } else {
            reject(new Error('Not support non-image type'));
        }
    });
}

function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    // default offset is center
    offsetX = typeof offsetX === 'number' ? offsetX : 0.5;
    offsetY = typeof offsetY === 'number' ? offsetY : 0.5;

    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
        ih = img.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
}