<template>
  <div :class="classnames" :style="`background-color:${color}`">
    <svg-icon class="icon" v-if="is_checked" name="checked-circle" />
  </div>
</template>

<script>
export default {
  name: "my-color-ui",

  props: {
    is_checked: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: "32",
    },

    color: {
      type: String,
      default: "",
    },
  },

  data() {
    return {};
  },

  computed: {
    classnames() {
      let arr = [
        "my-color-ui",
        `size-${this.size}`,
        this.is_checked ? "checked" : "",
      ];
      return arr;
    },
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss">
.my-color-ui {
  cursor: pointer;
  position: relative;
  border: solid 1px var(--Border-neu);
  color: var(--Surface-light);
  border-radius: 12px;

  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -4px;
    right: -4px;
  }

  &.checked{
    border:solid 1px var(--Border-primary);

    svg {
      fill: var(--Border-primary);
    }
  }

  &.size {
    &-32 {
      width: 32px;
      height: 32px;

      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>