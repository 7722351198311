<template>
    <div class="global-search-bar" v-if="is_show_search_bar" :class="[{ 'show-bg': show_bg }, { 'show-box': show_box }]">
        <div class="box">
            <div class="inputbox">
                <div class="close-ico" @click="hide()">
                    <svg-icon name="prev" />
                </div>
                <search placeholder="Search Motom" @input="doInput" @enter="gotoSearchPage" :text="search_value" :spec="true"/>
                <div class="search-cancel" @click="gotoSearchPage"><span>Search</span></div>
            </div>
        </div>
        <div class="bg" @click="hide()"></div>
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';

export default {
    name: 'searchbar',

    data() {
        return {
            is_show_search_bar: false,
            show_bg: false,
            show_box: false,
            search_value: '',
            enter_search: false
        };
    },

    props: {
        is_show: {
            type: Boolean,
            default: false
        }
    },

    watch:{
        '$store.state.global_search'(val){
            this.search_value =val;
        }
    },

    methods: {
        doInput(search_value) {
            this.search_value = search_value;
            this.$store.state.global_search = this.search_value.replace(/\(|\)/g, '');
        },

        doSearch(search_value) {
            this.search_value = search_value;
            this.gotoSearchPage()
        },

        gotoSearchPage() {
            this.search_value = this.search_value.replace(/\(|\)/g, '');

            if (this.search_value.length > 0 ) {
                this.enter_search = true;
            }

            if (this.search_value && this.$route.query.query !== this.search_value ) {
                if (this.$route.name !== 'globalsearch') {
                    this.is_show_search_bar = false;
                    this.show_bg = false;
                    this.show_box = false;
                    this.$router.push({
                        path: '/globalsearch/top',
                        query: { query: this.search_value }
                    });
                } else {
                    this.$router.replace({
                        path: '/globalsearch/' + this.$route.params.filter_type,
                        query: { query: this.search_value }
                    });
                }
            }
        },

        close() {
            this.search_value = '';
            this.enter_search = false;
        },

        hide() {
            if (this.$route.name === 'globalsearch') {
                // this.$router.go(-1);
                this.$router.replace(this.$store.state.back_urls.global_search);
                this.$store.state.back_urls.global_search = '';
            } else {
                this.show_bg = false;
                this.show_box = false;

                setTimeout(() => {
                    this.is_show_search_bar = false;
                }, 300);
            }
        },

        show() {
            this.is_show_search_bar = true;

            setTimeout(() => {
                this.show_bg = true;
            }, 1);

            setTimeout(() => {
                this.show_box = true;
                if (this.$refs.searchtext)
                    this.$refs.searchtext.focus();
            }, 100);
        }
    },

    mounted() {
        EventBus.$on('toggle-search-bar', obj => {
            if (this.is_show) return;

            if (obj.is_show) {
                this.show();
            } else {
                this.hide();
            }
        });

        if (this.is_show) this.is_show_search_bar = true;
        if (this.$route.query.query) {
            this.search_value = this.$route.query.query;
        }else if(this.$store.state.global_search){
            this.search_value = this.$store.state.global_search;
        }
        
        if (this.$route.name === 'globalsearch') this.is_show_search_bar = true;
    }
};

</script>

<style lang="scss">
@import '../resources/sass/_variables.scss';

.global-search-bar {
    width: 100%;
    position: fixed;
    z-index: 199999;
    top: 0;
    height: 100%;
    background-color: rgba(var(--Black-100-rgba), 0);
    transition: background-color .2s ease;

    .box {
        display: flex;
        flex-direction: column;
        padding: 8px;
        background-color: var(--Text-brand);
        z-index: 3;
        position: absolute;
        width: 100%;
        transform: translate(0, -100%);
        transition: transform .2s ease-in-out;

        .inputbox {
            display: flex;
            width: 100%;
            overflow: hidden;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;

                &.close-ico {
                    box-sizing: content-box;
                    justify-content: flex-start;
                    padding-right: 16px;
                    padding-left: 0;

                    svg {
                        fill: var(--Black-00);
                        width: 24px;
                        width: 24px;
                        cursor: pointer;
                    }
                }

                &.search-cancel {
                    cursor: pointer;
                    text-align: right;
                    color: var(--Text-invert);
                    font: var(--text-s-regular);
                    padding-left: 12px;
                    padding-right: 8px;
                    text-transform: uppercase;
                }
            }

            .m-search {
                max-width: initial;
            }

        }
    }

    .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }


    &.show-bg {
        background-color: rgba(var(--Black-100-rgba), .6);
    }

    &.show-box .box {
        transform: translate(0, 0%);
    }

    @at-root {

        @media (min-width: $mobile_size) {
            & {
                z-index: 10018;

                .box .inputbox {
                    max-width: var(--max-search-input);
                    margin: 0 auto;
                }
            }
        }
    }
}
</style>
