<template>
    <div :class="classnames" :id="`m-date-picker-${item_id}`">
        <input type="date" @change="changeInput" @focus="handleFocus" @blur="validation" :name="'data-input-' + item_id" :id="'data-input-' + item_id"
            v-model="value" :disabled="is_disabled" :min="range_time.start_min" :max="range_time.start_max" ref="myInput">
        <label class="span-date" :for="'data-input-' + item_id" @click="focusInput">{{ moment(value).tz(moment.tz.guess()).format(date_format) }}</label>
        <svg-icon name="calendar" />

    </div>
</template>

<script>
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'm-date-picker',

    props: {
        input_value: {
            type: [String, Date],
            default: new Date(),
        },

        is_disabled: {
            type: Boolean,
            default: false
        },

        range: {
            type: Object,
            default: function () {
                return {
                    start_min:'2019-01-01',
                    start_max:  null
                }
            },
        },

        date_format:{
            type:String,
            default: 'DD/MM/YYYY'
        }
    },

    data() {
        return {
            value: new Date(this.input_value),
            item_id: uuidv4(),
            timezone: moment.tz.guess(),
            range_time:{
                start_min:'2019-01-01',
                start_max:  null
            }
        }
    },

    watch: {
    },

    computed: {
        classnames() {
            let arr = ['m-date-picker'];
            return arr;
        }
    },

    methods: {
        focusInput(){
            const myInput = this.$refs.myInput;
            myInput.focus();
            myInput.showPicker();
        },

        changeInput() {
            this.$emit('changeInput', this.value);
        },

        handleFocus() {
            this.$emit('handleFocus', true);
        },

        validation() {
            this.$emit('handleFocus', false);
        },

        start() {
            this.range_time={
                start_min:moment(new Date(this.range.start_min)).tz(this.timezone).format(this.date_format),
                start_max: moment(new Date(this.range.start_max)).tz(this.timezone).format(this.date_format)
            }
        },

    },

    mounted() {
        this.start();
    },


}
</script>

<style lang="scss" scoped>
.m-date-picker {
    position: relative;
    cursor: pointer;
    z-index: 1;

    input {
        position: relative;
        padding: 0 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-radius: var(--radius-minimal);
        font: var(--text-md-light);
        width: 100%;
        color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        z-index: 1;
    }

    label {
        position: absolute;
        left: 3px;
        cursor: pointer;
        top: 50%;
        transform: translate(0, -50%);
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        box-sizing: border-box;
        width: calc(100% - 5px);
        background-color: var(--Bg-light);
        z-index: 3;
    }


    $cad_w: 16px;

    .svg-icon--calendar {
        position: absolute;
        right: 7px;
        top: 12px;
        width: $cad_w;
        height: $cad_w;
        min-width: $cad_w;
        min-height: $cad_w;
        fill: var(--Text-default);
        pointer-events: none;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

}
</style>
