<template>
    <div class="create-post" ref="share">
        <div class="create-post--wrapper">
            <div class="create-post--container">
                <header class="create-post--header">
                    <h2 class="create-post--title">CREATE SHOPPABLE CONTENT</h2>
                    <div class="create-post--close" @click="close(true)">
                        <svg-icon name="close" />
                    </div>
                </header>

                <div class="create-post--body">
                    <h5 class="required">Select from social</h5>
                    <div class="box">
                        <div @click="gotoCreatePreviewPage('tiktok')" class="box-sub">
                            <svg-icon name="tiktok" />
                            <p>TikTok</p>
                        </div>
                        <div @click="gotoCreatePreviewPage('instagram')" class="box-sub">
                            <svg-icon name="instagram" />
                            <p>Instagram</p>
                        </div>
                        <div @click="gotoCreatePreviewPage('video')" class="box-sub">
                            <svg-icon name="youtube" />
                            <p>YouTube</p>
                        </div>
                    </div>
                    <h5>Create original post </h5>
                    <div class="box">
                        <div @click="$refs.fileupload.click()" class="box-sub">
                            <input class="input" type="file" @change="uploadImage($event)" accept="image/x-png, image/jpeg, .png, .jpeg" ref="fileupload" style="display: none">
                            <svg-icon name="image-2" />
                            <p>Image</p>
                        </div>
                        <div @click="gotoCreatePreviewPage('thisorthat')" class="box-sub">
                            <svg-icon name="this-or-that" />
                            <p>ThisOrThat</p>
                        </div>
                    </div>
                    <div v-if="is_have_drafts" @click="gotoCreatePreviewPage('draft')" class="box-2">
                        <svg-icon name="edit" />
                        <p>Edit drafts</p>
                    </div>
                </div>

                <footer class="create-post--footer" @click="close(true)">
                    <m-button size="40" type="secondary">Cancel</m-button>
                </footer>
            </div>
            <div class="create-post--bg" @click="close(false)"></div>
        </div>
    </div>
</template>

<script>
import compressImageFile from '@/utils/compressImageFile';

export default {
    name: 'CreatePostPopUp',

    data() {
        return {
            is_have_drafts: false
        }
    },

    methods: {

        open() {
            this.$refs.share.classList.add('open');
            this.checkDraft();

            setTimeout(() => {
                this.$refs.share.classList.add('open-bg');
            }, 10);

            setTimeout(() => {
                this.$refs.share.classList.add('open-container');
            }, 150);

            this.$refs.fileupload.value = null;
        },

        close(is_click_button_close) {
            const self = this;

            if (self.$refs.share) self.$refs.share.classList.remove('open-container');

            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open-bg');
            }, 200);

            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open');
                self.$emit('closeCallback');

                if (is_click_button_close) {
                    self.$emit('callback');
                }
            }, 300);
        },

        gotoCreatePreviewPage(type) {
            if (this.$store.state.is_logged) {
                if (type === 'tiktok' && this.$store.state.is_tiktok_banned) {
                    this.$store.state.is_show_tiktok_down_popup = true;
                    return;
                }

                this.$router.push({ path: '/create-post/' + type });
                this.$cookie.delete('is_clicked_by_me');
                this.close();
            }
        },

        async uploadImage($event) {
            if ($event.target.files.length !== 0) {
                const compressed_image = await compressImageFile($event.target.files[0], 1);
                window.localStorage.setItem('image-post-file', window.URL.createObjectURL(compressed_image));
                this.gotoCreatePreviewPage('image');
            }
        },

        openLikeboardInfo() {
            this.$emit('openLikeboardInfo');
        },

        checkDraft() {
            this.$ajax('/draft/status?t=' + new Date().getTime(), {
                method: 'get',
                withCredentials: true
            }).then(res => {
                this.is_have_drafts = (res.data.data.count + res.data.data.expire_in_1_day_count) > 0;
            });
        }
    },

    // mounted() {
    //     let api = '/draft/status';
    //     // if (this.$route.name === 'private-profile-social') api = api + '?resource_from_type=1';
    //     this.$ajax('/draft/status', {
    //         method: 'get',
    //         withCredentials: true
    //     }).then(res => {
    //         this.draft_status = res.data.data;
    //     });
    // },
};
</script>

<style lang="scss">
    @import '../resources/sass/_variables.scss';

    .create-post {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index:9999999;
        display: none;

        &--wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &--container {
            position: absolute;
            z-index: 9;
            overflow: hidden;
            left: 50%;
            width: 100%;
            max-width: var(--modal-max-width);
            box-shadow: var(--pop-medium);
            bottom: 0;
            transition: transform .3s ease;
            transform: translate3d(-50%, 100%, 0);
            background: var(--Bg-light);
            border-radius:  16px 16px 0 0;
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(var(--Black-100-rgba), .8);
            opacity: 0;
            transition: opacity .2s ease;
        }

        &--header {
            padding: 0 16px;
            min-height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--title {
            font: var(--sub-3);
            position: relative;
            color: var(--Text-primary);
            overflow: hidden;
            text-transform: uppercase;
        }

        &--close {
            height: 32px;
            width: 32px;
            color: var(--Text-invert);
            cursor: pointer;
            position: relative;
            right: -.8rem;

            svg {
                height: 32px;
                width: 32px;
                display: block;
                fill: var(--Text-default);
            }

            &:hover svg {
                fill: var(--Brand-Primary);
            }
        }

        &--body {
            color: var(--Text-primary);
            background: var(--Bg-01);
            max-height: 70vh;
            overflow: auto;
            padding: var(--spacing-05);

            h5 {
                margin-bottom: 8px;
                font: var(--sub-5);
                color: var(--Text-secondary);
            }

            .box {
                display: flex;
                margin: 0 -2px 16px;

                .box-sub {
                    margin: 0 2px;
                    background: var(--Bg-light);
                    border-radius: var(--radius-minimal);
                    width: 33.33%;
                    height: 114px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: .2s all ease;

                    svg {
                        width: 44px;
                        height: 44px;
                        color: var(--Text-primary);
                        transition: .2s all ease;
                    }

                    p {
                        font: var(--text-md-light);
                        color: var(--Text-primary);
                        margin-top: 8px;
                        transition: .2s all ease;
                    }

                    &:hover {
                        background: var(--Neu-20);

                        p,
                        svg {
                            color: var(--Text-brand);
                        }
                    }
                }
            }

            .box-2 {
                margin-bottom: 8px;
                padding: var(--spacing-04);
                background: var(--Bg-light);
                border-radius: var(--radius-medium);
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: .2s all ease;

                &:hover {
                    background: var(--Neu-20);

                    p,
                    svg {
                        color: var(--Text-brand);
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                & > svg {
                    width: 22px;
                    height: 22px;
                    color: var(--Text-primary);
                    margin-right: 10px;
                    transition: .2s all ease;
                }

                p {
                    font: var(--heading-6);
                    color: var(--Text-primary);
                    transition: .2s all ease;
                }
            }
        }

        &--footer {
            display: block;
            padding: var(--spacing-05);
            background-color: var(--Bg-01);

            .m-button {
                display: flex;
            }
        }

        &.open {
            display: block;
        }

        &.open-container &--container {
            transform: translate3d(-50%, 0%, 0);
        }

        &.open-bg &--bg {
            opacity: 1;
        }
    }

    @media (min-width: $mobile_size) {
        .create-post {

            &--container {
                opacity: 0;
                transition: opacity .2s ease;
                transform: translate3d(-50%, -50%, 0);
                border-radius:  16px;
                right: auto !important;
                bottom: auto !important;
                left: 50%;
                top: 50%;
            }

            &--header {
                display: flex;
                align-items: center;
            }

            &.open-container &--container {
                transform: translate3d(-50%, -50%, 0);
            }

            &.open-bg &--container,
            &.open-bg &--bg {
                opacity: 1;
            }
        }
    }
</style>