<template>
    <div>
        <div class="repost" ref="share">
            <div class="repost--wrapper">
                <div class="repost--container">
                    <div class="repost--body">
                        <div class="box" @click="edit">Edit repost</div>
                        <div class="box" @click="show_delete_modal = true">Undo repost</div>
                    </div>

                    <footer class="repost--footer">
                        <m-button @click.native="close(false)" size="40" type="base">Close</m-button>
                    </footer>
                </div>
                <div class="repost--bg" @click="close(false)"></div>
            </div>
            <Modal v-show="show_delete_modal" :classname="['new-delete-modal']">
                <h2>Are you sure you want to delete this post?</h2>
                <p> Your content and tags will be removed</p>
                <div class="btns">
                    <span @click="show_delete_modal = false">Cancel</span>
                    <span @click="deletePost">Yes</span>
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';
import Modal from '@/components/Modal.vue';

export default {
    name: 'RepostPopUp',

    components: {
        Modal,
    },

    data() {
        return {
            post: Object,
            show_delete_modal: false,
        };
    },

    props: {
    },
    
    watch: {
    },

    methods: {

        open(obj) {
            this.post = obj;
            this.$refs.share.classList.add('open');

            setTimeout(() => {
                this.$refs.share.classList.add('open-bg');
            }, 10);

            setTimeout(() => {
                this.$refs.share.classList.add('open-container');
            }, 150);
        },

        close() {
            const self = this;

            if (self.$refs.share) self.$refs.share.classList.remove('open-container');

            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open-bg');
            }, 200);

            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open');
                self.$emit('closeCallback');
            }, 300);
        },

        edit() {
            console.log(this.post);
            const obj = {};
            this.post.post_id = this.post.last_repost_id;
            this.post.post_caption = this.post.last_post_caption;
            this.post.post_author_id = Number(this.$store.state.user.id);
            obj.post = this.post;
            obj.is_edit_post = true;
            EventBus.$emit('edit-post', obj);
            this.close();
        },

        deletePost() {
            this.$store.state.show_loading = true;
            this.$ajax(`/user/post/${this.post.last_repost_id}/delete`, {
                method: 'post',
                withCredentials: true
            }).then(res => {
                this.$store.state.show_loading = false;

                if (res.data.status === 'success') {
                    if (this.post.post_author_id.toString() === this.$store.state.user.id.toString()) {
                        const el = document.querySelector('#js-post--' + this.post.post_id);
                        if (el !== null) el.style.display = 'none';
                    }
                    // this.$redrawVueMasonry('user_profile_social');
                    this.post.is_reposted = false;
                    this.show_delete_modal = false;
                    this.$store.state.show_loading = false;
                    this.close();
                    // Removed all cache
                    this.$ajax.cache.store = {};
                }
            })
                .catch(this.err);
        },
    },

    mounted () {

    },
};
</script>

<style lang="scss">
    @import '../resources/sass/_variables.scss';

    .repost {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index:9999999;
        display: none;

        &--wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &--container {
            position: absolute;
            z-index: 9;
            overflow: hidden;
            left: 50%;
            width: 100%;
            max-width: var(--modal-max-width);
            box-shadow: var(--pop-medium);
            bottom: 0;
            transition: transform .3s ease;
            transform: translate3d(-50%, 100%, 0);
            background: var(--Bg-light);
            border-radius:  16px 16px 0 0;
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(var(--Black-100-rgba), .8);
            opacity: 0;
            transition: opacity .2s ease;
        }

        &--body {
            color: var(--Text-primary);
            max-height: 70vh;
            overflow: auto;
            padding: 16px 24px 8px 24px;
            background-color: var(--Bg-01);

            .box {
                height: 40px;
                color: var(--Text-primary);
                cursor: pointer;
                padding: 0 8px;
                border-radius: var(--radius-minimal);
                background-color: var(--Surface-light);
                color: var(--Text-primary);
                font: var(--text-md-regular);
                margin: 8px 0;
                transition: .2s all ease;
                display: flex;
                align-items: center;

                &:hover {
                    background-color: var(--Neu-10);
                }

            }
        }

        &--footer {
            padding: 16px 24px;

            .m-button {
                display: flex;
            }
        }

        &.open {
            display: block;
        }

        &.open-container &--container {
            transform: translate3d(-50%, 0%, 0);
        }

        &.open-bg &--bg {
            opacity: 1;
        }
    }

    @media (min-width: $mobile_size) {
        .repost {

            &--container {
                opacity: 0;
                transition: opacity .2s ease;
                transform: translate3d(-50%, -50%, 0);
                border-radius:  16px;
                right: auto !important;
                bottom: auto !important;
                left: 50%;
                top: 50%;
            }

            &.open-container &--container {
                transform: translate3d(-50%, -50%, 0);
            }

            &.open-bg &--container,
            &.open-bg &--bg {
                opacity: 1;
            }
        }
    }
</style>
<style lang="scss" scoped>
    
</style>