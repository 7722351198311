const Instagram = {
    key: 'instagram',
    text: 'Instagram',
    icon: 'instagram',
    show: false,
};

const TikTok = {
    key: 'tikTok',
    text: 'TikTok',
    icon: 'tiktok',
    show: false,
};

const TikTokWhite = {
    key: 'tikTokWhite',
    text: 'TikTok',
    icon: 'tiktok-circle',
    show: false,
};

const IgWhite={
    key: 'IgWhite',
    text: 'Instagram',
    icon: 'instagram-circle',
    show: false,
    
}

const Youtube = {
    key: 'youtube',
    text: 'YouTube',
    icon: 'youtube',
    show: false,
};

const Image = {
    key: 'image',
    text: 'Image',
    icon: 'image',
    show: false,
};

const ThisOrThat = {
    key: 'thisorthat',
    text: 'ThisOrThat',
    icon: 'this-or-that',
    show: false,
};

const LikeBoard = {
    key: 'likeboard',
    text: 'Collections',
    icon: 'likeboard',
    show: false,
};

const PrimaryLogo = {
    key: 'primarylogo',
    text: 'Motom',
    icon: 'hero-logo-blue',
    show: false,
};

const PrimaryLogoWhite = {
    key: 'primaryLogoWhite',
    text: 'Motom',
    icon: 'hero-logo-white',
    show: false,
};

const Snapchat={
    key: 'snapchat',
    text: 'Snapchat',
    icon: 'snapchat-no-circle',
    show: false,
}

const Pinterest={
    key: 'pinterest',
    text: 'Pinterest',
    icon: 'pinterest-no-circle',
    show: false,
}

const Facebook={
    key: 'facebook',
    text: 'Facebook',
    icon: 'facebook',
    show: false,
}

const Twitter={
    key: 'twitter',
    text: 'Twitter',
    icon: 'twitter',
    show: false,
}

const Apple={
    key: 'apple',
    text: 'Apple',
    icon: 'apple',
    show: false,
}

const Google={
    key: 'google',
    text: 'Google',
    icon: 'google-g-no-circle',
    show: false,
}



const Orgin_social_list = [
    Instagram,
    Youtube,
    TikTok,
    Image,
    ThisOrThat,
    LikeBoard,
];

const Link_social_list = [
    Instagram,
    Facebook,
    Youtube,
    Twitter,
    TikTok,
    Snapchat,
    Pinterest
];

const cus_list=[
    {
      key: "website",
      text: "Website",
      link: "",
      icon: "website-only",
      selected: false,
    },
    {
      key: "blog",
      text: "Blog",
      link: "",
      icon: "blog-only",
      selected: false,
    },
    {
      key: "shop",
      text: "Shop",
      link: "",
      icon: "shop-only",
      selected: false,
    },
    {
      key: "other",
      text: "Other",
      link: "",
      icon: "other-only",
      selected: false,
    },
];

const shareList = [
    {
        key: "link",
        text: "Copy URL",
        icon: "copy2",
    },
    {
        key: "email",
        text: "Email",
        icon: "email",
    },
    {
        key: "twitter",
        text: "Twitter",
        icon: "twitter",
    },
    {
        key: "facebook",
        text: "Facebook",
        icon: "facebook",
    },
    {
        key: "pinterest",
        text: "Pinterest",
        icon: "pinterest-no-circle",
    },
]

const Hashtags=[
    TikTok,
    Instagram,
    PrimaryLogo
]

const HashtagsColor=[
    TikTokWhite,
    IgWhite,
    PrimaryLogoWhite
]

const HashtagsTypeList=[
    { name: 'tt', desc: 'App\\TikTokVideo', title:'TikTok' },
    { name: 'ig', desc: 'App\\InstagramMedia', title:'Instagram' },
    { name: 'mm', desc: 'App\\UserPost', title:'Motom' }
]

const MakeHashtags=(arr)=>{
    return arr.map(e => {
        let icon = '';
        if (e.resource_type === 'App\\TikTokVideo') {
            icon = Hashtags[0].icon;
        } else if (e.resource_type === 'App\\InstagramMedia') {
            icon = Hashtags[1].icon;
        } else if (e.resource_type === 'App\\UserPost') {
            icon = Hashtags[2].icon;
        }
        return {
            ...e,
            left_icon: icon,
            name: `#${e.text}`
        }
    })
}

export { 
    Instagram, TikTok, Youtube, Image, ThisOrThat, LikeBoard, 
    Twitter, Facebook , Pinterest , Snapchat,
    Orgin_social_list, Link_social_list,
    PrimaryLogo, 
    TikTokWhite, PrimaryLogoWhite,IgWhite,
    Apple,Google,
    cus_list,shareList,
    Hashtags,HashtagsColor,
    HashtagsTypeList,MakeHashtags
 };


