<template>
    <router-link v-if="to" :class="classnames" :to="to">
        <figure><img :src="avatar_image" @error="imageError($event)" alt="avatar" /></figure>
        <i v-if="is_influencer"><svg-icon name="checked-circle" /></i>
    </router-link>
    <span :class="classnames" v-else>
        <figure><img :class="[type===3,'radius']" :src="avatar_image" @error="imageError($event)" alt="avatar" /></figure>
        <i v-if="is_influencer"><svg-icon name="checked-circle" /></i>
    </span>
</template>

<script>
export default {
    name: 'ui-avatar',

    data() {
        return {
            avatar_image: '',
            classnames: ['ui-avatar'],
            default_CMSUser_image: require('@/assets/images/Motom-Creator.png'),
            default_user_image: require('@/assets/images/default-user.png'),
            default_brand_image: require('@/assets/images/default-brand.png'),
            default_motom_image: require('@/assets/images/MOTOM_512x512.png'),
        };
    },

    props: {
        type: {
            type: [Number, String],
            default: 0
        },
        image: {
            type: String,
            default: ''
        },
        to: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'medium'
        },
        is_influencer: {
            type: Boolean,
            default: false
        },
        is_brand: {
            type: Boolean,
            detault: false
        },
    },
    methods: {
        imageError(event) {
            if (event.target.src.indexOf('.webp') > -1) {
                event.target.src = event.target.src.replace('.webp', '.jpg');
                event.target.onerror = () => {
                    if (!this.is_brand) {
                        if(this.type === 3){
                            event.target.src = this.default_motom_image;
                        }else{
                            event.target.src = this.default_user_image;
                        }
                        
                    } else {
                        event.target.src = this.default_brand_image;
                    }
                };
            } else {
                if (!this.is_brand) {
                    if(this.type===3){
                        event.target.src = this.default_motom_image;
                    }else{
                        event.target.src = this.default_user_image;
                    }
                } else {
                    event.target.src = this.default_brand_image;
                }
            }
        },
        isDataUriImage(str) {
            const regex = /^data:image\/([a-zA-Z]*);base64,([^\"]*)$/;
            return regex.test(str);
        }
    },
    mounted() {
        if (this.type === 2) {
            this.avatar_image = this.default_CMSUser_image;
        } else if (!this.image) {
            if (this.is_brand) {
                this.avatar_image = this.default_brand_image;
            } else {
                if(this.type===3){
                    this.avatar_image = this.default_motom_image;
                }else{
                    this.avatar_image = this.default_user_image;
                }
            }
        } else {
            if (this.isDataUriImage(this.image)) {
                this.avatar_image = this.image;
            } else {
                this.avatar_image = this.Get_Image(this.image, '/avatars/200x200/');
            }
        }

        this.classnames.push('size-' + this.size);
        if (this.is_brand) this.classnames.push('is-brand');
    }
};
</script>

<style lang="scss">
@import '../resources/sass/_variables.scss';

.ui-avatar {
    display: inline-flex;
    position: relative;

    figure {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 50%;
        background-color: var(--Bg-02);

    }

    i {
        height: 12px;
        width: 12px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--Bg-light);

        svg {
            height: 100%;
            width: 100%;
            min-width: 100%;
            display: block;
            fill: var(--Text-warning);
            margin: 0 !important;
        }
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;

        &.radius{
            border-radius: 50%;
            overflow: hidden;
        }
    }

    &.is-brand {
        
        figure {
            background-color: var(--Bg-light);
            // border: 2px solid var(--Bg-02);
        }

        img {
            object-fit: contain;
        }
    }

    &.size {
        &-extra-small {
            width: 24px;
            height: 24px;
            min-width: 24px;
            max-width: 24px;

            i {
                height: 10px;
                width: 10px;
            }
        }

        &-small {
            width: 32px;
            height: 32px;
            min-width: 32px;
            max-width: 32px;

            i {
                height: 12px;
                width: 12px;
            }
        }

        &-medium {
            width: 40px;
            height: 40px;
            min-width: 40px;
        }

        &-48 {
            width: 48px;
            height: 48px;
            min-width: 48px;

            i {
                height: 14px;
                width: 14px;
            }
        }

        &-large {
            width: 60px;
            height: 60px;
            min-width: 60px;

            i {
                height: 18px;
                width: 18px;
            }
        }

        &-82 {
            width: 82px;
            height: 82px;
            min-width: 82px;

            i {
                height: 18px;
                width: 18px;
            }
        }

        &-big {
            width: 86px;
            height: 86px;

            i {
                height: 20px;
                width: 20px;
            }
        }

        &-maximum {
            width: 120px;
            height: 120px;

            i {
                height: 22px;
                width: 22px;
            }
        }

        &-maximum {
            width: 128px;
            height: 128px;

            i {
                height: 22px;
                width: 22px;
            }
        }
    }
}
</style>
