/* eslint-disable */
import getQueryVariable from '@/utils/GetQueryVariable';

export default function (text = '', object = null) {
    let debug = getQueryVariable('debug');

    if (debug === '0') {
        window.localStorage.removeItem('debug');
    }

    else if (debug === '1' || window.localStorage.getItem('debug') === '1') {
        window.localStorage.setItem('debug', '1');

        if (typeof text === 'string') {
            console.log('%c' + text + ' ', 'background: #222; color: #bada55')
        }

        else if (object !== null) {
            console.log('%c' + text + ' ', 'background: #222; color: #bada55', object);
        } else {
            console.log('%c debug ', 'background: #222; color: #bada55', text)
        }
    }
    

}
  