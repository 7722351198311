<template>
    <div v-if="product">
        <MoreMenu 
            :title="'What would you like to do?'" 
            :type="'more-menu'" 
            :show_likeboards="show_likeboards" 
            :confirm_actived="add_ids.length !== 0 || remove_ids.length !== 0" 
            ref="edit_menu"
            @confirmLikeboard="confirmLikeboard('select');" 
            @backMainPage="show_likeboards=false;"  
            @createLikeboard="createLikeboard"
        >
            <!-- same template and css anywhere.... watch EditMenu && AddToLikeboards -->
            <div class="add-to-likeboards--list" v-if="show_likeboards">
                <div class="box" v-if="likeboards.length > 0" >
                    <!-- is_default -->
                    <!-- <div class="is-default" v-if="likeboards[0] && likeboards[0].is_default == 1">
                        <Item type="likeboard" 
                            @click.native="select(likeboards[0], likeboards[0].is_liked_post)">
                            <Layout :item="likeboards[0]" />
                            <div class="add-to-info">
                                <h3>All Liked</h3>
                                <p v-if="likeboards[0].is_public">Default shopping list</p>
                                <p v-if="!likeboards[0].is_public"><svg-icon name="private3" />Default shopping
                                    list</p>
                            </div>
                            <Checkbox :is_checked="(likeboards[0].is_liked_post && !remove_ids.includes(likeboards[0].id)) || (!likeboards[0].is_liked_post && add_ids.includes(likeboards[0].id))" :type="'radio'" :key="refrash_key"/>
                        </Item>
                    </div> -->
                    <div v-if="likeboards.length <= 1" class="create-likeboard-item" @click="$refs.edit_menu.openLikeboardInfo(0);">
                        <h2>Create your first collection</h2>
                        <m-button>
                            <span>Get started</span>
                        </m-button>
                    </div>
                    <div class="like-list" v-else>
                        <Item type="likeboard" v-for="item in likeboards" :key="item.id"
                            @click.native="select(item, item.is_liked_post)" v-show="item.is_default !== 1">
                            <Layout :item="item" />
                            <div class="add-to-info">
                                <h3>{{ item.name }}</h3>
                                <!-- <svg-icon name="view" />private2  -->
                                <p v-if="item.is_public">Public</p>
                                <p v-if="!item.is_public"><svg-icon name="private3" />Private</p>
                                <!-- <p class="number">{{ item.likes_count ? item.likes_count : 0 }} Likes</p> -->
                            </div>
                            <Checkbox
                                :is_checked="(item.is_liked_post && !remove_ids.includes(item.id)) || (!item.is_liked_post && add_ids.includes(item.id))"
                                :type="'radio'" />
                        </Item>
                    </div>
                </div>

            </div>
            <div style="padding: 8px 16px" v-else>
                <Item v-if="$store.state.is_logged && $route.name !== 'private-profile-links'" @click.native="newLike();">{{ (product.is_liked) ? 'Remove ‘like’' : 'Like'}}</Item>
                <Item v-if="$store.state.is_logged" @click.native="openPopupStorefront();">{{ ((product.is_storefront) || ($route.name === 'private-profile-storefront')) ? 'Remove from your Shop' : 'Add to your Shop'}}</Item>
                <Item v-if="$store.state.is_logged" @click.native="showCusLikeboard();">Add to / Remove from Collection</Item>
                <Item v-if="show_seen_in_link" @click.native="openMerchant('all');">View associated posts</Item>
                <Item @click.native="openMerchant('share');">
                    Share
                </Item>
                <Item @click.native="sendEmail('Report a tagged product'); closeMoreMenu();">Report</Item>
            </div>
        </MoreMenu>

        <Modal v-show="show_dislike_alert" @close="show_dislike_alert = false" type="confirm" algin="center">
            <i class="ico question"><svg-icon name="question" /></i>
            <h2>Unlike this product?</h2>
            <p>This will remove the item from your 'Liked' section and any associated Collections.</p>
            <footer>
                <m-button @click.native="show_dislike_alert = false;" type="tertiary">Nevermind</m-button>
                <m-button @click.native="confirmLikeboard('all');show_dislike_alert = false;">Yes</m-button>
            </footer>
                <!-- <ul>
                    <li ><m-button @click.native="show_dislike_alert = false;">Nevermind</m-button></li>  
                    <li ><m-button @click.native="confirmLikeboard('all');show_dislike_alert = false;">Yes</m-button></li>
                </ul> -->
        </Modal>

        <Modal class="sure-remove-store-pop" v-show="is_show_popup_storefront"  @close="is_show_popup_storefront = false" type="confirm" algin="center">
            <h2>Are you sure you want to remove product from your Storefront?</h2>
            <footer>
                <m-button @click.native="is_show_popup_storefront = false" type="tertiary">Cancel</m-button>
                <m-button @click.native="store()">Yes</m-button>
            </footer>
        </Modal>
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';
import Pool from '@/utils/Pool';
import Modal from '@/components/Modal.vue';
import MoreMenu from '@/components/MoreMenu.vue';
import Layout from '@/components/likeboards/ImagesLayout.vue';
import handleProductData from "@/utils/HandleProductData.js";
import Item from '@/components/ui/NewUi/ListItem.vue';
import Checkbox from '@/components/ui/NewUi/CheckOrRadio.vue';

export default {
    name: 'ProductItemPopup',
    props: {
        my_likeboard_key: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            url: '',
            data: false,
            product: false,
            show_dislike_alert: false,
            tooltip_text: '',
            tooltip_bg_color: '',
            is_private: false,
            shorten_link:'',
            selected_merchant_id: 0,
            show_likeboards: false,
            likeboards: [],
            add_ids: [],
            remove_ids: [],
            likeboard_id: 0,
            skimlinks_tracking: '',
            btn_type: '',
            is_show_share_button: false,
            show_seen_in_link: false,
            post_ref: '',
            is_show_popup_storefront:false,
            refrash_key:0,
        }
    },

    watch: {
        '$route' (to, from) {
            this.product = false;
            if (to.name) this.is_private = to.name.startsWith('private');
        }
    },

    components: {
        Pool,
        Modal,
        MoreMenu,
        Layout,
        Item,
        Checkbox,
    },

    mounted() {

        EventBus.$off('profile_product_popup');
        EventBus.$on('profile_product_popup', (obj) => {
            
            if (obj.post_ref) {
                this.post_ref = obj.post_ref;
            } else {
                this.post_ref = '';
            }

            if (obj.data.productInfo.merchant_prices) {
                this.popup(obj);
            } else {
                let ref = '';
                if (obj.post_ref && obj.post_ref.indexOf('B0000') !== -1) {
                    ref = '?post_ref=' + obj.post_ref;
                }

                this.$store.state.show_loading = true;
                this.$ajax.get('/product/' + obj.data.productInfo.id + ref).then(res => {
                    // console.log(obj.data.productInfo)
                    obj.data.productInfo = handleProductData(res.data.data);
                    this.popup(obj);
                    this.$store.state.show_loading = false;
                });
            }
        });
    },

    methods: {
        openMerchant(type = 'all') {
            const rname = this.$route.name;
            const query = {};
            query.merchant = true;
            if (rname.includes('likeboard')) query.lb = true;

            if (this.post_ref) {
                query.post_ref = this.post_ref;
            } else if (query.lb && this.my_likeboard_key) {
                query.post_ref = this.my_likeboard_key;
            }

            if (type === 'share') query.hide_content = true;

            query.product = this.data.productInfo;
            query.view_type = type;
            query.type = type;


            this.closeMoreMenu();
            EventBus.$emit('open-merchant-list', query);
        },

        popup(obj) {
            this.data = obj.data;
            this.product = obj.data.productInfo;
            this.btn_type = obj.btn_type;
            // this.pin_length = obj.pin_length;

            this.show_seen_in_link = (
                (obj.data.productInfo.imageposts_count && obj.data.productInfo.imageposts_count !== 0) ||
                (obj.data.productInfo.instagramposts_count && obj.data.productInfo.instagramposts_count !== 0) ||
                (obj.data.productInfo.likeboards_count && obj.data.productInfo.likeboards_count !== 0) ||
                (obj.data.productInfo.tiktok_posts_count && obj.data.productInfo.tiktok_posts_count !== 0) ||
                (obj.data.productInfo.thisorthatposts_count && obj.data.productInfo.thisorthatposts_count !== 0) ||
                (obj.data.productInfo.video_posts_count && obj.data.productInfo.video_posts_count !== 0) 
            );

            setTimeout(() => {
                console.log(obj.type);

                switch (obj.type) {
                    case 'share_merchant':
                        // this.$refs.merchant.open('share', '');
                        break;
                    // case 'open_merchant':
                    //     this.$refs.merchant.open();
                    //     break;
                    case 'open_more_menu':
                        this.$nextTick(()=>{
                            this.$refs.edit_menu.open();
                        });
                        break;
                    case 'show_tooltip':
                        this.tooltip_text = obj.tooltip_text;
                        this.tooltip_bg_color = obj.tooltip_bg_color;

                        this.$notify({
                            text: this.tooltip_text,
                            type: this.tooltip_bg_color,
                            duration: 5000,
                        });
                        break;
                }
            }, 1);
        },
        
        showCusLikeboard() {
            this.show_likeboards = true;
            this.getLikeboard();
        },

        getLikeboard() {
            this.$store.state.show_loading = true;
            this.add_ids = [];
            this.remove_ids = [];
            this.likeboards = [];

            const lbtype = '3';
            const lbtargetID = this.product.id;
            const lbapi = '/api/likeboards?type=' + lbtype + '&target_id=' + lbtargetID;

            this.$ajax(lbapi, {
                method: 'get',
                withCredentials: true
            }).then(res => {
                res.data.data.forEach(list => {
                    if (list.is_default) this.default_likeboard_id = list.id;
                    const timestamp = new Date(list.updated_at.replace(' ','T'));
                    list.timestamp = timestamp.getTime();

                    list.is_liked_post = list.target_in_the_likeboard;
                });

                //const list = res.data.data;
                //list.sort((a, b) => b.timestamp - a.timestamp);
                this.likeboards = res.data.data;
                console.log('this.likeboards',this.likeboards)
                console.log('remove_ids',this.remove_ids)
                console.log('this.add_ids',this.add_ids)
                this.$store.state.show_loading = false;
            });
        },

        select(item, type) {
            if (type) {
                console.log('to remove');

                if (this.remove_ids.includes(item.id)) {
                    for (var i = 0; i < this.remove_ids.length; i++) {
                        if (this.remove_ids[i] === item.id) {
                            this.remove_ids.splice(i, 1);
                        }
                    }
                } else {
                    this.remove_ids.push(item.id);
                }
            } else {
                console.log('to add');

                if (this.add_ids.includes(item.id)) {
                    console.log('conatins');
                    for (let i = 0; i < this.add_ids.length; i++) {
                        if (this.add_ids[i] === item.id) {
                            this.add_ids.splice(i, 1);
                        }
                    }
                } else {
                    this.add_ids.push(item.id);
                }
            }

            this.refrash_key+=1;
            console.log('this.likeboards',this.likeboards)
                console.log('remove_ids',this.remove_ids)
                console.log('this.add_ids',this.add_ids)
        },

        confirmLikeboard(type, id = false) {
            if (type === 'select' && this.add_ids.length === 0 && this.remove_ids.length === 0) return;

            Pool(
                () => {
                    return (this.likeboards.length !== 0);
                },
                () => {
                    this.add(type);
                    this.remove(type, id);
                }
            );
            if (type === 'all') {
                this.tooltip_text = '<strong>Removed from Liked Collections</strong>';
                this.tooltip_bg_color = 'black';
            }
            else {
                this.tooltip_text = '<strong>Saved to Collection</strong>';
                this.tooltip_bg_color = 'primary';
            }

            window.setTimeout(() => {
                this.$notify({
                    text: this.tooltip_text,
                    type: this.tooltip_bg_color,
                    duration: 5000,
                });

                window.setTimeout(() => {
                    if (this.$route.name === 'private-profile-storefront') EventBus.$emit('reloadLikeProduct');
                }, 3000);
            }, 600);

            this.closeMoreMenu();
            this.show_likeboards = false;
        },

        createLikeboard(likeboard) {
            this.likeboards.splice(0, 0, likeboard);
            this.select(this.likeboards[0], this.likeboards[0].is_liked_post);
        },

        remove(type, id) {
            if (type === 'select' && this.remove_ids.length === 0) return;

            const form_data = new FormData();
            let is_unlike = 'LIKE';
            let api = '/api/likeboards/dislike-post';

            switch (type) {
            case 'all':
                is_unlike = 'UNLIKE';
                this.likeboards.forEach(likeboard => {
                    form_data.append('likebook_ids[]', likeboard.id);
                });
                break;
            case 'number':
                if (id === this.default_likeboard_id) is_unlike = 'UNLIKE';
                form_data.append('likebook_ids[]', id);
                break;
            case 'select':
                this.remove_ids.forEach(id => {
                    form_data.append('likebook_ids[]', id);
                });
                break;
            }

            let pid = this.product.id;
            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            
            label = label + ',,,' + pid;
            api = '/api/likeboards/dislike-product';
            
            form_data.append('target_id', pid);

            this.$ajax(api, {
                method: 'post',
                data: form_data,
                withCredentials: true
            }).then(res => {
                if (res.data.status === 'success') {
                    // Removed all cache
                    this.$ajax.cache.store = {};
                    this.GA_Tracking(is_unlike, 'CLICK', label);
                    if (this.$route.name === 'private-profile-liked' && type === 'all')
                        this.$emit('removeItem');
                    return;
                }
            }).catch(this.err);
        },

        add(type) {
            const self = this;

            if (self.add_ids.length === 0) return;
            // console.log('like', self.add_ids)

            const form_data = new FormData();
            let api = '/api/likeboards/like-post';

            self.add_ids.forEach(id => {
                form_data.append('likebook_ids[]', id);
            });

            form_data.append('target_user_id', self.$store.state.user.id);

            api = '/api/likeboards/like-product';
            let pid = this.product.id;
            form_data.append('target_id', pid);

            self.$ajax(api, {
                method: 'post',
                data: form_data,
                withCredentials: true
            }).then(res => {
                if (res.data.status === 'success') {
                    // Removed all cache
                    self.$ajax.cache.store = {};

                    let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
                    label = label + ',,,' + pid;
                    self.GA_Tracking('LIKE', 'CLICK', label);

                    self.fb_add_wishlist_function();

                    self.pinterest_leads_function();

                    if (this.$route.name === 'private-profile-storefront')
                        window.location.reload();
                    return;
                }
            }).catch(self.err);
        },

        newLike() {
            
            this.getLikeboard();
            
            if (this.product.storefront_only_from_likeboard && this.product.is_liked) {
                this.show_dislike_alert=true;
                this.closeMoreMenu();
                if (this.$route.name === 'private-profile-storefront')
                    EventBus.$emit('reloadLikeProduct');
            }
            else if (this.product.is_liked) {
                this.confirmLikeboard('all');
                this.closeMoreMenu();
                this.product.is_liked = !this.product.is_liked;
            }
            else {
                this.like();
                this.closeMoreMenu();
            }
        },

        like() {
            if (this.$store.state.is_logged) {
                let obj = {};
                obj.type = 'product';

                if (this.product) {
                    obj.post = this.product;
                } else {
                    obj.post = this.data;
                }

                obj.unique_key = this.product.id;
                obj.is_liked = this.$refs.like ? this.$refs.like.classList.contains('is-liked') : false;
                obj.likeboard_id = this.likeboard_id;

                this.product.is_liked = !obj.is_liked;

                EventBus.$emit('open-add-likeboards', obj);
            } else {
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Like & collect',
                    text: "You’ll need an account to add what you like to a Collections or simply log in. "
                });
            }
        },

        store() {
            this.is_show_popup_storefront = false;

            if (!this.$store.state.is_logged) {
                this.$store.commit('openLogin', {
                    is_show: true,
                });

                return;
            }


            this.$store.state.show_loading = true;
            let url = this.product.is_storefront ? '/api/store-front/remove' : '/api/store-front/create';
            let remove_from = (this.$route.name === 'private-profile-storefront') ? 0 : (this.$route.name === 'private-profile-tagged') ? 1 : 2;
            this.$ajax(url, {
                method: 'post',
                withCredentials: true,
                data: {
                    product_ids: [this.product.id],
                    id: this.product.id,
                    remove_from: remove_from
                }
            }).then(res => {
                if (this.$route.name === 'private-profile-storefront')
                    EventBus.$emit('removeStorefront', this.product.id);
                else {
                    this.product.is_storefront = !this.product.is_storefront;
                    this.tooltip_text = (this.product.is_storefront) ? `Added to your Shop！` : `Removed from your Shop！`;
                    this.tooltip_bg_color = (this.product.is_storefront) ? 'primary' : 'black';
                    this.$notify({
                        text: this.tooltip_text,
                        type: this.tooltip_bg_color,
                        duration: 5000,
                    });

                };
                // remove all cache
                this.$ajax.cache.store = {};
                this.$store.state.show_loading = false;
            })
            .catch(error => {
                // console.log(error)
                this.$store.state.show_loading = false;
            });

        },

        openPopupStorefront() {
            if (this.$route.name === 'private-profile-storefront' || this.product.is_storefront) {
                this.is_show_popup_storefront = true;
            } else {
                this.store();
            }

            this.closeMoreMenu();
            // this.is_show_popup_storefront=true;
        },

        // checkPin() {
        //     if (!this.product.is_pin && this.pin_length === 20) {
        //         this.show_pin_alert = true;
        //     } else {
        //         this.pin();
        //     }
                
        // },

        // pin() {
        //     let url = this.product.is_pin ? '/api/unpin' : '/api/pin';
        //     this.$ajax(url, {
        //         method: 'post',
        //         withCredentials: true,
        //         data: {
        //             item_id: this.product.id,
        //             item_type: 4
        //         }
        //     }).then(res => {
        //         this.$emit('actionPin', this.product);
        //     }).catch(error => {
        //         console.log(error)
        //     });
        // },

        closeMoreMenu() {
            this.$refs.edit_menu.close();
        },


        
        sendEmail(title) {
            // help@motom.me
            // console.log(this.post.post_id)
            const body1 = `The product id is ${this.product.id}`;
            const body2 = ``;
            window.location.assign('mailto:help@motom.me?Subject=' + encodeURIComponent(title) + '&body=' + encodeURIComponent(body1) + '%0D%0A' + body2);
        },
    }

}
</script>
<style lang="scss">
    .product-info-seen-in .swiper-container .swiper-wrapper {
        padding: 2px 6px 2px 6px !important;
    }

    .sure-remove-store-pop{
        z-index: 100002;
    }

    .profile-delete-modal {
        z-index: 10002;

        .modal--container {
            border-radius: 16px;
        }

        .box {
            padding: 30px 20px;
            text-align: center;
            width: 320px;
            margin: 0 auto;
        }

        h1 {
            font: var(--sub-4);
            color: var(--Text-primary);
        }

        p {
            margin: 10px 0;
            font-weight: var(--font-light);
            font-size: var(--font-s);
            color: var(--Text-default);
        }

        .ico {
            display: block;
            margin: 0 0 30px;

            svg {
                fill: var(--Yellow-30);
                height: 56px;
                width: 56px;
            }
        }

        ul {
            display: flex;
            justify-content: center;
            margin: 30px 0 0;

            li {
                border: none !important;
            }
        }

    }

    //same template and css anywhere.... watch EditMenu && AddToLikeboards
    .add-to-likeboards--list .box {
        .likeboard-layout-wrap {
            border-radius: 6px;
            overflow: hidden;
            border: solid 2px var(--Border-light);
        }

        .create-likeboard-item {
            margin: 32px auto;
            overflow: hidden;
            cursor: pointer;

            .m-button {
                display: flex;
                margin: 16px auto;
                max-width: 220px;
            }


            h2 {
                text-align: center;
                font: var(--heading-1);
                color: var(--Text-brand);
                text-transform: uppercase;
            }
        }

        .add-to-info {
            flex: 1;
            margin: 0 var(--spacing-03);

            h3 {
                font: var(--sub-5);
                margin-bottom: 5px;
            }

            p {
                font: var(--text-s-regular);
                color: var(--Text-secondary);
                display: flex;
                align-items: center;

                .svg-icon {
                    width: 16px;
                    margin-right: 2px;
                }
            }
        }
    }   
</style>
