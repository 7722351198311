const { v4: uuidv4 } = require('uuid');

export default function(type, obj, vue) {    
    // console.log('TiktokTracking', type, obj, vue);
    let backend_type = type;
    if (type === 'OnlyPageView') backend_type = 'PageView';
    let backend_obj = obj;
    backend_obj.event_type = backend_type;
    backend_obj.event_id = uuidv4();
    backend_obj.current_url = window.location.href;
    backend_obj.user_agent = navigator.userAgent;
    let params = new URLSearchParams(backend_obj).toString();
    vue.$ajax.get('/api/tiktok/send-pixel-event?' + params);

    if (type !== 'OnlyPageView' && typeof ttq === 'object' && typeof ttq.track === 'function') {
        // console.log('TiktokTracking', type, obj);
        if (type === 'PageView') {
            ttq.page();
        } else if (Object.keys(obj).length === 0) {
            ttq.track(type);
        } else {
            ttq.track(type, obj);
        }
    }
}