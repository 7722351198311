<template>
    <div :class="classnames">
        <input v-if="type === 'text' || type === 'number' || type === 'email' || is_password"
            :type="is_password ? is_password_type : type" :placeholder="placeholder" @input="changeInput"
            @focus="handleFocus" @blur="validation" v-model="value" :disabled="is_disabled" :name="name" :id="name"
            :maxlength="char_limit">

        <textarea v-if="type === 'textarea'" :rows="rows" cols="50" :placeholder="placeholder" @input="changeInput"
            :maxlength="char_limit" @focus="handleFocus" @blur="validation" v-model="value" :disabled="is_disabled"
            :name="name" :id="name" ref="textarea"></textarea>

        <div v-if="type === 'textarea'" :class="['number', { 'limitDisplay': error }]">{{ value ? value.length : 0 }}/{{
            char_limit }}</div>
        <svg-icon v-if="error && type === 'text'" name="alert" />
        <svg-icon v-if="success && type === 'text'" name="checked-circle-2" />

        <div @click="openEye" v-if="is_password">
            <svg-icon class="password-eye" :name="password_eye" />
        </div>
    </div>
</template>

<script>


export default {
    name: 'm-input',

    props: {
        name: {
            type: String,
            default: '',
        },

        input_value: {
            type: String,
            default: '',
        },

        rows: {
            type: Number,
            default: 4,
        },

        placeholder: {
            type: String,
            default: '',
        },

        error: {
            type: Boolean,
            default: false,
        },

        success: {
            type: Boolean,
            default: false,
        },

        type: {
            type: String,
            default: 'text' // placeholder //number //password
        },

        is_disabled: {
            type: Boolean,
            default: false
        },

        char_limit: {
            type: Number,
            default: undefined
        },

        is_password: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            value: this.input_value,
            eye_show: false,
            pos:0
        }
    },

    watch: {
        input_value(val) {
            this.value = val;
        },
        pos(val){
            this.$emit('focus', {
                field: this.name,
                value: this.value,
                pos:val
            });
        }
    },

    computed: {
        classnames() {
            let arr = ['m-input'];
            return arr;
        },
        is_password_type() {
            return this.eye_show ? 'text' : 'password';
        },
        password_eye() {
            return this.eye_show ? 'view' : 'private2';
        }
    },

    methods: {
        openEye() {
            this.eye_show = this.eye_show ? false : true;
        },

        changeInput() {
            let caretPosition = this.type === 'textarea' ? this.$refs.textarea.selectionStart : 0;
            setTimeout(() => {
                this.$emit('changeInput', {
                    field: this.name,
                    value: this.value,
                    pos: caretPosition
                });

            }, 100);
   
            this.$emit('change', {
                field: this.name,
                value: this.value
            });

        },

        handleFocus() {
            setTimeout(() => {
                const activeElement = document.activeElement;
                let caretPosition =( this.type==='textarea')? activeElement.selectionStart:0;
                
                this.$emit('handleFocus', true);
                this.$emit('focus', {
                    field: this.name,
                    value: this.value,
                    pos:caretPosition
                });
            }, 50);
        },

        validation() {
            this.$emit('handleBlur', false);
            this.$emit('blur', {
                field: this.name,
                value: this.value
            });
        },

        handleMouseUp() {
            this.pos = this.$refs.textarea.selectionStart;
        },

    },
    mounted(){
        if(this.type==='textarea'){
            this.$refs.textarea.addEventListener('mouseup', this.handleMouseUp);
        }
    },
    beforeDestroy() {
        if(this.type==='textarea'){
            this.$refs.textarea.removeEventListener('mouseup', this.handleMouseUp);
        }
    },
}
</script>

<style lang="scss">
.m-input {
    display: flex;
    position: relative;

    input {
        padding: 0 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-radius: var(--radius-minimal);
        font: var(--text-md-light);
        width: 100%;
        color: var(--Text-default);
        border: solid 1px var(--Border-default);
        background-color: var(--Surface-light);
    }

    textarea {
        padding: 10px;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        border-radius: var(--radius-minimal);
        font: var(--text-md-light);
        width: 100%;
        resize: none;
        color: var(--Text-default);
        border: solid 1px var(--Border-default);
        background-color: var(--Surface-light);
    }

    input,
    textarea {
        &:hover {
            color: var(--Text-secondary);
            border: solid 1px var(--Border-neu);
            background-color: var(--Surface-light);
        }

        &:focus {
            outline: none;
            color: var(--Text-secondary);
            border: solid 1px var(--Border-primary);
            background-color: var(--Surface-light);
        }
    }

    &.error {

        input,
        textarea {
            color: var(--Text-danger);
            border: solid 1px var(--Red-30);
            background-color: var(--Surface-light);
        }

        input:hover,
        textarea:hover {
            color: var(--Text-danger);
            border: solid 1px var(--Red-30);
            background-color: var(--Surface-light);
        }

        input:focus,
        textarea:focus {
            color: var(--Text-danger);
            border: solid 1px var(--Red-30);
            background-color: var(--Surface-light);
        }
    }

    &.success {

        input,
        textarea {
            color: var(--Text-safe);
            border: solid 1px var(--Border-safe);
            background-color: var(--Surface-light);
        }

        input:hover,
        textarea:hover {
            color: var(--Text-safe);
            border: solid 1px var(--Border-safe);
            background-color: var(--Surface-light);
        }

        input:focus,
        textarea:focus {
            color: var(--Text-safe);
            border: solid 1px var(--Border-safe);
            background-color: var(--Surface-light);
        }
    }

    $alert_w: 18px;

    .svg-icon--checked-circle-2,
    .svg-icon--alert {
        position: absolute;
        right: 6px;
        top: 11px;
        width: $alert_w;
        height: $alert_w;
        min-width: $alert_w;
        min-height: $alert_w;
        color: var(--Text-danger);
    }

    .svg-icon--checked-circle-2 {
        color: var(--Text-safe);
    }

    .password-eye {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        right: 10px;
        top: 12px;
        cursor: pointer;
        z-index: 5;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    .number {
        font: var(--text-s-light);
        text-align: right;
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    .limitDisplay {
        color: var(--Text-danger);
    }

    .error-text {
        color: var(--Text-danger);
        font: var(--text-s-light);
    }
}
</style>
