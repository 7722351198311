<template>
    <div class="ui-popup-menu" ref="menu">
        <div class="ui-popup-menu--wrapper">
            <div class="ui-popup-menu--container">
                <header v-if="title" class="ui-popup-menu--header">
                    <h2 class="ui-popup-menu--title">{{ title }}</h2>
                    <slot name="close"></slot>
                </header>
                <div class="ui-popup-menu--body">
                    <slot></slot>
                </div>
                <footer class="ui-popup-menu--footer" v-if="close_text">
                    <m-button :size="button_size" type="secondary" @click.native="close(true)">{{ close_text }}</m-button>
                </footer>
            </div>
            <div class="ui-popup-menu--bg" @click="close(false)"></div>
        </div>
    </div>
</template>

<script>
import Button from './ui/NewButton.vue';
export default {
    name: 'popup',
    components: {
        'm-button': Button,
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        close_text: {
            type: String,
            default: ''
        },
        button_size: {
            type: String,
            default: '40'
        }
    },

    methods: {
        open() {
            let self = this;
            self.$refs.menu.classList.add('open');

            setTimeout(() => {
                self.$refs.menu.classList.add('open-bg');
            }, 10);

            setTimeout(() => {
                self.$refs.menu.classList.add('open-container');
            }, 150);
        },

        close(is_click_button_close) {
            let self = this;
            
            if (self.$refs.menu) self.$refs.menu.classList.remove('open-container');
            
            setTimeout(() => {
                if (self.$refs.menu) self.$refs.menu.classList.remove('open-bg');
            }, 200);

            setTimeout(() => {
                if (self.$refs.menu) self.$refs.menu.classList.remove('open');
                self.$emit('closeCallback');

                if (is_click_button_close) {
                    self.$emit('callback');
                }
            }, 300);
        },
    },


}
</script>

<style lang="scss">

    .ui-popup-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999999;
        display: none;

        &--wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &--container {
            position: absolute;
            z-index: 9;
            overflow: hidden;
            left: 0;
            right: 0;
            bottom: 0;
            transition: transform .3s ease;
            transform: translate3d(0, 110%, 0);
            background: var(--Black-10);
            border-radius: 16px 16px 0 0;
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(var(--Black-100-rgba), .5);
            opacity: 0;
            transition: opacity .2s ease;
        }

        &--header {
            background: var(--Bg-light);
            height: 48px;
            display: flex;
            align-items: center;
            // justify-content: center;
            padding: 0 16px;
            position: relative;

            .close {
                height: 20px;
                width: 20px;
                cursor: pointer;
                color: var(--Text-default);

                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }

        &--title {
            font: var(--sub-3);
            color: var(--Text-primary);
            margin: 0;        
            flex: 1;
            text-transform: uppercase;
        }

        &--body {
            color: var(--Text-primary);
            text-align: center;
            background: var(--Bg-01);
        }

        &--footer {
            background: var(--Bg-light);
            margin-top: 16px;
        }

        &.open {
            display: block;
        }

        &.open-container &--container {
            transform: translate3d(0, 0%, 0);
        }

        &.open-bg &--bg {
            opacity: 1;
        }
    }

    @media (min-width: 580px) {
        .ui-popup-menu {
            &--container {
                opacity: 0;
                transition: opacity .2s ease;
                transform: translate3d(-50%, -50%, 0);
                border-radius:  16px;
                right: auto !important;
                bottom: auto !important;
                left: 50%;
                top: 50%;
            }

            &.open-container &--container {
                transform: translate3d(-50%, -50%, 0);
            }

            &.open-bg &--container,
            &.open-bg &--bg {
                opacity: 1;
            }
        }
    }

    #earning-popup {
        .ui-popup-menu--body {
            padding-top: 24px;

            .content {
                margin: 0 24px 24px 24px;
                text-align: center;

                h2 {
                    font-size: var(--font-l);
                    font-weight: var(--font-medium);
                    margin-bottom: 24px;
                }

                p {
                    font-size: var(--font-s);
                    font-weight: var(--font-light);
                    margin: 0;
                }
            }

            .continue {
                background: var(--Black-10);

                border-radius: 0 0 10px 10px;
                border: none;

                a {
                    font-size: var(--font-m);
                    font-weight: var(--font-medium);
                    color: var(--Text-brand);
                    border-top: none;
                }

            }
        }
    }

    .login-popup {
        .ui-popup-menu--body {
            max-width: 360px;

            @media screen and (max-width: 580px) {
                max-width: 100%;
            }

            .inner {
                padding: 16px 24px 24px;

                header {
                    position: relative;

                    i {
                        height: 24px;
                        width: 24px;
                        position: absolute;
                        left: -8px;
                        top: 50%;
                        margin-top: -12px;
                        color: var(--Text-primary);

                        svg {
                            height: 24px;
                            width: 24px;
                        }
                    }

                    span {
                        height: 16px;
                        width: 88px;
                        display: block;
                        margin: 0 auto;

                        svg {
                            height: 16px;
                            width: 88px;
                            fill: var(--Text-brand);
                        }
                    }
                }

                .text {
                    padding: 32px 8px;

                    h2 {
                        font: var(--sub-4);
                        color: var(--Text-primary);
                        margin-bottom: 8px;
                        text-transform: uppercase;
                    }

                    p {
                        color: var(--Text-primary);
                        font: var(--text-s-light);
                    }
                }

                .btns {
                    display: flex;
                    margin: 24px -8px 12px;

                    .m-button {
                        flex: 1;
                        margin: 0 8px;
                    }
                }
                
                .cp {
                    font: var(--text-s-light);
                    color: var(--Text-brand);
                    text-align: left;

                    a {
                        color: var(--Text-link);
                    }
                }

            }

            
        }
    }

</style>
