import { render, staticRenderFns } from "./SvgIcon.vue?vue&type=template&id=5449c5c8"
import script from "./SvgIcon.vue?vue&type=script&lang=js"
export * from "./SvgIcon.vue?vue&type=script&lang=js"
import style0 from "./SvgIcon.vue?vue&type=style&index=0&id=5449c5c8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports