<template>
    <div id="mobile-wrap" :class="[updateClass(), { 'show-header': show_header }, { 'is-sticky': is_sticky }, { 'motom-app': isMotomApp }]">
        <Header v-if="$route.meta !== 'create' && $route.meta !== 'private-profile-storefront-add' && $route.meta !== 'private-profile-merchant-add' && $route.meta !== 'private-profile-likeboards-add' && $route.meta !== 'trending-list'" :is_back_header="is_back_header" :is_close_header="is_close_header" />
        <SearchBar v-if="$route.meta !== 'create' && $route.meta !== 'reset-password'" />
        <LoginModal v-if="!$store.state.is_logged" class="login-modal" @close="$store.state.login_modal.is_show = false" />
        <AnchorInviteModal v-if="is_show_invite_popup" :data="invite_popup_data" />

        <router-view />

        <Loading v-if="$store.state.show_loading" :classname="['all-page']" />
        <AddToLikeboards v-if="is_show_add_likeboards" v-bind:post="post_of_edit" :is_bookmark="is_bookmark" ref="books" />
        <MerchantList v-if="is_open_merchant_list" :data="merchant_data" ref="merchant" :key="merchant_key"/>
        <TaggedProductPopUp v-if="$store.state.show_tagged_products_popup" :post="post_of_new_tagged" :see_all="see_all"
            :show_add_button="post_of_new_tagged_is_show_button" />
        <Share :title="'Share'" ref="share" :shareURL="share_url" :postType="post_type" :description="description"
            :email_subject="email_subject" :email_body="email_body" :twitter_text="twitter_text" :share_post="share_post"
            :is_show_share_button="share_is_show_share_button" @copied="shareCallback($event)" />
        <EditMenu :post="post_of_edit" :type="edit_menu_type" :likeboard_id="likeboard_id" ref="edit_menu" />
        <PopupPost />
        <BalanceModal v-if="show_deactivation" :earnings="earnings" :from_login="true" @close="show_deactivation = false"
            :delete_date="$store.state.deactivated_time">

            <template v-slot:restore-buttons>
                <div class="button-row">
                    <m-button size="32" @click.native="restore();">Reactivate</m-button>
                    <m-button size="32" type="base" @click.native="logout();">Logout</m-button>
                </div>
            </template>
            <template v-slot:footer>
                <m-button size="32" @click.native="$refs.popup.open();">Cash out now</m-button>
            </template>
        </BalanceModal>
        <GlobalNavi />
        <GlobalMenu
            v-if="!is_back_header && !is_close_header && $store.state.type === 'mobile' && $route.name !== 'globalsearch' && $route.name !== 'reset-password' && $route.meta !== 'private-profile-storefront-add' && $route.meta !== 'private-profile-merchant-add'" />
        <notifications :position="notification_position">
            <template slot="body" slot-scope="{ item, close }">
                <div class="ajax-notification" :class="['type-' + item.type]">
                    <div class="row" @click="callbackEvent(item)">
                        <svg-icon v-if="item.data && item.data.icon" :name="item.data.icon"></svg-icon>
                        <svg-icon v-else :name="'checked-circle'"></svg-icon>
                        <div class="title" v-if="item.title" v-html="item.title"></div>
                        <div class="text" v-if="item.text" v-html="item.text"></div>
                    </div>
                </div>
            </template>
        </notifications>
        <Popup id="earning-popup" ref="popup" close_text="Cancel">
            <div class="content">
                <h2>You are about to leave Motom.me to request payment. </h2>
                <p>If you have already recently requested payment, please check the email you provided on your payment info
                    for the most recent status updates on your payment.</p>
            </div>
            <div class="continue">
                <a href="https://airtable.com/shrWurwmmuIKLSgB8" target="_blank" @click="$refs.popup.close();"> Continue to
                    cash out</a>
            </div>
        </Popup>
        <Popup ref="profile_menu" title="Menu" class="profile-menu-box">
            <template v-slot:close>
                <i class="close" @click="$refs.profile_menu.close();"><svg-icon name="close-no-circle"></svg-icon></i>
            </template>

            <ul class="profile-menu" @click="$refs.profile_menu.close();" :key="$store.state.key_refrash.home_header">
                <!-- <li><router-link to="/me/following"><svg-icon name="user-followed" /> Following</router-link></li>
                <li><router-link to="/me/followers"><svg-icon name="followers-2" /> Followers</router-link></li> -->
                <template v-if="$store.state.is_logged">
                    <template v-if="$route.name === 'brand-sub-page'">
                        <li>
                            <div class="be-a-a" @click="goFollow(true)"><svg-icon
                                    :name="$route.params.is_followed ? 'user-followed' : 'user-follower'" />{{ $route.params.is_followed
                                        ? 'Unfollow' : 'Follow' }} this brand</div>
                        </li>
                        <li class="hr"></li>
                    </template>
                    <template v-if="$route.meta === 'public-profile'">
                        <li>
                            <div class="be-a-a" @click="goFollow(false)"><svg-icon
                                    :name="$route.params.is_followed ? 'user-followed' : 'user-follower'" />{{ $route.params.is_followed
                                        ? 'Unfollow' : 'Follow' }} this account</div>
                        </li>
                        <li>
                            <div class="be-a-a" @click="showShareProfile"><svg-icon name="share" /> Share this profile</div>
                        </li>
                        <li class="hr"></li>
                    </template>
                    <li><router-link to="/"><svg-icon name="home" /> Back to Home</router-link></li>
                    <li>
                        <div class="be-a-a" @click="showSearchBar"><svg-icon name="search" /> Search</div>
                    </li>
                    <li class="hr"></li>
                    <li><router-link to="/me"><svg-icon name="user" /> Your Profile</router-link></li>
                    <li><router-link to="/me/following"><svg-icon name="user-followed" /> Your Following </router-link></li>
                    <li><router-link to="/me/followers"><svg-icon name="followers-2" /> Your Followers  </router-link></li>
                    <li><router-link to="/me/earnings"><svg-icon name="bank" /> Your Bank</router-link></li>
                    <li><router-link to="/me/settings"><svg-icon name="settings3" /> Your Settings & Privacy</router-link>
                    </li>
                    <li class="btn-flex-box"><m-button :is_width="true" type="secondary" size="40" @click.native="gotoCreatePage()">Create</m-button></li>
                </template>

                <template v-else>
                    <template v-if="$route.name === 'brand-sub-page'">
                        <li><div class="be-a-a" @click="showLogin"><svg-icon name="user-follower" /> Follow this brand</div></li>
                        <li class="hr"></li>
                    </template>
                    <template v-if="$route.meta === 'public-profile'">
                        <li>
                            <div class="be-a-a" @click="showLogin"><svg-icon name="user-follower" /> Follow this account</div>
                        </li>
                        <li>
                            <div class="be-a-a" @click="showShareProfile"><svg-icon name="share" /> Share this profile</div>
                        </li>
                        <li class="hr"></li>
                    </template>
                    <li><router-link to="/"><svg-icon name="home" /> Home</router-link></li>
                    <li><router-link to="/home/shop"><svg-icon name="shopping_bag" /> Shop</router-link></li>
                    <li><router-link to="/home/collections"><svg-icon name="grid" /> Collection</router-link></li>
                    <li>
                        <div class="be-a-a" @click="showSearchBar"><svg-icon name="search" /> Search</div>
                    </li>
                    <li class="btn-flex-box">
                        <m-button :is_width="true" size="40" @click.native="openLogin(1)">Sign Up</m-button>
                        <m-button :is_width="true" type="secondary" size="40" @click.native="openLogin(2)">Log In</m-button>
                    </li>

                </template>
            </ul>
        </Popup>
        <Modal class="draft-modal" v-if="is_show_draft_modal" @close="is_show_draft_modal = false">
            <PostDraft :post="post_of_edit" class="show-last-draft-post" />
            <div class="content">
                <h2>You have an unsaved post </h2>
                <p>Save this as a draft so you can work on it later.</p>
            </div>
            <footer>
                <m-button size="40" type="tertiary" @click.native="discardDraft(post_of_edit.post_id)">Discard</m-button>
                <m-button size="40" @click.native="confirmDraft(post_of_edit.post_id)">Save as draft</m-button>
            </footer>
            <div class="continue">
                <span @click="goonDraft">Open unsaved post</span>
            </div>
        </Modal>

        <Modal class="page-modal" v-if="is_show_page_modal" @close="is_show_page_modal = false" :title="page_data.title">
            <Loading v-if="is_loading_page" class="static" />
            <div class="content">
                <div class="text" v-html="page_data.content">
                </div>
            </div>
            <footer>
                <m-button size="40" @click.native="is_show_page_modal = false">Confirm</m-button>
            </footer>
        </Modal>

        <!-- <ModalCheckSocial v-if="$store.state.is_logged && $store.state.social_state.is_show_popup" /> -->

        <transition v-if="is_show_whats_new" name="fade">
            <PopupWhatsNew :slider="whats_new_slider" :id="whats_new_id" @close="is_show_whats_new = false" />
        </transition>
        <CreatePostPopUp ref="create_post" @openLikeboardInfo="openLikeboardInfo" />
        <LikeboardInfoPopUp ref="likeboard_info_popup" @createLikeboard="createLikeboard" :show_next="true" />
        <RepostPopUp ref="repost_modal" />
        <ProductPopUp />
        <Popup v-if="!$store.state.is_logged" ref="login_popup" class="login-popup">
            <div class="inner">
                <header>
                    <i class="close" @click="$refs.login_popup.close();"><svg-icon name="close-no-circle" /></i>
                    <span><svg-icon name="logo-motom" /></span>
                </header>
                <div class="text">
                    <h2 v-if="login_popup_data.title" v-html="login_popup_data.title"></h2>
                    <p v-if="login_popup_data.text" v-html="login_popup_data.text"></p>
                </div>
                <div class="btns">
                    <m-button size="40" @click.native="openLogin(1)">Sign Up</m-button>
                    <m-button size="40" type="secondary" @click.native="openLogin(2)">Log In</m-button>
                </div>

                <p class="cp">By continuing you agree to Motom's <router-link to="/page/terms_of_use">Terms of
                        Use</router-link> & <router-link to="/page/privacy_policy">Privacy Policy</router-link></p>
            </div>
        </Popup>

        <ConnectSocialModal ref="connect_social_modal" />
        <Modal class="tiktok-banned-modal" v-if="$store.state.is_need_add_email" @close="closeTiktokAlert(false)">
            <div class="content">
                <i><svg-icon name="alert" /></i>
                <h2>Back up your Motom account</h2>
                <p>If you are using TikTok to login to your Motom account, please add an email + password to ensure your continued access.</p>
            </div>
            <footer>
                <m-button size="40" @click.native="closeTiktokAlert(true)">GO TO SETTINGS</m-button>
            </footer>
        </Modal>
        
        <Modal class="tiktok-banned-modal" v-show="$store.state.is_show_tiktok_down_popup" @close="$store.state.is_show_tiktok_down_popup = false">
            <div class="content">
                <i><svg-icon name="alert" /></i>
                <h2>TikTok login no longer supported</h2>
                <p>Due to the U.S. ban on TikTok, you can no longer access your Motom account via TikTok. Please contact us if you need help accessing your account.</p>
            </div>
            <footer>
                <m-button size="40" @click.native="$store.state.is_show_tiktok_down_popup = false" to="mailto:help@motom.me?subject=TikTok login&body=Describe your issue above this line">CONTACT US </m-button>
                <m-button type="tertiary" size="40" @click.native="$store.state.is_show_tiktok_down_popup = false">Close</m-button>
            </footer>
        </Modal>
    </div>
</template>

<script>
import { EventBus } from '@/events/bus.js';
import dataURLtoFile from '@/utils/DataURLtoFile';
import getQueryVariable from '@/utils/GetQueryVariable';
import Header from '@/components/Header.vue';
import Loading from '@/components/Loading.vue';
import Share from '@/components/Share.vue';
import EditMenu from '@/components/EditMenu.vue';
import Back from '@/components/Back.vue';
// import PopupPosts from '../components/PopupPosts.vue';
import PopupPost from '@/components/PopupPost.vue';
import GlobalMenu from '@/components/GlobalMenu.vue';
import GlobalNavi from '@/components/GlobalNavi.vue';
import SearchBar from '@/components/globalsearch/Bar.vue';
import BalanceModal from '../components/profile/BalanceModal.vue';
import Popup from '@/components/Popup.vue';
import CreatePostPopUp from '@/components/create/CreatePostPopUp.vue';
import LikeboardInfoPopUp from '../components/LikeboardInfoPopUp.vue';
import Modal from '@/components/Modal.vue';
import PostDraft from '@/components/post/PostDraft.vue';
import RepostPopUp from '@/components/create/RepostPopUp.vue';
import ProductPopUp from '@/components/product/ProductPopUp.vue';
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Pool from '@/utils/Pool';
import ConnectSocialModal from '../components/register/ConnectSocialModal.vue';
// import ModalCheckSocial from "../components/ModalCheckSocial.vue";

export default {
    name: 'mobile',

    data() {
        return {
            post_of_new_tagged: {},
            post_of_new_tagged_is_show_button: true,
            post_of_edit: {},
            likeboard_id: 0,
            is_show_add_likeboards: false,
            is_show_draft_modal: false,
            is_show_page_modal: false,
            is_loading_page: false,
            is_show_whats_new: false,
            is_show_invite_popup: false,
            invite_popup_data: {},
            whats_new_slider: [],
            whats_new_id: 0,
            share_url: '',
            post_type: '',
            description: '',
            twitter_text: '',
            email_body: '',
            email_subject: '',
            share_post: {},
            share_is_show_share_button: false,
            edit_menu_type: 'like',
            tooltip_text: '',
            tooltip_color: 'black',
            current_scroll_position: 0,
            show_header: false,
            is_sticky: false,
            offset: 48,
            register_data: false,
            is_home_page: false,
            is_back_header: false,
            is_close_header: false,
            is_open_merchant_list: true,
            merchant_data: {},
            see_all: false,
            prompt_signup: false,
            earnings: '',
            show_deactivation: false,
            is_bookmark: false,
            referral_id: '',
            login_popup_data: {},
            merchant_key:0,
            notification_position: 'bottom right',
            page_data: {},
        }
    },

    components: {
        getQueryVariable,
        Header,
        Loading,
        Share,
        EditMenu,
        PopupPost,
        // PopupPosts,
        // PopupTiktok,
        // ModalCheckSocial: () => import(/* webpackChunkName: "modal-check-social" */ '@/components/ModalCheckSocial'),
        Back,
        GlobalMenu,
        GlobalNavi,
        SearchBar,
        BalanceModal,
        Popup,
        Modal,
        CreatePostPopUp,
        LikeboardInfoPopUp,
        PostDraft,
        RepostPopUp,
        ProductPopUp,
        ConnectSocialModal,
        'svg-icon': SvgIcon,
        MerchantList: () => import(/* webpackChunkName: "merchant-list" */ '@/components/product/MerchantList.vue'),
        PopupWhatsNew: () => import(/* webpackChunkName: "whats-new-modal" */ '@/components/PopupWhatsNew.vue'),
        LoginModal: () => import(/* webpackChunkName: "login-modal" */ '@/components/LoginModal.vue'),
        // InviteModal: () => import(/* webpackChunkName: "login-modal" */ '@/components/InviteModal.vue'),
        AnchorInviteModal: () => import(/* webpackChunkName: "anchor-modal" */ '@/components/AnchorInviteModal.vue'),
        AddToLikeboards: () => import(/* webpackChunkName: "product-popup" */ '@/components/likeboards/AddToLikeboards.vue'),
        TaggedProductPopUp: () => import(/* webpackChunkName: "all-tagged-products" */ '@/components/product/TaggedProductPopUp.vue'),
        // RegisterPopUp: () => import(/* webpackChunkName: "register-popup" */ '@/components/register/PopUp.vue'),
    },

    watch: {
        '$route': function (newRoute, oldRoute) {
            if (this.$route.name !== 'register') {
                // window.localStorage.removeItem('connected_socials');

                if (window.localStorage.getItem('prior_register_payload_key') !== null) {

                    this.$ajax('/api/clean-prior-register-payload-json', {
                        method: 'post',
                        withCredentials: true,
                        data: {
                            login_credential_key: window.localStorage.getItem('prior_register_payload_key'),
                        },
                    });
                    window.localStorage.removeItem('prior_register_payload_key');


                }///api/clean-prior-register-payload-json 
            }

            if (this.$route.name === 'productdetails'){
                this.merchant_key += 1;
            } else {
                this.merchant_key += 1;
            }

            if (newRoute.name !== 'notifications' && window.localStorage.getItem('notifications_maxID') && window.localStorage.getItem('notifications_maxID') > 0) {
                this.updateNotifications();
            }

            if (newRoute.name !== 'sales' && newRoute.name !== 'earnings-product') {
                this.$store.state.sales_date.text = '';
                this.$store.state.sales_date.key = 0;
                this.$store.state.sales_date.start = null;
                this.$store.state.sales_date.end = null;
            }

            this.referral_id = getQueryVariable('referral_id');

            this.$store.state.show_tagged_products_popup = false;
            document.body.classList.remove('is-show-tagged-products-popup');
            this.$refs.edit_menu.close();

            this.checkStages();
            this.updateClass();

            if (this.is_open_merchant_list && this.$refs.merchant) {
                this.$refs.merchant.close();
            }

            // setTimeout(() => {
            //     const container = document.querySelector('#mobile-wrap > .container');
            //     const alert = document.querySelector('#js-tiktok-alert');
            //     const alert_html = `
            //         <div id="js-tiktok-alert" class="tiktok-alert">
            //             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-680q17 0 28.5-11.5T520-720q0-17-11.5-28.5T480-760q-17 0-28.5 11.5T440-720q0 17 11.5 28.5T480-680Zm-40 320h80v-240h-80v240ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z"/></svg>
            //             <p>TikTok-related capabilities may become limited starting January 19th, 2025</p>
            //             <i class="close">
            //                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
            //             </i>
            //         </div>
            //     `;

            //     // html to dom


            //     if (container && !alert && !this.$cookie.get('motom_hide_tiktok_banner')) {
            //         container.insertAdjacentHTML('afterbegin', alert_html);

            //         const close = document.querySelector('#js-tiktok-alert .close');
            //         close.addEventListener('click', () => {
            //             document.querySelector('#js-tiktok-alert').remove();
            //             this.$cookie.set('motom_hide_tiktok_banner', 1, { expires: '1D' });
            //         });
            //     }
            // }, 10);

        },
    },


    methods: {
        checkTiktokStatus() {
            if (window.is_logged && !this.$cookie.get('motom_hide_tiktok_popup')) {
                this.$ajax('/api/check-have-other-login-method', {
                    method: 'get',
                    withCredentials: true,
                }).then(res => {
                    if (res.data.data.show_tiktok_alert_popup) {
                        this.$store.state.is_need_add_email = true;
                    }
                });
            }

            this.$ajax('/api/check-is-tiktok-api-down', {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                if (!res.data.data.is_tiktok_api_alive) {
                    this.$store.state.is_tiktok_banned = true;
                }
            });
        },
        
        closeTiktokAlert(is_open = false) {
            this.$store.state.is_need_add_email = false;
            this.$cookie.set('motom_hide_tiktok_popup', 1, { expires: '1D' });

            if (is_open) {
                this.$router.push('/me/settings/account-security/info');
            }
        },
        checkWhatsNew() {
            let hide_whats_new_ids = window.localStorage.getItem('hide_whats_new_ids');
            if (hide_whats_new_ids !== null) {
                hide_whats_new_ids = JSON.parse(hide_whats_new_ids);
            }

            this.$ajax('/api/whats-new-sliders/list', {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                if (res.data.data.contents && (hide_whats_new_ids === null || (!hide_whats_new_ids.includes(res.data.data.id)))) {
                    this.is_show_whats_new = true;
                    this.whats_new_slider = res.data.data.contents;
                    this.whats_new_id = res.data.data.id;
                }
            });
        },

        callbackEvent(obj) {
            if (obj.data && obj.data.callback) {
                switch (obj.data.callback) {
                    case 'EditLikeBoard':
                        this.$refs.books.openEditCustom();
                        break;
                    // case 'UndoRemoveDraft':
                    //     EventBus.$emit('undo-remove-draft', obj.data.post_id);
                    //     break;
                }

                this.$notify({
                    clean: true,
                })
            }
        },

        onScroll() {
            const current_scroll_position = window.pageYOffset || document.documentElement.scrollTop;

            if (current_scroll_position > this.offset) {
                this.is_sticky = true;
            } else {
                this.is_sticky = false;
            }

            if (current_scroll_position < this.offset || this.$store.state.is_disable_scroll) {
                this.show_header = false;
                return;
            }

            this.show_header = current_scroll_position < this.last_scroll_position;
            this.last_scroll_position = current_scroll_position;
        },

        checkStages() {
            // console.log('name is: ' + this.$route.name, 'meta is: ' + this.$route.meta);
            const name = this.$route.name;
            const meta = this.$route.meta;
            this.back_text = '';
            this.back_url = '';
            this.back_type = '';
            this.prompt_signup =
                meta === 'public-profile' ||
                (name !== 'home' && name !== 'shop' && name !== 'reset-password' && name !== 'register' && name !== 'productdetails' && name !== 'notifications');
            this.is_close_header =
                name === 'email-login';
            this.is_home_page =
                name === 'home';
            this.is_back_header =
                name === 'postdetails' ||
                name === 'notifications' ||
                name === 'about' ||
                name === 'invitefriend' ||
                meta === 'public-profile' ||
                meta === 'private-profile' ||
                meta === 'private-likeboard' ||
                meta === 'likeboard' ||
                meta === 'metrics' ||
                meta === 'earnings' ||
                name === 'register-settings' ||
                meta === 'page' ||
                meta === 'switch-to-creator' ||
                meta === 'create' ||
                meta === 'private-profile-settings' ||
                meta === 'brand-profile' ||
                name === 'register' ||
                name === 'login' ||
                name === 'productposts' ||
                name === 'hotproducts' ||
                name === 'live-video' ||
                name === 'article' ||
                name === 'productdetails' ||
                name === 'trending' ||
                name === 'globalsearch' ||
                name === 'connect-socials' ||
                name === 'home-email-login' ||
                (name === 'reset-password' && this.$route.params.type === 'email');
            // console.log(this.prompt_signup);
        },


        checkDeviceType() {
            const root_el = document.documentElement;
            const ww = window.innerWidth;
            const vh = window.innerHeight * 0.01;

            root_el.style.setProperty('--vh', ''.concat(vh, 'px'));
            root_el.classList.remove('device-is-desktop');
            root_el.classList.remove('device-is-tablet');
            root_el.classList.remove('device-is-mobile');


            if (ww > 1100) {
                this.$store.state.type = 'desktop';
            } else if (ww <= 1100 && ww > 680) {
                this.$store.state.type = 'tablet';
                root_el.classList.remove('collapse-desktop-navi');
            } else {
                this.$store.state.type = 'mobile';
            }

            root_el.classList.add('device-is-' + this.$store.state.type)
        },

        updateClass() {
            let back_page = '';
            let app_page = '';

            if (this.is_back_header) {
                back_page = 'is-back-page ';
            }

            if (this.isMotomApp && this.$route.query.app_landing === '1' && (this.$route.name === 'register' || this.$route.name === 'home-email-login' || this.$route.name === 'reset-password')) {
                app_page = 'is-app-landing ';
            }

            if (typeof this.$route.meta === 'string') {
                let is_home = '';
                if ((this.$store.state.is_logged && (this.$route.meta.substring(0, 4) === 'home')) || this.$route.meta === 'shop-brands' || this.$route.meta === 'home-shop') {
                    is_home = 'is-home ';
                }

                return app_page + back_page + is_home + 'page--' + this.$route.meta + ' page--' + this.$route.meta + '-' + this.$route.name;
            } else {
                return app_page + back_page + 'page--' + this.$route.name;
            }
        },

        shareCallback(e) {
            this.tooltip_color = 'black';
            switch (e) {
                case 'text':
                    this.tooltip_text = 'Text copied!';
                    break;

                case 'link':
                    this.tooltip_text = 'Link copied!';
                    break;

                case 'merchant-link':
                    this.tooltip_color = 'primary';
                    this.tooltip_text = 'Link copied!';
                    break; ``

                case 'coupon-code':
                    this.tooltip_color = 'primary';
                    this.tooltip_text = 'Code successfully copied!';
                    break;
            }

            this.$notify({
                text: this.tooltip_text,
                type: this.tooltip_color,
                duration: 5000,
            });

            // window.setTimeout(() => {
            //     this.show_tooltip = true;
            // }, 500);

            // window.setTimeout(() => {
            //     this.show_tooltip = false;
            // }, 5000);
        },

        debounce(func) {
            let timer;
            return function (event) {
                if (timer) clearTimeout(timer);
                timer = setTimeout(func, 100, event);
            };
        },

        restore() {
            this.$ajax('/api/me/remove-account/cancel', {
                method: 'post',
                withCredentials: true,
            }).then(res => {
                if (res.data.status === 'success') {
                    this.show_deactivation = false;
                    window.location.href = '/';
                }
            });
        },

        logout() {
            this.show_deactivation = false;
            window.location.href = '/logout';
        },

        updateNotifications() {
            let self = this;
            let update_api = '/api/me/notification/update-status';
            this.$ajax(update_api, {
                method: 'post',
                data: {
                    max_id: window.localStorage.getItem('notifications_maxID'),
                    read_status: 2
                },
                withCredentials: true
            }).then(res => {
                if (res.data.status === 'success') {
                    localStorage.setItem('notifications_maxID', 0);
                }
            }).catch(err=>{
                console.log('err',err)
            })
        },

        getLastDraft() {
            this.$ajax('/draft/get-last-draft', {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                // self.just_for_you = res.data.data;
                if (res.data.data) {
                    this.post_of_edit = res.data.data;
                    this.is_show_draft_modal = true;
                    //irene: need add draft content
                }
            });
        },

        comfirm() {
            this.$ajax('/api/clean-prior-register-payload-json', {
                method: 'post',
                withCredentials: true,
                data: {
                    login_credential_key: window.localStorage.getItem('prior_register_payload_key'),
                },
            });
        },

        confirmDraft(draft_id) {
            this.$ajax(`/draft/update-confirm-status/${draft_id}/1`, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
            })
                .catch(error => {
                    console.log(error);
                });
            this.is_show_draft_modal = false;
        },

        discardDraft(draft_id) {
            EventBus.$emit('delete-draft', draft_id);
            this.is_show_draft_modal = false;
        },

        goonDraft() {
            const obj = {};
            obj.post = this.post_of_edit;
            obj.is_edit_post = false;
            EventBus.$emit('edit-draft', obj);
            this.is_show_draft_modal = false;
        },

        createLikeboard() {
            this.$router.push('/me/likeboards/');
        },

        openLikeboardInfo() {
            this.$refs.create_post.close();
            this.$refs.likeboard_info_popup.open(0);
        },

        openRepostModal(obj) {
            this.$refs.repost_modal.open(obj);
        },
        
        getReferralData(uid) {
            this.$ajax.get('/api/user/getByReferralId/' + uid).then(res => {
                if (res.data.data.user_profile) {
                    // this.referral_data = res.data.data.user_profile;
                    this.$cookie.set('motom_invite_user', res.data.data.user_profile.user_id, { expires: '15m' });
                    if (this.$route.query.notify !== undefined) this.$cookie.set('notify', 0);

                    let query = this.$route.query;
                    this.$router.replace({
                        path: '/user/' + res.data.data.user_profile.user_id + '/profile',
                        query: query,
                    });

                    this.invite_popup_data = {
                        type: 'user',
                        id: res.data.data.user_profile.user_id,
                        image: res.data.data.user_profile.avatar,
                        name: res.data.data.user_profile.nickname,
                    };

                    this.is_show_invite_popup = true;
                }
            });
            // api/user/getByReferralId/
        },

        getInfluencerData(key, token) {
            this.$ajax.get('/api/influencer/' + key + '/claim').then(res => {
                if (res.data.data.name !== null && res.data.data.is_bound.google === false && res.data.data.is_bound.facebook === false) {
                    this.invite_popup_data = {
                        type: 'influencer',
                        id: 0,
                        image: require('@/assets/hero-logo-white.svg'),
                        name: res.data.data.name,
                    };
                    this.is_show_invite_popup = true;
                }
            });
        },

        openLogin(type) {
            this.$refs.login_popup.close();
            this.$store.commit('openLogin', {
                is_show: true,
                is_signup: type === 1,
                title: '',
                text: ''
            });
        },

        checkInvitePopup() {
            const referral_id = getQueryVariable('referral_id');
            const client_group_token = getQueryVariable('client_group_token');
            const group_id = getQueryVariable('group_id');
            const key = getQueryVariable('key');
            const token = getQueryVariable('token');


            if (window.invite_merchant_account_id && window.invite_merchant_account_name) {
                this.invite_popup_data = {
                    type: 'merchant',
                    id: window.invite_merchant_account_id,
                    image: window.invite_merchant_account_logo,
                    name: window.invite_merchant_account_name,
                };

                this.is_show_invite_popup = true;
            } else if (typeof getQueryVariable('social_platform') !== 'string' && window.location.pathname !== '/register') {

                if (typeof group_id === 'string' && typeof client_group_token === 'string' && typeof referral_id !== 'string') {
                    // this.is_join_group = true;
                    // this.open();
                    this.invite_popup_data = {
                        type: 'group',
                        id: 0,
                        image: require('@/assets/hero-logo-white.svg'),
                        name: '',
                    };
                    this.is_show_invite_popup = true;
                }

                else if (typeof referral_id === 'string' && typeof client_group_token === 'string') {
                    this.getReferralData(referral_id);
                }

                else if (typeof key === 'string' && typeof token === 'string') {
                    this.getInfluencerData(key, token);
                }
            }
        },

        showSearchBar() {
            const obj = {};
            obj.is_show = true;

            EventBus.$emit('toggle-search-bar', obj);
        },

        showLogin() {
            this.$store.commit('openLoginPopup', {
                is_show: true,
                title: `Follow  ${this.$route.params.user_name}`,
                text: 'Don’t want to miss their posts or tag on Motom. It’s as simple as sign up and follow'
            });
            return;
        },

        showShareProfile() {
            this.$store.state.popup_open.share_profile = true;
        },

        gotoCreatePage() {
            if (this.$store.state.is_logged) {
                this.$refs.create_post.open();
            }
        },

        goFollow(is_brand) {
            let follow_api = ''
            let unfollow_api = ''
            let label = window.user_id_alpha ? window.user_id_alpha : 'undefined';
            if (is_brand) {
                let brUrl='/api/post/brand/' + this.$route.params.brand_id;
                follow_api = brUrl + '/watch';
                unfollow_api = brUrl + '/unwatch';
                label = label + ',' + this.$route.params.brand_id;
            } else {
                let poUrl='/api/post/user/' + this.$route.params.user_id;
                follow_api = poUrl + '/follow';
                unfollow_api = poUrl + '/unfollow';
                label = label + ',' + this.$route.params.unique_key;
            }


            this.$store.state.show_loading = true;
            let api = this.$route.params && this.$route.params.is_followed ? unfollow_api : follow_api;


            this.$ajax(api, {
                method: 'post',
                withCredentials: true
            }).then(res => {
                if (res && res.data && res.data.status === 'success') {
                    if (this.is_followed) {
                        this.GA_Tracking('Follow People', 'Profile-Unfollow', label);
                    } else {
                        this.GA_Tracking('Follow People', 'Profile-Follow', label);
                    }
                    this.$route.params.is_followed = this.$route.params.is_followed ? false : true;
                    this.$store.state.show_loading = false;
                    // Removed all cache
                    this.$ajax.cache.store = {};
                    location.reload();
                    // EventBus.$emit('follow-user', {
                    //     user_id: this.$route.params.user_id,
                    //     is_followed: this.$route.params.is_followed
                    // });
                }
            }).catch(err => {
                this.$store.state.show_loading = false;
            });
        },

        checkHadInvite(){
            if (this.$store.state.is_logged) {
                this.$ajax('/api/whitelabel/check-have-exist-invitation', {
                    method: 'post',
                    withCredentials: true
                }).then(res => {
                    if(res.data.message=== "found a invitation" && res.data.merchant_account){
                        this.invite_popup_data = {
                            type: 'merchant',
                            id: res.data.merchant_account.id,
                            image: res.data.merchant_account.brand_logo,
                            name: res.data.merchant_account.merchant_display_name
                        };

                        this.is_show_invite_popup = true;
                    }

                })
            }
            
        }

    },

    mounted() {
        Date.prototype.addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        // this.$notify({
        //     text: 'text',
        //     type: 'alert',
        //     data: {
        //         icon: 'close-circle',
        //     },
        // });

        this.checkInvitePopup();
        this.checkHadInvite();

        const self = this;

        if (this.$route.name !== 'register-settings' && window.localStorage.getItem('motom_register_data') !== null) {
            this.register_data = JSON.parse(window.localStorage.getItem('motom_register_data'));
            window.localStorage.removeItem('motom_register_data');
        }

        EventBus.$on('show-connect-social-modal', (type, act) => {
            setTimeout(() => {
                this.$refs.connect_social_modal.show(type, act);
            }, 10);
        });

        EventBus.$on('show-select-ig-account', (item) => {
            setTimeout(() => {
                this.$refs.connect_social_modal.showSelectAccount(item);
            }, 10);
        });

        EventBus.$on('open-profile-menu', obj => {
            this.$refs.profile_menu.open();
            this.$store.state.key_refrash.home_header+=1;
            console.log('open_is_followed', this.$route.params.is_followed)
        });

        EventBus.$on('open-merchant-list', obj => {
            this.merchant_data = obj;
            let time = 40;


            if (!this.is_open_merchant_list) {
                this.is_open_merchant_list = true;
                time = 300;
            }

            setTimeout(() => {
                this.$nextTick(() => {
                    this.$refs.merchant.open();
                })
            }, time);
        });

        this.checkTiktokStatus();

        if (window.is_logged) {
            self.$store.state.is_logged = true;
            self.$store.state.user.id = user_id;
            self.$store.state.user.name = user_name;
            self.$store.state.user.avatar = user_avatar;
            self.$store.state.is_set_password = is_set_password;
            self.$store.state.is_set_email = is_set_email;
            self.$store.state.is_deactivated = is_deactivated;
            self.$store.state.is_deactivated = is_deactivated;
            self.show_deactivation = is_deactivated;
            if(register_type){
                self.$store.state.user.register_type = register_type
            }

            window.localStorage.removeItem('connected_socials');
            window.localStorage.removeItem('connected_socials_time');
            this.checkWhatsNew();

            self.$store.state.is_show_motom_bank = (user_type !== '2' && user_id !== '1643');

            if (is_deactivated) {
                this.$ajax('/api/user/earning-report', {
                    method: 'get',
                    withCredentials: true
                }).then(res => {
                    // res.data.data.current_cash_out_balance = 100;
                    self.earnings = res.data.data;
                });
            }
        } else {
            const connected_socials_time = window.localStorage.getItem('connected_socials_time');

            if (connected_socials_time !== null) {
                if (new Date().getTime() - connected_socials_time > 86400000) {
                    window.localStorage.removeItem('connected_socials');
                    window.localStorage.removeItem('connected_socials_time');
                }
            }

        }

        EventBus.$on('copied', obj => {
            this.shareCallback(obj);
        });


        EventBus.$on('open-add-likeboards', obj => {
            this.post_of_edit = obj.post;
            this.likeboard_id = 0;
            if (obj.is_my_likeboard) this.likeboard_id = obj.likeboard_id;
            this.is_show_add_likeboards = true;


            if (obj.is_bookmark) {

                this.is_bookmark = true;

                if (this.$refs.books) {
                    setTimeout(() => {
                        this.$refs.books.openEditCustom();
                    }, 1);
                } else {
                    Pool(
                        () => {
                            return (this.$refs.books)
                        },
                        () => {
                            this.$refs.books.openEditCustom();
                        }
                    )
                }
            } else {
                this.is_bookmark = false;
                
                if (this.$refs.books) {
                    console.log('book')
                    if (obj.is_liked) {
                        this.$refs.books.removeFromDefault(obj);
                    } else {
                        this.$refs.books.saveToDefault(obj);
                    }
                } else {
                    console.log('pool')
                    Pool(
                        () => {
                            return (this.$refs.books)
                        },
                        () => {
                            if (obj.is_liked) {
                                this.$refs.books.removeFromDefault(obj);
                                return
                            } else if(!obj.is_liked){
                                this.$refs.books.saveToDefault(obj);
                            }
                        }
                    )
                }
                //}
            }

        });

        EventBus.$on('login-popup', obj => {
            this.login_popup_data = obj;
            // console.log(this.$refs, this.$refs.login_popup)
            this.$refs.login_popup.open();
        });

        EventBus.$on('create-post', obj => {
            if (this.$store.state.is_logged) {
                obj.post.is_confirm = 0;
                if (obj.type === 'tiktok') {
                    this.$ajax(`/api/tiktok?url=${obj.post.social_post_url}`, {
                        method: 'get',
                    }).then(res => {
                        this.$store.state.show_loading = false;
                        if (res.status == 200) {
                            var n = res.data.data.html.indexOf('<script');
                            obj.post.tiktok_data = {};
                            obj.post.tiktok_data.embed_data = res.data.data.html.substr(0, n);
                            obj.post.tiktok_data.video_url = res.data.data.video_url;
                            obj.post.tiktok_data.influencer_id = res.data.data.author_url.replace('https://www.tiktok.com/@', '');
                            obj.post.tiktok_data.thumbnail_url = res.data.data.thumbnail_url;
                            obj.post.tiktok_data.thumbnail_width = res.data.data.thumbnail_width;
                            obj.post.tiktok_data.thumbnail_height = res.data.data.thumbnail_height;
                            obj.post.tiktok_data.payload = res.data.data;
                            obj.post.tiktok_data.tagged_list = [];
                        }
                    });
                }
                else if (obj.type === 'instagram') {
                    obj.post.instagram_data = {};
                    obj.post.instagram_data.social_post_url = obj.post.social_post_url;
                    obj.post.instagram_data.tagged_list = [];
                }
                else if (obj.type === 'video') {
                    obj.post.video_data = {};
                    obj.post.video_data.youtube_url = obj.post.social_post_url;
                    obj.post.video_data.tagged_list = [];
                }
                obj.post.type = obj.type;
                this.$store.state.create.post_of_new_tag = obj.post;
                this.$store.state.create.is_repost = true;
                this.$store.state.create.is_add_tag = true;
                EventBus.$emit('save-draft', obj.post);
                this.$router.push({ path: `/create-post` });
            } else {
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Inspired to tag products?',
                    text: 'You’ll need an account to tag products to a post or simply log in.  '
                });
            }
        });

        EventBus.$on('disable-post', obj => {
            self.$ajax('/api/disable-post', {
                method: 'post',
                withCredentials: true,
                data: {
                    post_id: obj.post_id,
                },
            }).then(res => {
                this.log(res);
            });

        });


        EventBus.$on('open-tagged-popup', obj => {
            this.post_of_new_tagged = obj.post;

            if (obj.is_see_all) {
                this.see_all = true;
            } else {
                this.see_all = false;
            }

            if (typeof obj.show_add_button !== 'undefined') this.post_of_new_tagged_is_show_button = obj.show_add_button;

            this.$store.state.show_tagged_products_popup = true;
            document.body.classList.add('is-show-tagged-products-popup');
        });

        EventBus.$on('tag-new-products', obj => {
            let UserID = '';
            let PostID = obj.post.unique_key;

            if (this.$store.state.is_logged) {
                this.$store.state.create.post_of_new_tag = obj.post;
                this.$store.state.create.is_add_tag = true;
                UserID = window.user_id_alpha;
                // this.$store.state.create.back_path = this.$router.path;
                this.$router.push({ path: `/create-post` });
            } else {
                this.$store.commit('openLoginPopup', {
                    is_show: true,
                    title: 'Inspired to tag products?',
                    text: 'You’ll need an account to tag products to a post or simply log in.'
                });
            }

            this.GA_Tracking('Add Tag', 'Click', `${UserID},${PostID}`);
        });

        EventBus.$off('edit-post');
        EventBus.$on('edit-post', obj => {
            if (this.$store.state.is_logged) {
                obj.post.is_edit = true;
                this.$store.state.create.post_of_new_tag = obj.post;
                this.$store.state.create.is_edit_old_post = true;
                // this.$store.state.create.back_path = this.$router.path;
                this.$router.push({ path: `/create-post` });
            } else {
                this.$store.commit('openLogin', {
                    is_show: true,
                });
            }
        });

        EventBus.$off('edit-draft');
        EventBus.$on('edit-draft', obj => {
            // console.log('edit-draft')
            if (this.$store.state.is_logged) {
                obj.post.is_edit = true;
                // console.log(this.data.thisorthat_data)
                if (obj.post.left_image && obj.post.left_image.product)
                    obj.post.tot_product_ids = [obj.post.left_image.product.id, obj.post.right_image.product.id];
                this.$store.state.create.post_of_new_tag = obj.post;
                this.$store.state.create.is_edit = true;
                // this.$store.state.create.back_path = this.$router.path;
                this.$router.push({ path: `/create-post` });
            } else {
                this.$store.commit('openLogin', {
                    is_show: true,
                });
            }
        });

        EventBus.$on('open-post-share', obj => {
            if (typeof self.$refs.share === 'object') {

                if (obj.onlyUrl) {
                    this.share_url = obj.shareURL;
                } else {
                    this.share_url = obj.shareURL.includes('?') ? obj.shareURL + "&shared=true" : obj.shareURL + "?shared=true";
                }
                this.post_type = obj.postType;
                this.description = '';
                this.email_subject = '';
                this.email_body = '';
                this.twitter_text = '';

                if (obj.description) this.description = obj.description;
                if (obj.email_subject) this.email_subject = obj.email_subject;
                if (obj.email_body) this.email_body = obj.email_body;
                if (obj.twitter_text) this.twitter_text = obj.twitter_text;
                if (obj.share_post) this.share_post = obj.share_post;

                if (obj.is_show_share_button) {
                    this.share_is_show_share_button = true;
                } else {
                    this.share_is_show_share_button = false;
                }

                this.$refs.share.open();
            }
        });

        EventBus.$on('open-edit-menu', obj => {
            this.post_of_edit = obj.post;
            this.likeboard_id = 0;
            this.edit_menu_type = 'edit-post';
            this.$refs.edit_menu.open();
        });

        EventBus.$on('open-create-post', obj => {
            this.$refs.create_post.open();
        });

        EventBus.$on('open-page-popup', slug => {
            this.is_loading_page = true;
            this.is_show_page_modal = true;
            this.$ajax('/api/page/get?name=' + slug, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.is_loading_page = false;
                this.page_data = res.data.data;
            });
        });

        EventBus.$off('save-draft');
        EventBus.$on('save-draft', post => {
            if (post.is_edit_old_post || post.is_add_tag || post.is_repost) return;
            let form_data = new FormData();

            if (post.post_id)
                form_data.append('draft_id', post.post_id);
            else
                form_data.append('draft_id', post.draft_id);
            form_data.append('is_confirm', post.is_confirm);

            if (post.post_caption) {
                form_data.append('caption', post.post_caption);
            }

            let type = post.type ? post.type : this.$route.params.type;

            switch (type) {
                case 'instagram':
                    if (!post.instagram_data.social_post_url) return;
                    form_data.append('resource_origin', 'instagram');
                    form_data.append('link', post.edit_post.draft_media_url ? post.edit_post.draft_media_url : post.instagram_data.social_post_url);
                    form_data.append('scraped', post.instagram_data.post_scraped);
                    form_data.append('influencer_id', post.instagram_data.influencer_id);

                    post.instagram_data.tagged_list.forEach((item, index) => {
                        if (item.product.id) {
                            form_data.append('products[' + index + '][productId]', item.product.id);
                        }
                    });
                    break;

                case 'video':
                    if (!post.video_data.youtube_url) return;
                    form_data.append('resource_origin', 'youtube');
                    form_data.append('link', post.edit_post.draft_media_url ? post.edit_post.draft_media_url : post.video_data.youtube_url);
                    form_data.append('scraped', post.video_data.post_scraped);

                    post.video_data.tagged_list.forEach((item, index) => {
                        if (item.product.id) {
                            form_data.append('products[' + index + '][productId]', item.product.id);
                        }
                    });
                    break;

                case 'tiktok':
                    if (!post.tiktok_data.video_url && !post.edit_post) return;
                    form_data.append('resource_origin', 'tiktok');
                    form_data.append('link', post.edit_post.draft_media_url ? post.edit_post.draft_media_url : post.tiktok_data.video_url);
                    form_data.append('scraped', post.tiktok_data.post_scraped);
                    form_data.append('thumbnail_url', post.edit_post.draft_thumbnail_url ? post.edit_post.draft_thumbnail_url : post.tiktok_data.thumbnail_url);
                    form_data.append('thumbnail_width', post.edit_post.draft_thumbnail_width ? post.edit_post.draft_thumbnail_width : post.tiktok_data.thumbnail_width);
                    form_data.append('thumbnail_height', post.edit_post.draft_thumbnail_height ? post.edit_post.draft_thumbnail_height : post.tiktok_data.thumbnail_height);
                    form_data.append('payload', JSON.stringify(post.edit_post.tiktok_payload ? post.edit_post.tiktok_payload : post.tiktok_data.payload));

                    post.tiktok_data.tagged_list.forEach((item, index) => {
                        if (item.product.id) {
                            form_data.append('products[' + index + '][productId]', item.product.id);
                        }
                    });
                    break;

                case 'image':
                    if (!post.image_data.result_image) return;
                    form_data.append('resource_origin', 'native');
                    if (post.image_data.result_image.substring(0, 4) !== 'http')
                        form_data.append('photos[]', dataURLtoFile(post.image_data.result_image));

                    post.image_data.tagged_list.forEach((item, index) => {
                        form_data.append('products[' + index + '][productId]', item.product.id);
                    });
                    break;

                case 'thisorthat':
                    form_data.append('resource_origin', 'thisorthat');

                    if (post.thisorthat_data.left_image_file && post.thisorthat_data.left_image_file.substring(0, 4) !== 'http') {
                        form_data.append('photos[]', dataURLtoFile(post.thisorthat_data.left_image_file));
                    }
                    if (post.thisorthat_data.right_image_file && post.thisorthat_data.right_image_file.substring(0, 4) !== 'http') {
                        form_data.append('photos[]', dataURLtoFile(post.thisorthat_data.right_image_file));
                    }

                    if (post.thisorthat_data.tot_product_ids[0] && post.thisorthat_data.tot_product_ids[0] !== undefined) {
                        form_data.append('tot_product_ids[]', post.thisorthat_data.tot_product_ids[0]);
                        form_data.append('tot_product_ids[]', post.thisorthat_data.tot_product_ids[1]);
                    }

                    post.thisorthat_data.tagged_list.forEach((item, index) => {
                        form_data.append('products[' + index + '][productId]', item.product.id);
                    });
                    break;
            }
            // this.$store.state.show_loading = true;

            this.$ajax('/draft/create', {
                method: 'post',
                withCredentials: true,
                data: form_data,
            }).then(res => {
                if (res.data.status === 'success') {
                    this.$store.state.show_loading = false;
                    // console.log(this.$cookie.get('draft_back_url'))
                    if (post.is_confirm === 1) {
                        if (this.$route.name === 'create-post' || this.$route.name === 'create-post-action') {
                            let els = document.querySelectorAll('.social-post-list .draft-post')
                            if (!els || els.length === 0) {
                                let exit_url = '/';
                                if (this.$store.state.create.exit_url) exit_url = this.$store.state.create.exit_url;
                                window.location.replace(exit_url);
                            }
                        }
                    }
                    // if (post.is_confirm === 1) {
                    //     // window.location.replace('/create-post/draft');
                    //     if (this.$cookie.get('draft_back_url')) {
                    //         window.location.replace(this.$cookie.get('draft_back_url'));
                    //         // this.$router.replace({ path: this.$cookie.get('draft_back_url') });
                    //         this.$cookie.remove('draft_back_url');
                    //     } else {
                    //         window.location.replace({ path: `/me/posts` });
                    //     }
                    // }
                } else {
                    alert('error ' + res.data.status)
                }
            }).catch(err => {
                // this.$store.state.show_loading = false;
            });
        });

        EventBus.$on('delete-draft', draft_id => {
            this.$ajax('/draft/delete', {
                method: 'post',
                withCredentials: true,
                data: {
                    draft_id: draft_id,
                }
            }).then(res => {
                // console.log(this.$route.name)

                if (this.$route.name === 'create-post' || this.$route.name === 'create-post-action') {
                    let els = document.querySelectorAll('.social-post-list .draft-post')
                    if (!els || els.length === 0) {
                        let exit_url = '/';
                        if (this.$store.state.create.exit_url) exit_url = this.$store.state.create.exit_url;
                        window.location.replace(exit_url);
                    }
                }
            })
                .catch(error => {
                    if (this.$router.path !== '/create-post/draft')
                        window.location.replace('/');
                });
        });

        EventBus.$off('open-repost-modal');
        EventBus.$on('open-repost-modal', obj => {
            this.openRepostModal(obj)
        });

        if (window.localStorage.getItem('created_post') !== null || window.localStorage.getItem('edited_post') !== null) {

            if (window.localStorage.getItem('created_post') !== null) {
                this.tooltip_text = `<span style="font-weight: bold;">Post created </span>`;
            } else {
                this.tooltip_text = `<span style="font-weight: bold;">Post is updated </span>`;
            }

            this.tooltip_color = 'primary';

            window.localStorage.removeItem('edited_post');
            window.localStorage.removeItem('created_post');

            this.$notify({
                text: this.tooltip_text,
                type: this.tooltip_color,
                duration: 5000,
            });
        }

        let token = window.MOTOM.IG_ACCESS_TOKEN;
        window.__igEmbedLoaded = function (loadedItem) {
            if (token === 'null') return;

            let $el = document.querySelector('#' + loadedItem.frameId);
            if ($el !== null) {
                let $parent = $el.parentNode;
                let link = $parent.dataset.link;
                if (typeof link === 'undefined') return;
                let url = `https://graph.facebook.com/v3.2/instagram_oembed?omitscript=true&hidecaption=true&url=${link}&access_token=${token}`;

                self.$ajax.get(url).then(res => {
                    let r = (res.data.thumbnail_height / res.data.thumbnail_width);
                    if (
                        (res.data.thumbnail_height === 853 && res.data.thumbnail_width === 480) ||
                        ((res.data.thumbnail_height === 1136 || res.data.thumbnail_height === 1137 || res.data.thumbnail_height === 1138) && res.data.thumbnail_width === 640)
                    ) {
                        r = 1.245;
                    }
                    $parent.querySelector('.bg').style.paddingBottom = 'calc(54px + ' + 100 * r + '%)';

                    EventBus.$emit('render-ig', {
                        post_id: $el.dataset.postId,
                    });
                }).catch(err => {
                    console.log('ig api error => ', err.response ? err.response.data : '');

                    let $el = document.querySelector('#' + loadedItem.frameId);
                    let inx = 0;

                    while ($el.parentNode) {
                        inx++;
                        if (inx > 5 || !$el || $el === null) break;

                        if ($el !== null && $el.classList.contains('post--instagram')) {
                            EventBus.$emit('disable-post', {
                                post_id: $el.dataset.postId,
                            });
                        } else {
                            $el = $el.parentNode;
                        }
                    }
                });
            }
        };

        this.checkDeviceType();
        if (window.location.pathname.indexOf('/create-post') > -1 || window.location.pathname.indexOf('/page/') > -1) {
            this.is_show_draft_modal = false;
        } else {
            if (this.$store.state.is_logged) this.getLastDraft();
        }

        window.addEventListener('resize', this.debounce(this.checkDeviceType));
        window.addEventListener('scroll', this.onScroll);
    },


}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import '../resources/sass/frontend/_reset.scss';

$mobile_size: 680px;
$tablet_size: 1100px;
$laptop_size: 1540px;

:root {
    --font: 'Kanit', sans-serif;
    --font-dm: 'DM Serif Display', sans-serif;

    --sidebar-width: 310px;
    --collapsed-sidebar-width: 74px;
    --post-max-width: 496px;
    --modal-max-width: 488px;
    --overlay-max-width: 746px;
    --pick-brand-width: 1000px;
    --normal-width: 820px;
    --setting-width: 552px;
    --max-search-input: 780px;
    --max-product-card: 250px;

    // https://project42.atlassian.net/browse/P42-7918

    --PB-100: #232a60;
    --PB-80: #2f398b;
    --PB-60: #505bbc;
    --PB-40: #7c85ce;
    --PB-20: #abb1e0; //#8592B2 had replace
    --Brand-Primary: var(--PB-100);
    --Brand-Orange: #FAA378;
    --Brand-purple: #7E86BF;
    --Brand-grey: #EFEFEF;

    --Red-10: #FFEBEB;
    --Red-30: #F22028;
    --Yellow-10: #FFF5D5;
    --Yellow-30: #FFC107;
    --Blue-10: #D3E1FE;
    --Blue-30: #007BFF;
    --Green-10: #E8F8F2;
    --Green-30: #00A870;

    --Black-100: #000000;
    --Black-90: #333333;
    --Black-75: #58595B;
    --Black-60: #666666;
    --Black-50: #828282;
    --Black-40: #BDBDBD;
    --Black-20: #E0E0E0; //ccc//ddd
    --Black-10: #E5E5E5;
    --Black-05: #F2F2F2; //f8f8f8
    --Black-03: #fafafa;
    --Black-00: #fff;

    --Black-100-rgba: 0, 0, 0;
    --Black-90-rgba: 51, 51, 51;
    --Black-50-rgba: 130, 130, 130;
    --Black-00-rgba: 255, 255, 255;

    --Neu-00: #fff;
    --Neu-10: #F6F8FC;
    --Neu-20: #F1F4F9;
    --Neu-30: #E2E8F0;
    --Neu-40: #CBD4E1;
    --Neu-50: #94A3B8;
    --Neu-60: #64748B;
    --Neu-70: #475569;
    --Neu-80: #27364B;
    --Neu-90: #1E2A3B;
    --Neu-100: #0F1A2A;

    --lb-default: #fff;
    --lb-1: #F6F0E7;
    --lb-2: #F4F4AB;
    --lb-3: #F6E5A5;
    --lb-4: #EFB178;
    --lb-5: #ECCDB0;
    --lb-6: #FFC3C3;
    --lb-7: #F5DADF;
    --lb-8: #C7CDFF;
    --lb-9: #BAE6FF;
    --lb-10: #D3F0FF;
    --lb-11: #BDE9C9;
    --lb-12: #BFFFEC;
    --lb-13: var(--Surface-default);
    --lb-14: #BDBDBD;

    --Spotify: #1DB954;
    --Facebook-new: #1877F2;
    --Facebook-old: #3B5998;
    --Insta: #c32aa3;
    --Pinterest: #E60023;
    --Twitter: #1DA1F2;
    --X-twitter: #000000;
    --YouTube: #FF0000;
    --Snapchat: #FFFC00;
    --Google: #0F9D58;
    --Linktree: #3DDC84;
    --Apple-iOS: #8E8E93;
    --Win: #0078D4;
    --TikTok-Pink: #EE1D52;
    --TikTok-Blue: #69C9D0;
    --TikTok-dark: #010101;

    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // https://project42.atlassian.net/browse/P42-7918
    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    --Text-brand: var(--PB-100);
    --Text-primary: var(--Black-90);
    --Text-secondary: var(--Black-75);
    --Text-default: var(--Black-50);
    --Text-disabled: var(--Black-40);
    --Text-invert: var(--Black-00);
    --Text-element1: #f16529;
    --Text-danger: var(--Red-30);
    --Text-warning: var(--Yellow-30);
    --Text-safe: var(--Green-30);
    --Text-link: var(--Blue-30);

    --Border-primary: var(--PB-100);
    --Border-secondary: var(--Brand-Orange);
    --Border-dark: var(--Black-90);
    --Border-light: var(--Black-00);
    --Border-default: var(--Black-10);
    --Border-hover: var(--Black-10);
    --Border-disable: var(--Black-20);
    --Border-neu: var(--Neu-40);
    --Border-danger: var(--Red-30);
    --Border-Warning: var(--Yellow-30);
    --Border-safe: var(--Green-30);

    --Surface-primary: var(--PB-100);
    --Surface-primary-hover: var(--Neu-70);
    --Surface-secondary: var(--Brand-Orange);
    --Surface-dark: var(--Black-90);
    --Surface-light: var(--Black-00);
    --Surface-neu: var(--Neu-30);
    --Surface-alert: var(--Red-30);
    --Surface-default: var(--Black-05);
    --Surface-hover: var(--Black-10);
    --Surface-disable: var(--Black-20);
    --Surface-tertiary-selected: var(--Neu-20);
    --Surface-danger: var(--Red-10);
    --Surface-safe: var(--Green-10);
    --Surface-warn: var(--Yellow-10);
    --Surface-normal: var(--Blue-10);

    --Bg-dark: var(--Black-90);
    --Bg-light: var(--Black-00);
    --Bg-01: var(--Black-03);
    --Bg-02: var(--Black-05);
    --Bg-03: var(--Black-10);

    // shadow https://www.figma.com/file/OZYT5l0h3pSMxgtBh722ff/%F0%9F%A7%A7-%E3%80%90v-2.0.0%E3%80%91-Design-Library---Basic?node-id=891%3A10101
    --bot-1dp: 0px 1px 2px 0px rgba(0, 0, 0, .1);
    --bot-2dp: 0px 2px 4px 0px rgba(0, 0, 0, .1);
    --bot-4dp: 0px 4px 8px 0px rgba(0, 0, 0, .1);
    --bot-6dp: 0px 6px 8px 0px rgba(0, 0, 0, .1);

    --top-1dp: 0px -1px 2px 0px rgba(0, 0, 0, .1);
    --top-2dp: 0px -2px 4px 0px rgba(0, 0, 0, .1);
    --top-4dp: 0px -4px 8px 0px rgba(0, 0, 0, .1);
    --top-6dp: 0px -6px 8px 0px rgba(0, 0, 0, .1);

    --right-1dp: 1px 0px 2px 0px rgba(0, 0, 0, .1);
    --right-2dp: 2px 0px 4px 0px rgba(0, 0, 0, .1);
    --right-4dp: 4px 0px 8px 0px rgba(0, 0, 0, .1);
    --right-6dp: 6px 0px 8px 0px rgba(0, 0, 0, .1);

    --left-1dp: -1px 0px 2px 0px rgba(0, 0, 0, .1);
    --left-2dp: -2px 0px 4px 0px rgba(0, 0, 0, .1);
    --left-4dp: -4px 0px 8px 0px rgba(0, 0, 0, .1);
    --left-6dp: -6px 0px 8px 0px rgba(0, 0, 0, .1);

    --card: 0px 2px 4px 0px rgba(0, 0, 0, .1);
    --pop-medium: 2px 4px 30px 1px rgba(0, 0, 0, .15);
    --pop-light: 2px 4px 20px 0px rgba(0, 0, 0, .1);
    --neno-01: 0px 4px 8px 0px rgba(241, 101, 41, 0.35), 1px 2px 4px 0px rgba(249, 163, 38, 0.32);

    --mbg-bttb: 348.82deg, var(--Red-30) 8.25%, var(--Yellow-30) 91.52%;
    --mbg-bttf: 102.8deg, var(--Red-30) 6.94%, var(--Yellow-30) 91.73%;
    --mbg-bttl: 168.82deg, var(--Red-30) 11.16%, var(--Yellow-30) 91.53%;

    --font-xxxl: 32px;
    --font-xxl: 24px;
    --font-xl: 22px;
    --font-l: 20px;
    --font-md: 18px;
    --font-m: 16px;
    --font-s: 14px;
    --font-xs: 12px;
    --font-xxs: 10px;

    --font-lh-xxxl: 34px;
    --font-lh-xxl: 26px;
    --font-lh-xl: 24px;
    --font-lh-l: 22px;
    --font-lh-md: 20px;
    --font-lh-m: 18px;
    --font-lh-s: 16px;
    --font-lh-xs: 14px;
    --font-lh-xxs: 12px;

    --font-semi: 600;
    --font-medium: 500;
    --font-regular: 400;
    --font-light: 300;

    // https://project42.atlassian.net/browse/P42-7905
    --heading-0: var(--font-semi) var(--font-xxl)/var(--font-lh-xxl) var(--font);
    --heading-1: var(--font-semi) var(--font-xl)/var(--font-lh-xl) var(--font);
    --heading-2: var(--font-semi) var(--font-l)/var(--font-lh-l) var(--font);
    --heading-3: var(--font-semi) var(--font-md)/var(--font-lh-md) var(--font);
    --heading-4: var(--font-semi) var(--font-m)/var(--font-lh-m) var(--font);
    --heading-5: var(--font-semi) var(--font-s)/var(--font-lh-s) var(--font);
    --heading-6: var(--font-semi) var(--font-xs)/var(--font-lh-xs) var(--font);

    --sub-0: var(--font-medium) var(--font-xxl)/var(--font-lh-xxl) var(--font);
    --sub-1: var(--font-medium) var(--font-xl)/var(--font-lh-xl) var(--font);
    --sub-2: var(--font-medium) var(--font-l)/var(--font-lh-l) var(--font);
    --sub-3: var(--font-medium) var(--font-md)/var(--font-lh-md) var(--font);
    --sub-4: var(--font-medium) var(--font-m)/var(--font-lh-m) var(--font);
    --sub-5: var(--font-medium) var(--font-s)/var(--font-lh-s) var(--font);
    --sub-6: var(--font-medium) var(--font-xs)/var(--font-lh-xs) var(--font);
    --sub-7: var(--font-medium) var(--font-xxs)/var(--font-lh-xxs) var(--font);

    --text-l-regular: var(--font-regular) var(--font-m)/var(--font-lh-m) var(--font);
    --text-md-regular: var(--font-regular) var(--font-s)/var(--font-lh-s) var(--font);
    --text-s-regular: var(--font-regular) var(--font-xs)/var(--font-lh-xs) var(--font);
    --text-l-light: var(--font-light) var(--font-m)/var(--font-lh-m) var(--font);
    --text-md-light: var(--font-light) var(--font-s)/var(--font-lh-s) var(--font);
    --text-s-light: var(--font-light) var(--font-xs)/var(--font-lh-xs) var(--font);

    --span-semi: var(--font-semi) var(--font-xxs)/var(--font-lh-xxs) var(--font);
    --span-medium: var(--font-medium) var(--font-xxs)/var(--font-lh-xxs) var(--font);
    --span-regular: var(--font-regular) var(--font-xxs)/var(--font-lh-xxs) var(--font);
    --span-light: var(--font-light) var(--font-xxs)/var(--font-lh-xxs) var(--font);

    --display-1: var(--font-regular) var(--font-xxxl)/var(--font-xxxl) var(--font-dm);
    --display-2: var(--font-regular) var(--font-xxl)/var(--font-xxl) var(--font-dm);
    --display-3: var(--font-regular) var(--font-l)/var(--font-l) var(--font-dm);
    --display-4: var(--font-regular) var(--font-md)/var(--font-md) var(--font-dm);
    --display-5: var(--font-regular) var(--font-m)/var(--font-m) var(--font-dm);

    --spacing-01: 2px;
    --spacing-02: 4px;
    --spacing-03: 8px;
    --spacing-04: 12px;
    --spacing-05: 16px;
    --spacing-06: 24px;
    --spacing-07: 32px;
    --spacing-08: 36px;
    --spacing-09: 40px;
    --spacing-10: 48px;
    --spacing-11: 64px;

    --radius-minimal: 2px;
    --radius-small: 4px;
    --radius-medium: 8px;
    --radius-large: 12px;

    --mobile_size: 680px;
    --tablet_size: 1100px;
    --laptop_size: 1540px;
}

html {
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size: 10px;
    min-height: 100vh;
}

body {
    font: var(--text-md-regular);

    /* Font smoothing */
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: var(--font-s);

    background-color: var(--Bg-01);
    min-height: 100%;

    &.disabled-scroll {
        overflow: hidden !important;
    }
}

* {
    box-sizing: border-box;
}

.bottom-space {
    width: 100%;
    height: 80px;
    display: flex;
}

.vue-notification-group {
    bottom: 0 !important;
    right: 6px !important;
    width: auto !important;
    max-width: calc(100% - 32px);
    transition: bottom .2s ease;
    z-index: 10000000 !important;

    &>span {
        position: relative;
        padding: 0 20px;
    }

    @media (max-width: $mobile_size) {
        .show-header .global-menu+& {
            bottom: 9.2rem !important;
        }
    }

}


.ajax-notification {
    font: var(--text-md-regular);
    color: var(--Text-brand);
    bottom: 0;
    cursor: pointer;
    background: var(--Green-10);
    left: 0;
    margin: 0 10px 8px 10px;
    border-radius: 6px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20);
    position: relative;

    &.type-black {
        background-color: var(--Bg-dark);
        color: var(--Text-invert);
    }

    &.type-error {
        background-color: var(--Red-10);
        color: var(--Text-brand);

        svg {
            fill: var(--Text-brand);
        }

        a {
            color: var(--Text-link);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.type-success {
        background-color: var(--Green-10);
        color: var(--Text-brand);

        svg {
            fill: var(--Text-brand);
        }
    }

    &.type-red {
        background-color: var(--Red-10);
        color: var(--Text-primary);
    }

    &.type-alert {
        background-color: var(--Surface-alert);
        color: var(--Text-invert);
    }

    .row {
        display: flex;
        align-items: center;
    }

    .svg-icon {
        height: 14px;
        width: 14px;
        position: absolute;

        &+.title,
        &+.text,
        &+.text+.text,
        &+.title+.text {
            margin-left: 20px;
        }
    }

    .link {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.important-updates-modal {
    .m-modal--container {
        padding: var(--spacing-06);
        max-width: 330px;
        text-align: center;
        color: var(--Text-primary);

        h2 {
            font: var(--sub-5);
        }

        p  {
            font: var(--sub-2);
            margin: 8px 0 32px;
        }

        .m-button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .svg-icon--logo-m {
        height: 120px;
        width: 120px;
        margin: 0px auto 52px;
    }

    .m-modal--close {
        position: absolute;
        top: 2px;
        left: -4px;
        color: var(--Text-default);
        cursor: pointer;
        height: 24px;
        width: 24px;
        right: auto;

        svg {
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}

.page-modal {
    z-index: 199999999999;
    
    .m-modal--header {
        justify-content: center;
    }

    .content {
        max-height: calc(80vh - env(safe-area-inset-top));
        overflow: auto;
        padding: 0 16px;

        color: var(--Text-primary);
        font: var(--text-md-regular);
        
        h2 {
            font: var(--heading-3);
            margin-bottom: 16px;
        }

        h3 {
            font: var(--heading-4);
            margin-bottom: 16px;
        }

        h4 {
            font: var(--heading-5);
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 16px;
        }

        .section-header {
            display: none;
        }
    }

    footer {
        padding: 16px;

        .m-button {
            display: flex;
        }
    }
}

.draft-modal {
    .m-modal--container {
        padding: var(--spacing-06);
        max-width: 300px;
        text-align: center;
    }

    .m-modal--header {
        height: 0;
        padding: 0;
        min-height: unset;
    }

    .m-modal--close {
        top: -8px;
        right: -16px;
    }

    .content {
        margin: 24px 0;

        h2 {
            font-size: var(--font-m);
            font-weight: var(--font-medium);
            color: var(--Text-primary);
            margin-bottom: 10px;
        }

        p {
            font-size: var(--font-xs);
            font-weight: var(--font-light);
            color: var(--Text-secondary);
        }
    }

    footer {
        .m-button {
            margin: 0 10px;
        }
    }

    .continue {
        margin: 12px 0 0;

        span {
            cursor: pointer;
            color: var(--Text-link);
            font-weight: var(--font-light);
        }
    }
}

.profile-menu-box {
    .profile-menu {
        padding-top: 0;
    }
    
    @media (min-width: 580px) {
        .ui-popup-menu--container {
            width: var(--modal-max-width);
        }
    }
}

.profile-menu {
    padding: var(--spacing-04) var(--spacing-03);
    background: var(--Bg-light);

    &::before {
        content: '';
        height: 10px;
        width: 90%;
        display: block;
        clear: both;
        position: absolute;
        top: -10px;
        right: 0;
    }

    .hr{
        background:var(--Border-neu);
        height: 1px;
        width: 100%;
    }

    .btn-flex-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 8px;

        .m-button.primary {
            margin-right: var(--spacing-04);
        }
    }

    li {
        color: var(--Text-secondary);
        cursor: pointer;

        a,
        .be-a-a {
            display: flex;
            align-items: center;
            padding: 0 var(--spacing-03);
            height: 40px;
            color: var(--Text-primary);
            cursor: pointer;
            transition: .2s all ease;

            &:hover {
                background-color: var(--Neu-10);
                color: var(--Text-brand);
            }

            svg {
                &:first-child {
                    margin-right: 4px;
                    height: 20px;
                    width: 20px;
                }

                //special icon
                &.svg-icon--user {
                    width: 22px;
                    height: 22px;
                    margin-left: -2.5px;
                    margin-right: 4.3px;
                }

                &.svg-icon--user-followed {
                    width: 17px;
                    height: 17px;
                    margin-left: 2.2px;
                    margin-right: 4.5px;
                }

            }
        }

        &:hover {
            color: var(--Text-primary);
        }
    }
}

.show-last-draft-post {
    padding: unset;

    .draft-post--footer {
        display: none;
    }
}

#mobile-wrap {
    &.motom-app .back-header.hashtag-menu {
        padding-top: env(safe-area-inset-top);
        height: calc(7.8rem + env(safe-area-inset-top));
    }

    &.page--register,
    &.page--home-email-login {
        .header .right {
            display: none !important;
        }
    }
}

// .tiktok-alert {
//     background-color: var(--Black-90);
//     color: white;
//     display: flex;
//     padding: 12px;
//     align-items: center;

//     & > svg {
//         height: 16px;
//         width: 16px;
//         min-width: 16px;
//         margin-right: 8px;
//         fill: white;
//     }

//     p {
//         font: var(--text-md-regular);
//         margin: 0;
//         flex: 1;
//     }

//     .close {
//         cursor: pointer;
//         margin-left: 10px;
//         height: 16px;
//         width: 16px;

//         svg {
//             height: 100%;
//             width: 100%;
//             fill: white;
//         }
//     }
// }

.tiktok-banned-modal {
    z-index: 199999999999;

    .m-modal--header {
        display: none;
    }

    .m-modal--container {
        max-width: 380px;
    }

    .m-modal--content {
        padding: 24px;

        i {
            display: block;
            height: 68px;
            width: 68px;
            margin-bottom: 24px;
            color: var(--Red-30);

            svg {
                display: block;
                height: 100%;
                width: 100%;
            }
        }

        h2 {
            font: var(--sub-3);
            margin-bottom: 16px;
            color: var(--Text-primary);
        }

        p {
            font: var(--text-md-regular);
            color: var(--Text-secondary);
            margin-bottom: 24px;
        }

        .m-button {
            display: flex;
            justify-content: center;

            & + .m-button {
                margin-top: 8px;
            }
        }
    }
}


.motom-app {
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

    .invite-container .back-header,
    .popup-posts--header,
    .popup-tiktok--header,
    .header {
        padding-top: env(safe-area-inset-top);
        height: calc(48px + env(safe-area-inset-top)) !important;
    }

    &#mobile-wrap.page--create .back-header {
        top: env(safe-area-inset-top);
    }

    .global-menu {
        height: 62px;
        padding-bottom: 12px;
    }

    .merchant--body {
        &::after {
            content: '';
            height: 24px;
            width: 100%;
            display: block;
            clear: both;
            background-color: white;
        }
    }

    .modal-login .login-content .top {
        padding-top: calc(7.5vh + env(safe-area-inset-top));
    }

    .modal-login .close {
        top: calc(6px + env(safe-area-inset-top));
    }

    .register-settings-page--footer,
    .profile-menu-box .profile-menu,
    .switch-to-creator .connect footer,
    .switch-to-creator .desc footer,
    .register-complete-wrap .btns,
    .profile-menu,
    .register-page--footer,
    .submit-row,
    .edit-popup-menu--footer,
    .popup-storefront--footer,
    .m-modal.type-dialog footer,
    .likeboard-info--footer,
    .more-popup-menu--footer {
        padding-bottom: 32px;
    }

    .popup-posts--header .close {
        margin-top: env(safe-area-inset-top);
        top: 14px;
        transform: translateY(0);
    }

    .select-brands--container {
        grid-template-rows: auto auto auto 1fr auto;
    }

    .select-brands--container,
    .global-search-bar .box,
    .global-navi .inner {
        &::before {
            content: '';
            height: env(safe-area-inset-top);
            clear: both;
            display: block;
            position: relative;
            z-index: 1001;
            background: var(--Bg-light);
        }
    }

    &#mobile-wrap .brand-products,
    .pin-product,
    .like-products,
    .search-web-products,
    .create-main,
    .like-products-sub,
    .all-brands-page,
    .tag-products {
        &::before {
            content: '';
            height: env(safe-area-inset-top);
            clear: both;
            display: block;
            position: sticky;
            z-index: 1001;
            background: var(--Bg-light);
            top: 0;
        }
    }

    .full>.add-container>.like-products::before {
        display: none;
    }

    &.page--productposts .product-info {
        top: calc(40px + env(safe-area-inset-top));
    }

    &.page--private-profile,
    &.page--public-profile {
        .user-profile-menu {
            top: calc(40px + env(safe-area-inset-top));
        }

        .user-profile-sub-menu {
            top: calc(40px + 42px + env(safe-area-inset-top));
        }
    }

    .global-navi .inner {
        grid-template-rows: auto auto 1fr auto;
    }

    .global-navi .inner::before {
        background: var(--Black-20);
    }

    .global-search-bar .box::before {
        background: var(--Brand-Primary);
    }


    .site-navbar {
        top: calc(48px + env(safe-area-inset-top));
    }

    &.page--create .back-header {
        top: env(safe-area-inset-top);
    }
}

@media (min-width: $mobile_size) {
    :root {
        --sidebar-width: 290px;
    }
}



// @media (min-width: $tablet_size) {

// }

select,
textarea,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="submit"] {
    // font: var(--text-md-regular);
    // border-radius: 0;
    -webkit-appearance: none;

    &:focus {
        outline: none
    }
}

a {
    text-decoration: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.confirm-modal {
    .modal--header {
        padding: var(--spacing-05);
    }
}

.is-desktop-item {
    display: none !important;
}

.is-mobile-item {
    display: block !important;
}

#mobile-wrap.is-app-landing {
    .header .logo a {
        pointer-events: none;
    }

    .header .left,
    .back {
        display: none;
    }

    &.page--reset-password {

        .header .left,
        .back {
            display: block;
        }
    }
}


#mobile-wrap.page--register-register,
#mobile-wrap.page--login {
    .header .right .ui-button {
        display: none !important;
    }
}

@media (min-width: $mobile_size) {

    .likeboard-layout img,
    .new-post--image-item img,
    .post--image-item img .tot-container--img-bg,
    .tot-container--img-outer .tot-container--img,
    .related-list .related-product-item .image img,
    .image-section .image img,
    .tiktok-player .bg-image img,
    .product-item .box img,
    .product-card .box img {
        pointer-events: none;
    }

    #mobile-wrap.page--private-profile,
    #mobile-wrap.page--public-profile,
    #mobile-wrap.page--brand-profile,
    #mobile-wrap.page--trending-product,
    #mobile-wrap.page--trending-post {
        .container-inner {
            width: 100%;
            max-width: var(--normal-width);
            margin-left: auto;
            margin-right: auto;
        }
    }


    #mobile-wrap.page--private-profile-settings-settings-account-deactivate .footer,
    html body .profile-settings--edit-login .footer-btn,
    #mobile-wrap .product-details .actions-container,
    body .profile-settings-brand .footer-btn,
    html body .submit-row {
        max-width: unset;
        left: var(--sidebar-width);
        width: auto;
        right: 0;
        transition: left .2s ease-in-out;

        .large-button {
            max-width: 520px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    html.collapse-desktop-navi #mobile-wrap.page--private-profile-settings-settings-account-deactivate .footer,
    html.device-is-tablet #mobile-wrap.page--private-profile-settings-settings-account-deactivate .footer,
    html.device-is-tablet #mobile-wrap.is-home::before,
    html.collapse-desktop-navi #mobile-wrap.is-home::before {
        left: var(--collapsed-sidebar-width);
    }

    html.device-is-tablet .profile-settings--edit-login .footer-btn,
    html.collapse-desktop-navi .profile-settings--edit-login .footer-btn,
    html.device-is-tablet #mobile-wrap .product-details .actions-container,
    html.device-is-tablet body .profile-settings-brand .footer-btn,
    html.device-is-tablet body .submit-row,
    html.collapse-desktop-navi #mobile-wrap .product-details .actions-container,
    html.collapse-desktop-navi body .profile-settings-brand .footer-btn,
    html.collapse-desktop-navi body .submit-row,
    html.collapse-desktop-navi body .top-error-msg {
        left: var(--collapsed-sidebar-width);
    }

    .profile-settings--box .post {
        max-width: unset;
    }

    body #mobile-wrap.page--globalsearch .ui-list {
        margin-left: auto;
        margin-right: auto;
    }

    body .home-content .post-filters,
    body .container .header-wrapper {
        width: var(--modal-max-width);
        margin: 0 auto;
    }

    body .container .header-wrapper,
    body .container .hot-products,
    body .ui-popup-menu--container,
    body #mobile-wrap.page--globalsearch .total-results,
    .filters-wrapper ul,
    .earnings-container .overview,
    .earnings-sales-container .overview,
    .earnings-product-container .overview,
    .metrics-container .back-header+div,
    .profile-settings--box,
    body .hero-content,
    .post-list,
    // .user-profile-menu ul,
    body .profile-settings,
    .home-content {
        //max-width: var(--modal-max-width);
        margin-left: auto;
        margin-right: auto;
    }

    body .container .menus {
        left: calc(50vw + var(--sidebar-width)/2);
    }

    .explain-piggy-modal .modal-wrapper {
        padding-left: var(--sidebar-width);
        transition: padding-left .2s ease-in-out;
    }

    .is-desktop-item {
        display: block !important;
    }

    .is-mobile-item {
        display: none !important;
    }

    html .product-details {
        padding-left: var(--sidebar-width);
        transition: padding-left .2s ease-in-out;
    }

    html.device-is-tablet .product-details,
    html.collapse-desktop-navi .product-details {
        padding-left: var(--collapsed-sidebar-width);
    }

    html.device-is-desktop .login-footer-wrap,
    html.device-is-tablet .login-footer-wrap {
        display: none
    }

    #mobile-wrap {
        &.page--switch-to-creator,
        &.page--login,
        &.page--register,
        &.page--reset-password,
        &.page--register-settings,
        &.page--home-email-login-home-email-login {



            .header .back-wrap + .search-icon,
            .header .logo span,
            .global-navi {
                display: none;
            }

            .header {
                box-shadow: none !important;

                .logo {
                    left: 50%;
                    width: 110px;
                    transform: translateX(-50%);
                    position: absolute;
                }

                .left {
                    padding-left: 0;

                    .m-search {
                        display: none;
                    }
                }
            }
        }

        &.page--login .login-page-wrap .login-modal {
            max-width: unset;
        }

    }

}

.tippy-content {
    font-size: var(--font-s);

    a {
        color: var(--Text-invert);
        text-decoration: underline;
    }
}

//if you need to show,open in that template,or other template will show this
.swiper-pagination-bullet {
    display: none !important;
}

@media (max-width: $mobile_size ) {
    .tippy-popper {
        opacity: 0;
    }
}

.image-placeholder {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--Black-10);
    z-index: 1;
    overflow: hidden;
    left: 0;
    top: 0;

    &::before {
        position: absolute;
        left: -45%;
        height: 100%;
        width: 45%;
        content: "";
        z-index: -1;
        pointer-events: none;
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
        animation: loading 1s infinite
    }

    img {
        background-color: transparent !important;
    }
}

@keyframes loading {
    0% {
        left: -45%
    }

    100% {
        left: 100%
    }
}

// .page--likeboards-home-likeboards {
//     .container {
//         height: 100vh;
//         margin-top: 36px;
//     }

//     .header {
//         position: fixed;
//         width: 100vw;
//         height: 40px;
//     }
// }
</style>
