<template>
    <div :class="classnames">

        <label class="ui-label" v-if="label" :fro="type + '-' + name">
            <span class="label-text">
                {{ label }}<sup v-if="is_need">*</sup>
            </span>
            <slot name="label"></slot>
        </label>
        <slot name="text"></slot>

        <div class="input-box" @mouseover="is_hover = true" @mouseleave="is_hover = false">
            <InputBox
                v-if="type === 'text' || type === 'textarea' || type === 'number' || type === 'email' || type === 'password'"
                :is_disabled="is_disabled" :input_value="input_value" :placeholder="placeholder" :type="type" @focus="specFocus"
                :error="display_error" @changeInput="changeInput" @handleFocus="handleFocus" @handleBlur="handleBlur" :is_password="is_password" :rows="rows" :name="type + '-' + name"
                :char_limit="char_limit" />

            <InputDatePicker v-if="type === 'date'" :is_disabled="is_disabled" :input_value="input_value"
                :date_format="date_format" :error="display_error" @changeInput="changeDate" @handleFocus="handleFocus"
                :range="range" />

            <InputCode v-if="type === 'code'" @changeInput="changeInput" @validateCode="validateCode"
                :error="display_error" />

            <InputSelect v-if="type === 'select'" @handleFocus="handleFocus" @changeSelect="changeSelect" :datas="datas"
                :name="type + '-' + name" :input_value="input_value"
                :default_text="placeholder ? placeholder : '---Select---'" />
        </div>

        <p v-if="display_error" class="error-text" v-html="error_text"></p>
        <p v-if="display_success" class="success-text">{{ success_text }}</p>
    </div>
</template>

<script>
// import moment from 'moment';
import InputBox from '@/components/ui/Input/Input.vue';
import InputDatePicker from '@/components/ui/Input/InputDatePicker';
import InputCode from '@/components/ui/Input/InputCode';
import InputSelect from '@/components/ui/Input/InputSelect';

export default {
    name: 'm-form',

    components: {
        InputBox,
        InputDatePicker,
        InputCode,
        InputSelect
    },

    props: {
        label: {
            type: String,
            default: ''
        },

        rows: {
            type: Number,
            default: 4
        },

        is_need: {
            type: Boolean,
            default: false
        },

        is_disabled_validation: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: 'text' //number, textarea, date , select , code , search
        },

        placeholder: {
            type: String,
            default: '',
        },

        field_name: {
            type: String,
            default: '',
        },

        error_text: {
            type: String,
            default: '',
        },

        success_text: {
            type: String,
            default: '',
        },

        show_error: {
            type: Boolean,
            default: false
        },

        show_success: {
            type: Boolean,
            default: false
        },

        styles: {
            type: String,
            default: 'default' // filled, default, hover, safe, error
        },

        proertys: {
            type: String,
            default: 'outline' // outline
        },

        is_disabled: {
            type: Boolean,
            default: false
        },

        //value
        input_value: {
            type: [String, Object, Number, Date],
            default: '',
        },

        datas: {
            type: Array,
            default: function () {
                return [
                    { text: '' },
                    { text: '' }
                ];
            },
        },

        is_password: {
            type: Boolean,
            default: false
        },

        char_limit: {
            type: Number,
            default: undefined
        },

        size: {
            type: String,
            default: '40'
        },

        range: {
            type: Object,
            default: function () {
                return {
                    start_min: '2019-01-01',
                    start_max: null
                }
            },
        },

        date_format: {
            type: String,
            default: 'DD/MM/YYYY'
        }
    },

    data() {
        return {
            display_error: false,
            display_success: false,
            the_styles: this.styles,
            is_hover: false,
            had_not_key: true,
            name: Math.random().toString(36).substring(2, 8)
        }
    },

    watch: {
        show_error(val) {
            if (!this.is_disabled_validation && val) {
                this.handleError();
            }
        },
        show_success(val) {
            if (!this.is_disabled_validation && val) {
                this.handleSuccess();
            }
        },
    },

    computed: {
        classnames() {
            let arr = ['m-form', `style-${this.the_styles}`, `port-${this.proertys}`, { 'style-hover': this.is_hover }, `size-${this.size}`];
            return arr;
        }
    },

    methods: {

        handleError() {
            this.display_error = true;
            this.display_success = false;
            this.the_styles = 'error';
        },

        handleSuccess() {
            this.display_error = false;
            this.display_success = true;
            this.the_styles = 'safe';
        },

        handleDefault() {
            if (!this.show_error && !this.show_success) {
                this.display_error = false;
                this.display_success = false;
                this.the_styles = 'filled';
            } else if (this.show_error) {
                this.handleError();
            } else if (this.show_success) {
                this.handleSuccess();
            }
        },

        needValue(value) {
            if (this.is_need && value === '') {
                this.display_error = true;
                this.the_styles = 'error';
            } else if (!this.show_error) {
                this.display_error = false;
            }
        },

        changeInput(v) {
            this.had_not_key = false
            this.$emit('changeInput', { field: this.field_name, value: v.value, pos: v.pos });
            this.handleDefault();
        },

        changeDate(v) {
            this.had_not_key = false
            this.$emit('changeInput', { field: this.field_name, value: v, pos: null });
            this.handleDefault();
        },

        changeSelect(v) {
            this.$emit('changeSelect', { field: this.field_name, value: v });
            this.handleDefault();
        },

        handleBlur(v) {
            if (!this.is_disabled_validation) {
                if (!this.show_error && !this.show_success) {
                    this.the_styles = v ? 'filled' : 'default'
                }

                if (!v) {
                    this.needValue(this.input_value);
                    this.$emit('validation');
                }
            }

            this.$emit('blur');
        },

        handleFocus(v) {
            if (!this.is_disabled_validation) {
                if (!this.show_error && !this.show_success) {
                    this.the_styles = v ? 'filled' : 'default';
                }
                if (!v) {
                    this.needValue(this.input_value);
                    this.$emit('validation');
                }
            }
            this.$emit('focus');
        },

        specFocus(v){
            this.$emit('specFocus',v);
        },

        validateCode(v) {
            this.$emit('validation', v);
        }

    }


}
</script>

<style lang="scss">
.m-form {
    position: relative;

    .ui-label {
        display: flex;
        align-items: center;
        font: var(--text-md-regular);
        color: var(--Text-secondary);

        sup {
            color: var(--Text-danger);
        }

        .label-text {
            flex: 1;
        }

        i svg {
            fill: var(--Text-default);
            height: 16px;
            width: 16px;
        }

        a {
            color: var(--Text-link);
            font: var(--text-s-regular);
        }
    }

    .input-box {
        margin: 6px 0;
    }

    &.port {

        //---------------------------------
        //filled
        //---------------------------------
        &-filled {
            &.style {
                &-default {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-default);
                        border: solid 1px var(--Border-light) !important;
                        background-color: var(--Surface-default);
                    }

                    .span-date {
                        background-color: var(--Surface-default);
                    }
                }

                &-hover {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-secondary);
                        border: solid 1px var(--Border-neu);
                        background-color: var(--Surface-hover);
                    }

                    .span-date {
                        background-color: var(--Surface-hover);
                    }
                }

                &-filled {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-primary);
                        border: solid 1px var(--Border-primary) !important;
                        background-color: var(--Surface-light);
                    }

                    .span-date {
                        background-color: var(--Surface-light);
                    }
                }

                &-safe {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-secondary) !important;
                        border: solid 1px var(--Border-safe) !important;
                        background-color: var(--Surface-light) !important;
                    }

                    .span-date {
                        background-color: var(--Surface-light);
                    }
                }

                &-error {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-primary) !important;
                        border: solid 1px var(--Border-danger) !important;
                        background-color: var(--Surface-light) !important;
                    }

                    .span-date {
                        background-color: var(--Surface-light);
                    }
                }
            }

            //css same as -error
            &.show-err {

                input,
                textarea,
                .input {
                    color: var(--Text-primary) !important;
                    border: solid 1px var(--Border-danger) !important;
                    background-color: var(--Surface-light) !important;
                }

            }
        }

        //---------------------------------
        //outline
        //---------------------------------
        &-outline {
            &.style {
                &-default {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-default);
                        border: solid 1px var(--Border-default) !important;
                        background-color: var(--Surface-light);
                    }

                    .span-date {
                        background-color: var(--Surface-light);
                    }
                }

                &-hover {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-secondary);
                        border: solid 1px var(--Border-neu) !important;
                        background-color: var(--Surface-light);
                    }

                    .span-date {
                        background-color: var(--Surface-light);
                    }
                }

                &-filled {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-primary);
                        border: solid 1px var(--Border-primary) !important;
                        background-color: var(--Surface-light);
                    }

                    .span-date {
                        background-color: var(--Surface-light);
                    }
                }

                &-safe {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-secondary);
                        border: solid 1px var(--Border-safe) !important;
                        background-color: var(--Surface-light);
                    }

                    .span-date {
                        background-color: var(--Surface-light);
                    }
                }

                &-error {

                    input,
                    textarea,
                    .input {
                        color: var(--Text-primary);
                        border: solid 1px var(--Border-danger) !important;
                        background-color: var(--Surface-light);
                    }

                    .span-date {
                        background-color: var(--Surface-light);
                    }
                }
            }

            //css same as -error
            &.show-err {

                input,
                textarea,
                .input {
                    color: var(--Text-primary);
                    border: solid 1px var(--Border-danger) !important;
                    background-color: var(--Surface-light);
                }

            }
        }
    }

    &.size {
        &-24 {
            .m-select input {
                padding: var(--spacing-02);
                font: var(--text-s-regular);
                height: 24px;
            }


            .m-select ul {
                padding: var(--spacing-01);

                li {
                    padding: var(--spacing-01);
                }
            }
        }
    }

    .error-text {
        color: var(--Text-danger) !important;
        font: var(--text-s-light);
    }



    .success-text {
        color: var(--Text-safe);
        font: var(--text-s-light);
    }

}
</style>
