<template>
    <transition name="bmodal">
        <div class="bmodal" ref="modal" :class="classname">
            <div class="bmodal--wrapper">
                <div v-if="from_login" class="bmodal--container deactivate-alert">
                    <div class="bmodal--body">
                        <svg-icon name="exclaimation-white" />
                        <h4>Account Deactivated</h4>
                        <p>The account information you provided is connected to a 
                            deactivated account. Your content and data will be 
                            permanently deleted on <time>{{datetime}}</time>, 
                            but you can reactivate your account now if this was a mistake or you changed your mind.</p>
                    </div>
                    <footer class="bmodal--footer">
                        <slot name="restore-buttons"></slot>
                    </footer>
                </div>
                <div v-if="earnings.current_cash_out_balance >= 25" class="bmodal--container">

                    <div class="bmodal--body">
                        <div class="earnings-header">
                            <p>Last updated {{  moment(earnings.last_updated).format('MM/DD/YYYY') }}</p>
                            <a v-if="!from_login" @click="gotoEarningsPage">View details</a>
                        </div>
                        <p class="balance-label">Current cash-out balance</p>
                        <p class="amount">${{earnings.current_cash_out_balance}}</p>
                        <div class="cashout-note">
                            <svg-icon name="exclaimation-red" />
                            <p>You still have money in your Motom Bank! If you delete your account before you cash out, the funds will go back to Motom and the Motom Community.</p>
                        </div>
                    </div>
                    <footer class="bmodal--footer">
                        <slot name="footer"></slot>
                        <h5 style="margin-top: 12px">
                            $25 minimum balance to cash out. <i
                            class="question"
                            @click="show_explaination = true;"
                            ><svg-icon name="question-circle" /></i>
                        </h5>
                    </footer>

                </div>

                <div class="bmodal--bg"></div>
            </div>
        </div>

    </transition>
</template>

<script>
import moment from 'moment';

export default {
    name: 'balancemodal',
    props: {
        classname: Array,
        earnings: '',
        from_login: false,
        delete_date: '',
    },

    data() {
        return {
            datetime: '',
            have_fill_in_tax_form: false,
            have_payment_info: false,
        }
    },

    components: {
    },

    methods: {
        gotoEarningsPage() {
            this.$router.replace('/me/earnings');
        }
    },

    mounted() {
        if (this.delete_date) this.delete_date = this.delete_date.replace(' ', 'T');
        const tt = new Date(this.delete_date);
        this.datetime = moment(tt.toString()).format('MM/DD/YYYY HH:mm:ss') + ', ' + moment.tz.guess();

        this.$ajax('/api/tipalti/check', {
            method: 'get',
            withCredentials: true
        }).then(res => {
            this.have_fill_in_tax_form = res.data.data.have_fill_in_tax_form;
            this.have_payment_info = res.data.data.have_payment_info;
        });
    },
}
</script>

<style lang="scss" scopped>
    @import '../resources/sass/_variables.scss';
    
    .bmodal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10008;
        transition: opacity .3s ease;
        
        &--wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            overflow: auto;
            align-items: center;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;       
        }

        &--container {
            position: relative;
            z-index: 9;
            background: var(--Surface-warn);
            margin: 8px auto;
            border-radius: 16px;
            overflow: hidden;
            min-width: 260px;
            width: calc(100% - 32px);
            max-width: 32rem;
            box-shadow: var(--pop-medium);
            transition: all .3s ease;
            padding: var(--spacing-06);


            &.deactivate-alert {
                background: var(--Surface-danger);
                color: var(--Text-primary);
                
                svg {
                    width: 58px;
                    height: 58px;
                    margin: 4px auto 24px;
                    fill: var(--Text-danger);
                    display: block;
                }

                h4 {
                    color: var(--Text-primary);
                    font: var(--sub-3);
                    margin-bottom: 16px;
                }

                p {
                    font: var(--text-md-light);
                    color: var(--Text-primary);

                    time {
                        font: var(--text-md-regular);
                    }
                }
            }
            .cashout-note {
                display: flex;
                align-items: flex-start;
                margin: 16px auto;
                svg {
                    height: 16px;
                    width: 16px;
                    min-width: 16px;
                    margin-right: 8px;
                }

                p {
                    color: var(--Text-danger);
                    font: var(--text-s-regular);

                    a {
                        color: var(--Text-link);
                    }
                }

            }

            
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(var(--Black-100-rgba), .8);
        }



        &--header {
            padding: 0 16px;
            // border-bottom: 1px solid var(--Black-20);
        }

        &--title {
            font: var(--sub-4);
            color: var(--Text-primary);
            margin: 0;
            padding: 5px 0;
            text-align: center;
            text-transform: uppercase;
        }

        &--body {
            font: var(--sub-5);
            color: var(--Text-default);
        }

        &--footer {
            border-top: none;

            h5 {
                display: flex;
                align-items: center;
                justify-content: center;
                color:var(--Black-75);
                font: var(--sub-6);

                svg {
                    height: 16px;
                    width: 16px;
                    fill: var(--Text-secondary);
                }
            }
            .m-button {
                margin: 8px 0;
                display: flex;
            }
        }

    }

    .earnings-header {
        font: var(--text-s-light);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        a {
            color: var(--Text-link);

        }
    }

    .balance-label {
        font: var(--sub-3);
        color: var(--Text-secondary);
    }

    .amount {
        font: var(--heading-0);
        font-weight: var(--font-medium);
        color: var(--Text-primary);
    }

    .button-row {
        margin: 16px auto 0;
        width: 100%;

        .m-button {
            margin: 8px 0;
            display: flex;
        }
    }

    
    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal--container,
    .modal-leave-active .modal--container {
        transform: scale(1.1);
    }
</style>
