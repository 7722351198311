/* eslint-disable */
export default function (link = '') {            
    let youtubeID = '';
    
    link = link.trim();
    if (link === '') {
        return '';
    }


    var url = new URL(link);       

    if (url.hostname.includes("youtu.be")) {
        var paths = url.pathname;
        paths = paths.split("/");
        youtubeID = paths[1];
    } else if (url.hostname.includes("youtube.com") && url.pathname.includes("/embed")) {
        var paths = url.pathname;
        paths = paths.split("/");
        youtubeID = paths[2];
    } else if (url.hostname.includes("youtube.com") && url.pathname.includes("/watch")) {
        youtubeID = url.searchParams.get("v");
    } else {
        return "";
    }

    // console.log(youtubeID)


    if(youtubeID == null || youtubeID == '') {
        return "";
    }

    return "https://www.youtube.com/embed/" + youtubeID;
}