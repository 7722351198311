/* eslint-disable */
function FBTracking(eventName, eventParams, eventID) {
    if (typeof fbq === 'function') {

        if (arguments.length == 2) {
            
            fbq('track', eventName,
                // begin parameter object data
                eventParams
                // end parameter object data
            );
        }
        else {
            
            fbq('track', eventName,
                // begin parameter object data
                eventParams,
                // end parameter object data
                eventID
            );
        }
       
    }
}

function FBTrackingCustom(eventName, eventParams, eventID) {
    if (typeof fbq === 'function') {
        if (arguments.length == 1) {
            
            fbq('trackCustom', eventName);
        }
        else {
            
            fbq('trackCustom', eventName, eventParams, eventID);
        }
        
    }
}

export {FBTracking, FBTrackingCustom};