<template>
    <div :id="`m-select-${select_item_id}`" class="m-select" @click="open" :key="key_refrash"  :class="[{'open' : is_open}, 's-' + select_item_id]">
        <i v-if="had_icon" class="input-icon"><svg-icon :name="had_icon" /></i>
        <input :class="[had_icon && 'input-had-icon']" type="text" :name="name" :id="name" :placeholder="default_text" @blur="blur"
            v-model="had_text" :disabled="is_disabled" autocomplete="off" />
        <div class="selected-btn">
            <svg-icon name="arrow-select" />
        </div>

        <ul :id="`m-dropdown-${select_item_id}`" :class="[handle_drop_position && 'up']" v-if="is_open" ref="dropdown">
            <li v-for="(item, index) in datas" :key="index" @mousedown="selected(index)"
                :class="{ 'selected': index === selected_index }">
                <i v-if="item.icon"><svg-icon :name="item.icon" /></i>
                {{ item.text }}
            </li>
        </ul>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'm-select',

    props: {
        name: {
            type: String,
            default: '',
        },

        input_value: {
            type: [String, Object, Number],
            default: {},
        },

        input_index: {
            type: Number,
            default: null,
        },

        error: {
            type: Boolean,
            default: false,
        },


        datas: {
            type: Array,
            default: function () {
                return [
                    { text: '111' },
                    { text: '222' }
                ];
            },
        },

        default_text: {
            type: String,
            default: '---Select---',
        },

        is_disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            is_open: false,
            selected_index: -1,
            selected_object: {},
            select_item_id: uuidv4(),
            handle_drop_position: false,
            key_refrash: 0,
        }
    },

    computed: {
        had_icon() {
            return this.selected_object && this.selected_object.icon ? this.selected_object.icon : false;
        },
        had_text() {
            return this.selected_object && this.selected_object.text ? this.selected_object.text : '';
        }
    },

    watch: {
        is_open(newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.adjustDropdownPosition();
                });
                window.addEventListener('resize', this.adjustDropdownPosition);
            } else {
                window.removeEventListener('resize', this.adjustDropdownPosition);
            }
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.adjustDropdownPosition);
        window.removeEventListener('click', this.closeDropdown);
    },

    methods: {
        adjustDropdownPosition() {
            const dropdown = this.$refs.dropdown;
            if (!dropdown) return;

            const dropdownRect = dropdown.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Check if there is enough space below the dropdown
            const spaceBelow = windowHeight - dropdownRect.bottom;

            if (spaceBelow < 357) {
                this.handle_drop_position = true;
            } else {
                this.handle_drop_position = false;
            }
        },



        open() {
            if (!this.is_open) {
                let myInput = document.getElementById(`m-select-${this.select_item_id}`);
                myInput.addEventListener('focus', function () { });
                this.is_open = true;
                this.$emit('handleFocus', true);
            } else {
                this.is_open = false;
                this.$emit('handleFocus', false);
            }

            this.$emit('focus', false);
        },

        blur(e) {
            this.is_open = false;
            this.$emit('handleFocus', false);
            this.$emit('blur', false);
        },

        selected(index) {

            if (index !== this.selected_index) {
                this.add_item = '';
                this.selected_index = index;
                this.selected_object = this.datas[index];
                this.$emit('changeSelect', this.datas[this.selected_index]);
                this.$emit('selected', this.datas[this.selected_index])
                //this.$emit('selected', this.selected_index, this.data[this.selected_index]);
            }
        },

        start() {
            let obj = JSON.parse(JSON.stringify(this.input_value));

            if (this.datas.length > 0 && obj && obj.text) {
                this.selected_object = obj;
            }

            else if (this.datas.length > 0 && (typeof obj === 'string' || typeof obj === 'number')) {
                this.selected_index = this.datas.findIndex(e => {
                    return String(e.text) == String(obj);
                });
                if(this.selected_index ==-1){
                    this.selected_index = this.datas.findIndex(e => {
                        return String(e.value) == String(obj);
                    });
                }
                this.selected_object = this.datas[this.selected_index];

            }

            else if (this.input_index !== null) {
                this.selected_index = this.input_index;
            };
            // this.key_refrash += 1;
        },
        
        closeDropdown(e) {
            if (e.target.closest(`#m-select-${this.select_item_id}`) === null) {
                this.is_open = false;
            }
        },
    },

    mounted() {
        this.start();

        window.addEventListener('click', this.closeDropdown);
    },


}
</script>

<style lang="scss">
.m-select {
    position: relative;

    input {
        cursor: pointer;
        caret-color: transparent;
        padding: 0 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-radius: var(--radius-minimal);
        font: var(--text-md-light);
        width: 100%;
        color: var(--Text-default);
        border: solid 1px var(--Border-default);
        background-color: var(--Surface-light);

        &:hover {
            color: var(--Text-secondary);
            border: solid 1px var(--Border-neu);
            background-color: var(--Surface-light);
        }
    }

    .input-had-icon {
        padding-left: 40px;
    }

    $alert_w: 16px;

    .selected-btn {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translate(0%, -50%);
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--Text-default);



    }


    &.error {
        input {
            color: var(--Text-danger);
            border: solid 1px var(--Red-30);
            background-color: var(--Surface-light);
        }
    }

    &.open {
        input {
            outline: none;
            color: var(--Text-secondary);
            border: solid 1px var(--Border-primary);
            background-color: var(--Surface-light);
        }

        .selected-btn {
            transform: translate(0%, -50%) rotate(180deg);
        }
    }

    .input-icon {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 8px;
        transform: translate(0%, -50%);

        .svg-icon {
            width: 100%;
            height: 100%;
        }
    }

    ul {
        position: absolute;
        //top: 100%;
        left: -1px;
        right: -1px;
        background: var(--Bg-light);
        box-sizing: border-box;
        border: 1px solid var(--Black-20);
        border-top: none;
        padding-bottom: 10px;
        border-radius: 0 0 2px 2px;
        overflow: scroll;
        max-height: 356px;
        z-index: 9;
        padding: var(--spacing-03);
        display: unset;
        cursor: pointer;

        li {
            padding: var(--spacing-03);
            color: var(--Text-primary);
            margin: auto;
            display: flex;
            align-items: center;
            border-radius: var(--radius-minimal);

            .svg-icon {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            &:hover {
                background: var(--Black-10);
            }

        }
    }

    ul.up {
        bottom: 100%;
        border-radius: 2px 2px 0px 0px;
        border-top: 1px solid var(--Black-20);
        border-bottom: none;
    }

}
</style>
