const Genders = [
    { text: 'Woman', value: 'female', },
    { text: 'Man', value: 'male', },
    { text: 'Non-Binary', value: 'non_binary', },
    { text: 'Other', value: 'other', }
];

const LikeBoardColors = [
    { id: 255, color: "var(--lb-default)" },
    { id: 0, color: "var(--lb-1)" },
    { id: 1, color: "var(--lb-2)" },
    { id: 2, color: "var(--lb-3)" },
    { id: 3, color: "var(--lb-4)" },
    { id: 4, color: "var(--lb-5)" },
    { id: 5, color: "var(--lb-6)" },
    { id: 6, color: "var(--lb-7)" },
    { id: 7, color: "var(--lb-8)" },
    { id: 8, color: "var(--lb-9)" },
    { id: 9, color: "var(--lb-10)" },
    { id: 10, color: "var(--lb-11)" },
    { id: 11, color: "var(--lb-12)" },
    { id: 12, color: "var(--lb-13)" },
    { id: 13, color: "var(--lb-14)" },
];

const Privacylist = [
    {
        icon: 'private3',
        text: 'Private',
        id: 0
    },
    {
        icon: 'views',
        text: 'Public',
        id: 1
    }
]

export {
    Genders,
    LikeBoardColors,
    Privacylist
};


