<template>
    <div>
        <div class="likeboard-info" ref="share">
            <div class="likeboard-info--wrapper">
                <div class="likeboard-info--container">
                    <header class="likeboard-info--header">
                        <h2 class="likeboard-info--title">{{ info_type === 0 ? 'Create New Collection' : 'Edit Collection info' }}</h2>
                        <svg-icon class="likeboard-info--close" name="close" @click.native="close(true)"/>
                    </header>

                    <div class="likeboard-info--body" :key="key_refrash">
                        <div class="row">
                            <InputForm :placeholder="'Type Collection name'" :label="'Collection name'" :is_need="true"
                                :field_name="'name'" :input_value="name" :char_limit="60" @changeInput="changeInput" />
                            <p class="name-count">{{ name ? name.length: 0 }}/60</p>
                        </div>

                        <div class="row">
                            <InputForm  :type="'textarea'" :placeholder="'Tell us something about your collection…'"
                                :label="'Description (optional)'" :field_name="'description'" :input_value="description"
                                :char_limit="99" @changeInput="changeInput"/>
                        </div>

                        <div class="row">
                            <label>Color</label>
                            <LikeboardsColorList :active_color_id="color_id" @changeColor="changeColor"/>
                        </div>

                        <hr class="row"/>

                        <div class="row row-flex" >
                            <label>Privacy</label>
                            <InputForm :key="is_public" :type="'select'" :field_name="'is_public'" :input_value="privacy_list[is_public]"
                                :datas="privacy_list"  @changeSelect="changeSelect"  />
                        </div>
                    </div>

                    <footer class="likeboard-info--footer">
                        <m-button size="40" v-if="show_next" @click.native="next" :key="name + '1'" :is_disabled="name === ''">Next</m-button>
                        <m-button size="40" v-else @click.native="apply" :key="name" :is_disabled="name === ''">{{ info_type === 0 ? 'Create' : 'Save' }}</m-button>
                    </footer>
                </div>
                <div class="likeboard-info--bg" @click="close(false)"></div>
            </div>
        </div>
    </div>
</template>

<script>
import InputForm from '@/components/ui/Input/InputForm.vue';
import LikeboardsColorList from '@/components/likeboards/LikeboardsColorList.vue';
import { LikeBoardColors,Privacylist } from "@/utils/OrginList";

export default {
    name: 'LikeboardInfoPopUp',

    data() {
        return {
            info_type: 0,
            name: '',
            description: '',
            color_id: LikeBoardColors[0].id,
            is_public: 0,
            privacy_list: Privacylist,
            colors: LikeBoardColors,
            key_refrash:0,
        };
    },

    components: {
        InputForm,
        LikeboardsColorList,
    },

    props: {
        likeboard: Object,
        show_next: {
            type: Boolean,
            default: false,
        },
    },
    
    watch: {
    },

    methods: {
        changeInput(val) {
            this[val.field]= val.value;
        },

        changeSelect(val){
            this.is_public=val.value.id;
        },

        open(info_type) {
            this.info_type = info_type;

            this.$refs.share.classList.add('open');

            setTimeout(() => {
                this.$refs.share.classList.add('open-bg');
            }, 10);

            setTimeout(() => {
                this.$refs.share.classList.add('open-container');
            }, 150);
        },

        clean(){
            this.name='';
            this.description='';
            this.color_id=LikeBoardColors[0].id;
            this.is_public=0;
            this.key_refrash+=1;
        },

        close(is_click_button_close) {
            const self = this;
            console.log('close')

            if (self.$refs.share) self.$refs.share.classList.remove('open-container');

            setTimeout(() => {
                if (self.$refs.share) self.$refs.share.classList.remove('open-bg');
            }, 200);

            setTimeout(() => {
                if (self.$refs.share){
                    self.$refs.share.classList.remove('open');
                } 
                
                self.$emit('closeCallback');

                if (is_click_button_close) {
                    self.$emit('callback');
                }
                self.clean();
            }, 300);
        },

        changeColor(inx) {
            this.color_id = inx;
        },

        apply() {
            if (this.name === '') return;
            this.close();
            if (this.info_type === 0) {
                let form_data = new FormData();
                form_data.append('name', this.name);
                form_data.append('description', this.description);
                form_data.append('is_public', (this.is_public) ? 1 : 0);
                form_data.append('is_default', 0);
                form_data.append('color', 'v2');
                form_data.append('color_id', this.color_id);

                this.$ajax('/api/likeboards', {
                    method: 'post',
                    data: form_data,
                    withCredentials: true,
                }).then(res => {
                    res.data.data.layout = '4-6';
                    res.data.data.is_public = Number(res.data.data.is_public);
                    res.data.data.created_default = 0;
                    // Removed all cache
                    this.$ajax.cache.store = {};

                    // self.likeboards.unshift(res.data.data);
                    // self.$emit('created', res.data.data);
                    this.close();
                    this.$emit('createLikeboard', res.data.data);

                }).catch(err => {
                    alert('The name has already been taken.');
                });
            }
        },

        next() {
            if (this.name === '') return;
            this.close();
            if (this.info_type === 0) {
                let form_data = new FormData();
                form_data.append('name', this.name);
                form_data.append('description', this.description);
                form_data.append('is_public', (this.is_public) ? 1 : 0);
                form_data.append('is_default', 0);
                form_data.append('color', 'v2');
                form_data.append('color_id', this.color_id);

                this.$ajax('/api/likeboards', {
                    method: 'post',
                    data: form_data,
                    withCredentials: true,
                }).then(res => {
                    res.data.data.layout = '4-6';
                    res.data.data.is_public = Number(res.data.data.is_public);
                    res.data.data.created_default = 0;
                    // Removed all cache
                    this.$ajax.cache.store = {};

                    this.$router.push('/me/likeboards/' + res.data.data.id + '/add-product');

                }).catch(err => {
                    alert('The name has already been taken.');
                });
            }
        },
    },
};
</script>

<style lang="scss">
    @import '../resources/sass/_variables.scss';

    @mixin maxWidth(){
        max-width: 580px;
        width: 100%;
    }

    @mixin flexCenter(){
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .likeboard-info {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index:9999999;
        display: none;

        &--wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &--container {
            position: absolute;
            z-index: 9;
            overflow: hidden;
            left: 50%;
            width: 100%;
            max-width: var(--modal-max-width);
            box-shadow: var(--pop-medium);
            bottom: 0;
            transition: transform .3s ease;
            transform: translate3d(-50%, 100%, 0);
            background: var(--Bg-light);
            border-radius:  16px 16px 0 0;
        }

        &--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(var(--Black-100-rgba), .8);
            opacity: 0;
            transition: opacity .2s ease;
        }

        &--header {
            padding: 0 16px;
            min-height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--title {
            font: var(--sub-2);
            position: relative;
            background: var(--Bg-light);
            color: var(--Text-primary);
            overflow: hidden;
            text-transform: uppercase;
        }

        &--close {
            height: 32px;
            width: 32px;
            color: var(--Text-invert);
            cursor: pointer;
            position: relative;
            right: -.8rem;
            fill: var(--Text-default);

            &:hover{
              fill: var(--Brand-Primary);
            }
        }

        &--body {
            color: var(--Text-primary);
            background: var(--Bg-01);
            max-height: 70vh;
            overflow: auto;
            padding: var(--spacing-05);

            .row {
                //@include maxWidth;
                margin: auto;
                margin-bottom: var(--spacing-05);

                label{
                    font: var(--text-md-regular);
                    color: var(--Text-secondary);
                }

                .name-count {
                    text-align: right;
                    font: var(--text-s-light);
                }

            }

            .row-flex{
                @include flexCenter;
                justify-content: space-between;
                
                ul .svg-icon,.input-icon .svg-icon{
                    fill:var(--Text-default);
                }
            }

            .row-end{
                @include flexCenter;
                justify-content: flex-end;

                .m-button.tertiary{
                    color:var(--Text-danger);
                }
            }
        
            
        }

        &--footer {
            padding: var(--spacing-05);
            border-top: 1px solid var(--Black-20);
            display: flex;

            .m-button {
                flex: 1;
            }
        }

        &.open {
            display: block;
        }

        &.open-container &--container {
            transform: translate3d(-50%, 0%, 0);
        }

        &.open-bg &--bg {
            opacity: 1;
        }
    }

    @media (min-width: $mobile_size) {
        .likeboard-info {

            &--container {
                opacity: 0;
                transition: opacity .2s ease;
                transform: translate3d(-50%, -50%, 0);
                border-radius:  16px;
                right: auto !important;
                bottom: auto !important;
                left: 50%;
                top: 50%;
            }

            &--header {
                display: flex;
                align-items: center;
            }

            &.open-container &--container {
                transform: translate3d(-50%, -50%, 0);
            }

            &.open-bg &--container,
            &.open-bg &--bg {
                opacity: 1;
            }
        }
    }
</style>
